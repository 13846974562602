import React, { FC, useContext } from "react";
import { Button, Heading } from "@components/ui";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import Badge from "@components/common/Badge/Badge";
import { ReactComponent as DiscountIcon } from "@icons/discount-colored.svg";
import { ReactComponent as CalendarIcon } from "@icons/calendar-colored.svg";
import { IProjectDetailInfoBlock } from "@components/investor/ProjectDetail/ProjectDetails.props";
import classNames from "classnames";
import styles from "@components/investor/ProjectDetail/projectDetail.module.scss";
import { ReactComponent as LocationIcon } from "@icons/location-small-colored.svg";
import { ReactComponent as ActivityIcon } from "@icons/activity-small-colored.svg";
import { ReactComponent as WalletIcon } from "@icons/wallet-small-colored.svg";
import { ReactComponent as ShieldIcon } from "@icons/shield-small-colored.svg";
import ProfileSection from "@components/profile/Section/ProfileSection";
import FundraisingProgress from "@components/common/FundraisingProgress/FundraisingProgress";
import moment from "moment";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { profileContext } from "@context/profileContext";
import ProjectDetailBox from "@components/investor/ProjectDetail/ProjectDetailBox";
import { convertRate, declinationOfNum } from "@common/utils";
import InvestedBox from "@components/profile/reused/InvestedBox/InvestedBox";
import ProjectExpiredMessage from "@components/profile/reused/ExpiredMessage/ProjectExpiredMessage";
import { ReactComponent as CoinIcon } from "@icons/coin.svg";
import { ReactComponent as TimeIcon } from "@icons/time-circle-colored.svg";
import { defineProjectMedia } from "@components/investor/utils";
import projectStyles from "@components/investor/Project/project.module.scss";
import {
	ADAPTIVE_BREAKPOINTS,
	MONTHS_WORDS,
	RGB_COLORS,
} from "@common/constants";
import { IProjectDetails } from "@features/projects/types";
import { investContext, InvestmentPlaceEnum } from "@context/investContext";
import useWindowSize from "@hooks/useWindowSize";
import InvestorInvestmentButton from "@components/investor/reused/InvestmentButton";
import { BorrowerProjectStatusEnum } from "@features/borrower/types";
import { useProjectExpired } from "@hooks/useProjectExpired";
import { useNumAmount } from "@hooks/useNumAmount";

const ProjectDetailAbout: FC<{ details: IProjectDetails }> = ({ details }) => {
	const {
		title,
		rate,
		term,
		description,
		objectVideo,
		objectImage,
		goalAmount,
		goalCurrentAmount,
		goalEndDate,
		actualObjectAddress,
		investGoal,
		monetizeWay,
		returnGuarantee,
		investAmount,
		alreadyInvested,
		profitExpected,
		projectId,
		status,
	} = details;

	const infoBlocks: IProjectDetailInfoBlock[] = [
		{
			title: "Адрес объекта",
			Icon: LocationIcon,
			data: actualObjectAddress,
		},
		{
			title: "Цель получения инвестиций",
			Icon: ActivityIcon,
			data: investGoal,
		},
		{
			title: "Способ монетизации",
			Icon: WalletIcon,
			data: monetizeWay,
		},
		{
			title: "Гарантия возврата/залог",
			Icon: ShieldIcon,
			data: returnGuarantee,
		},
	];

	const { setModalVideoSrc, setVideoModal } = useContext(profileContext),
		{
			setCancelModal,
			setCurrentProjectId,
			setCurrentProjectTitle,
			setInvestmentPlace,
		} = useContext(investContext),
		{ width: ww } = useWindowSize(),
		expired = useProjectExpired(status),
		numInvestAmount = useNumAmount(investAmount);
	const buttonsRow = (className?: string): JSX.Element => {
		return !expired &&
			(numInvestAmount > 0 || Math.floor(numInvestAmount) === 0) ? (
			<div
				className={classNames(
					"row gx-3 mt-5 pt-sm-4 justify-content-end justify-content-lg-start",
					className,
				)}>
				{status !== BorrowerProjectStatusEnum.LOAN_COLLECTED_MIN &&
					status === BorrowerProjectStatusEnum.COLLECTION &&
					numInvestAmount > 0 && (
						<div className='col-auto'>
							<Button
								as={ButtonTypesEnum.BUTTON}
								variant={ButtonVariant.outlined}
								onClick={() => {
									setCancelModal(true);
									setCurrentProjectId(projectId);
									setCurrentProjectTitle(title);
								}}>
								Отменить
							</Button>
						</div>
					)}

				<InvestorInvestmentButton
					status={status}
					onClick={() => {
						setInvestmentPlace(InvestmentPlaceEnum.DETAILED);
					}}
					wrapperClassName={"col-auto"}
					projectId={projectId}
					investAmount={investAmount}
				/>
			</div>
		) : (
			<></>
		);
	};

	const termDesc = declinationOfNum(term, MONTHS_WORDS);
	return (
		<div>
			<div className='row align-items-xxl-center pb-3 d-none d-sm-flex'>
				<div className='col-md-6'>
					<Heading
						tag={"h3"}
						variant={HeadingVariant.large}
						className={classNames(
							{ "text-uppercase": ww >= ADAPTIVE_BREAKPOINTS.xxl },
							"mb-0 pt-2 pt-xxl-0",
						)}>
						{title}
					</Heading>
				</div>
				<div className='col-6 d-none d-md-block'>
					<div className='row justify-content-end g-2 g-md-3'>
						<div className='col-auto'>
							<Badge
								content={
									<>
										Ставка: <strong>{convertRate(rate)}</strong>
									</>
								}
								icon={<DiscountIcon />}
								variant={"success"}
								size={"md"}
							/>
						</div>
						<div className='col-auto'>
							<Badge
								content={
									<>
										Срок:{" "}
										<strong>
											{term}&nbsp;{termDesc}
										</strong>
									</>
								}
								icon={<CalendarIcon />}
								variant={"info"}
								size={"md"}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className='row mb-4 mb-sm-5 pt-sm-3 pt-md-0'>
				<div className='col-md-6'>
					<p className={classNames(styles.description, "text-dark mb-0")}>
						{description}
					</p>
				</div>
			</div>
			<div className='row mb-4 mb-sm-5 g-3'>
				{infoBlocks.map((block) => {
					return block.data ? (
						<div
							key={block.title}
							className='col-sm-6 col-lg-4 col-xl-6 col-xxl-4 col-lt-3'>
							<ProjectDetailBox {...block} />
						</div>
					) : null;
				})}
			</div>
			<div className='row pt-md-5 g-3 g-lg-5'>
				<div className='order-2 order-md-1 col-md-5 me-auto pt-4 pt-md-0 d-none d-sm-block'>
					<ProfileSection title={"Об объекте"} hideTitle={"md"}>
						{defineProjectMedia({
							video: objectVideo,
							image: objectImage,
							videoClassName: styles.videoPlayer,
							placeholderClassName: projectStyles.mediaPlaceholder,
							imageClassName: projectStyles.mediaPlaceholder,
							onVideoPlay: (video) => {
								setVideoModal(true);
								setModalVideoSrc(video.url);
							},
						})}
						{buttonsRow("d-md-none")}
					</ProfileSection>
				</div>
				<div className='order-1 order-md-2 col-md-7 col-hd-6'>
					{expired || status === BorrowerProjectStatusEnum.REQUEST_CANCELLED ? (
						<ProjectExpiredMessage
							alreadyInvested={alreadyInvested}
							status={status}
							className={"text-xl pt-md-5 mt-sm-4"}
						/>
					) : (
						<ProfileSection title={"Собрано"} hideTitle={"sm"}>
							<FundraisingProgress
								className={"pb-4 pt-md-4"}
								total={goalAmount || 0}
								current={goalCurrentAmount || 0}
							/>
							{Boolean(goalEndDate) && moment(goalEndDate).isValid() && (
								<p className={classNames(styles.goalEnd, "mb-0 text-dark")}>
									{status === BorrowerProjectStatusEnum.COLLECTED ||
									status === BorrowerProjectStatusEnum.PAID ||
									status === BorrowerProjectStatusEnum.ISSUED ||
									status === BorrowerProjectStatusEnum.COLLECTION_FINISH
										? "Сбор завершен"
										: "Сбор до " + moment(goalEndDate).format("L")}
								</p>
							)}

							<div className='row g-2 pt-4 d-md-none'>
								<div className='col-auto'>
									<Badge
										content={
											<>
												Ставка: <strong>{convertRate(rate)}</strong>
											</>
										}
										icon={<DiscountIcon />}
										variant={"success"}
										size={"md"}
									/>
								</div>
								<div className='col-auto'>
									<Badge
										content={
											<>
												Срок:{" "}
												<strong>
													{term}&nbsp;{termDesc}
												</strong>
											</>
										}
										icon={<CalendarIcon />}
										variant={"info"}
										size={"md"}
									/>
								</div>
							</div>

							<div className='row mt-2 mt-md-4 pt-md-4 g-4'>
								{numInvestAmount > 0 && (
									<div className={"col-xxl-6 col-xxl-6"}>
										<InvestedBox
											amount={investAmount}
											iconSize={"md"}
											bgColor={"pear"}
											iconBg={RGB_COLORS.pear}
											className={"d-flex h-100"}
											icon={CoinIcon}
											label={"Инвестировано:"}
										/>
									</div>
								)}

								{/* {
										parseFloat(profitExpected) > 0 && <div className={'col-xxl-6 col-xxl-6'}><InvestedBox
											amount={profitExpected}
											iconSize={'md'}
											bgColor={'turquoise'}
											iconBg={RGB_COLORS.turquoise}
											className={'d-flex h-100'}
											icon={TimeIcon}
											label={'Ожидается'}
											tooltipInfo={'Сумма, которую вернет вам заемщик с учетом процентов'}
										/></div>
									} */}
							</div>

							<div className='d-sm-none pt-4'>
								{defineProjectMedia({
									video: objectVideo,
									image: objectImage,
									videoClassName: styles.videoPlayer,
									placeholderClassName: projectStyles.mediaPlaceholder,
									imageClassName: projectStyles.mediaPlaceholder,
									onVideoPlay: (video) => {
										setVideoModal(true);
										setModalVideoSrc(video.url);
									},
								})}
							</div>
							{buttonsRow("d-sm-none d-md-flex")}
						</ProfileSection>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProjectDetailAbout;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';

import { API_USER_ANALYTIC_INFO_URL, STORAGE_TOKEN_KEY } from '@common/constants';

export const fetchAnalyticsInfo = createAsyncThunk(
    'GET_ANALYTIC_INFO',
    async (_, { rejectWithValue }) => {
        try {

            const url = process.env.REACT_APP_API_ROOT_URL + API_USER_ANALYTIC_INFO_URL;
            const token = localStorage.getItem(STORAGE_TOKEN_KEY);
            const response: AxiosResponse = await axios.get(
                url,
                {

                    headers: {
                        Accept: 'application/json; odata=verbose',
                        Authorization: `Bearer ${token}`
                    },
                    withCredentials: true,
                }
            );

            return response.data;
        } catch (error) {
            return rejectWithValue(error);
        }
    },
);
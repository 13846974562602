import React, {FC, useContext, useState} from 'react';
import {profileContext} from '@context/profileContext';
import styles from './profileTabs.module.scss'
import classNames from 'classnames';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import {Button} from '@components/ui';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {ConditionalWrapper} from '@components/common/ConditionalWrapper';
import {useDetectClickOutside} from 'react-detect-click-outside/dist';

const ProfileTabsControl:FC = () => {
	const
		{tabs, activeTab, setActiveTabKey} = useContext(profileContext),
		{width: ww} = useWindowSize(),
		[selectShown, setSelectShown] = useState<boolean>(false),
		selectRef = useDetectClickOutside({onTriggered() {setSelectShown(false)}})
	;

	return (
		<div className={classNames('tabs__controls', styles.controls)}>

			<ConditionalWrapper
				condition={ww < ADAPTIVE_BREAKPOINTS.xl}
				wrapper={children => (
					<div className={styles.select} ref={selectRef}>
						{
							activeTab && <Button
								variant={ButtonVariant.outlined}
								as={ButtonTypesEnum.BUTTON}
								icon={ButtonIconsVariant.arrowUp}
								className={classNames(styles.selectButton, {'is-active': selectShown})}
								onClick={() => setSelectShown(!selectShown)}
							>
								{activeTab?.name}
							</Button>
						}
						<div className={classNames(styles.selectList, {'is-shown': selectShown})}>
							{children}
						</div>

					</div>
				)}
			>
				<ul className={'d-flex flex-column flex-xl-row flex-xl-nowrap mb-0'}>
					{
						tabs.map(tab => (
							<li
								className={classNames(styles.control, {'is-active': tab.isActive})}
								key={tab.key}
								onClick={() => {
									setSelectShown(false);
									setActiveTabKey(tab.key)
								}}
							>
								<span className={styles.content}>{tab.name}</span>
							</li>
						))
					}
				</ul>
			</ConditionalWrapper>
		</div>
	);
};

export default ProfileTabsControl;

import React, {createContext, Dispatch, FC, SetStateAction, useState} from 'react';
import {IAttachment, IQualificationAttachment, QualificationAttachmentTypeEnum} from '@common/types';
import utilsApi from '@api/utilsApi';
import {fileListToFormData} from '@common/utils';

export interface IQualificationContext {
	documents: {
		[QualificationAttachmentTypeEnum.SECURITY_DOC]: IAttachment[]
		[QualificationAttachmentTypeEnum.EMPLOYMENT_DOC]: IAttachment[]
		[QualificationAttachmentTypeEnum.DEAL_DOC]: IAttachment[]
		[QualificationAttachmentTypeEnum.EDUCATION_DOC]: IAttachment[]
		[QualificationAttachmentTypeEnum.DEPOSIT_DOC]: IAttachment[]
	}

	appendAttachment: (event: React.ChangeEvent<HTMLInputElement>, field: QualificationAttachmentTypeEnum, onEnd: () => void) => void
	deleteAttachment: (field: QualificationAttachmentTypeEnum, uid: string) => void
	isUnqualified: boolean
	setIsUnqualified: Dispatch<SetStateAction<boolean>>

	changeConfirmModal: boolean
	setChangeConfirmModal: Dispatch<SetStateAction<boolean>>

	qualificationData: IQualificationAttachment[]
	setQualificationData: Dispatch<SetStateAction<IQualificationAttachment[]>>
}

export const qualificationContext = createContext<IQualificationContext>({} as IQualificationContext);


export const QualificationProvider: FC = ({children}) => {

	const
		[fileUtility] = utilsApi.useFileMutation(),

		[securityDocuments, setSecurityDocuments] = useState<IAttachment[]>([]),
		[employmentDocuments, setEmploymentDocuments] = useState<IAttachment[]>([]),
		[dealDocuments, setDealDocuments] = useState<IAttachment[]>([]),
		[educationDocuments, setEducationDocuments] = useState<IAttachment[]>([]),
		[depositDocuments, setDepositDocuments] = useState<IAttachment[]>([]),

		[isUnqualified, setIsUnqualified] = useState<boolean>(true),
		[changeConfirmModal, setChangeConfirmModal] = useState<boolean>(false),
		[qualificationData, setQualificationData] = useState<IQualificationAttachment[]>([])
	;

	const
		appendAttachment = async (event: React.ChangeEvent<HTMLInputElement>, field: QualificationAttachmentTypeEnum,  onEnd: () => void) => {
			if (event.target.files && event.target.files.length > 0) {

				const [fileFromApi] = await fileUtility({
					formData: fileListToFormData(event.target.files, 'qualification document'),
					isPrivate: true
				}).unwrap();

				switch (field) {
					case QualificationAttachmentTypeEnum.SECURITY_DOC:
						setSecurityDocuments([
							...securityDocuments,
							fileFromApi
						]);
						break;
					case QualificationAttachmentTypeEnum.EMPLOYMENT_DOC:
						setEmploymentDocuments([
							...employmentDocuments,
							fileFromApi
						]);
						break;
					case QualificationAttachmentTypeEnum.DEAL_DOC:
						setDealDocuments([
							...dealDocuments,
							fileFromApi
						]);
						break;
					case QualificationAttachmentTypeEnum.EDUCATION_DOC:
						setEducationDocuments([
							...educationDocuments,
							fileFromApi
						]);
						break;
					case QualificationAttachmentTypeEnum.DEPOSIT_DOC:
						setDepositDocuments([
							...depositDocuments,
							fileFromApi
						]);
						break;
				}

				onEnd()
			}
		},
		deleteAttachment = (field: QualificationAttachmentTypeEnum, uid: string) => {
			switch (field) {
				case QualificationAttachmentTypeEnum.SECURITY_DOC:
					setSecurityDocuments(securityDocuments.filter(d => d.uid !== uid));
					break;
				case QualificationAttachmentTypeEnum.EMPLOYMENT_DOC:
					setEmploymentDocuments(employmentDocuments.filter(d => d.uid !== uid));
					break;
				case QualificationAttachmentTypeEnum.DEAL_DOC:
					setDealDocuments(dealDocuments.filter(d => d.uid !== uid));
					break;
				case QualificationAttachmentTypeEnum.EDUCATION_DOC:
					setEducationDocuments(educationDocuments.filter(d => d.uid !== uid));
					break;
				case QualificationAttachmentTypeEnum.DEPOSIT_DOC:
					setDepositDocuments(depositDocuments.filter(d => d.uid !== uid));
					break;
			}
		}
	;

	const payload = {
		documents: {
			[QualificationAttachmentTypeEnum.SECURITY_DOC]: securityDocuments,
			[QualificationAttachmentTypeEnum.EMPLOYMENT_DOC]: employmentDocuments,
			[QualificationAttachmentTypeEnum.DEAL_DOC]: dealDocuments,
			[QualificationAttachmentTypeEnum.EDUCATION_DOC]: educationDocuments,
			[QualificationAttachmentTypeEnum.DEPOSIT_DOC]: depositDocuments
		},

		appendAttachment,
		deleteAttachment,
		isUnqualified,
		setIsUnqualified,
		changeConfirmModal,
		setChangeConfirmModal,
		qualificationData,
		setQualificationData
	};

	return (
		<qualificationContext.Provider value={payload}>
			{children}
		</qualificationContext.Provider>
	)
};

import React, {FC, useContext, useState} from 'react';
import {personalDataContext} from '@context/personalDataContext';
import {Modal} from 'react-bootstrap';
import RecoveryNote from '@components/common/RecoveryNote/RecoveryNote';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import userApi from '@api/userApi';
import {CODES_RESPONSE_SUCCESS} from '@common/constants';

const PersonalDataPhoneChangeRequest:FC = () => {
	const
		{
			phoneChangeRequestModal,
			setPhoneChangeRequestModal,
			setPhoneChangeConfirmModal,
			requestData
		} = useContext(personalDataContext),
		{phone} = requestData,
		[requestButtonDisabled, setRequestButtonDisabled] = useState<boolean>(false),
		[phoneChangeRequest, {isLoading: phoneChangeRequestIsLoading}] = userApi.usePhoneChangeRequestMutation()
	;

	const onPhoneChangeRequest = () => {
		setRequestButtonDisabled(true);

		phoneChangeRequest()
			.unwrap()
			.then(response => {
				if (response.code === CODES_RESPONSE_SUCCESS) {
					setPhoneChangeRequestModal(false);
					setPhoneChangeConfirmModal(true);
				}
			})
			.finally(() => {
				setRequestButtonDisabled(false)
			})
	};

	return (
		<Modal
			show={phoneChangeRequestModal}
			onHide={() => setPhoneChangeRequestModal(false)}
			centered
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				{
					phone
						? <>
							<RecoveryNote
								phone={phone}
								message={'Для изменения номера телефона мы отправим вам проверочный код в СМС на ваш новый номер'}
							/>
							<div className="text-center">
								<Button
									variant={ButtonVariant.contained}
									type={'button'}
									onClick={onPhoneChangeRequest}
									as={ButtonTypesEnum.BUTTON}
									isLoading={phoneChangeRequestIsLoading}
									disabled={requestButtonDisabled}
								>Получить код</Button>
							</div>
						</>
						: <p className={'mb-0'}>Что-то пошло не так :(</p>
				}
			</Modal.Body>
		</Modal>
	);
};

export default PersonalDataPhoneChangeRequest;

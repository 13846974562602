import { useEffect, useState } from 'react'
import {DEFAULT_DELAY} from '@common/constants';

function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || DEFAULT_DELAY);

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay]);

	return debouncedValue
}

export default useDebounce

import React, {FC, ReactElement} from 'react';
import styles from './profileSection.module.scss'
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import classNames from 'classnames';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';

interface IProfileSection {
	title?: string
	link?: ReactElement | null
	isLoading?: boolean
	isTableInside?: boolean
	hideTitle?: keyof typeof ADAPTIVE_BREAKPOINTS | false
	nearbyTitleContent?: JSX.Element
	limitMinHeight?: boolean
}

const ProfileSection:FC<IProfileSection & React.HTMLAttributes<HTMLElement>> = (
	{
		className,
		link,
		title,
		isLoading = false,
		isTableInside = false,
		hideTitle= false,
		nearbyTitleContent,
		limitMinHeight = true,
		...props
	}
) => {

	const {width: ww} = useWindowSize();

	return (
		<section className={
			classNames(
				styles.item,
				{
					'is-table-inside': isTableInside,
					'is-limit-min-height': limitMinHeight
				},
				className
			)
		} {...props}>
			{
				(link || nearbyTitleContent)
					? (
						<div className="row align-items-center justify-content-between mb-3 mb-sm-4 pb-md-3">
							{
								title && <div className="col-auto">
									<Heading
										tag={'h2'}
										className={classNames(styles.heading, 'mb-0', hideTitle && `d-none d-${hideTitle}-block`)}
										variant={HeadingVariant.large}
									>{title}</Heading>
								</div>
							}
							{
								(ww > ADAPTIVE_BREAKPOINTS.sm && Boolean(link)) && <div className='col-auto'>
									{link}
								</div>
							}
							{
								nearbyTitleContent && <div className='col-auto'>
									{nearbyTitleContent}
								</div>
							}
						</div>
					)
					: (
						title && <Heading
							tag={'h2'}
							className={classNames(styles.heading, 'mb-3 mb-sm-4 pb-md-3', hideTitle && `d-none d-${hideTitle}-block`)}
							variant={HeadingVariant.large}
						>{title}</Heading>
					)
			}

			{props.children}

			{
				(ww <= ADAPTIVE_BREAKPOINTS.sm && link) && <div
					style={{
						opacity: isLoading ? .5 : undefined,
						pointerEvents: isLoading ? 'none' : undefined
					}}
					className='text-end mt-3'>
					{link}
				</div>
			}
		</section>
	);
};

export default ProfileSection;

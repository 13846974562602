import React, {FC, useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ProfilePagesTitleEnum} from '@common/types';
import projectsApi from '@api/projectsApi';
import {useAppActions} from '@hooks/useAppActions';
import {Spinner} from 'react-bootstrap';
import styles from './projectDetail.module.scss';
import ProfileContainer from '@components/profile/Views/Container/Container';
import {IProfileTab, profileContext} from '@context/profileContext';
import ProjectDetailAbout from '@components/investor/ProjectDetail/tabs/ProjectDetailAbout';
import ProjectDetailBorrower from '@components/investor/ProjectDetail/tabs/ProjectDetailBorrower';
import ProjectDetailDocuments from '@components/investor/ProjectDetail/tabs/ProjectDetailDocuments';
import ProfileTabsContent from '@components/profile/reused/Tabs/ProfileTabsContent';
import {useCashedProject} from '@hooks/useCashedProject';
import {projectsContext} from '@context/projectsContext';
import {ParameterizedDocumentsEnum} from '@features/projects/types';
import borrowerApi from '@api/borrowerApi';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import useMounted from '@hooks/useMounted';

export enum ProjectDetailTabsKeysEnum {
	ABOUT = 'projectDetailAbout',
	BORROWER = 'projectDetailBorrower',
	DOCUMENTS = 'projectDetailDocuments'
}

const projectTabs: IProfileTab[] = [
	{
		name: 'О проекте',
		key: ProjectDetailTabsKeysEnum.ABOUT,
		isActive: true
	},
	{
		name: 'О заемщике',
		key: ProjectDetailTabsKeysEnum.BORROWER,
		isActive: false
	},
	{
		name: 'Документы',
		key: ProjectDetailTabsKeysEnum.DOCUMENTS,
		isActive: false
	}
];

const ProfileProjectDetail:FC = () => {

	const
		{id} = useParams<{ id: string }>(),
		routeId = Number(id),
		{setProjectDetails, addParameterizedDocument} = useAppActions(),
		detailCashed = useCashedProject(routeId),
		{setTabs} = useContext(profileContext),
		{isParameterizedDocumentCashed} = useContext(projectsContext)
	;

	const
		[
			getProjectById,
			{
				isLoading: projectDetailIsLoading,
				isError: projectDetailIsError
			}
		] = projectsApi.useGetByIdMutation(),
		[getOfferDocument] = borrowerApi.useInvestOfferDocumentMutation(),
		[getConditionsDocument] = projectsApi.useGetInvestorConditionsMutation(),
		[getAcceptDocument] = projectsApi.useGetAcceptMutation(),
		{width: ww} = useWindowSize(),
		[pageTitle, setPageTitle] = useState<string>('')
	;

	useMounted({
		onResolve(isMounted) {
			if (!detailCashed) {

				getProjectById(routeId)
					.unwrap()
					.then(response => {
						if (!isParameterizedDocumentCashed(routeId, ParameterizedDocumentsEnum.OFFER)) {

							(isMounted && response.bidId) && getOfferDocument(response.bidId)
								.unwrap()
								.then(document => {
									addParameterizedDocument({
										id: routeId, document,
										name: ParameterizedDocumentsEnum.OFFER
									})
								});
						}

						isMounted && setProjectDetails({key: routeId, value: response});
					});

				isMounted && getConditionsDocument(routeId)
					.unwrap()
					.then(document => {
						addParameterizedDocument({
							id: routeId, document,
							name: ParameterizedDocumentsEnum.CONDITIONS
						})
					});

				isMounted && getAcceptDocument({id: routeId})
					.unwrap()
					.then(document => {
						addParameterizedDocument({
							id: routeId, document,
							name: ParameterizedDocumentsEnum.ACCEPT
						})
					});
			}

			setTabs(projectTabs, 0);
		},
		onDestroy() {
			setTabs([]);
		}
	});

	useEffect(() => {
		if (ww < ADAPTIVE_BREAKPOINTS.xl && detailCashed?.title) {
			setPageTitle(detailCashed.title);
		} else {
			setPageTitle(ProfilePagesTitleEnum.PROJECT);
		}
	}, [ww, detailCashed]);

	return (
		<ProfileContainer title={pageTitle}>
			<section className={styles.card}>
				{
					projectDetailIsLoading
						? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
						: projectDetailIsError
						? <p>Не удалось загрузить</p>
						: detailCashed && <ProfileTabsContent
							items={{
								[ProjectDetailTabsKeysEnum.ABOUT]: <ProjectDetailAbout details={detailCashed}/>,
								[ProjectDetailTabsKeysEnum.BORROWER]: <ProjectDetailBorrower details={detailCashed}/>,
								[ProjectDetailTabsKeysEnum.DOCUMENTS]: <ProjectDetailDocuments details={detailCashed}/>
							}}
						/>
				}
			</section>
		</ProfileContainer>
	);
};

export default ProfileProjectDetail;

import React, {FC, useContext, useMemo} from 'react';
import ProfileProjectsList from '@components/investor/Views/Projects/ProjectsList';
import projectsApi from '@api/projectsApi';
import {useAppActions} from '@hooks/useAppActions';
import {projectsContext} from '@context/projectsContext';
import {useAppSelector} from '@hooks/useAppSelector';
import {usePagination} from '@hooks/usePagination';
import {searchWithParams} from '@common/utils';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import {Spinner} from 'react-bootstrap';
import useWindowSize from '@hooks/useWindowSize';
import ProfileLoader from '@components/common/ProfileLoader';
import useMounted from '@hooks/useMounted';
import ProfileProjectsHeader from '@components/investor/Views/Projects/Header/ProjectsHeader';
import ProfileProjectsFilter from '@components/investor/Views/Projects/Filter/Filter';

const ProfileProjectsUser:FC = () => {

	const
		{setProjects} = useAppActions(),
		{
			filters,
			orders,
			setSort
		} = useContext(projectsContext),
		{projects} = useAppSelector(state => state.projects),
		{
			setPaginationCount,
			setDefaultPaginationOffset,
			increasePaginationOffset,
			resetPaginationOffset,
			paginationOffset,
			showUploadMoreButton
		} = usePagination(projects),
		[
			searchUserProjects,
			{
				isLoading: projectsUserIsLoading,
				isError: projectsUserIsError
			}
		] = projectsApi.useSearchInvestedByUserMutation(),
		searchParams = useMemo(() => (
			searchWithParams({
				orders,
				filters,
				offset: paginationOffset
			})
		), [orders,	filters, paginationOffset]),
		{width: ww} = useWindowSize()
	;


	useMounted({
		onResolve(isMounted) {
			searchUserProjects(searchParams)
				.unwrap()
				.then((response) => {
					if (isMounted) {
						setProjects(response.items);
						setPaginationCount(response.count);
						setDefaultPaginationOffset();
					}
				});
		},
		onDestroy(){
			setProjects([]);
		},
		dependencies: [orders]
	});

	return (
		<>
			<ProfileProjectsHeader
				onSortChange={sortOption => {
					resetPaginationOffset();
					setSort(sortOption);
				}}
			/>
			{(projectsUserIsLoading && ww < ADAPTIVE_BREAKPOINTS.xl) && <Spinner className={'spinner--centered'} variant={'primary'} animation={'border'}/>}
			<ProfileProjectsList/>
			{(projects.length === 0 && !projectsUserIsLoading) && <p>Проекты не найдены</p>}
			<ProfileLoader
				isError={projectsUserIsError}
				isLoading={projectsUserIsLoading}
				errorMessage={'Ошибка при попытке загрузки списка проектов, в которые пользователь инвестировал средства'}
				onClick={
					() => {
						searchUserProjects(searchParams)
							.unwrap()
							.then(response => {
								setProjects([...projects, ...response.items]);
								setPaginationCount(response.count);
								increasePaginationOffset();
							})
					}
				}
				needToShown={showUploadMoreButton}
			/>
			<ProfileProjectsFilter
				onFilterSubmit={({count}) => {
					setDefaultPaginationOffset();
					setPaginationCount(count);
				}}
				onFilterReset={({count}) => {
					setDefaultPaginationOffset();
					setPaginationCount(count);
				}}
			/>
		</>
	);
};

export default ProfileProjectsUser;

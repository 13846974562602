import React, {FC, useContext, useState} from 'react';
import {Modal} from 'react-bootstrap';
import {personalDataContext} from '@context/personalDataContext';
import RecoveryNote from '@components/common/RecoveryNote/RecoveryNote';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {Button} from '@components/ui';
import userApi from '@api/userApi';
import {CODES_RESPONSE_SUCCESS} from '@common/constants';
import {useAppSelector} from '@hooks/useAppSelector';

const PersonalDataChangeRequest:FC = () => {
	const {
		dataChangeRequestModal,
		setDataChangeRequestModal,
		requestData,
		setDataChangeConfirmModal
	} = useContext(personalDataContext);

	const
		[
			profileChangeRequest,
			{isLoading: profileChangeRequestIsLoading}
		] = userApi.useProfileChangeRequestMutation();

	const [requestButtonDisabled, setRequestButtonDisabled] = useState<boolean>(false);
	const {phone} = useAppSelector(state => state.user.investorProfile);

	const onDataChangeRequest = () => {
		setRequestButtonDisabled(true);

		profileChangeRequest({body: requestData})
			.unwrap()
			.then(response => {
				if (response.code === CODES_RESPONSE_SUCCESS) {
					setDataChangeConfirmModal(true);
				}
			})
			.finally(() => {
				setDataChangeRequestModal(false);
				setRequestButtonDisabled(false);
			})
	};

	return (
		<Modal
			show={dataChangeRequestModal}
			onHide={() => setDataChangeRequestModal(false)}
			centered
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<RecoveryNote
					phone={phone}
					message={'Для сохранения данных мы отправим вам проверочный код в СМС на номер'}
				/>
				<div className="text-center">
					<Button
						variant={ButtonVariant.contained}
						type={'button'}
						onClick={onDataChangeRequest}
						as={ButtonTypesEnum.BUTTON}
						isLoading={profileChangeRequestIsLoading}
						disabled={requestButtonDisabled}
					>Получить код</Button>
				</div>
			</Modal.Body>
		</Modal>
	)
};

export default PersonalDataChangeRequest;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthRolesEnum, IAuthState } from './types';
// import { STORAGE_USER_ROLE_KEY } from '@common/constants';
// import { checkStorage } from '@common/utils';

/*
const defineUserRole = (): AuthRolesEnum => {
    const storageRole = localStorage.getItem(STORAGE_USER_ROLE_KEY);

    if (storageRole) return storageRole as AuthRolesEnum;

    return AuthRolesEnum.INVESTOR;
};
*/

const initialState: IAuthState = {
    isAuthorized: false,
    isLoading: false,
    // role: defineUserRole()
    role: AuthRolesEnum.INVESTOR
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthLoading(state, { payload }: PayloadAction<boolean>) {
            state.isLoading = payload;
        },
        setAuthorized(state, { payload }: PayloadAction<boolean>) {
            state.isAuthorized = payload;
        },
        setRole(state, { payload }: PayloadAction<AuthRolesEnum>) {
            state.role = payload;

            // checkStorage(STORAGE_USER_ROLE_KEY, payload);
        }
    }
});

export default authSlice.reducer;






import React, {DetailedHTMLProps, HTMLAttributes} from 'react';
import {NumberFormatValues} from 'react-number-format';
import {PropsValue} from 'react-select';
import {UseFormRegister} from 'react-hook-form/dist/types/form';
import {FieldValues} from 'react-hook-form';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';
import {IAttachment} from '@common/types';
import {ISuggestionSearchResult} from '@features/daData/types';

export enum FormGroupTypes {
	TEXT = 'text',
	SEARCH = 'search',
	NUMBER_FORMAT = 'number-format',
	RADIO = 'radio',
	CHECKBOX = 'checkbox',
	PHONE = 'phone',
	PASSWORD = 'password',
	EMAIL = 'email',
	DATE = 'date',
	FILE = 'file',
	SELECT = 'select',
	NUMBER = 'number',
	TEXTAREA = 'textarea',
	SUGGESTIONS = 'suggestions'
}


export interface IFormGroupProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	id: string
	fieldType: FormGroupTypes
	label?: string | React.ReactElement
	placeholder?: string
	name?: string
	value?: string
	defaultValue?: string
	note?: string
	checked?: boolean
	disabled?: boolean
	selected?: Date | null | undefined
	onValueChange?: (values: NumberFormatValues) => void
	onChange?: (event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement> ) => void
	onSelectChange?: (option: SelectOptionType) => void
	onDateChange?: (date: Date) => void
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
	numberFormat?: {
		format: string
		mask: string
	},
	isLoading?: boolean
	thousandSeparator?: boolean | string
	decimalSeparator?: string
	suffix?: string
	readonly?: boolean
	Icon?: React.ComponentType | null
	options?: SelectOptionType[]
	selectDefaultValue?: PropsValue<SelectOptionType>
	selectValue?: PropsValue<SelectOptionType>
	register?: UseFormRegister<FieldValues>
	registerOptions?: RegisterOptions
	errorMessage?: string
	acceptedFiles?: string[]
	fileAttachment?: IAttachment | null
	onAttachmentDelete?: (a:IAttachment) => void
	suggestionsSearchResult?: Partial<ISuggestionSearchResult>[]
	suggestionIsOpen?: boolean
	handleSuggestionChoice?: (choice: Partial<ISuggestionSearchResult>) => void
	rootRef?: React.RefObject<HTMLDivElement>
}

export type SelectOptionType = { label: string; value: string };



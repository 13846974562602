import React, { FC } from "react";
import AuthMain from "../components/auth/Main/Main";
import { AuthProvider } from "@context/authContext";

const Auth: FC = () => {
	return (
		<AuthProvider>
			<AuthMain />
		</AuthProvider>
	);
};

export default Auth;

import React, {FC, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {qualificationContext} from '@context/qualificationContext';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import userApi from '@api/userApi';
import {UserDataKeysEnum, VerificationStatusEnum} from '@features/user/types';
import {useAppActions} from '@hooks/useAppActions';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useHistory} from 'react-router-dom';
import {profileContext} from '@context/profileContext';
import {useAppSelector} from '@hooks/useAppSelector';
import {useScrollToTop} from '@hooks/useScrollToTop';
import {useEmitter} from '@hooks/customEvent';
import {CUSTOM_EVENT__SET_MODAL_CODE_FOCUS} from '@common/constants';

const ProfileQualificationChangeConfirm:FC = () => {

	const
		{
			changeConfirmModal,
			setChangeConfirmModal,
			qualificationData
		} = useContext(qualificationContext),
		{setSmsError} = useContext(profileContext)
	;

	const
		[checkCode, {isLoading: codeIsChecking}] = userApi.useQualificationConfirmMutation(),
		[
			qualificationRequest,
			{isLoading: qualificationRequestIsLoading}
		] = userApi.useQualificationRequestMutation(),
		[getUserInfo] = userApi.useLazyInfoQuery(),
		{verificationStatus} = useAppSelector(state => state.user.investorProfile),
		{setUserData} = useAppActions(),
		history = useHistory(),
		scrollToTop = useScrollToTop(),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(({profile}) => {
					setUserData({
						data: profile,
						key: UserDataKeysEnum.IP
					});

					setChangeConfirmModal(false);
					history.push(RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.DATA);
					getUserInfo();
					scrollToTop()
				})
				.catch(() => setSmsError(true))
		},
		onCodeResend = () => {
			qualificationRequest({
				attachments: qualificationData,
				withVerification: verificationStatus !== VerificationStatusEnum.VERIFIED
			})
				.then(() => setModalCodeFocus())
		}
	;

	return (
		<Modal
			show={changeConfirmModal}
			onHide={() => setChangeConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking || qualificationRequestIsLoading}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>

		</Modal>
	);
};

export default ProfileQualificationChangeConfirm;

import React, {createContext, Dispatch, FC, SetStateAction, useState} from 'react';

export interface IQuestionsContext {
	askQuestionModal: boolean
	setAskQuestionModal: Dispatch<SetStateAction<boolean>>
	searchQuestionModal: boolean
	setSearchQuestionModal: Dispatch<SetStateAction<boolean>>
}

export const questionsContext = createContext<IQuestionsContext>({} as IQuestionsContext);

export const QuestionsProvider: FC = ({children}) => {

	const
		[askQuestionModal, setAskQuestionModal] = useState<boolean>(false),
		[searchQuestionModal, setSearchQuestionModal] = useState<boolean>(false)
	;

	const payload = {
		askQuestionModal, setAskQuestionModal,
		searchQuestionModal, setSearchQuestionModal
	};

	return <questionsContext.Provider value={payload}>
		{children}
		</questionsContext.Provider>
};

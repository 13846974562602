import React, {FC, useContext, useState} from 'react';
import ReactPlayer from 'react-player';
import {Modal, Ratio, Spinner} from 'react-bootstrap';
import {profileContext} from '@context/profileContext';
import styles from './profileVideoModal.module.scss'
import {ReactComponent as CloseIcon} from '@icons/close.svg';

const ProfileVideoModal:FC = () => {

	const {
		modalVideoSrc,
		setModalVideoSrc,
		videoModal,
		setVideoModal
	} = useContext(profileContext);

	const [playerLoaded, setPlayerLoaded]  = useState<boolean>(false);

	return (
		<Modal
			show={videoModal}
			onHide={() => {
				setVideoModal(false);
				setModalVideoSrc(null);
				setPlayerLoaded(false);
			}}
			centered
			dialogClassName={'modal-dialog-video'}
		>
			<button type={'button'} className={styles.close} onClick={() => setVideoModal(false)}>
				<CloseIcon/>
			</button>
			<div className="position-relative">
				<Ratio
					aspectRatio={'16x9'}
				>
					<ReactPlayer
						width={'100%'}
						height={'100%'}
						controls
						url={modalVideoSrc || ''}
						onReady={() => {
							setPlayerLoaded(true)
						}}
					/>
				</Ratio>
				{!playerLoaded &&	<Spinner className={'spinner--centered'} animation='border' variant='primary'/>}
			</div>
		</Modal>

	);
};

export default ProfileVideoModal;

import React, {FC} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import styles from '@components/investor/Views/Data/data.module.scss';
import {
	EXTERNAL_LINK_DOCUMENT_EXECUTIVE, EXTERNAL_LINK_DOCUMENT_INCASSO,
	EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES,
	EXTERNAL_LINK_DOCUMENT_RISKS, EXTERNAL_LINK_DOCUMENT_TAX_RESIDENT,
} from '@common/constants';
import {
	INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED,
	INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED,
	INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED, INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED
} from '@components/investor/Views/Data/constants';
import {useFormContext} from 'react-hook-form';

export const ProfileDataRules:FC = () => {

	const
		{
			register,
			setValue,
			watch,
			formState: {errors}
		} = useFormContext()
	;

	return (
		<ProfileSection
			title={'Присоединение к правилам платформы PANFILOV'}
			className={styles.rules}
		>
			<div className="row">
				<div className="col-md-6">
					<div className="mb-1">
						<FormGroup
							id={'personalDataInvestmentRisks'}
							className={styles.formGroup}
							fieldType={FormGroupTypes.CHECKBOX}
							label={<span>Подтверждаю, что ознакомился и принимаю <a rel="noreferrer" target='_blank' href={EXTERNAL_LINK_DOCUMENT_RISKS}>риски</a>, связанные с инвестированием</span>}
							readonly={false}
							checked={watch(INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED, event.target.checked)}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED]?.message}
							name={INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED}
						/>
					</div>
					<div className="mb-1">
						<FormGroup
							id={'personalDataInvestmentRules'}
							className={styles.formGroup}
							fieldType={FormGroupTypes.CHECKBOX}
							label={<span>Присоединяюсь к <a target={'_blank'} rel="noreferrer" href={EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES}>Правилам платформы</a></span>}
							readonly={false}
							checked={watch(INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED, event.target.checked)}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED]?.message}
							name={INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED}
						/>
					</div>
					<div className="mb-1">
						<FormGroup
							id={'personalDataInvestmentTaxes'}
							className={styles.formGroup}
							fieldType={FormGroupTypes.CHECKBOX}
							label={<span>Подтверждаю, что являюсь гражданином РФ, <a rel="noreferrer" target='_blank' href={EXTERNAL_LINK_DOCUMENT_TAX_RESIDENT}>налоговым резидентом РФ </a> и не являюсь <a rel="noreferrer" target='_blank' href={EXTERNAL_LINK_DOCUMENT_EXECUTIVE}>российским или иностранным должностным лицом</a>, действую к собственной выгоде и не имею бенефициаров</span>}
							readonly={false}
							checked={watch(INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED, event.target.checked)}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED]?.message}
							name={INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED}
						/>
					</div>
					<FormGroup
						id={'personalDataInvestmentCollectionCessions'}
						className={styles.formGroup}
						fieldType={FormGroupTypes.CHECKBOX}
						label={<span>Присоединяюсь к <a rel="noreferrer" target='_blank' href={EXTERNAL_LINK_DOCUMENT_INCASSO}>договору инкассо-цессии</a></span>}
						readonly={false}
						checked={watch(INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED)}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED, event.target.checked)}
						register={register}
						errorMessage={errors[INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED]?.message}
						name={INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED}
					/>
				</div>
			</div>
		</ProfileSection>
	)
};





import React, {FC, useContext} from 'react';
import {personalDataContext} from '@context/personalDataContext';
import {Modal} from 'react-bootstrap';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import userApi from '@api/userApi';
import {CODES_RESPONSE_SUCCESS, CUSTOM_EVENT__SET_MODAL_CODE_FOCUS} from '@common/constants';
import {profileContext} from '@context/profileContext';
import {useScrollToTop} from '@hooks/useScrollToTop';
import {useEmitter} from '@hooks/customEvent';

const PersonalDataPhoneChangeConfirm:FC = () => {

	const {
		phoneChangeConfirmModal,
		setPhoneChangeConfirmModal,
		setPhoneChangeSuccessModal,
		setEditMode
	} = useContext(personalDataContext);

	const
		[checkCode, {isLoading: codeIsChecking}] = userApi.usePhoneChangeConfirmMutation(),
		[getUserInfo] = userApi.useLazyInfoQuery(),
		{setSmsError} = useContext(profileContext),
		[phoneChangeRequest, {isLoading: phoneChangeRequestIsLoading}] = userApi.usePhoneChangeRequestMutation(),
		scrollToTop = useScrollToTop('smooth'),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(response => {
					setPhoneChangeConfirmModal(false);

					if (response.code === CODES_RESPONSE_SUCCESS) {
						getUserInfo();
						setPhoneChangeSuccessModal(true)
					}
					setEditMode(false);
					scrollToTop()
				})
				.catch(() => setSmsError(true))

		},
		onCodeResend = () => {
			phoneChangeRequest()
		}
	;

	return (
		<Modal
			show={phoneChangeConfirmModal}
			onHide={() => setPhoneChangeConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking || phoneChangeRequestIsLoading}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default PersonalDataPhoneChangeConfirm;

import React, {FC} from 'react';
import {Accordion, Spinner} from 'react-bootstrap';
import styles from '@components/investor/Views/Questions/questions.module.scss';
import Heading from '@components/ui/Heading/Heading';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {IUserQuestion} from '@common/types';



const ProfileQuestionsMyThemeOutput:FC<{
	isLoading?: boolean
	userQuestions: IUserQuestion[]
}> = (
	{
		isLoading = false,
		userQuestions = []
	}
) => {

	return (
		<div>
			{
				isLoading
					? <Spinner className={'mx-auto d-block'} animation='border' variant='primary'/>
					: <>
						{
							userQuestions.length > 0
								? <div className={styles.accordion}>
									<Accordion>
										{
											userQuestions.map(({question, questionId, answer, theme}) => {
												return (
													<Accordion.Item key={questionId} eventKey={questionId.toString()}>
														<Accordion.Header className={'text-md'} as={'div'}>{theme.title}</Accordion.Header>
														<Accordion.Body className={'text-sm'}>
															{
																Boolean(answer)
																	? <>
																		<p>{question}</p>
																		<Heading
																			tag={'h3'}
																			variant={HeadingVariant.middle}
																			className={'text-md pt-4 pb-3'}
																		>Ответ Panfilov:</Heading>
																		<p>{answer}</p>
																	</>
																	: <p>{question}</p>
															}
														</Accordion.Body>
													</Accordion.Item>
												)
											})
										}
									</Accordion>
								</div>
								: <p>Ничего не найдено</p>
						}
					</>
			}
		</div>
	);
};

export default ProfileQuestionsMyThemeOutput;

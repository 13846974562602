import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import {
	DEFAULT_ERROR_MESSAGE,
	STORAGE_TOKEN_KEY,
	TOAST_AUTO_HIDE_DELAY,
} from "@common/constants";
import { toast } from "react-toastify";
import React from "react";
import ToastErrorMessage from "@components/common/Toast/ToastErrorMessage";
import notificationsApi from "@api/notificationsApi";

export const defaultErrorOptions = {
	autoClose: TOAST_AUTO_HIDE_DELAY,
	position: toast.POSITION.TOP_LEFT,
	closeOnClick: false,
};

export const apiErrorHandler: Middleware = () => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		if (action.payload.status === 401) {
			localStorage.removeItem(STORAGE_TOKEN_KEY);
			window.location.replace("/auth/phone-check");
			return;
		} else {
			console.log(action);
			const { type, payload, meta } = action;
			if (
				type.includes(notificationsApi.reducerPath) ||
				meta.arg?.endpointName === "info"
			)
				return;
			if (!type.includes("GET_ANALYTIC_INFO")) {
				console.log(action.payload?.data?.message);
				toast.error(
					<ToastErrorMessage
						message={
							action.payload?.data?.message ||
							payload?.data?.detail ||
							payload?.data?.code ||
							payload?.error ||
							DEFAULT_ERROR_MESSAGE
						}
					/>,
					defaultErrorOptions,
				);
			}
		}
	}

	return next(action);
};

import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {SEARCH_LIMIT} from '@common/constants';

interface ReturnType {
	paginationOffset: number

	paginationCount: number
	setPaginationCount: Dispatch<SetStateAction<number>>

	showUploadMoreButton: boolean

	increasePaginationOffset: () => void
	setDefaultPaginationOffset: () => void
	resetPaginationOffset: () => void
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const usePagination = (items: any[]):ReturnType => {

	const
		[offset, setOffset] = useState<number>(0),
		[count, setCount] = useState<number>(0),
		[showUploadMoreButton, setShowUploadMoreButton] = useState<boolean>(false),
		increaseOffset = ():void => setOffset(prevState => prevState+SEARCH_LIMIT),
		resetOffset = ():void => setOffset(0),
		setDefaultOffset = ():void => setOffset(SEARCH_LIMIT)
	;

	useEffect(() => {
		setShowUploadMoreButton(items.length < count)
	}, [count, items]);

	return {
		paginationOffset: offset,
		paginationCount: count,
		setPaginationCount: setCount,
		showUploadMoreButton,
		increasePaginationOffset: increaseOffset,
		resetPaginationOffset: resetOffset,
		setDefaultPaginationOffset: setDefaultOffset
	}
};

import React, {FC, useContext} from 'react';
import classNames from 'classnames';
import styles from '@components/profile/Main/main.module.scss';
import ProfileNotificationsModal from '@components/profile/NotificationsModal/NotificationsModal';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import {profileContext} from '@context/profileContext';

export const ProfileMain:FC = (props) => {

	const {width: ww} = useWindowSize();
	const {mainIsOutOfFocus} = useContext(profileContext);

	return (
		<div className={classNames(styles.root, {'is-backdrop': mainIsOutOfFocus})}>
			{ww < ADAPTIVE_BREAKPOINTS.sm && <ProfileNotificationsModal/>}
			{props.children}
		</div>
	);
};


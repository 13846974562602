import React, {FC, useContext} from 'react';
import classNames from 'classnames';
import styles from './projectsHeader.module.scss';
import {FormGroup, Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {FormGroupTypes, SelectOptionType} from '@components/ui/FormGroup/FormGroup.props';
import {v4 as uuidv4} from 'uuid';
import {ReactComponent as SortingIcon} from '@icons/sort.svg';
import {ReactComponent as SettingsIcon} from '@icons/settings.svg';
import {projectsContext} from '@context/projectsContext';
import formGroupStyles from '@components/ui/FormGroup/formGroup.module.scss';

enum SortingOptionsValueEnum {
	NEW = 'new',
	URGENCY = 'goalEndDate',
	RATE = 'rate'
}

export const projectsSortingOptions:SelectOptionType[] = [
	{
		label: 'Сначала новые',
		value: SortingOptionsValueEnum.NEW
	},
	{
		label: 'По срочности',
		value: SortingOptionsValueEnum.URGENCY
	},
	{
		label: 'По ставке',
		value: SortingOptionsValueEnum.RATE
	}
];

const ProfileProjectsHeader:FC<{onSortChange?: (option: SelectOptionType) => void}> = ({onSortChange}) => {

	const
		{
			sort,
			filterIsInvolved,
			setFilterIsOpen
		} = useContext(projectsContext)
	;

	return (
		<div className={classNames(styles.filter, 'mb-sm-5')}>
			<div className="row gx-0 gx-sm-3 flex-nowrap justify-content-between align-items-center">
				<div className="d-none d-xl-block col-auto col-xxl-6 col-hd-8 me-auto">
					<Heading tag={'h2'} variant={HeadingVariant.large} className={'mb-0'}>Проекты</Heading>
				</div>
				<div className="col-6 col-sm-auto col-xxl-3 col-hd-2">
					<FormGroup
						fieldType={FormGroupTypes.SELECT}
						id={uuidv4()}
						className={classNames(styles.filterSorting, 'pb-0')}
						options={projectsSortingOptions}
						Icon={SortingIcon}
						onSelectChange={(sortOption) => {
							onSortChange && onSortChange(sortOption);
						}}
						selectValue={sort}
					/>
				</div>
				<div className="col-6 col-sm-auto col-xxl-3 col-hd-2">
					<div className={classNames(formGroupStyles.root, 'pb-0')}>
						<button
							type={'button'}
							className={
								classNames(
									formGroupStyles.field,
									styles.filterButton,
									'd-flex align-items-center',
									{'is-involved': filterIsInvolved}
								)
							}
							onClick={() => setFilterIsOpen(true)}
						>
							<div className={classNames(formGroupStyles.selectControlIcon, 'me-3')}><SettingsIcon/></div>
							<span>Задать параметры</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileProjectsHeader;

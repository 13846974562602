import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {PersonalDataViewsEnum} from '@components/investor/Views/Data/types';
import ProfileDataControls from '@components/investor/Views/Data/Ui/ProfileDataControls';
import {personalDataContext} from '@context/personalDataContext';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {FormGroup} from '@components/ui';
import {
	entityFields,
	INVESTOR_FIELD_NAME_HEAD_FIRST_NAME,
	INVESTOR_FIELD_NAME_HEAD_LAST_NAME,
	INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME,
	INVESTOR_FIELD_NAME_HEAD_POST, INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_FULL_NAME,
	INVESTOR_FIELD_NAME_ORG_INN,
	INVESTOR_FIELD_NAME_ORG_KPP,
	INVESTOR_FIELD_NAME_ORG_OGRN,
	INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
	INVESTOR_FIELD_NAME_ORGANIZATION_FORM
} from '@components/investor/Views/Data/constants';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {useFormContext} from 'react-hook-form';
import styles from '@components/investor/Views/Data/data.module.scss';
import {validateInnMinMax} from '@common/utils';
import {
	INN_MAX_LENGTH,
	INN_MIN_LENGTH
} from '@common/constants';
import ProfileDataFields from '@components/investor/Views/Data/Ui/ProfileDataFields';
import useDebounce from '@hooks/useDebounce';
import daDataApi from '@api/daDataApi';
import {ISuggestion, ISuggestionSearchResult} from '@features/daData/types';
import {useInnErrors, useSearchValidation} from '@components/investor/Views/Data/hooks';

const ProfileDataPageEntityEdit:FC = () => {
	const {
		watch,
		register,
		setValue,
		getValues,
		formState: {errors},
		clearErrors,
	} = useFormContext();

	const
		{
			setCurrentPersonalDataView,
			inn,
			setInn,
			dataChangeRequestModal,
			dataChangeConfirmModal,
			dataChangeSuccessModal
		} = useContext(personalDataContext),
		innDebounced = useDebounce<string>(inn),
		[entitySearch, {isLoading: entityResultsIsLoading}] = daDataApi.useOrganisationSearchMutation(),
		[suggestionIsOpen, setSuggestionOpen] = useState<boolean>(false),
		[searchResult, setSearchResults] = useState<ISuggestion[]>([]),
		processedSearchResult = useMemo(() => {
			return searchResult.map((result):Partial<ISuggestionSearchResult> => ({
				name: result.value,
				hid: result.data.hid,
				address: result.data.address.value,
				inn: result.data.inn
			}))
		}, [searchResult]),
		{
			setResult: setCurrentResult,
			result: currentResult
		} = useSearchValidation(),
		orgInnError = useInnErrors(errors[INVESTOR_FIELD_NAME_ORG_INN])
	;

	const
		handleAddressMatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(
				INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION,
				event.target.checked
					? getValues(INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION)
					: ''
			);

			clearErrors(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION)
		},
		handleSuggestionChoice = (choice:Partial<ISuggestionSearchResult>) => {
			const suggestion:ISuggestion | undefined = searchResult.find(result => result.data.hid === choice.hid);

			if (suggestion) {
				setCurrentResult(suggestion);
				const {opf, name, ogrn, kpp, ogrn_date, management, address, inn} = suggestion.data;
				clearErrors();

				setValue(INVESTOR_FIELD_NAME_ORGANIZATION_FORM, opf?.full || '');
				setValue(INVESTOR_FIELD_NAME_ORG_SHORT_NAME, name?.short_with_opf || '');
				setValue(INVESTOR_FIELD_NAME_ORG_FULL_NAME, name?.full_with_opf || '');
				setValue(INVESTOR_FIELD_NAME_ORG_OGRN, ogrn || '');
				setValue(INVESTOR_FIELD_NAME_ORG_KPP, kpp || '');
				setValue(INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION, ogrn_date || '');
				setValue(INVESTOR_FIELD_NAME_HEAD_POST, management?.post || '');
				setValue(INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION, address?.value || '');
				setValue(INVESTOR_FIELD_NAME_ORG_INN, inn || '');
				clearErrors(INVESTOR_FIELD_NAME_ORG_INN);

				if (management?.name) {
					const [lastName, firstName, middleName] = management.name.split(' ');

					setValue(INVESTOR_FIELD_NAME_HEAD_LAST_NAME, lastName);
					setValue(INVESTOR_FIELD_NAME_HEAD_FIRST_NAME, firstName);
					setValue(INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME, middleName);
				} else {
					setValue(INVESTOR_FIELD_NAME_HEAD_LAST_NAME, '');
					setValue(INVESTOR_FIELD_NAME_HEAD_FIRST_NAME, '');
					setValue(INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME, '');
				}
			} else {
				setCurrentResult(null)
			}

			setSuggestionOpen(false);
		}
	;

	useEffect(() => {
		innDebounced && entitySearch(innDebounced)
			.unwrap()
			.then(response => {
				setSearchResults(response);

				if (response.length >= 1 && !Boolean(currentResult))
					setSuggestionOpen(true)
			});
	}, [innDebounced]);

	return (
		<>
			<ProfileSection title={'Введите ИНН организации'}>
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<FormGroup
							id={INVESTOR_FIELD_NAME_ORG_INN}
							fieldType={FormGroupTypes.SUGGESTIONS}
							label={'ИНН'}
							value={watch(INVESTOR_FIELD_NAME_ORG_INN)}
							register={register}
							name={INVESTOR_FIELD_NAME_ORG_INN}
							onValueChange={({value}) => {
								(Boolean(currentResult) && value.length < INN_MIN_LENGTH) && setCurrentResult(null);
								setValue(INVESTOR_FIELD_NAME_ORG_INN, value);
								setInn(value)
							}}
							isLoading={entityResultsIsLoading}
							suggestionsSearchResult={processedSearchResult}
							suggestionIsOpen={suggestionIsOpen}
							handleSuggestionChoice={handleSuggestionChoice}
							errorMessage={orgInnError}
							registerOptions={
								{
									validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
								}
							}
						/>
					</div>
				</div>
			</ProfileSection>
			<ProfileSection title={'Данные организации'}>

				<ProfileDataFields fields={entityFields}>
					<div className="col-sm-6 col-md-4">
						<FormGroup
							label={'Фактический адрес совпадает с юридическим'}
							id={'is-registration-match-location-address'}
							fieldType={FormGroupTypes.CHECKBOX}
							className={'pb-1 pb-sm-0'}
							onChange={handleAddressMatchChange}
						/>
					</div>
					<div className="col-sm-6 col-md-4">
						<FormGroup
							id={INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION}
							fieldType={FormGroupTypes.TEXT}
							label={'Фактический адрес организации'}
							value={watch(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION, event.target.value)}
							className={styles.formGroup}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION]?.message}
							name={INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION}
						/>
					</div>
				</ProfileDataFields>

				<div className="row py-4">
					<div className="col-md-6">
						<FormGroup
							label={'Подтверждаю, что являюсь высшим единоличным руководителем организации. Права распоряжения расчетным счетом не ограничены'}
							id={INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG}
							fieldType={FormGroupTypes.CHECKBOX}
							checked={watch(INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG, event.target.checked)}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG]?.message}
							name={INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG}
						/>
					</div>
				</div>
			</ProfileSection>
			<ProfileDataControls
				onCancel={() => setCurrentPersonalDataView(PersonalDataViewsEnum.DEFAULT)}
				cancelText={'Назад'}
				submitText={'Сохранить'}
				submitIsDisabled={dataChangeRequestModal || dataChangeConfirmModal ||	dataChangeSuccessModal}
			/>
		</>
	);
};

export default ProfileDataPageEntityEdit;

import React, { FC, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as LogoIcon } from "@icons/logo.svg";
import styles from "./footer.module.scss";
import { Container } from "react-bootstrap";
import { CustomLink } from "@components/ui";
import { LinkVariant } from "@components/ui/Link/Link.props";
import { authContext } from "@context/authContext";
import CompanyPhone from "@components/common/CompanyPhone";
import CompanySocial from "@components/common/CompanySocial";
import CompanyEmail from "@components/common/CompanyEmail";

const { simple } = LinkVariant;

const AuthFooter: FC = (): JSX.Element => {
	const currentYear = new Date().getFullYear();
	const { setAboutModalShown } = useContext(authContext);
	const isTablet = useMediaQuery({ query: "(max-width: 1200px)" });
	const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

	return (
		<div className={styles.root}>
			<Container fluid={true}>
				<div className={styles.footerRow}>
					<div className=''>
						<LogoIcon className={styles.logo} />
					</div>
					<div className={styles.address}>
						<CustomLink
							className={styles.link}
							variant={simple}
							onClick={(event) => {
								event.preventDefault();
								setAboutModalShown(true);
							}}>
							О компании
						</CustomLink>
					</div>
					{!isMobile && (
						<>
							<div className={styles.footerSocialGroup}>
								{!isTablet && <CompanySocial className='col-4' />}
								<CompanyEmail className='col-12 col-xl-4' />
								<CompanyPhone
									className='col-12 col-xl-4 mb-3 mb-xl-0'
									withIcon={!isTablet}
								/>
							</div>
							<div className={styles.address}>
								<small>
									614066, Пермский край, г. Пермь, ул. Чайковского, д. 19, пом.
									16
								</small>
							</div>
						</>
					)}
				</div>
				{isMobile && (
					<div className='d-flex justify-content-between align-items-start mt-3'>
						<div className={styles.address}>
							<small>
								614066, Пермский край, г. Пермь, ул. Чайковского, д. 19, пом. 16
							</small>
						</div>
						<div className={styles.footerSocialGroup}>
							<CompanyEmail className='' />
							<CompanyPhone className='mb-1' />
						</div>
					</div>
				)}
				{isMobile && <CompanySocial className='mt-3 justify-content-center' />}
				<div className={isMobile ? "" : styles.footerRow}>
					<div className={styles.rights}>
						<small>© {currentYear} ООО «ИнсайтФинанс». </small>
						<br />
						<small>Все права защищены.</small> <br />
						<small>ИНН 5904281530 ОГРН 1135904000430</small>
					</div>
					<div />
					{isTablet && !isMobile && <CompanySocial className={styles.rights} />}
				</div>
			</Container>
		</div>
	);
};

export default AuthFooter;

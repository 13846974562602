import React, {FC, useContext, useState} from 'react';
import {ProfilePagesTitleEnum} from '@common/types';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {ReactComponent as CalendarIcon} from '@icons/calendar-colored.svg';
import {ReactComponent as FilterIcon} from '@icons/filter-colored.svg';
import {ReactComponent as ArrowDownIcon} from '@icons/accordion-arrow.svg';
import styles from './history.module.scss';
import {FormGroupTypes, SelectOptionType} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import classNames from 'classnames';
import operationsApi from '@api/operationsApi';
import moment from 'moment';
import 'moment/locale/ru';
import {formatDate, searchWithParams} from '@common/utils';
import {
	ADAPTIVE_BREAKPOINTS,
	API_DATE_FORMAT
} from '@common/constants';
import {IOperation, operationInvestTypeEnum} from '@features/operations/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import Table from '@components/common/Table/Table';
import {usePagination} from '@hooks/usePagination';
import ProfileLoader from '@components/common/ProfileLoader';
import InvestorHistoryMonths, {MonthCountType} from '@components/investor/Views/History/HistoryMonths';
import {ConditionalWrapper} from '@components/common/ConditionalWrapper';
import {Accordion, AccordionContext, useAccordionButton} from 'react-bootstrap';
import useWindowSize from '@hooks/useWindowSize';
import {Fade} from '@components/common/Transitions/Fade';
import useMounted from '@hooks/useMounted';

moment.locale('ru');

export enum OperationCodesEnum {
	WITHDRAW = 'withdraw',
	REFILL = 'deposit',
	INVEST = 'invest',
	PROFIT = 'profit',
	ALL = 'all'
}

enum OperationTypesEnum {
	AUTO = 'auto',
	MANUAL = 'manual',
	ALL = 'all'
}

const operationCodeOptions: SelectOptionType[] = [
	{
		value: OperationCodesEnum.ALL,
		label: 'Все операции'
	},
	{
		value: OperationCodesEnum.WITHDRAW,
		label: 'Вывод средств'
	},
	{
		value: OperationCodesEnum.REFILL,
		label: 'Пополнение л/с'
	},
	{
		value: OperationCodesEnum.INVEST,
		label: 'Выдача займа'
	},
	{
		value: OperationCodesEnum.PROFIT,
		label: 'Получение дохода (проценты, пени)'
	}
];

const operationTypeOptions: SelectOptionType[] = [
	{
		value: OperationTypesEnum.ALL,
		label: 'Все инвестиции'
	},
	{
		value: OperationTypesEnum.AUTO,
		label: 'Автоинвест'
	},
	{
		value: OperationTypesEnum.MANUAL,
		label: 'Ручное'
	}
];


const FilterAccordionToggleButton:FC<{eventKey: string}> = ({eventKey, children }) => {
	const
		{activeEventKey} = useContext(AccordionContext),
		decoratedOnClick = useAccordionButton(eventKey),
		isCurrentEventKey = activeEventKey === eventKey
	;

	return (
		<button
			className={classNames(
				styles.accordionButton,
				'text-dark d-flex justify-content-between align-items-center',
				{'collapsed': isCurrentEventKey}
			)}
			type='button'
			onClick={decoratedOnClick}
		>
			{children}
		</button>
	);
};

type FilterType = {[key: string]: {eq: string}};

export const ProfileHistory:FC = () => {
	const
		[
			searchOperationRequest,
			{
				isLoading: historyListIsLoading,
				isError: historyListIsError
			}
		] = operationsApi.useSearchMutation({}),
		[filterDateStart, setFilterDateStart] = useState<Date | null>(null),
		[filterDateEnd, setFilterDateEnd] = useState<Date | null>(moment().toDate()),
		[filterCode, setFilterCode] = useState<string | undefined>(OperationCodesEnum.ALL),
		[filterType, setFilterType] = useState<string | undefined>(OperationCodesEnum.ALL),
		[historyItems, setHistoryItems] = useState<IOperation[]>([]),
		[monthRange, setMonthRange] = useState<MonthCountType>(OperationCodesEnum.ALL),
		{
			showUploadMoreButton,
			paginationOffset,
			increasePaginationOffset,
			resetPaginationOffset,
			setDefaultPaginationOffset,
			setPaginationCount
		} = usePagination(historyItems),
		{width: ww} = useWindowSize()
	;

	const
		dateToParams = (date: Date | null): string | null => date ? formatDate(date, API_DATE_FORMAT) : null,
		defineFilters = ():FilterType => {
			const filters: FilterType = {};

			if (filterCode && filterCode !== OperationCodesEnum.ALL)
				filters['&code'] = {eq: filterCode};

			if (filterType && filterType !== OperationTypesEnum.ALL)
				filters['&investType'] = {eq: filterType};

			return filters;
		},
		searchAndMergeOperations = ():void => {
			searchOperationRequest({
				params: {
					dateStart: dateToParams(filterDateStart),
					dateEnd: dateToParams(filterDateEnd)
				},
				body: searchWithParams({
					filters: defineFilters(),
					offset: paginationOffset,
					orders: {
						dateCreated: 'DESC'
					}
				})
			})
				.unwrap()
				.then(({items, count}) => {
					setHistoryItems(prevState => [...prevState, ...items]);
					increasePaginationOffset();
					setPaginationCount(count);
				});
		},
		defineInvestType = (t: string): string => {
			switch (t) {
				case operationInvestTypeEnum.MANUAL:
					return 'Ручной';
				case operationInvestTypeEnum.AUTO:
					return 'Авто';
				default:
					return '';
			}
		},
		handleMonthChange = (value: MonthCountType) => {
			setMonthRange(value);

			setFilterDateStart( value === 'all' ? null : moment(filterDateEnd).subtract(value, 'months').toDate());
			resetPaginationOffset();
		}
	;

	useMounted({
		dependencies: [filterDateStart, filterDateEnd, filterCode, filterType],
		onResolve(isMounted) {
			searchOperationRequest({
				params: {
					dateStart: dateToParams(filterDateStart),
					dateEnd: dateToParams(filterDateEnd)
				},
				body: searchWithParams({
					filters: defineFilters(),
					offset: paginationOffset,
					orders: {
						dateCreated: 'DESC'
					}
				})
			})
				.unwrap()
				.then(({items, count}) => {
					if (isMounted) {
						setHistoryItems(items);
						setDefaultPaginationOffset();
						setPaginationCount(count);
					}
				});
		}
	});

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.HISTORY} className={styles.root}>
			<ProfileSection title={'История операций'} limitMinHeight={false} className={styles.filtersSection}>
				<ConditionalWrapper
					condition={ww < ADAPTIVE_BREAKPOINTS.md}
					wrapper={children => {
						return <Accordion>
							<Accordion.Item eventKey={'InvestorHistoryFilter'}>
								<FilterAccordionToggleButton eventKey={'InvestorHistoryFilter'}>
									<span>Фильтры</span>
									<ArrowDownIcon/>
								</FilterAccordionToggleButton>
								<Accordion.Body>
									<Fade inProp={true}>
										{children}
									</Fade>
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					}}
				>
					<div className={classNames(styles.header, 'pt-4 pb-md-0')}>
						<InvestorHistoryMonths
							onChange={handleMonthChange}
							currentValue={monthRange}
							name={'mobile'}
							className={'d-md-none mb-3 pt-2 pb-3'}
						/>
						<div className='row g-3'>

							<div className='col-md-6'>
								<div className='row g-3'>
									<div className='col-auto d-lt-none'>
										<div className={styles.icon}><CalendarIcon/></div>
									</div>
									<div className='col'>
										<div className='pe-md-4 pe-lt-0'>
											<div className='row align-items-center g-3'>
												<div className="col-auto d-none d-lt-block">
													<div className={styles.icon}><CalendarIcon/></div>
												</div>
												<div className="col-lt-auto">
													<FormGroup
														id={'profileHistoryDataFrom'}
														fieldType={FormGroupTypes.DATE}
														onDateChange={(date: Date) => {setFilterDateStart(date);resetPaginationOffset()}}
														disabled={historyListIsLoading}
														defaultValue={filterDateStart ? formatDate(filterDateStart) : undefined}
														selected={filterDateStart && moment(filterDateStart).toDate()}
														placeholder={'Укажите дату'}
														className='pb-0'
													/>
												</div>
												<div className="col-auto d-none d-lt-block"><span className={styles.dash}>&mdash;</span></div>
												<div className="col-lt-auto">
													<FormGroup
														id={'profileHistoryDataTo'}
														fieldType={FormGroupTypes.DATE}
														onDateChange={(date: Date) => {setFilterDateEnd(date);resetPaginationOffset()}}
														disabled={historyListIsLoading}
														defaultValue={filterDateEnd ? formatDate(filterDateEnd) : undefined}
														selected={filterDateEnd && moment(filterDateEnd).toDate()}
														placeholder={'Укажите дату'}
														className='pb-0'
													/>
												</div>
											</div>
											<InvestorHistoryMonths
												onChange={handleMonthChange}
												currentValue={monthRange}
												name={'desktop'}
												className={'d-none d-md-block py-3'}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='row g-3'>
									<div className='col-auto d-lt-none'>
										<div className='ps-md-4 ps-lt-0'>
											<div className={styles.icon}><FilterIcon width={24} height={24}/></div>
										</div>
									</div>

									<div className='col'>
										<div className='row g-3 justify-content-lt-end'>
											<div className='col-lt-auto'>
												<FormGroup
													id={'profileHistoryOperationCode'}
													fieldType={FormGroupTypes.SELECT}
													options={operationCodeOptions}
													onSelectChange={({value}) => {setFilterCode(value);resetPaginationOffset()}}
													selectDefaultValue={operationCodeOptions[0]}
													className={'pb-0'}
												/>
											</div>
											<div className='col-lt-auto'>
												<FormGroup
													id={'profileHistoryOperationType'}
													fieldType={FormGroupTypes.SELECT}
													options={operationTypeOptions}
													onSelectChange={({value}) => {setFilterType(value);resetPaginationOffset()}}
													selectDefaultValue={operationTypeOptions[0]}
													className={'pb-0'}
												/>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ConditionalWrapper>
			</ProfileSection>

			<ProfileSection isTableInside className={classNames(styles.tableSection)}>
				{
					!historyListIsError && <Table
						className={styles.table}
						emptyMessage={'Операций не найдено'}
						horizontallyScrolled
						thCells={[
							'Дата',
							'Тип',
							'Приход',
							'Расход',
							'Комментарий',
							'Компания-заёмщик',
							'Тип инвестиции'
						]}
						tdCells={historyItems.map(operation => {
							const {
								dateCreated,
								income,
								outcome,
								comment,
								investType,
								borrower
							} = operation;

							return [
								formatDate(dateCreated) || '-',
								operation.title || '-',
								income || '-',
								outcome || '-',
								comment || '-',
								borrower?.orgName || '-',
								defineInvestType(investType) || '-'
							]
						})}
					/>
				}
				<ProfileLoader
					isError={historyListIsError}
					isLoading={historyListIsLoading}
					errorMessage={'Ошибка загрузки истории операций'}
					onClick={() => searchAndMergeOperations()}
					needToShown={showUploadMoreButton}
				/>
			</ProfileSection>
		</ProfileContainer>
	);
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ISection, IUtilsState} from '@features/utils/types';

const initialState: IUtilsState = {
	sections: []
};

export const utilsSlice = createSlice({
	name: 'utils',
	initialState,
	reducers: {
		setSections(state, {payload}: PayloadAction<ISection[]>) {
			state.sections = payload;
		}
	}
});

export default utilsSlice.reducer;

import React, {FC, HTMLAttributes, useEffect, useState} from 'react';
import {ProgressBar, ProgressBarProps} from 'react-bootstrap';
import AmountOutput from '@components/common/AmountOutput';
import style from './fundraisingProgress.module.scss';
import classNames from 'classnames';

interface IFundraisingProgressProps {
	total: number,
	current: number
}

const FundraisingProgress:FC<ProgressBarProps & IFundraisingProgressProps & HTMLAttributes<HTMLDivElement>> = (
	{
		total,
		current = 0,
		className
	}
) => {

	const [percent, setPercent] = useState<number>(0);

	useEffect(() => {
		setPercent(Math.floor(current * 100 / total))
	}, [current, total]);


	return (
		<div className={classNames(style.wrap, className)}>
			<div className={'row g-2'}>
				<div className="col-10 col-md-9">
					<p>{<AmountOutput amount={current.toString()}/>} из {<AmountOutput amount={total.toString()}/>}</p>
				</div>
				<div className="col-2 col-md-3">
					<p className={'text-end mb-0'}>{percent}%</p>
				</div>
				<div className="col-12">
					<ProgressBar className={style.progressBar} now={percent} variant={percent < 100 ? 'primary' : 'success'}/>
				</div>
			</div>
		</div>
	);
};

export default FundraisingProgress;

import React, {FC} from 'react';
import {Spinner} from 'react-bootstrap';
import classNames from 'classnames';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';

interface IScrollContentLoader {
	isLoading: boolean
	isError: boolean
	errorMessage?: string
	className?: string
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
	content?: string | JSX.Element
	needToShown?: boolean
}

const ProfileLoader:FC<IScrollContentLoader> = (
	{

		isLoading = false,
		isError = false,
		errorMessage = 'Ошибка загрузки',
		content = 'Загрузить ещё',
		className,
		onClick,
		needToShown
	}
) => {

	if (isError) return <p className='text-danger py-4'>{errorMessage}</p>;

	return <div className={classNames('d-block text-center py-4', className)}>
		{
			needToShown
				? <Button
					as={ButtonTypesEnum.BUTTON}
					variant={ButtonVariant.outlined}
					onClick={onClick}
					isLoading={isLoading}
				>
					{content}
				</Button>
				: (isLoading && <Spinner animation='border' variant='primary'/>)
		}
	</div>
};

export default ProfileLoader;

import React from 'react';
import {ParagraphProps, ParagraphSize} from '@components/ui/Paragraph/Paragraph.props';
import classNames from 'classnames';
import styles from './Paragraph.module.scss';

const Paragraph = (
	{
		children,
		size = ParagraphSize.default,
		className,
		...props
	}
		: ParagraphProps
): JSX.Element => {
	return <p className={classNames(styles.root, styles[size], className)} {...props}>{children}</p>
};

export default Paragraph;

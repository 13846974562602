import React, {FC} from 'react';
import styles from '@components/investor/Project/project.module.scss';
import {Accordion} from 'react-bootstrap';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {
	createDataList,
	IDataRow
} from '@components/investor/Project/Project';
import {useCashedProject} from '@hooks/useCashedProject';
import {ProjectDetailsSectionsEnum} from '@components/investor/Project/Accordion/ProjectAccordion';
import {IProject} from '@features/projects/types';

const ProfileProjectAccordionAbout:FC<{projectId: IProject['projectId']}> = ({projectId}) => {

	const	detailCashed = useCashedProject(projectId);

	const aboutData: IDataRow[] = [
			{
				key: 'Фактический адрес объекта',
				value: detailCashed?.actualObjectAddress
			},
			{
				key: 'Цель получения инвестиций',
				value: detailCashed?.investGoal
			},
			{
				key: 'Способ монетизации',
				value: detailCashed?.monetizeWay
			},
			{
				key: 'Гарантия возврата/залог',
				value: detailCashed?.returnGuarantee
			}
		];

	return (
		<Accordion.Item className={styles.section} eventKey={ProjectDetailsSectionsEnum.ABOUT_PROJECT+projectId}>
			<Accordion.Header as={'div'}>
				<Heading
					tag={'h4'}
					variant={HeadingVariant.middle}
					className={'mb-0'}
				>О проекте</Heading>
			</Accordion.Header>
			<Accordion.Body>
				<div className={'row pb-3'}>
					<div className="col-lt-8">
						{createDataList(aboutData)}
					</div>
				</div>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default ProfileProjectAccordionAbout;

import React, { FC, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import dialogStyles from "@components/auth/Dialog/dialog.module.scss";
import { Button, FormGroup, Paragraph } from "@components/ui";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import {
	EXTERNAL_LINK_DOCUMENT_ELECTRONIC_SIGNATURE,
	EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA,
	EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA_POLICY,
	EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES,
	REQUIRED_PHONE_LENGTH,
} from "@common/constants";
import { useAppSelector } from "@hooks/useAppSelector";
import { phoneToString, testEmail } from "@common/utils";
import { authContext } from "@context/authContext";

export const RegistrationRequest: FC = (props): JSX.Element => {
	const {
			phone,
			updatePhone,
			email,
			updateEmail,
			rulesAgreement,
			updateRulesAgreement,
			personalDataAgreement,
			updatePersonalDataAgreement,
			registrationRequestHandler,
		} = useContext(authContext),
		{ isLoading } = useAppSelector((store) => store.auth);
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			registrationRequestHandler();
		},
		handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
			updateEmail(event.target.value);
	useEffect(() => {
		setButtonDisabled(
			!(rulesAgreement && personalDataAgreement && testEmail(email)) ||
				phoneToString(phone).length !== REQUIRED_PHONE_LENGTH ||
				isLoading,
		);
	}, [rulesAgreement, personalDataAgreement, phone, email, isLoading]);

	return (
		<form {...props} onSubmit={handleSubmit}>
			<Paragraph className={classNames(dialogStyles.title)}>
				Регистрация
			</Paragraph>
			<FormGroup
				id={"authRegistrationPhone"}
				fieldType={FormGroupTypes.PHONE}
				onChange={(
					event: React.ChangeEvent<HTMLInputElement>, // eslint-disable-next-line
				): void => // @ts-ignore
					updatePhone(event)
				}
				label={"Номер телефона"}
				value={phoneToString(phone)}
				placeholder={"8 (999) 999 99-99"}
				note={
					"Этот номер не привязан к учетной записи. Мы можем выслать СМС с кодом для регистрации на платформе."
				}
			/>
			<FormGroup
				id={"authRegistrationEmail"}
				fieldType={FormGroupTypes.EMAIL}
				onChange={handleEmailChange}
				label={"E-mail"}
				placeholder={"Введите email"}
				value={email}
			/>
			<FormGroup
				id={"authRegistrationAgreement"}
				fieldType={FormGroupTypes.CHECKBOX}
				label={
					<>
						Я соглашаюсь на обработку{" "}
						<a
							href={EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA}
							target='_blank'
							rel='noreferrer'
							className={"text-decoration-underline"}>
							персональных данных
						</a>{" "}
						в соответствии с{" "}
						<a
							href={EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA_POLICY}
							target='_blank'
							rel='noreferrer'
							className={"text-decoration-underline"}>
							политикой обработки персональных данных
						</a>
					</>
				}
				className={"text-dark"}
				onChange={updateRulesAgreement}
				checked={rulesAgreement}
			/>
			<FormGroup
				id={"PersonalDataProcessing"}
				fieldType={FormGroupTypes.CHECKBOX}
				label={
					<>
						Я ознакомился с{" "}
						<a
							href={EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES}
							target='_blank'
							rel='noreferrer'
							className={"text-decoration-underline"}>
							Правилами платформы
						</a>
						, согласен с использованием{" "}
						<a
							href={EXTERNAL_LINK_DOCUMENT_ELECTRONIC_SIGNATURE}
							target='_blank'
							rel='noreferrer'
							className={"text-decoration-underline"}>
							Простой электронной подписи
						</a>
					</>
				}
				className={"text-dark"}
				onChange={updatePersonalDataAgreement}
				checked={personalDataAgreement}
			/>
			<div className='text-center pt-5 mt-2'>
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={"submit"}
					as={ButtonTypesEnum.BUTTON}>
					Отправить СМС-код
				</Button>
			</div>
		</form>
	);
};

export default RegistrationRequest;

import React, {FC, useContext, useEffect, useState} from 'react';
import styles from './accountDocument.module.scss';
import {Ratio, Spinner} from 'react-bootstrap';
import {BorrowerDocumentsKeysEnum, BorrowerDocumentStatus} from '@features/user/types';
import {ReactComponent as PlaceholderIcon} from '@icons/borrower-document-placeholder.svg';
import {ReactComponent as DocumentLoadedIcon} from '@icons/document-loaded.svg';
import {ReactComponent as ExclamationIcon} from '@icons/exclamation.svg';
import {ReactComponent as PlusIcon} from '@icons/plus.svg';
import {ReactComponent as GearIcon} from '@icons/gear.svg';
import {ReactComponent as CheckIcon} from '@icons/check-square-colored.svg';
import {ReactComponent as WaitIcon} from '@icons/time-square-colored.svg';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import LazyImage from '@components/common/LazyImage/LazyImage';
import {configureAssetPath, withRootUrl} from '@common/utils';
import {borrowerContext, IDocumentsIsLoading} from '@context/borrowerContext';
import {useAppSelector} from '@hooks/useAppSelector';
import {IAttachment} from '@common/types';
import {ReactComponent as DeleteIcon} from '@icons/delete.svg';
import {useScrollToTop} from '@hooks/useScrollToTop';

export enum DocumentWhomEnum {
	O = 'ООО',
	I = 'ИП'
}

type DocumentFileType = 'document' | 'picture';

export interface IBorrowerAccountDocumentProps {
	title: JSX.Element | string
	documentKey: BorrowerDocumentsKeysEnum
	fileType: DocumentFileType
	onChange?: (files: FileList | null) => void
	required?: boolean | string
	forWhom?: DocumentWhomEnum
	className?: string
	onAttachmentDelete?: (a:IAttachment) => void
}

enum DocumentAcceptRulesEnum {
	PICTURE = 'image/jpeg,image/png',
	DOCUMENT = '.doc,.docx,.pdf'
}

const BorrowerAccountDocument:FC<IBorrowerAccountDocumentProps> = (
	{
		title,
		documentKey,
		fileType,
		onChange,
		required = 'Этот документ обязателен',
		forWhom,
		className,
		onAttachmentDelete
	}
) => {

	const id = `account-document-${documentKey}`;

	const
		{
			register,
			setValue,
			clearErrors,
			formState: {errors}
		} = useFormContext(),
		{documentsIsLoading} = useContext(borrowerContext),
		{borrowerProfile} = useAppSelector(state => state.user),
		[documentSrc, setDocumentSrc] = useState<string | null>(null),
		[accept, setAccept] = useState<DocumentAcceptRulesEnum>(),
		[attachment, setAttachment] = useState<IAttachment | null>(null),
		[status, setStatus] = useState<BorrowerDocumentStatus | null>(null),
		scrollToTop = useScrollToTop('smooth')
	;

	const
		onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(documentKey, event.target.files);

			onChange && onChange(event.target.files);
			clearErrors(documentKey)
		},
		generateStatusIcon = ():JSX.Element | null => {
			switch (status) {
				case BorrowerDocumentStatus.WAITING:
					return <WaitIcon/>;
				case BorrowerDocumentStatus.APPROVED:
					return <CheckIcon/>;
				case BorrowerDocumentStatus.NOT_APPROVED:
					return <ExclamationIcon/>;
				default: return null;
			}
		},

		generateStatusContent = ():string | null => {
			switch (status) {
				case BorrowerDocumentStatus.WAITING:
					return 'Ожидает проверки';
				case BorrowerDocumentStatus.APPROVED:
					return 'Документ проверен';
				case BorrowerDocumentStatus.NOT_APPROVED:
					return 'Документ не прошёл проверку';
				default: return null;
			}
		}
	;

	useEffect(() => {
		if (borrowerProfile[documentKey]) {
			const {attachment, status} = borrowerProfile[documentKey];
			setAttachment(attachment);
			setStatus(status);
		}
	}, [borrowerProfile[documentKey]]);

	useEffect(() => {
		switch (fileType) {
			case 'document':
				setAccept(DocumentAcceptRulesEnum.DOCUMENT);
				break;

			case 'picture':
				setAccept(DocumentAcceptRulesEnum.PICTURE);
				break;
		}
	}, [fileType]);

	useEffect(() => {
		attachment && setDocumentSrc(configureAssetPath(attachment));

		if (required)	{
			register(documentKey, {
				validate: () => {
					const isInValid = Boolean(attachment) || required;

					isInValid && scrollToTop();

					return isInValid;
				}
			})
		}
	}, [attachment, register]);

	return (
		<div className={classNames(styles.item, 'text-center', className)}>
			<div className='pb-2 pb-sm-4'>
				<p className={'text-m pb-1 mb-0'}>{title}</p>
				{forWhom && <p className='text-m mb-0 '>{`(Для ${forWhom})`}</p>}
			</div>
			<Ratio>
				<div className={styles.itemWrap}>
					{
						documentsIsLoading[documentKey as keyof IDocumentsIsLoading]
							? <Spinner className={'spinner--centered'} animation='grow' variant='primary'/>
							: <>
								{
									status && <div className={styles.controls}>
										<div className='row gx-3'>
											<div className='col-auto'>
												<div className={classNames(styles.status, `is-${status}`)}>
													<span className={styles.statusIcon}>{generateStatusIcon()}</span>
													<small className={classNames(styles.statusContent, 'text-dark d-none d-xl-block')}>
														{generateStatusContent()}
													</small>
												</div>
											</div>
											<div className='col-auto'>
												<button
													type='button'
													className={styles.delete}
													onClick={() => {
														setAttachment(null);
														setStatus(null);
														if (onAttachmentDelete && attachment)
															onAttachmentDelete(attachment)
													}}
												>
													<DeleteIcon width={20} height={20}/>
												</button>
											</div>
										</div>
									</div>
								}
								{
									attachment
										? (
											fileType === 'document'
												? <span className={classNames(styles.documentPlaceholder, 'text-primary')}><DocumentLoadedIcon/></span>
												: (documentSrc && <LazyImage alt={'Document'} src={withRootUrl(documentSrc)}/>)
										)
										: <label htmlFor={id} className={classNames(styles.labelMain, 'd-block h-100')}>
											<input
												type='file'
												id={id}
												className='visually-hidden'
												accept={accept}
												onChange={onChangeHandler}
											/>
											<span className={styles.itemIcon}><PlaceholderIcon/></span>
										</label>
								}

							</>
					}
				</div>
			</Ratio>
			<label htmlFor={id} className={classNames(styles.labelSecondary, 'd-inline-flex align-items-center justify-content-center justify-content-sm-start mt-3')}>
				<input
					type='file'
					id={id}
					className='visually-hidden'
					onChange={onChangeHandler}
					accept={accept}
				/>
				<span className={classNames(styles.itemIcon, 'd-inline-block mt-n1 mt-sm-0')}>
						{
							attachment
								? <GearIcon/>
								: <PlusIcon/>
						}
					</span>
				<span className={classNames(styles.labelSecondaryTitle, 'text-m d-inline-block ps-2 ps-sm-3')}>
						{
							attachment
								? 'Изменить'
								: 'Загрузить'
						}
					</span>
			</label>
			{
				errors[documentKey] && <p className={classNames(styles.error, 'text-danger text-nowrap mb-0')}>
					<small>{errors[documentKey].message}</small>
				</p>
			}
		</div>
	);
};

export default BorrowerAccountDocument;

import React, {FC, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {investContext} from '@context/investContext';
import {Button, Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import operationsApi from '@api/operationsApi';
import {CODES_RESPONSE_SUCCESS} from '@common/constants';

const InvestCancelModal:FC = () => {


	const
		{
			cancelModal,
			setCancelModal,
			currentProjectId,
			setCurrentProjectTitle,
			currentProjectTitle,
			setInvestmentPlace,
			setCancelConfirmModal
		} = useContext(investContext),
		[cancelInvestRequest, {isLoading: cancelInvestIsLoading}] = operationsApi.useCancelInvestRequestMutation()
	;

	const onCancelInvest = () => {
		currentProjectId && cancelInvestRequest(currentProjectId)
			.unwrap()
			.then(response => {
				if (response.code === CODES_RESPONSE_SUCCESS) {
					setCancelModal(false);
					setCancelConfirmModal(true)
				}
			})

	};

	return (
		<Modal
			show={cancelModal}
			onHide={() => {
				setCancelModal(false);
				setCurrentProjectTitle('');
				setInvestmentPlace(null)
			}}
			centered
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<div className="text-center">
					<Heading
						variant={HeadingVariant.large}
						tag={'h3'}
						className={'pb-4'}
					>
						Вы уверены, что хотите отменить инвестиции в проект{currentProjectTitle ? ` '${currentProjectTitle}' `: ''} ?
					</Heading>
					<Button
						as={ButtonTypesEnum.BUTTON}
						variant={ButtonVariant.contained}
						type={'submit'}
						className={'mt-5 px-5'}
						isLoading={cancelInvestIsLoading}
						onClick={onCancelInvest}
					>
						Да, отмените
					</Button>
				</div>

			</Modal.Body>
		</Modal>
	);
};

export default InvestCancelModal;

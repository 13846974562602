import React, { FC } from "react";
import InvestorMain from "@components/investor/Main/Main";
import { InvestorAside } from "@components/investor/Aside/Aside";
import { InvestProvider } from "@context/investContext";
import { InvestorContent } from "@components/investor/Content/Content";
import { WithdrawProvider } from "@context/withdrawContext";
import { ProfileProvider } from "@context/profileContext";

const Investor: FC = () => {
	return (
		<ProfileProvider>
			<WithdrawProvider>
				<InvestorMain>
					<InvestorAside />
					<InvestProvider>
						<InvestorContent />
					</InvestProvider>
				</InvestorMain>
			</WithdrawProvider>
		</ProfileProvider>
	);
};

export default Investor;

import React, {FC} from 'react';
import {ReactComponent as DocumentIcon} from '@icons/document-corner.svg';
import styles from './statementDocument.module.scss';
import classNames from 'classnames';
import {IStatement} from '@features/user/types';

const ProfileDataStatementDocument:FC<{document?: IStatement}> = ({document}) => {

	if (document) {
		const {title, url} = document;

		return <a className={styles.statement} href={url} target={'_blank'} rel="noreferrer">
			<div className="row gx-4 align-items-center">
				<div className="col-auto">
					<div className={styles.statementIcon}>
						<DocumentIcon/>
					</div>
				</div>
				<div className="col">
					{title && <p className={classNames(styles.statementTitle, 'mb-0 pt-1')}>{title}</p>}
				</div>
			</div>
		</a>
	}

	return null;
};

export default ProfileDataStatementDocument;

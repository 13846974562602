import React, {FC} from 'react';
import {HeadingProps} from '@components/ui/Heading/Heading.props';
import styles from './heading.module.scss';
import classNames from 'classnames';

const Heading: FC<HeadingProps> = (
	{
		tag,
		children,
		variant,
		className,
		...props
	}
): JSX.Element => {

	const TagName: keyof JSX.IntrinsicElements = `${tag}`;

	return <TagName className={classNames('heading', styles.root, styles[variant], className)} {...props}>{children}</TagName>;
};

export default Heading;

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
	IBorrowerManager,
	IBorrowerProfile,
	IInvestorInfo,
	IInvestorProfile,
	IUserState,
	UserDataKeysEnum
} from '@features/user/types';
import {STORAGE_TOKEN_KEY} from '@common/constants';
import {checkStorage} from '@common/utils';

const initialState: IUserState = {
	token: localStorage.getItem(STORAGE_TOKEN_KEY),
	investorInfo: {} as IInvestorInfo,
	investorProfile: {} as IInvestorProfile,
	borrowerProfile: {} as IBorrowerProfile,
	borrowerManager: null
};

type UserDataType = Partial<IInvestorInfo> | Partial<IInvestorProfile> | Partial<IBorrowerProfile> | null;

export interface IUserDataPayload {
	data: UserDataType
	key: UserDataKeysEnum
}

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setToken(state, {payload}: PayloadAction<string | null>) {
			state.token = payload;
			checkStorage(STORAGE_TOKEN_KEY, payload);
		},
		setUserData(state, {payload}: PayloadAction<IUserDataPayload>) {
			const {data, key} = payload;

			function setupUserData<T>(d: T | null): T {
				if (d === null) return {} as T;
				return {...state[key], ...d} as T
			}

			switch (key) {
				case UserDataKeysEnum.II:
					state[key] = setupUserData<Partial<IInvestorInfo>>(data) as IInvestorInfo;
					break;
				case UserDataKeysEnum.IP:
					state[key] = setupUserData<Partial<IInvestorProfile>>(data) as IInvestorProfile;
					break;
				case UserDataKeysEnum.BP:
					state[key] = setupUserData<Partial<IBorrowerProfile>>(data) as IBorrowerProfile;
					break;
			}
		},
		setManager(state, {payload}:PayloadAction<IBorrowerManager | null>) {
			state.borrowerManager = payload
		},
		resetUserData(state) {
			state[UserDataKeysEnum.II] = {} as IInvestorInfo;
			state[UserDataKeysEnum.IP] = {} as IInvestorProfile;
			state[UserDataKeysEnum.BP] = {} as IBorrowerProfile;
		}
	}
});

export default userSlice.reducer;







import React, {FC, useContext} from 'react';
import {IPersonalDataField} from '@components/investor/Views/Data/types';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {Controller, useFormContext} from 'react-hook-form';
import {MESSAGE_VALIDATION_REQUIRED_FIELD} from '@common/constants';
import {FormGroup} from '@components/ui';
import styles from '@components/investor/Views/Data/data.module.scss';
import {personalDataContext} from '@context/personalDataContext';

const ProfileDataFields:FC<{fields: IPersonalDataField[]}> = ({fields, children}) => {
	const
		{
			watch,
			register,
			setValue,
			control,
			formState: {errors}
		} = useFormContext(),
		{editMode} = useContext(personalDataContext)
	;

	return (
		<div className="row align-items-center g-3">
			{
				fields.map(({type, id, label, registerOptions}) => {

					const wrap = (children: React.ReactElement):JSX.Element => (<div key={id} className='col-sm-6 col-md-4'>{children}</div>);

					switch (type) {
						case FormGroupTypes.DATE:
							return wrap(
								<Controller
									name={id}
									control={control}
									rules={{
										required: MESSAGE_VALIDATION_REQUIRED_FIELD
									}}
									render={(
										{
											field: { onChange, onBlur, value },
											fieldState: {error},
										}
									) => (
										<FormGroup
											id={id}
											fieldType={FormGroupTypes.DATE}
											label={label}
											onDateChange={onChange}
											onBlur={onBlur}
											readonly={!editMode}
											selected={value ? new Date(value) : null}
											className={styles.formGroup}
											errorMessage={error?.message}
										/>
									)}
								/>
							);
						default: return wrap(
							<FormGroup
								id={id}
								fieldType={type}
								label={label}
								value={watch(id)}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(id, event.target.value)}
								className={styles.formGroup}
								register={register}
								errorMessage={errors[id]?.message}
								name={id}
								registerOptions={registerOptions}
								readonly={!editMode}
							/>
						)
					}
				})
			}
			{
				children
			}
		</div>
	)
};

export default ProfileDataFields;

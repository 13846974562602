import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {API_CLUB_PROPOSALS_URL} from '@common/constants';

import {baseQuery} from '@api/utils';
import {IClubProposal} from '@features/club/types';

const clubApi = createApi({
	reducerPath: 'clubApi',
	baseQuery,
	endpoints: build => ({
		proposals: build.query<IClubProposal[], void>({
			query: () => API_CLUB_PROPOSALS_URL
		})
	})
});

export default clubApi;

import React, { FC } from 'react';
import { ReactComponent as PhoneCall } from '@icons/phone-call.svg';
import styled from 'styled-components';

const StyledPhone = styled.a`
  transition: color .25s ease;
  font-size: 1rem;
  color: #5BC1FF;
  display: inline-flex;
  align-items: center;

  &:hover {
    color: #0087DA;
  }

  small {
    color: #96A6B8;
  }

`;

interface CompanyPhotoProps {
    withIcon?: boolean;
    className?: string;
}

const CompanyPhone: FC<CompanyPhotoProps> = ({ withIcon = false, className = '' }) => {
    return (
        <StyledPhone className={className} href="tel:88001013216">
            {
                withIcon && <PhoneCall width={43} height={43} />
            }
            <span className={`d-flex flex-column ps-0 ${withIcon ? 'ps-3' : ''}`}>
               <strong>8 (800) 101-32-16</strong>
                <small className="text-gray d-none d-xl-inline-block">
                    Бесплатно по России
                </small>
            </span>
        </StyledPhone>
    );
};

export default CompanyPhone;

import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {
	API_UTILS_COMPANY_DOCUMENTS_URL,
	API_UTILS_DOCUMENTS_URL,
	API_UTILS_FILE_URL,
	API_UTILS_SECTIONS_URL
} from '@common/constants';
import {IDocument, ISection, SectionsCodeEnum} from '@features/utils/types';
import {IAttachment} from '@common/types';

interface IFileRequestData {
	formData: FormData,
	isPrivate?: boolean
}

const utilsApi = createApi({
	reducerPath: 'utilsApi',
	baseQuery,
	endpoints: build => ({
		file: build.mutation<IAttachment[], IFileRequestData>({

			query: ({formData, isPrivate}) => {

				return {
					url: API_UTILS_FILE_URL,
					method: 'post',
					body: formData,
					params: {
						private: Boolean(isPrivate)
					}
				}
			}
		}),
		documents: build.query<IDocument[], {area: string}>({
			query: (params) => {
				const {area} = params;

				return {
					url: API_UTILS_DOCUMENTS_URL,
					params: {area}
				}
			}
		}),
		sections: build.query<ISection[], {section_code: SectionsCodeEnum | null}>({
			query: (params) => {
				const {section_code} = params;

				return {
					url: API_UTILS_SECTIONS_URL,
					params: section_code ? {section_code} : undefined
				}
			}
		}),
		companyDocuments: build.query<IDocument[], void>({
			query: () => API_UTILS_COMPANY_DOCUMENTS_URL
		}),
	})
});

export default utilsApi;

import React, {FC, useContext, useState} from 'react';
import classNames from 'classnames';
import styles from '@components/investor/InvestorStatus/investorStatus.module.scss';
import {Button} from '@components/ui';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {ReactComponent as StarColoredIcon} from '@icons/star-colored.svg';
import {ReactComponent as StarDefaultIcon} from '@icons/star-default.svg';
import {ReactComponent as UserIcon} from '@icons/default-avatar-clean.svg';
import {ReactComponent as WatchIcon} from '@icons/time-circle-full.svg';
import {ReactComponent as TrianglesIcon} from '@icons/triangles-colored.svg';
import {ReactComponent as LayersIcon} from '@icons/layers-colored.svg';
import {ReactComponent as StarColoredPartialIcon} from '@icons/star-colored-partial.svg';
import {useAppSelector} from '@hooks/useAppSelector';
import {InvestorLawTypeEnum, InvestorTypeEnum, VerificationStatusEnum} from '@features/user/types';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {ReactComponent as ErrorIcon} from '@icons/danger-circle-colored.svg';
import {profileContext} from '@context/profileContext';

const InvestorStatus:FC = () => {

	const
		{
			investorType,
			verificationStatus,
			lawType
		} = useAppSelector(state => state.user.investorProfile),
		{verifyErrorIsShown} = useContext(profileContext),
		[verificationOnCheckMessage] = useState<string>('Спасибо за вашу заявку. Мы обработаем её в ближайшее время.')
	;

	const
		circleDecoration:JSX.Element = <svg className={styles.decoration} width="240" height="228" viewBox="0 0 240 228" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M125.321 4.74555C-5.07837 36.5838 -24.2325 141.938 24.9902 227.869L237.926 119.859C243.857 70.8753 229.641 -20.725 125.321 4.74555Z" fill="#F4F6FB"/>
		</svg>,
		generateVerificationContent = ():JSX.Element => {

			const
				isNotVerified = verificationStatus === VerificationStatusEnum.NOT_VERIFIED,
				isOnCheck = verificationStatus === VerificationStatusEnum.ON_CHECK
			;

			return <div className={classNames(
				isNotVerified && styles.verificationNotVerified,
				isOnCheck && styles.verificationOnCheck
			)}>
				{circleDecoration}
				{isNotVerified && <UserIcon className={styles.verificationNotVerifiedIcon}/>}
				{isOnCheck && <WatchIcon className={styles.verificationOnCheckIcon}/>}
				<div className='position-relative'>
					<h5 className={styles.title}>
						{isNotVerified && 'Неверифицированный инвестор'}
						{isOnCheck && 'Верификация на проверке'}
					</h5>
					<p className={styles.message}>
						{isNotVerified && 'Заполните данные, чтобы начать инвестировать'}
						{isOnCheck && verificationOnCheckMessage}
					</p>
				</div>
			</div>
		},
		qualificationContent = (
			{
				rootClassName,
				icon,
				title,
				subtitle,
				qualifyButton,
				verifyCheckMessage
			}: {
				rootClassName: string
				icon: JSX.Element
				title: string
				subtitle?: string
				qualifyButton?: boolean
				verifyCheckMessage?: boolean
			}
		):JSX.Element => {

			return <div className={classNames(rootClassName)}>
				{circleDecoration}
				{icon}
				<div className='position-relative'>
					<h5 className={styles.title}>{title}</h5>
					{
						qualifyButton && <Button
							variant={ButtonVariant.contained}
							type={'button'}
							className={'d-inline-flex align-items-center mt-4'}
							icon={ButtonIconsVariant.arrowRight}
							as={ButtonTypesEnum.LINK}
							to={RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.QUALIFICATION}
						>
							Пройти квалификацию
						</Button>
					}
					{(verifyCheckMessage || subtitle) && <hr/>}
					{subtitle && <p className={classNames(styles.title, 'mb-0')}>{subtitle}</p>}
					{verifyCheckMessage && <p className={styles.message}>{verificationOnCheckMessage}</p>}
				</div>
			</div>
		},
		generateQualificationContent = ():JSX.Element | null => {

			const
				Q_isNotQualified = investorType === InvestorTypeEnum.NOT_QUALIFIED,
				V_isVerified = verificationStatus === VerificationStatusEnum.VERIFIED,
				V_changeOnCheck = verificationStatus === VerificationStatusEnum.CHANGE_ON_CHECK,
				Q_onChange = investorType === InvestorTypeEnum.ON_CHECK,
				Q_isQualified = investorType === InvestorTypeEnum.QUALIFIED
			;

			if (Q_isNotQualified && V_isVerified) {
				return qualificationContent({
					rootClassName: styles.qualificationNotQualified,
					icon: <StarDefaultIcon className={classNames(styles.star, styles.qualificationNotQualifiedIcon)}/>,
					title: 'Неквалифицированный инвестор',
					qualifyButton: true
				})
			}

			if (Q_isNotQualified && V_changeOnCheck) {
				return qualificationContent({
					rootClassName: styles.verificationChangeOnCheck,
					icon: <>
						<StarDefaultIcon className={styles.verificationChangeOnCheckStarIcon}/>
						<WatchIcon className={styles.verificationChangeOnCheckWatchIcon}/>
					</>,
					title: 'Неквалифицированный инвестор',
					subtitle: 'Изменения на проверке',
					verifyCheckMessage: true
				})
			}

			if (Q_isQualified && V_changeOnCheck) {
					return qualificationContent({
						rootClassName: styles.verificationChangeOnCheck,
						icon: <>
							<StarColoredIcon className={styles.verificationChangeOnCheckStarIcon}/>
							<WatchIcon className={styles.verificationChangeOnCheckWatchIcon}/>
						</>,
						title: 'Квалифицированный инвестор',
						subtitle: 'Изменения на проверке',
						verifyCheckMessage: true
					})
			}

			if (Q_onChange) {
				return qualificationContent({
					rootClassName: styles.qualificationOnCheck,
					icon: <StarColoredPartialIcon className={styles.qualificationOnCheckIcon}/>,
					title: 'Квалификация на проверке'
				})
			}

			if (Q_isQualified) {
				return qualificationContent({
					rootClassName: styles.qualificationQualified,
					icon: <StarColoredIcon className={styles.qualificationQualifiedIcon}/>,
					title: 'Квалифицированный инвестор'
				})
			}

			return null
		},
		defineLawName = ():string => {
			switch (lawType) {
				case InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR:
					return 'Индивидуальный предприниматель инвестор';
				case InvestorLawTypeEnum.ENTITY:
					return 'Юридическое лицо инвестор';
				default: return '';
			}
		},
		generateLawTypeContent = ():JSX.Element => {
			const verifyOnCheck = verificationStatus ===VerificationStatusEnum.ON_CHECK;
			const verifyChangeOnCheck = verificationStatus ===VerificationStatusEnum.CHANGE_ON_CHECK;
			const isOnCheck = verifyOnCheck || verifyChangeOnCheck;
			const isVerified = verificationStatus === VerificationStatusEnum.VERIFIED;

			return <div className={classNames(isOnCheck && styles.verificationOnCheck)}>
				{circleDecoration}
				<h5 className={classNames(styles.title, {
					'pb-5 mb-3': isVerified
				})}>
					{defineLawName()}
				</h5>
				{
					isOnCheck && <>
						<hr/>
						<p className={classNames(styles.title, 'mb-0')}>
							{verifyOnCheck && 'Верификация на проверке'}
							{verifyChangeOnCheck && 'Изменения на проверке'}
						</p>
						<p className={styles.message}>{verificationOnCheckMessage}</p>
						<WatchIcon className={styles.verificationChangeOnCheckWatchIcon}/>
					</>
				}
				{
					lawType === InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR && <TrianglesIcon className={
						classNames(styles.lawTypeTrianglesIcon, {
							[styles.lawTypeTrianglesIconOnCheck]: isOnCheck,
							[styles.lawTypeTrianglesIconVerified]: isVerified
						})
					}/>
				}
				{
					lawType === InvestorLawTypeEnum.ENTITY && <LayersIcon className={
						classNames(styles.lawTypeLayersIcon, {
							[styles.lawTypeLayersIconOnCheck]: isOnCheck,
							[styles.lawTypeLayersIconVerified]: isVerified
						})
					}/>
				}
			</div>
		}
	;

	return (
		<>
			<section className={classNames(styles.root)}>
				<p className={classNames(styles.subtitle, 'text-dark')}>Статус</p>
				{
					lawType === InvestorLawTypeEnum.INDIVIDUAL && <>
						{
							(
								verificationStatus === VerificationStatusEnum.NOT_VERIFIED
								|| verificationStatus === VerificationStatusEnum.ON_CHECK
							)
								? generateVerificationContent()
								: generateQualificationContent()
						}
					</>
				}

				{
					lawType != InvestorLawTypeEnum.INDIVIDUAL && generateLawTypeContent()
				}

			</section>
			{
				verifyErrorIsShown && <div className='text-danger d-flex pt-4 mt-3'>
					<ErrorIcon className='flex-shrink-0 me-3'/>
					<p>Завершите процесс верификации, чтобы инвестировать, пополнять баланс и выводить средства</p>
				</div>
			}
		</>
	);
};

export default InvestorStatus;




import { IVideo } from "@common/types";
import { BorrowerProjectStatusEnum, IBorrower } from "@features/borrower/types";
import { IDocument } from "@features/utils/types";

export interface IProject {
	projectId: number;
	title: string;
	description: string;
	goalAmount: number;
	goalCurrentAmount: number;
	goalEndDate: string;
	rate: number;
	term: number;
	rating: string;
	objectVideo: IVideo;
	objectImage: string;
	details: IProjectDetails;
	investAmount: string;
	alreadyInvested: string;
	status: BorrowerProjectStatusEnum;
	minLoan: string;
}

export interface IProjectDetails extends Omit<IProject, "details"> {
	profitExpected: string;
	actualObjectAddress: string;
	investGoal: string;
	monetizeWay: string;
	returnGuarantee: string;
	documents: IDocument[];
	borrower: IBorrower;
	bidId: number | null;
}

export interface IProjectParams {
	goalAmountMax: string;
	goalAmountMin: string;
	termMax: number;
	termMin: number;
	amountToInvestMin: string;
	amountToInvestMax: string;
}

export enum ParameterizedDocumentsEnum {
	OFFER = "investOfferDocument",
	CONDITIONS = "investorConditionsDocument",
	ACCEPT = "acceptDocument",
}

export type DocumentUrlType = { url: string };

export interface IParameterizedDocumentsPayload {
	id: IProject["projectId"];
	document: DocumentUrlType;
	name: ParameterizedDocumentsEnum;
}

export type ParameterizedDocumentType = {
	[key in ParameterizedDocumentsEnum]?: DocumentUrlType;
};

export interface IProjectsState {
	projects: IProject[];
	detailedProjects: { [key: IProject["projectId"]]: IProjectDetails };
	parameterizedDocuments: {
		[key: IProject["projectId"]]: ParameterizedDocumentType;
	};
}

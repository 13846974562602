import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {ReactComponent as SearchIcon} from '@icons/search.svg';
import {v4 as uuid} from 'uuid';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import {ADAPTIVE_BREAKPOINTS, TRANSITION_TIMEOUT} from '@common/constants';
import {CSSTransition} from 'react-transition-group';
import useWindowSize from '@hooks/useWindowSize';
import classNames from 'classnames';
import {useDetectClickOutside} from 'react-detect-click-outside/dist';
import {questionsContext} from '@context/questionsContext';
import styles from './questionsSearch.module.scss';

interface IProfileQuestionsSearchProps {
	value: string,
	onSearch: (value: string) => void,
	onReset: () => void
	onCollapse?: () => void
}

const ProfileQuestionsSearch:FC<IProfileQuestionsSearchProps> = ({value, onSearch, onReset, onCollapse}) => {
	const
		clearSearchBtnRef = useRef<HTMLButtonElement>(null),
		[expanded, setExpanded] = useState<boolean>(false),
		{setSearchQuestionModal} = useContext(questionsContext),
		{width: ww} = useWindowSize(),
		formRef = useDetectClickOutside({
			onTriggered() {
				setExpanded(false);
				onCollapse && onCollapse()
			}
		})
	;

	useEffect(() => {
		if (ww >= ADAPTIVE_BREAKPOINTS.xl || ww <= ADAPTIVE_BREAKPOINTS.sm) setExpanded(false);
		if (ww > ADAPTIVE_BREAKPOINTS.sm) setSearchQuestionModal(false);
	}, [ww]);

	useEffect(() => {
		(!expanded && onCollapse) && onCollapse()
	}, [expanded]);

	return (
		<form
			ref={formRef}
			className={classNames(styles.search, {[styles.searchExpanded]: ww < ADAPTIVE_BREAKPOINTS.xl && expanded})}
			onSubmit={event => event.preventDefault()}
		>
			<div className={styles.searchIcon} onClick={() => {
				if ((ww < ADAPTIVE_BREAKPOINTS.xl && ww >= ADAPTIVE_BREAKPOINTS.sm) && !expanded) setExpanded(true);
				if (ww < ADAPTIVE_BREAKPOINTS.sm) setSearchQuestionModal(true);
			}}>
				<SearchIcon/>
			</div>

			<FormGroup
				id={uuid()}
				fieldType={FormGroupTypes.SEARCH}
				placeholder={'Поиск...'}
				value={value}
				className={classNames('pb-0', {'h-100': ww < ADAPTIVE_BREAKPOINTS.xl})}
				onChange={event => onSearch(event.target.value)}
			/>
			<CSSTransition
				in={value.length > 0}
				classNames={'animation--fade-in'}
				timeout={TRANSITION_TIMEOUT}
				unmountOnExit
				nodeRef={clearSearchBtnRef}
			>
				<button
					ref={clearSearchBtnRef}
					className={styles.searchReset}
					type={'reset'}
					onClick={onReset}
				>Очистить поиск</button>
			</CSSTransition>
		</form>
	);
};

export default ProfileQuestionsSearch;

import React, {FC, useCallback, useRef, useState} from 'react';
import styles from '@components/common/LazyImage/lazyImage.module.scss';

import {Ratio} from 'react-bootstrap';
import {useMutationObserver} from '@hooks/useMutationObserver';
import {CSSTransition} from 'react-transition-group';
import {TRANSITION_TIMEOUT} from '@common/constants';
import classNames from 'classnames';
import DualBallLoader from '@images/dual-ball-loader.svg'
import {ReactComponent as ExclamationIcon} from '@icons/exclamation.svg';
import {AspectRatio} from 'react-bootstrap/Ratio';
import {LazyLoad} from '@components/common/LazyLoad/LazyLoad';

interface ILazyImageProps {
	src: string,
	alt?: string,
	aspectRatio?: AspectRatio | number,
	onLoad?: (image: HTMLImageElement) => void,
	onError?: (image: HTMLImageElement) => void,
	className?: string,
	hideLoader?: boolean
}

const LazyImage:FC<ILazyImageProps> = (
	{
		src,
		alt,
		aspectRatio = '1x1',
		onLoad = undefined,
		onError = undefined,
		className,
		hideLoader = false,
		...props
	}
) => {
	const imgRef = useRef(null);
	const imgWrapperRef = useRef(null);
	const [loaded, setLoaded] = useState<boolean>(false);
	const [error, setError] = useState<boolean>(false);

	const handleMutations = useCallback(mutations => {
		mutations.forEach(
			({type}:{type: MutationRecordType}) => {
				if (type === 'attributes') setLoaded(false)
			}
		);
	}, []);

	useMutationObserver({
		target: imgRef,
		options: {attributes: true},
		callback: handleMutations
	});

	return (
		<Ratio className={classNames(styles.wrapper, className)} aspectRatio={aspectRatio}>
			<>
				<LazyLoad>
					<CSSTransition
						in={loaded}
						nodeRef={imgWrapperRef}
						timeout={TRANSITION_TIMEOUT}
						classNames='animation--fade-in'
					>
						<>
							<div ref={imgWrapperRef} className={'w-100 h-100'}>
								<img
									ref={imgRef}
									className={classNames(styles.image, 'lazyImage', {
										'd-none': !loaded || error
									})}
									src={src}
									alt={alt}
									onLoad={() => {
										setLoaded(true);
										setError(false);
										(onLoad && imgRef.current) && onLoad(imgRef.current)
									}}
									onError={() => {
										setLoaded(true);
										setError(true);
										(onError && imgRef.current) && onError(imgRef.current)
									}}
								/>

							</div>
							{props.children}
						</>
					</CSSTransition>
				</LazyLoad>
				{(!loaded && !hideLoader) && <img src={DualBallLoader} className={classNames('loader', styles.loader)} alt="Loader"/>}
				{(error && loaded) && <ExclamationIcon className={styles.errorIcon}/>}
			</>
		</Ratio>
	);
};

export default LazyImage;

import React, {createContext, Dispatch, FC, SetStateAction, useEffect, useMemo, useState} from 'react';
import {IFilters, ISort, RangeNumbersType} from '@common/types';
import {DocumentUrlType, IProject, ParameterizedDocumentsEnum} from '@features/projects/types';
import {useAppSelector} from '@hooks/useAppSelector';
import {parameterizedDocumentIsValid} from '@common/utils';
import isEmpty from 'lodash.isempty';
import {SelectOptionType} from '@components/ui/FormGroup/FormGroup.props';
import {projectsSortingOptions} from '@components/investor/Views/Projects/Header/ProjectsHeader';
import {PROJECT_PARAM_GOAL_TOTAL_MAX, PROJECT_PARAM_RATE_MAX, PROJECT_PARAM_TERM_MAX} from '@common/constants';
import useDebounce from '@hooks/useDebounce';

enum SortingOptionsValueEnum {
	NEW = 'new',
	URGENCY = 'goalEndDate',
	RATE = 'rate'
}

export interface IProjectsContext {
	sort: SelectOptionType
	setSort: Dispatch<SetStateAction<SelectOptionType>>

	orders: ISort['orders']
	updateOrders: (key: string) => void

	filters: IFilters['filters']
	setFilters: Dispatch<SetStateAction<IFilters['filters']>>

	filterIsOpen: boolean
	setFilterIsOpen: Dispatch<SetStateAction<boolean>>

	filterIsInvolved: boolean
	setFilterIsInvolved: Dispatch<SetStateAction<boolean>>

	getParameterizedDocument: (id: IProject['projectId'], name: ParameterizedDocumentsEnum) => DocumentUrlType | null
	isParameterizedDocumentCashed: (id: IProject['projectId'], name: ParameterizedDocumentsEnum) => boolean

	filterTermDefault: RangeNumbersType
	filterTermDebounced: RangeNumbersType
	filterTerm: RangeNumbersType
	setFilterTerm: Dispatch<SetStateAction<RangeNumbersType>>


	filterGoalAmountDefault: RangeNumbersType
	filterGoalAmountDebounced: RangeNumbersType
	filterGoalAmount: RangeNumbersType
	setFilterGoalAmount: Dispatch<SetStateAction<RangeNumbersType>>


	filterRateDefault: RangeNumbersType
	filterRateDebounced: RangeNumbersType
	filterRate: RangeNumbersType
	setFilterRate: Dispatch<SetStateAction<RangeNumbersType>>
}

export const projectsContext = createContext<IProjectsContext>({} as IProjectsContext);

export const ProjectsProvider: FC = ({children}) => {

	const [filters, setFilters] = useState<IFilters['filters']>({});
	const [orders, setOrders] = useState<ISort['orders']>({});
	const [filterIsOpen, setFilterIsOpen] = useState<boolean>(false);
	const [filterIsInvolved, setFilterIsInvolved] = useState<boolean>(false);
	const {parameterizedDocuments} = useAppSelector(state => state.projects);
	const [sort, setSort] = useState<SelectOptionType>(projectsSortingOptions[0]);

	const
		termDefault:RangeNumbersType = [0, PROJECT_PARAM_TERM_MAX],
		[term, setTerm] = useState<RangeNumbersType>(termDefault),
		termDebounced = useDebounce<RangeNumbersType>(term)
	;

	const
		goalAmountDefault:RangeNumbersType = [0, PROJECT_PARAM_GOAL_TOTAL_MAX],
		[goalAmount, setGoalAmount] = useState<RangeNumbersType>(goalAmountDefault),
		goalAmountDebounced = useDebounce<RangeNumbersType>(goalAmount)
	;

	const
		rateDefault:RangeNumbersType = [0, PROJECT_PARAM_RATE_MAX],
		[rate, setRate] = useState<RangeNumbersType>(rateDefault),
		rateDebounced = useDebounce<RangeNumbersType>(rate)
	;

	const
		updateOrders = (key: string):void => {
		switch (key) {
			case SortingOptionsValueEnum.RATE:
			case SortingOptionsValueEnum.URGENCY:
				setOrders({[key]: 'ASC'});
				break;

			case SortingOptionsValueEnum.NEW:
			default:
				setOrders({});
				break;
		}
	},
		compareByMaxVal = (prop: RangeNumbersType, maxConst: number):boolean => {
			const [from, to] = prop;

			return from !== 0 || to < maxConst
		}
	;

	useEffect(() => {
		sort && updateOrders(sort.value)
	}, [sort]);

	useEffect(() => {
		setFilterIsInvolved(
			compareByMaxVal(term, PROJECT_PARAM_TERM_MAX)
			||
			compareByMaxVal(goalAmount, PROJECT_PARAM_GOAL_TOTAL_MAX)
			||
			compareByMaxVal(rate, PROJECT_PARAM_RATE_MAX)
		)
	}, [term, goalAmount, rate]);

	return <projectsContext.Provider value={
		{
			filters,
			setFilters,
			orders,
			updateOrders,
			filterIsOpen,
			setFilterIsOpen,
			filterIsInvolved,
			setFilterIsInvolved,
			getParameterizedDocument: useMemo(() => {
				return (id: IProject['projectId'], name: ParameterizedDocumentsEnum) => {

					if (parameterizedDocuments.hasOwnProperty(id) && parameterizedDocuments[id].hasOwnProperty(name)) {

						const document = parameterizedDocuments[id][name] as DocumentUrlType;

						return (document.hasOwnProperty('url') && Boolean(document.url)) ? document : null;
					}

					return null;
				}
			}, [parameterizedDocuments]),
			isParameterizedDocumentCashed: (id: IProject['projectId'], name: ParameterizedDocumentsEnum):boolean => {

				return !isEmpty(parameterizedDocuments) &&
					parameterizedDocuments.hasOwnProperty(id)
					&& parameterizedDocuments[id].hasOwnProperty(name)
					&& parameterizedDocumentIsValid(parameterizedDocuments[id][name] || null)
			},
			sort, setSort,
			filterTermDefault: termDefault,
			filterTermDebounced: termDebounced,
			filterTerm: term,
			setFilterTerm: setTerm,

			filterGoalAmountDefault: goalAmountDefault,
			filterGoalAmountDebounced: goalAmountDebounced,
			filterGoalAmount: goalAmount,
			setFilterGoalAmount: setGoalAmount,

			filterRateDefault: rateDefault,
			filterRateDebounced: rateDebounced,
			filterRate: rate,
			setFilterRate: setRate
		}
	}>
		{children}
	</projectsContext.Provider>
};




import React, {FC} from 'react';
import {NumberFormatValues} from 'react-number-format';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import classNames from 'classnames';
import styles from '@components/investor/WithdrawRequestModal/withdrawModal.module.scss';
import Slider from 'rc-slider';

interface IAmountInput {
	textOutput: string
	textOnValueChange: (values: NumberFormatValues) => void
	textOnBlur: (event: React.FocusEvent<HTMLInputElement>) => void
	sliderMinVal?: number
	sliderMaxVal: number
	amount: number
	sliderOnChange: (value: number) => void
	title: string
	subTitle: JSX.Element
	sliderNote?: JSX.Element
}

const AmountInput:FC<IAmountInput> = (
	{
		textOutput,
		textOnValueChange,
		textOnBlur,
		sliderMinVal = 0,
		sliderMaxVal,
		amount,
		sliderOnChange,
		title,
		subTitle,
		sliderNote
	}
) => {


	return (
		<>
			<div className="text-center pb-4">
				<p className={styles.title}>{title}</p>
				<p className='text-dark mb-0'>
					{subTitle}
				</p>
			</div>
			<FormGroup
				id={'withdrawAmount'}
				fieldType={FormGroupTypes.NUMBER_FORMAT}
				thousandSeparator={' '}
				className={classNames(styles.amount, 'mb-4 pb-0')}
				value={textOutput}
				suffix={' ₽'}
				onValueChange={textOnValueChange}
				onBlur={textOnBlur}
			/>
			<Slider
				min={sliderMinVal}
				max={sliderMaxVal}
				value={amount}
				onChange={sliderOnChange}
			/>
			{sliderNote}
		</>
	);
};

export default AmountInput;

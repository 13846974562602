import React, {FC} from 'react';
import {Paragraph} from '@components/ui';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import dialogStyles from '@components/auth/Dialog/dialog.module.scss';
import {PHONE_NUMBER_FORMAT} from '@common/constants';
import {optimizePhone} from '@common/utils';

export interface NoteProps {
	message: string,
	phone: string
}

const RecoveryNote: FC<NoteProps> = ({message, phone}) => {

	return (
		<div className={'pb-4 mb-4 text-center'}>
			<Paragraph
				className={classNames(dialogStyles.text, 'mb-2')}
			>{message}</Paragraph>
			<NumberFormat
				{...PHONE_NUMBER_FORMAT}
				className={classNames(dialogStyles.title)}
				displayType={'text'}
				value={optimizePhone(phone)}
			/>
		</div>
	);
};

export default RecoveryNote;

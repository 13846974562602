import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {
	API_USER_BORROWER_BID_CONFIRM_URL,
	API_USER_BORROWER_BID_REQUEST_URL, API_USER_BORROWER_BID_SIGN_CONFIRM_URL, API_USER_BORROWER_BID_SIGN_REQUEST_URL,
	API_USER_BORROWER_BIDS_URL,
	API_USER_BORROWER_CREDIT_HISTORY_URL,
	API_USER_BORROWER_DOCUMENTS_URL,
	API_USER_BORROWER_MANAGERS_URL,
	API_USER_BORROWER_PROFILE_URL,
	API_USER_BORROWER_PROJECT_URL,
	API_USER_BORROWER_PROJECTS_URL,
	GET_API_USER_BORROWER_CONDITIONS_URL,
	GET_API_USER_BORROWER_INVEST_OFFER_URL
} from '@common/constants';
import {IDefaultResponse} from '@common/types';
import {DocumentUrlType, IProjectDetails} from '@features/projects/types';
import {IBorrowerDocuments, IBorrowerManager, IBorrowerProfile} from '@features/user/types';
import {
	IBorrowerBid, IBorrowerBidDetails,
	IBorrowerBidRequest,
	IBorrowerLoans, IBorrowerProjectDetails,
} from '@features/borrower/types';

const borrowerApi = createApi({
	reducerPath: 'borrowerApi',
	refetchOnMountOrArgChange: true,
	baseQuery,
	endpoints: build => ({
		getProfile: build.query<IBorrowerProfile, void>({
			query: () => API_USER_BORROWER_PROFILE_URL
		}),
		patchProfile: build.mutation<IBorrowerProfile, Partial<IBorrowerProfile>>({
			query: patch => ({
				url: API_USER_BORROWER_PROFILE_URL,
				method: 'PATCH',
				body: patch
			})
		}),
		getManager: build.query<IBorrowerManager, IBorrowerManager['id']>({
			query: id => API_USER_BORROWER_MANAGERS_URL+`/${id}`
		}),
		uploadDocuments: build.mutation<IBorrowerDocuments, Partial<IBorrowerDocuments>>({
			query: body => ({
				url: API_USER_BORROWER_DOCUMENTS_URL,
				method: 'POST',
				body
			})
		}),
		investOfferDocument: build.mutation<DocumentUrlType, IProjectDetails['bidId']>({
			query: id => ({
				url: GET_API_USER_BORROWER_INVEST_OFFER_URL(id),
				method: 'get'
			})
		}),
		getConditionsDocument: build.query<DocumentUrlType, IProjectDetails['bidId']>({
			query: id => GET_API_USER_BORROWER_CONDITIONS_URL(id)
		}),
		getCreditHistoryDocument: build.query<DocumentUrlType, void>({
			query: () => API_USER_BORROWER_CREDIT_HISTORY_URL
		}),
		bidRequest: build.mutation<IDefaultResponse, IBorrowerBidRequest>({
			query: body => ({
				url: API_USER_BORROWER_BID_REQUEST_URL,
				method: 'POST',
				body
			})
		}),
		bidConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_USER_BORROWER_BID_CONFIRM_URL,
				method: 'POST',
				body: {code}
			})
		}),
		getProject: build.query<IBorrowerProjectDetails, number>({
			query: id => API_USER_BORROWER_PROJECT_URL+`/${id}`
		}),
		getProjects: build.query<IBorrowerLoans, void>({
			query: () => API_USER_BORROWER_PROJECTS_URL
		}),
		getBid: build.query<IBorrowerBidDetails, IProjectDetails['bidId']>({
			query: id => API_USER_BORROWER_BIDS_URL+`/${id}`
		}),
		getBids: build.query<IBorrowerBid[], void>({
			query: () => API_USER_BORROWER_BIDS_URL
		}),
		bidsSignRequest: build.mutation<IDefaultResponse, IProjectDetails['bidId']>({
			query: bidId => ({
				url: API_USER_BORROWER_BID_SIGN_REQUEST_URL,
				method: 'POST',
				body: {bidId}
			})
		}),
		bidsSignConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_USER_BORROWER_BID_SIGN_CONFIRM_URL,
				method: 'POST',
				body: {code}
			})
		})
	})
});


export default borrowerApi;

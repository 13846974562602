import React, {FC, useContext, useRef} from 'react';
import {TRANSITION_TIMEOUT} from '@common/constants';
import {CSSTransition} from 'react-transition-group';
import {ReactComponent as EditIcon} from '@icons/edit-default.svg';
import classNames from 'classnames';
import styles from './editButton.module.scss';
import {personalDataContext} from '@context/personalDataContext';

const ProfileDataEditButton:FC<{isVisible?: boolean}> = ({isVisible}) => {
	const
		editBtnRef = useRef<HTMLButtonElement>(null),
		{editMode, setEditMode, canBeEdited} = useContext(personalDataContext)
	;

	if (canBeEdited && isVisible)
		return (
			<CSSTransition
				in={!editMode}
				classNames={'animation--slide-x'}
				timeout={TRANSITION_TIMEOUT}
				unmountOnExit
				nodeRef={editBtnRef}
			>
				<button
					ref={editBtnRef}
					type={'button'}
					className={classNames(styles.edit, 'd-inline-flex align-items-center')}
					onClick={() => setEditMode(true)}
				>
					<EditIcon className={'me-3'}/>
					<span>Редактировать данные</span>
				</button>
			</CSSTransition>
		);

	return null
};

export default ProfileDataEditButton;

import { useState } from 'react'
import useEventListener from '@hooks/useEventListener';
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicLayoutEffect';
import debounce from 'lodash.debounce';
import {DEFAULT_DEBOUNCE} from '@common/constants';

interface WindowSize {
	width: number
	height: number
}

function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: 0,
		height: 0,
	});

	const handleSize = () => {
		setWindowSize({
			width: window.innerWidth,
			height: window.innerHeight,
		})
	};

	useEventListener('resize', debounce(handleSize, DEFAULT_DEBOUNCE));

	useIsomorphicLayoutEffect(() => {
		let isMounted = true; // Can't perform a React state update on an unmounted component error

		isMounted && handleSize();

		return () => {
			isMounted = false;
		}
	}, []);

	return windowSize
}

export default useWindowSize

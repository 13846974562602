import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';
import {API_DADATA_BANK_URL, API_DADATA_PARTY_URL, API_DADATA_TOKEN} from '@common/constants';
import {ISuggestion, ISuggestionResponse} from '@features/daData/types';

const daDataApi = createApi({
	reducerPath: 'daDataApi',
	tagTypes: ['Banks'],
	baseQuery: fetchBaseQuery({
		prepareHeaders: (headers) => {
			headers.set('Authorization', `Token ${API_DADATA_TOKEN}`);

			return headers
		}
	}),
	endpoints: build => ({
		banksSearch: build.mutation<ISuggestion[], string>({
			query: query => ({
				url: API_DADATA_BANK_URL,
				method: 'post',
				body: {query}
			}),
			invalidatesTags: ['Banks'],
			transformResponse: (response: ISuggestionResponse) => response.suggestions
		}),
		organisationSearch: build.mutation<ISuggestion[], string>({
			query: query => ({
				url: API_DADATA_PARTY_URL,
				method: 'post',
				body: {query}
			}),
			transformResponse: (response: ISuggestionResponse) => response.suggestions
		})
	})
});

export default daDataApi;

import React, {FC} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import styles from '@components/investor/Views/Data/data.module.scss';
import {useFormContext} from "react-hook-form";
import {INVESTOR_FIELD_NAME_LAW_TYPE} from '@components/investor/Views/Data/constants';
import {InvestorLawTypeEnum} from '@features/user/types';

export const ProfileDataChooseLawType:FC = () => {
	const
		{
			register,
			watch,
			setValue,
		} = useFormContext();

	const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_LAW_TYPE, event.target.value);

	return (
		<ProfileSection
			title={'Выберите организационно-правовую форму'}
			className={'mt-6'}
		>
			<div className="row">
				<div className='col-mb-6'>
					<div className="row">
						<div className='col-12'>
							<FormGroup
								register={register}
								id={INVESTOR_FIELD_NAME_LAW_TYPE+InvestorLawTypeEnum.INDIVIDUAL}
								className={styles.formGroup}
								fieldType={FormGroupTypes.RADIO}
								label={'Физическое лицо'}
								name={INVESTOR_FIELD_NAME_LAW_TYPE}
								readonly={false}
								value={InvestorLawTypeEnum.INDIVIDUAL}
								onChange={handleTypeChange}
								checked={watch(INVESTOR_FIELD_NAME_LAW_TYPE) === InvestorLawTypeEnum.INDIVIDUAL}
							/>
						</div>
						<div className='col-12'>
							<FormGroup
								register={register}
								id={INVESTOR_FIELD_NAME_LAW_TYPE+InvestorLawTypeEnum.ENTITY}
								className={styles.formGroup}
								fieldType={FormGroupTypes.RADIO}
								label={'Юридическое лицо'}
								name={INVESTOR_FIELD_NAME_LAW_TYPE}
								value={InvestorLawTypeEnum.ENTITY}
								readonly={false}
								onChange={handleTypeChange}
								checked={watch(INVESTOR_FIELD_NAME_LAW_TYPE) === InvestorLawTypeEnum.ENTITY}
							/>
						</div>
						<div className='col-12'>
							<FormGroup
								register={register}
								id={INVESTOR_FIELD_NAME_LAW_TYPE+InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR}
								className={styles.formGroup}
								fieldType={FormGroupTypes.RADIO}
								label={'Индивидуальный предприниматель'}
								onChange={handleTypeChange}
								name={INVESTOR_FIELD_NAME_LAW_TYPE}
								readonly={false}
								value={InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR}
								checked={watch(INVESTOR_FIELD_NAME_LAW_TYPE) === InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR}
							/>
						</div>
					</div>
				</div>
			</div>
		</ProfileSection>
	)
};





import React, {FC} from 'react';
import classNames from 'classnames';
import styles from './aside.module.scss';
import AsideHeader from '@components/profile/Aside/Header/AsideHeader';
import {IAsideInfoSource} from '@features/user/types';
import {Spinner} from 'react-bootstrap';
import {ReactComponent as ErrorIcon} from '@icons/error.svg';
import {Fade} from '@components/common/Transitions/Fade';
import {useUserInfoKey} from '@hooks/useUserInfoKey';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import SimpleBar from 'simplebar-react';

export interface IProfileAsideProps {
	userDataIsLoading?: boolean
	isLoadingError?: boolean
	toPersonalDataLink?: {
		inProp?: boolean
		to: string
	}
}

export interface IProfileAsideHeaderPropsPick extends Pick<
	IProfileAsideProps,
	'toPersonalDataLink'
	>{
	userInfoStateKey: keyof IAsideInfoSource
}

export const ProfileAside:FC<IProfileAsideProps> = (
	{
		toPersonalDataLink,
		userDataIsLoading = false,
		isLoadingError = false,
		...props
	}) => {

	const
		userInfoKey = useUserInfoKey(),
		{width: ww} = useWindowSize()
	;

	return (
		<SimpleBar className={classNames(styles.root, 'position-relative d-flex flex-column', {
			'visually-hidden': ww < ADAPTIVE_BREAKPOINTS.xl
		})}>
			{
				isLoadingError
					? <p className='d-flex flex-column align-items-center text-danger py-5 my-auto'>
						<ErrorIcon width={'50px'} height={'50px'}/>
						<span className={'pt-3'}>Ошибка загрузки данных профиля</span>
					</p>
					: <>
						{
							userDataIsLoading
								? <Spinner className={'spinner--centered'} animation='grow' variant='primary'/>
								: <Fade inProp={Boolean(userInfoKey)}>
									{
										userInfoKey && <AsideHeader
											userInfoStateKey={userInfoKey}
											toPersonalDataLink={toPersonalDataLink}
										/>
									}
								</Fade>
						}
						{props.children}
					</>
			}
		</SimpleBar>
	)
};

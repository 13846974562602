import React, {ComponentType, CSSProperties, ReactNode, useRef} from 'react';
import {useIntersectionObserver} from '@hooks/useIntersectionObserver';

interface LazyLoadProps {
	tag?: ComponentType | keyof JSX.IntrinsicElements
	children: ReactNode
	style?: CSSProperties
	className?: string
	root?: Element | Document | null
	threshold?: number | number[]
	rootMargin?: string
	forward?: boolean
}

export function LazyLoad(props: LazyLoadProps):JSX.Element {
	const { tag = 'div', children, style, className } = props;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const Tag: any = tag;
	const ref = useRef<Element>(null);
	const isIntersecting = useIntersectionObserver(ref, {
		root: props.root ?? null,
		threshold: props.threshold ?? 0,
		rootMargin: props.rootMargin
	}, props.forward);

	return (
		<Tag
			ref={ref}
			style={style}
			className={className}
		>
			{isIntersecting ? children : null}
		</Tag>
	)
}

import React, {
	FC,
	createContext,
	useState,
	Dispatch,
	SetStateAction,
} from "react";
import { NumberFormatValues } from "react-number-format";
import { REQUIRED_CODE_LENGTH, DEFAULT_ERROR_MESSAGE } from "@common/constants";
import { PhoneNumberType } from "@common/types";
import { useAppActions } from "@hooks/useAppActions";
import authApi from "@api/authApi";
import { toast } from "react-toastify";
import ToastErrorMessage from "@components/common/Toast/ToastErrorMessage";
import { defaultErrorOptions } from "@api/errorHandler";
import { useHistory } from "react-router-dom";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { validatePhoneAsString } from "@common/utils";
import { useAppSelector } from "@hooks/useAppSelector";

interface IAuthContext {
	phone: PhoneNumberType;
	email: string;
	rulesAgreement: boolean;
	personalDataAgreement: boolean;
	code: string;
	error: string | null;
	smsError: boolean;
	updatePhone: (value: string) => void;
	updateEmail: (value: string) => void;
	updateRulesAgreement: (event: React.ChangeEvent<HTMLInputElement>) => void;
	updatePersonalDataAgreement: (
		event: React.ChangeEvent<HTMLInputElement>,
	) => void;
	updateCode: ({ value }: NumberFormatValues) => void;
	setCode: Dispatch<SetStateAction<string>>;
	setSmsError: Dispatch<SetStateAction<boolean>>;
	clearCode: () => void;
	recoverPasswordHandler: () => void;
	registrationRequestHandler: () => void;
	updateError: (value: string | null) => void;
	aboutModalShown: boolean;
	setAboutModalShown: Dispatch<SetStateAction<boolean>>;
}

export const authContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
	const [phone, setPhone] = useState<PhoneNumberType>(null),
		[email, setEmail] = useState<string>(""),
		[rulesAgreement, setRulesAgreement] = useState<boolean>(false),
		[smsError, setSmsError] = useState<boolean>(true),
		[personalDataAgreement, setPersonalDataAgreement] =
			useState<boolean>(false),
		[code, setCode] = useState<string>(""),
		[error, setError] = useState<string | null>(null),
		{ setAuthLoading } = useAppActions(),
		[recoverPasswordRequest] = authApi.useRecoverPasswordRequestMutation(),
		[tryRegister] = authApi.useRegisterRequestMutation(),
		history = useHistory(),
		{ role } = useAppSelector((store) => store.auth),
		[aboutModalShown, setAboutModalShown] = useState<boolean>(false);
	const updateError = (value: string | null) => {
		setError(value);

		if (value)
			toast.error(<ToastErrorMessage message={value} />, defaultErrorOptions);
	};

	const payload = {
		phone,
		email,
		rulesAgreement,
		personalDataAgreement,
		code,
		error,
		smsError,
		updatePhone: (value: string) => setPhone(validatePhoneAsString(value)),
		updateEmail: (value: string) => setEmail(value),
		updateRulesAgreement: (event: React.ChangeEvent<HTMLInputElement>) =>
			setRulesAgreement(event.target.checked),
		updatePersonalDataAgreement: (event: React.ChangeEvent<HTMLInputElement>) =>
			setPersonalDataAgreement(event.target.checked),
		updateCode: ({ value }: NumberFormatValues) =>
			value.length <= REQUIRED_CODE_LENGTH && setCode(value),
		setCode,
		setSmsError,
		clearCode: () => setCode(""),
		updateError,
		recoverPasswordHandler: () => {
			if (Boolean(phone)) {
				setAuthLoading(true);
				setCode("");
				phone &&
					recoverPasswordRequest({ phone })
						.unwrap()
						.then(() => {
							history.push(
								RouteNamesEnum.AUTH +
									AuthRouteNamesEnum.RECOVER_PASSWORD_CONFIRM,
							);
						})
						.catch((e) => updateError(e.data.message))
						.finally(() => setAuthLoading(false));

				return;
			}

			updateError(DEFAULT_ERROR_MESSAGE);
		},
		registrationRequestHandler: () => {
			setAuthLoading(true);

			typeof phone === "number" &&
				tryRegister({
					body: {
						email,
						phone,
						isAgreementWithRules: rulesAgreement,
						isPersonalDataProcessing: personalDataAgreement,
					},
					type: role,
				})
					.unwrap()
					.then(() => {
						history.push(
							RouteNamesEnum.AUTH + AuthRouteNamesEnum.REGISTRATION_CONFIRM,
						);
					})
					.finally(() => setAuthLoading(false));
		},
		aboutModalShown,
		setAboutModalShown,
	};

	return (
		<authContext.Provider value={payload}>{children}</authContext.Provider>
	);
};

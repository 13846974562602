import React, {FC, useEffect, useState} from 'react';
import {Button, Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import styles from './notFound.module.scss';
import classNames from 'classnames';
import {ReactComponent as SadIcon} from '@icons/sad.svg';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {useAppSelector} from '@hooks/useAppSelector';
import {AuthRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {ReactComponent as LogoIcon} from '@icons/logo.svg';
import {Link} from 'react-router-dom';
import {useRedirectToHomePage} from '@hooks/useRedirectToHomePage';


const NotFound:FC = () => {

	const
		{isAuthorized} = useAppSelector(state => state.auth),
		[route, setRoute] = useState<string>(''),
		[fallbackRouteName, setFallbackRouteName] = useState<string>(''),
		{baseHomeRoute} = useRedirectToHomePage()
	;

	useEffect(() => {
		baseHomeRoute && setRoute(isAuthorized
			? baseHomeRoute
			: RouteNamesEnum.AUTH+AuthRouteNamesEnum.CHECK_PHONE
		);

		setFallbackRouteName(isAuthorized ? 'Личным данным' : 'Авторизации');
	}, [isAuthorized, baseHomeRoute]);

	return (
		<div className={classNames(styles.view, 'p-5 d-flex flex-column align-items-center')}>
			<div className="py-5">
				<Link to={route}><LogoIcon width={200} height={50} className={'my-5'}/></Link>
			</div>
			<div className={classNames(styles.content, 'text-center pt-5 mt-5')}>


				<SadIcon className={styles.icon}/>
				<Heading
					tag={'h2'}
					variant={HeadingVariant.large}
					className={'py-5'}
				>Страница не найдена...</Heading>


				<Button
					variant={ButtonVariant.contained}
					as={ButtonTypesEnum.LINK}
					to={route}
				>

					Вернутся к {fallbackRouteName}
				</Button>
			</div>
		</div>
	);
};

export default NotFound;

import React, {FC} from 'react';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {Controller, useFormContext} from 'react-hook-form';
import {BorrowerProfileKeysEnum} from '@features/user/types';
import {MESSAGE_VALIDATION_REQUIRED_FIELD} from '@common/constants';

const BorrowerAccountDataFounder:FC = () => {

	const {
		register,
		setValue,
		watch,
		control,
		formState: {errors}
	} = useFormContext();

	return (
		<ProfileSection title={'Персональные данные руководителя'}>
			<div className="row g-3">
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						fieldType={FormGroupTypes.TEXT}
						id={'BorrowerAccountFounderLastName'}
						label={'Фамилия'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.LAST_NAME, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.LAST_NAME)}
						register={register}
						name={BorrowerProfileKeysEnum.LAST_NAME}
						errorMessage={errors[BorrowerProfileKeysEnum.LAST_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						fieldType={FormGroupTypes.TEXT}
						id={'BorrowerAccountFounderFirstName'}
						label={'Имя'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.FIRST_NAME, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.FIRST_NAME)}
						register={register}
						name={BorrowerProfileKeysEnum.FIRST_NAME}
						errorMessage={errors[BorrowerProfileKeysEnum.FIRST_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						fieldType={FormGroupTypes.TEXT}
						id={'BorrowerAccountFounderMiddleName'}
						label={'Отчество'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.MIDDLE_NAME, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.MIDDLE_NAME)}
						register={register}
						name={BorrowerProfileKeysEnum.MIDDLE_NAME}
						errorMessage={errors[BorrowerProfileKeysEnum.MIDDLE_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						fieldType={FormGroupTypes.TEXT}
						id={'BorrowerAccountFounderFullNameGenitive'}
						label={'ФИО в родительном падеже'}
						placeholder={'Ивановой Елены Сергеевны'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.GENITIVE_FIO, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.GENITIVE_FIO)}
						register={register}
						name={BorrowerProfileKeysEnum.GENITIVE_FIO}
						errorMessage={errors[BorrowerProfileKeysEnum.GENITIVE_FIO]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<Controller
						name={BorrowerProfileKeysEnum.BIRTH_DATE}
						control={control}
						rules={{
							required: MESSAGE_VALIDATION_REQUIRED_FIELD
						}}
						render={(
							{
								field: { onChange, onBlur, value },
								fieldState: {error},
							}
						) => (

							<FormGroup
								fieldType={FormGroupTypes.DATE}
								onDateChange={onChange}
								onBlur={onBlur}
								selected={value ? new Date(value) : null}
								id={'BorrowerAccountFounderBirthDate'}
								label={'Дата рождения'}
								errorMessage={error?.message}
							/>
						)}
					/>

				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						fieldType={FormGroupTypes.TEXT}
						id={'BorrowerAccountFounderPost'}
						label={'Должность руководителя'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.POST, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.POST)}
						register={register}
						name={BorrowerProfileKeysEnum.POST}
						errorMessage={errors[BorrowerProfileKeysEnum.POST]?.message}
					/>
				</div>
			</div>
		</ProfileSection>
	);
};

export default BorrowerAccountDataFounder;

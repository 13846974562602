import React, {FC} from 'react';
import ProfileContainer from '@components/profile/Views/Container/Container';
import {ProfilePagesTitleEnum} from '@common/types';
import operationsApi from '@api/operationsApi';
import {Spinner} from 'react-bootstrap';
import styles from './requisites.module.scss';
import classNames from 'classnames';
import ProfileSection from '@components/profile/Section/ProfileSection';
import InvestorRequisitesBackButton from '@components/investor/Views/Requisites/RequisitesBackButton';

export const ProfileRequisites:FC = () => {

	const {
		data: requisites,
		isLoading: requisitesIsLoading,
		isFetching: requisitesIsFetching
	} = operationsApi.useRequisitesQuery();

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.REQUISITES} >
			<div className="row">
				<div className="col-lt-8">
					<ProfileSection title={'Реквизиты для пополнения счета инвестора'} className={styles.main}>
						{
							requisitesIsLoading || requisitesIsFetching
								? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
								: <div className={'row'}>
									<div className="col-sm-8 col-md-8">
										<div className={styles.table}>
											<table>
												<tbody>
												<tr><td>Получатель</td><td>{requisites?.receiver}</td></tr>
												<tr><td>ИНН</td><td>{requisites?.inn}</td></tr>
												<tr><td>КПП</td><td>{requisites?.kpp}</td></tr>
												<tr><td>Банк</td><td>{requisites?.bankName}</td></tr>
												<tr><td>БИК</td><td>{requisites?.bik}</td></tr>
												<tr><td>Корр/сч</td><td>{requisites?.correspondentAccount}</td></tr>
												<tr><td>Номер счета</td><td>{requisites?.paymentAccount}</td></tr>
												<tr>
													<td>Назначение</td>
													<td>
														<p className={''}>{requisites?.paymentPurpose}</p>
														<small className={'text-dark'}>
															Указывайте именно такое назначение платежа. Это ускорит поступление денег.
														</small>
													</td>
												</tr>
												</tbody>
											</table>
											<p className={'text-danger pb-4'}>Во избежании сложностей с выводом пополняйте баланс только со своего личного счета.
												Обычно зачисление происходит в течении суток, но в редких случаях платеж может обрабатываться до трех банковских дней.
											</p>
											<InvestorRequisitesBackButton
												className={'mt-4 mt-md-5 d-none d-sm-inline-block'}
											/>
										</div>
									</div>
									<div className="col-sm-4 col-md-4">
										{
											requisites?.qrcode &&
											<p
												className={
													classNames(
														styles.qr,
														'text-dark px-md-3 d-flex flex-sm-column text-sm-center align-items-center'
													)
												}
											>
												<img src={'data:image/png;base64,'+requisites.qrcode} alt="QR-код для оплаты"/>
												<small className={'d-inline-block pt-sm-2 flex-grow-1 px-sm-2'}>QR код для оплаты из мобильного приложения вашего банка.</small>
											</p>
										}
									</div>
								</div>
						}
					</ProfileSection>
					<InvestorRequisitesBackButton
						className={'mt-4 d-sm-none'}
						wrapperClassName={'text-center'}
					/>
				</div>
			</div>
		</ProfileContainer>
	);
};


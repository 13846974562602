import React, {FC, useEffect, useState} from 'react';
import {getCurrentTime} from '@common/utils';

interface IErrorMessageProps {
	message: string
}

const ToastErrorMessage: FC<IErrorMessageProps> = (props) => {
	const [errorTime, setErrorTime] = useState<string>(getCurrentTime());

	useEffect(() => {
		setErrorTime(getCurrentTime())
	}, [props.message]);

	return (
		<>
			<div><small>{errorTime}</small></div>
			<div className={'text-black pt-2'}><strong>{props.message}</strong></div>
		</>
	)
};

export default ToastErrorMessage;

import React, {FC, HTMLAttributes, useState} from 'react';
import {IVideo} from '@common/types';
import LazyImage from '@components/common/LazyImage/LazyImage';
import {configureAssetPath, withRootUrl} from '@common/utils';
import {Ratio} from 'react-bootstrap';
import {ReactComponent as AssetPlaceholderIcon} from '@icons/asset-placeholder.svg';
import {ReactComponent as PlayIcon} from '@icons/play-colored.svg';
import styles from './videoPlayer.module.scss'
import classNames from 'classnames';


interface IVideoPlayerProps {
	video: IVideo,
	emptyText?: string,
	onPlayClick?: (currentVideo: IVideo) => void
}

const VideoPlayer:FC<IVideoPlayerProps & HTMLAttributes<HTMLDivElement>> = (
	{
		video,
		onPlayClick,
		emptyText = 'Видео скоро будет доступно',
		className
	}
) => {

	const [posterLoaded, setPosterLoaded] = useState<boolean>(false);

	return (
		<div className={classNames(styles.wrap, className)}>

			{
				video
					? <LazyImage
						aspectRatio={'16x9'}
						src={withRootUrl(configureAssetPath(video.preview) || '') || ''}
						onLoad={() => setPosterLoaded(true)}
					>
						<div className={classNames( styles.player, {
							'is-loaded': posterLoaded
						})}>
							<button
								type={'button'}
								className={classNames(styles.play, {
									'is-loaded': posterLoaded
								})}
								onClick={() => {
									onPlayClick && onPlayClick(video)
								}}
							>
								<PlayIcon className={'playIcon'}/>
							</button>
						</div>
					</LazyImage>
					: <Ratio aspectRatio={'16x9'} className={styles.placeholder}>
						<div className={styles.placeholderContent}>
							<AssetPlaceholderIcon/>
							{emptyText && <span className={classNames(styles.empty, 'pt-3')}>{emptyText}</span>}
						</div>
					</Ratio>
			}
		</div>
	);
};

export default VideoPlayer;

import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import styles from './notifications.module.scss'
import {INotification} from '@features/notifications/types';
import classNames from 'classnames';
import {ReactComponent as NotifyIcon} from '@icons/notification.svg';
import notificationsApi from '@api/notificationsApi';
import {Dropdown} from 'react-bootstrap';
import buttonStyles from '@components/ui/Button/button.module.scss';
import SimpleBar from 'simplebar-react';
import Heading from '@components/ui/Heading/Heading';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {useAppActions} from '@hooks/useAppActions';
import {useAppSelector} from '@hooks/useAppSelector';
import ProfileNotificationItem from '@components/profile/Header/Notifications/NotificationItem';
import {ADAPTIVE_BREAKPOINTS, CODES_RESPONSE_SUCCESS} from '@common/constants';
import useWindowSize from '@hooks/useWindowSize';
import {profileContext} from '@context/profileContext';
import {usePoolingInterval} from '@hooks/usePoolingInterval';

interface IProfileHeaderNotificationsProps {
	viewedTimeout?: number,
	poolingIntervalDefault?: number
}

const ProfileHeaderNotifications:FC<IProfileHeaderNotificationsProps> = ({viewedTimeout = 500}) => {
	const
		pollingInterval = usePoolingInterval(),
		{
			data: notificationData = [] as INotification[],
			isLoading: notificationIsLoading
		} = notificationsApi.useUserQuery(undefined, {pollingInterval}),
		[markAsViewed, {isLoading: markAsViewedIsLoading}] = notificationsApi.useMarkAsViewedMutation(),
		[deleteNotificationApi] = notificationsApi.useDeleteMutation(),
		markAsViewedTimer = useRef<ReturnType<typeof setTimeout> | null>(null),
		{notifications} = useAppSelector(state => state.notifications),
		{setNotifications, deleteNotification} = useAppActions(),
		[notifyIsLoading, setNotifyIsLoading] = useState<boolean>(false),
		{width: ww} = useWindowSize(),
		{setMobileNotificationsShown} = useContext(profileContext)
	;

	const handleMouseEnter = (notification: INotification) => {
		const {notificationId, viewed} = notification;

		if (!viewed) {
			markAsViewedTimer.current = setTimeout(() => {
				markAsViewed(notificationId)
					.unwrap()
					.then(response => {
						if (response.code === CODES_RESPONSE_SUCCESS) {
							setNotifications(notifications.map(notification => {
								if (notification.notificationId === notificationId) {
									return {
										...notification,
										viewed: true
									}
								}

								return notification
							}))
						}
					})
			}, viewedTimeout)
		}
	};

	const deleteNotificationHandler = (id: string) => {
		setNotifyIsLoading(true);

		deleteNotificationApi(id)
			.unwrap()
			.then(response => {
				if (response.code === CODES_RESPONSE_SUCCESS) {
					deleteNotification(id);
				}
			})
			.finally(() => setNotifyIsLoading(false))
		;
	};



	useEffect(() => {
		notificationData.length > 0 && setNotifications(notificationData);
	}, [notificationData]);

	useEffect(() => {
		setNotifyIsLoading(notificationIsLoading || markAsViewedIsLoading)
	}, [notificationIsLoading, markAsViewedIsLoading]);

	return (
		<div
			className={classNames(styles.root, 'text-dark')}
			style={{
				pointerEvents: notifyIsLoading ? 'none' : 'auto'
			}}
		>

			{
				ww >= ADAPTIVE_BREAKPOINTS.sm
					? <Dropdown>
						<Dropdown.Toggle
							className={classNames(styles.icon, buttonStyles.icon, {
								['is-active']: notifications.filter(notification => !notification.viewed).length > 0
							})}
						>
							<NotifyIcon/>
						</Dropdown.Toggle>
						<Dropdown.Menu>

							<div
								className={classNames(styles.dropdown)}
								style={{
									minHeight: notifications.length === 0 ? 'initial' : ''
								}}
							>
								{
									notifications.length === 0
										? <Heading tag={'h4'} className={'text-center mb-0 py-2'} variant={HeadingVariant.small}>Нет уведомлений</Heading>
										: <SimpleBar autoHide={false}>
											<ul className={classNames(styles.list, 'mb-0')}>
												{
													notifications.map((notification) => {

														const {
															notificationId,
															typeCode,
															message,
															viewed
														} = notification;

														return (
															<ProfileNotificationItem
																key={notificationId}
																viewed={viewed}
																typeCode={typeCode}
																message={message}
																onMouseEnter={() => handleMouseEnter(notification)}
																onMouseLeave={() => markAsViewedTimer.current && clearTimeout(markAsViewedTimer.current)}
																onDelete={() => deleteNotificationHandler(notificationId)}
															/>
														)
													})
												}
											</ul>
										</SimpleBar>
								}
							</div>
							{notifyIsLoading && <div className={styles.preloader}><span className={styles.preloaderInner}/></div>}
						</Dropdown.Menu>
					</Dropdown>
					: <button
						className={classNames(styles.icon, buttonStyles.icon, {
							['is-active']: notifications.filter(notification => !notification.viewed).length > 0
						})}
						onClick={() => {
							setMobileNotificationsShown(true)
						}}
					>
						<NotifyIcon/>
					</button>
			}


		</div>
	);
};

export default ProfileHeaderNotifications;

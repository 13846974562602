import React, {FC} from 'react';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {Button} from '@components/ui';
import classNames from 'classnames';

const InvestorRequisitesBackButton:FC<
	{
		content?: string | JSX.Element
		className?: string
		wrapperClassName?: string
	}
	> = (
	{content = 'Вернуться в личный кабинет', className, wrapperClassName}
) => {
	return (
		<Button
			variant={ButtonVariant.outlined}
			type={'button'}
			as={ButtonTypesEnum.LINK}
			to={RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.CABINET}
			className={classNames(className)}
			wrapperClassName={wrapperClassName}
		>{content}</Button>
	);
};

export default InvestorRequisitesBackButton;

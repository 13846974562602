import React, {FC, useContext, useRef} from 'react';
import {CSSTransition, SwitchTransition} from 'react-transition-group';
import {TRANSITION_TIMEOUT} from '@common/constants';
import {profileContext} from '@context/profileContext';
import styles from './profileHeader.module.scss';
import classNames from 'classnames';

const ProfileHeaderTransitionTitle:FC = () => {

	const
		animatedRef = useRef(null),
		{viewTitle} = useContext(profileContext)
	;

	return (
		<SwitchTransition>
			<CSSTransition
				nodeRef={animatedRef}
				key={viewTitle}
				timeout={TRANSITION_TIMEOUT / 2}
				classNames={'animation--slide-x'}
			>
				<div ref={animatedRef}>
					<p className={classNames(styles.title, 'text-dark mb-0')}>{viewTitle}</p>
				</div>
			</CSSTransition>
		</SwitchTransition>
	);
};

export default ProfileHeaderTransitionTitle;

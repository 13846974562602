import React, { FC, memo, useEffect, useState } from 'react';
import ProfileQuestionsControlBox from '@components/investor/Views/Questions/components/controlBox/QuestionsControlBox';
import {
	IQuestionTheme
} from '@common/types';
import {Spinner} from 'react-bootstrap';
import questionsApi from '@api/questionsApi';
import {searchFiltersByFields, searchWithParams} from '@common/utils';
import useDebounce from '@hooks/useDebounce';
import ProfileSection from '@components/profile/Section/ProfileSection';
import profileSectionStyles from '@components/profile/Section/profileSection.module.scss';
import ProfileQuestionsSearchOutput from '@components/investor/Views/Questions/components/search/QuestionsSearchOutput';
import ProfileQuestionsCommonThemeOutput from '@components/investor/Views/Questions/tabs/QuestionsCommon/QuestionsCommonThemeOutput';
import ProfileQuestionsAsk from '@components/investor/Views/Questions/components/ask/QuestionsAsk';
import ProfileQuestionsSearch from '@components/investor/Views/Questions/components/search/QuestionsSearch';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import classNames from 'classnames';
import styles from '../../questions.module.scss';
import {defineBoxTheme} from '@components/investor/Views/Questions/utils';

export const commonQuestionsSearchFields: string[] = ['title', 'question', 'answer'];

const ProfileQuestionsCommon:FC = () => {
	const
		[searchQuery, setSearchQuery] = useState<string>(''),
		[questionsByThemes, setQuestionsByThemes] = useState<IQuestionTheme[]>([]),
		[currentTheme, setCurrentTheme] = useState<IQuestionTheme | null>(null),
		[searchResults, setSearchResults] = useState<IQuestionTheme[]>([]),
		debouncedSearchQuery = useDebounce(searchQuery),
		{width: ww} = useWindowSize(),
		[adaptiveSearchMode, setAdaptiveSearchMode] = useState<boolean>(false)
	;

	const
		[
			getQuestions,
			{isLoading: questionsDataIsLoading}
		] = questionsApi.useSearchMutation(),
		[
			getThemes,
			{
				data: themesData,
				isLoading: themesDataIsLoading
			}
		] = questionsApi.useGetThemesMutation()
	;

	useEffect(() => {
		getThemes()
	}, []);

	useEffect(() => {
		if (themesData) setQuestionsByThemes(themesData);
	}, [themesData]);

	useEffect(() => {
		if (searchQuery.length === 0) setCurrentTheme(questionsByThemes[0] || null);
	}, [questionsByThemes]);

	useEffect(() => {
		if (debouncedSearchQuery.length > 0) {
			setCurrentTheme(null);

			getQuestions(searchWithParams({
				limit: -1,
				filters: searchFiltersByFields(commonQuestionsSearchFields, debouncedSearchQuery)
			}))
				.unwrap()
				.then(response => setSearchResults(response));
		} else {
			setSearchResults([]);
			setCurrentTheme(questionsByThemes[0])
		}

		setAdaptiveSearchMode(debouncedSearchQuery.length > 0);
	}, [debouncedSearchQuery]);

	return (
		<>
			<ProfileSection
				title={'Вопросы по темам'}
				nearbyTitleContent={
					<div className={'d-xl-none'}>
						<ProfileQuestionsSearch
							value={searchQuery}
							onSearch={query => setSearchQuery(query)}
							onReset={() => {
								setSearchQuery('');
								setSearchResults([]);
								setCurrentTheme(themesData ? themesData[0] : null)
							}}
						/>
					</div>
				}
			>

				{
					(adaptiveSearchMode && ww < ADAPTIVE_BREAKPOINTS.xl)
						? <ProfileQuestionsSearchOutput
							searchResults={searchResults}
							isLoading={questionsDataIsLoading}
						/>
						: themesDataIsLoading
						? <Spinner
							className={'spinner--centered'}
							size={ww <= ADAPTIVE_BREAKPOINTS.sm ? 'sm' : undefined}
							animation='border' variant='primary'/>
						: <div className="row g-4">

							{
								questionsByThemes.map((questionTheme) => {
									const {title, themeId, icon, type, questions} = questionTheme;

									return <div key={themeId} className="col-xl-6 col-xxl-4 col-lt-3">
										<ProfileQuestionsControlBox
											questions={questions}
											title={title}
											checked={currentTheme?.themeId === themeId}
											onRadioChange={() => {
												setCurrentTheme(questionTheme);
												setSearchQuery('');
											}}
											{...defineBoxTheme(icon, type)}
										/>
									</div>
								})
							}

						</div>
				}


			</ProfileSection>
			<div className={classNames(profileSectionStyles.item, styles.searchSection)}>
				<div className="row g-4 pb-2 d-none d-xl-flex">
					<div className="col-xxl-6">
						<ProfileQuestionsSearch
							value={searchQuery}
							onSearch={query => setSearchQuery(query)}
							onReset={() => {
								setSearchQuery('');
								setSearchResults([]);
								setCurrentTheme(themesData ? themesData[0] : null)
							}}
						/>
					</div>
				</div>

				<div className="row g-xl-4 pt-xl-5">
					<div className="col-xxl-6 d-none d-xl-block">
						{
							searchQuery.length > 0
								? <ProfileQuestionsSearchOutput searchResults={searchResults} isLoading={questionsDataIsLoading}/>
								: (
									currentTheme && <div>
										<ProfileQuestionsCommonThemeOutput currentTheme={currentTheme}/>
									</div>
								)
						}
					</div>
					<div className="col-xxl-6">
						<ProfileQuestionsAsk title={'Не нашли вопрос? Задайте свой!'}/>
					</div>
				</div>
			</div>
		</>
	);
};

export default memo(ProfileQuestionsCommon);

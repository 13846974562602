import React, {FC} from 'react';
import classNames from 'classnames';
import styles from '@components/investor/Project/project.module.scss';
import {configureAssetAnchorParams} from '@common/utils';
import BoxIcon from '@components/common/Icon/BoxIcon';
import {RGB_COLORS} from '@common/constants';
import {ReactComponent as DocumentIcon} from '@icons/document-colored.svg';
import {IAttachment} from '@common/types';
import {IDocument} from '@features/utils/types';

interface IProfileProjectAccordionDocumentProps {
	attachment: IAttachment | null | undefined
	link: IDocument['link']
	title: string
	bgColor?: string
	className?: string
}

const ProfileProjectAccordionDocument:FC<IProfileProjectAccordionDocumentProps> = (
	{
		link,
		attachment,
		title,
		bgColor = RGB_COLORS.blue,
		className
	}
) => {

	return (
		<a
			className={classNames(styles.document, 'd-flex align-items-center p-3', className)}
			{...configureAssetAnchorParams(attachment, link)}
		>
			<BoxIcon
				size={'sm'}
				bgColor={bgColor}
				className={'flex-shrink-0'}
			>
				<DocumentIcon/>
			</BoxIcon>
			<span className={'ps-3'}>{title}</span>
		</a>
	);
};

export default ProfileProjectAccordionDocument;

import React, {FC} from 'react';
import {Accordion, Spinner} from 'react-bootstrap';
import {IQuestionTheme} from '@common/types';
import styles from '../../questions.module.scss';

interface IProfileQuestionsSearchOutputProps {
	isLoading: boolean,
	searchResults: IQuestionTheme[]
}

const ProfileQuestionsSearchOutput:FC<IProfileQuestionsSearchOutputProps> = ({isLoading, searchResults}) => {

	return (
		<div className='position-relative'>
			{
				isLoading
					? <Spinner className={'d-block mx-auto'} animation='border' variant='primary'/>
					: <div className={styles.accordion}>
						{
							searchResults.length > 0
								? <Accordion>
									{
										searchResults.map(questionTheme => {
											return questionTheme.questions.map(({question, questionId, answer}) => {
												return (
													<Accordion.Item key={questionId} eventKey={questionId.toString()}>
														<Accordion.Header className={'text-md'} as={'div'}>{question}</Accordion.Header>
														<Accordion.Body className={'text-sm text-dark'}>{answer}</Accordion.Body>
													</Accordion.Item>
												)
											})
										})
									}
								</Accordion>
								: <p>Ничего не найдено</p>
						}

					</div>
			}
		</div>
	);
};

export default ProfileQuestionsSearchOutput;

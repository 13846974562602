import React, { FC, useContext } from "react";
import { Button } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import RecoveryNote from "@components/common/RecoveryNote/RecoveryNote";
import { authContext } from "@context/authContext";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { useHistory } from "react-router-dom";
import { phoneToString } from "@common/utils";

const RecoverPasswordRequest: FC = (props) => {
	const { recoverPasswordHandler, phone, clearCode } = useContext(authContext),
		history = useHistory();
	return (
		<div {...props}>
			<div className='text-center'>
				<RecoveryNote
					phone={phoneToString(phone)}
					message={
						"Для восстановления пароля мы отправим вам проверочный код в СМС на номер"
					}
				/>
			</div>
			<div className='text-center pb-5 mb-2'>
				<Button
					variant={ButtonVariant.contained}
					type={"button"}
					onClick={() => {
						clearCode();
						recoverPasswordHandler();
					}}
					as={ButtonTypesEnum.BUTTON}>
					Получить код
				</Button>
			</div>

			<div className='text-dark text-center'>
				<a
					href={"#"}
					className={"text-decoration-underline"}
					onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
						event.preventDefault();
						history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.LOGIN);
					}}>
					<small>Я вспомнил пароль</small>
				</a>
			</div>
		</div>
	);
};

export default RecoverPasswordRequest;

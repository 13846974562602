import {IAttachment} from '@common/types';
import {IProject} from '@features/projects/types';
import {IProjectBorrowerSimple} from '@features/borrower/types';

export enum InvestorTypeEnum {
	QUALIFIED = 'qualified',
	NOT_QUALIFIED = 'not_qualified',
	NOT_CHOSEN = 'not_chosen',
	ON_CHECK = 'on_check'
}

export enum VerificationStatusEnum {
	VERIFIED = 'verified',
	ON_CHECK = 'ver_on_check',
	CHANGE_ON_CHECK = 'ver_change_on_check',
	NOT_VERIFIED = 'not_verified'
}

export enum InvestorLawTypeEnum {
	INDIVIDUAL = 'individual',
	ENTITY = 'entity',
	INDIVIDUAL_ENTREPRENEUR = 'individual_entrepreneur'
}

export interface IInvestorInfo {
	userId: number
	firstName: string
	lastName: string
	middleName: string
	avatar: IAttachment | null
	email: string
	phone: string
	balance: string | null
	verificationStatus: VerificationStatusEnum
	investorType: InvestorTypeEnum
	lawType: InvestorLawTypeEnum
	needQualifyNotification: boolean
	orgFullName: string
	orgShortName: string
}

export interface IInvestorProfile extends Omit<IInvestorInfo, 'balance'>{
	birthDate: string
	residenceCity: string
	inn: string,
	passportNumber: string
	registrationAddress: string
	passportFirstPage: IAttachment | null
	passportSecondPage: IAttachment | null
	investRiskAccepted: boolean
	platformRulesAccepted: boolean
	rfResidentAccepted: boolean
	collectionAssignmentAccepted: boolean
	passportIssuedBy: string
	passportIssuedWhen: string
	passportDivisionCode: string
	birthPlace: string
	orgInn: string
	organizationForm: string
	orgOgrn: string
	orgKpp: string
	orgDateRegistration: string
	orgHeadLastName: string
	orgHeadFirstName: string
	orgHeadMiddleName: string
	orgHeadGrade: string
	orgAddressRegistration: string
	orgAddressLocation: string
	isAgreementHeadOrg: boolean
}

export enum BorrowerDocumentStatus {
	APPROVED = 'approved',
	WAITING = 'waiting',
	NOT_APPROVED = 'not_approved'
}

export interface IBorrowerDocument {
	attachment: IAttachment | null
	status: BorrowerDocumentStatus
}

export enum BorrowerDocumentsKeysEnum {
	PASSPORT_SECOND_THIRD_PAGE = 'scan23',
	PASSPORT_REGISTRATION_PAGE = 'scanReg',
	BALANCE_YEAR = 'balanceYear',
	BALANCE_LAST_DATE = 'balanceLast',
	INCOME_DECLARATION = 'incomeStatement'
}

export enum BorrowerProfileKeysEnum {
	USER_ID = 'userId',
	MANAGER_ID = 'managerId',
	OGRN = 'ogrn',
	ORG_NAME = 'orgName',
	INN = 'inn',
	LEGAL_ADDRESS = 'orgLegalAddress',
	ACTUAL_ADDRESS = 'businessAddress',
	FIRST_NAME = 'firstName',
	MIDDLE_NAME = 'middleName',
	LAST_NAME = 'lastName',
	GENITIVE_FIO = 'parentalFullName',
	BIRTH_DATE = 'birthDate',
	POST = 'position',
	PASSPORT_NUMBER = 'passportNumber',
	PASSPORT_ISSUED_BY = 'passportIssuedBy',
	PASSPORT_ISSUED_WHEN = 'passportIssuedWhen',
	PASSPORT_DIVISION_CODE = 'passportDivisionCode',
	BIRTH_PLACE = 'birthPlace',
	REGISTRATION_ADDRESS = 'registrationAddress',
	PHONE = 'phone',
	EMAIL = 'email',
	SNILS = 'snils',
	ABLE_NEW_BID = 'ableNewBid',
	REMAIN_DAYS = 'remainDays'
}

export interface IBorrowerDocuments {
	[BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE]: IBorrowerDocument
	[BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE]: IBorrowerDocument
	[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: IBorrowerDocument
	[BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE]: IBorrowerDocument
	[BorrowerDocumentsKeysEnum.INCOME_DECLARATION]: IBorrowerDocument
}

export interface IBorrowerProfile extends IBorrowerDocuments {
	[BorrowerProfileKeysEnum.USER_ID]:	number
	[BorrowerProfileKeysEnum.MANAGER_ID]: number
	avatar: IAttachment | null
	[BorrowerProfileKeysEnum.REMAIN_DAYS]:	number
	[BorrowerProfileKeysEnum.ABLE_NEW_BID]:	boolean
	[BorrowerProfileKeysEnum.FIRST_NAME]: string
	[BorrowerProfileKeysEnum.LAST_NAME]: string
	[BorrowerProfileKeysEnum.MIDDLE_NAME]: string
	[BorrowerProfileKeysEnum.PHONE]: string
	[BorrowerProfileKeysEnum.EMAIL]: string
	[BorrowerProfileKeysEnum.BIRTH_DATE]: string
	[BorrowerProfileKeysEnum.BIRTH_PLACE]: string
	[BorrowerProfileKeysEnum.INN]: string
	[BorrowerProfileKeysEnum.SNILS]: string
	[BorrowerProfileKeysEnum.OGRN]: string
	[BorrowerProfileKeysEnum.ACTUAL_ADDRESS]: string
	[BorrowerProfileKeysEnum.ORG_NAME]: string
	[BorrowerProfileKeysEnum.GENITIVE_FIO]: string
	[BorrowerProfileKeysEnum.LEGAL_ADDRESS]: string
	[BorrowerProfileKeysEnum.POST]: string
	[BorrowerProfileKeysEnum.PASSPORT_NUMBER]: string
	[BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY]: string
	[BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN]: string
	[BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE]: string
	[BorrowerProfileKeysEnum.REGISTRATION_ADDRESS]: string
}


export interface IBorrowerManager {
	id: number
	email: string
	phone: string
	fullName: string
	avatar: IAttachment | null
}

export enum UserDataKeysEnum {
	II = 'investorInfo',
	IP = 'investorProfile',
	BP = 'borrowerProfile'
}

export interface IUserState {
	token: string | null,
	[UserDataKeysEnum.II]: IInvestorInfo,
	[UserDataKeysEnum.IP]: IInvestorProfile,
	[UserDataKeysEnum.BP]: IBorrowerProfile,
	borrowerManager: IBorrowerManager | null
}

export interface IAsideInfoSource extends Pick<IUserState, UserDataKeysEnum.II | UserDataKeysEnum.BP> {}

export interface IUserAnalyticsInfoResponse {
	investmentTotal: string,
	profitTotal: string,
	investmentInProjects: string
}

export interface IUserAnalyticsFinancesResponse {
	investmentTotal:	number
	investmentInProjects:	number
	profitTotal:	number
	withdrawTotal:	number
	profitExpected:	number
	penaltiesTotal:	number
}

export interface IUserAnalyticsProjectsResponse {
	projectsTotal:	number
	projectsCompleted:	number
	projectsExpected:	number
	projectsOverdue:	number
}

export interface IUserInvestmentItem {
	investmentAmount: number
	returnAmount:	number
	returnReceived:	number
	returnLeft:	number
	nextPayment:	number
	nextPaymentDate: string,
	project: IProject,
	borrower: IProjectBorrowerSimple
}

export interface IStatement {
	url: string
	title: string
}




import React, {FC, useEffect, useState} from 'react';
import styles from './projectExpiredMessage.module.scss';
import classNames from 'classnames';
import {BorrowerProjectStatusEnum} from '@features/borrower/types';

interface iProjectExpiredMessageProps {
	alreadyInvested: string
	status: BorrowerProjectStatusEnum
	className?: string
}

const ProjectExpiredMessage:FC<iProjectExpiredMessageProps> = (
	{
		alreadyInvested,
		status,
		className
	}
) => {

	const [message, setMessage] = useState<string | null>(null);

	useEffect(() => {
		switch (status) {
			case BorrowerProjectStatusEnum.REQUEST_CANCELLED:
				setMessage('К сожалению сбор по проекту отменен.');
				break;
			case BorrowerProjectStatusEnum.EXPIRED:
				setMessage('К сожалению проект не собрал необходимые инвестиции.');
				break;
		}

		if (parseInt(alreadyInvested) > 0)
			setMessage(prevState => (prevState || '') + ' Деньги вернулись на ваш счет.');
	}, [alreadyInvested, status]);

	switch (status) {
		case BorrowerProjectStatusEnum.EXPIRED:
		case BorrowerProjectStatusEnum.REQUEST_CANCELLED:
			return <div className={classNames(styles.message, className)}>
				<p className={styles.paragraph}>{message}</p>
			</div>;
		default: return null;
	}
};

export default ProjectExpiredMessage;

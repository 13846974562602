import {useEffect, useState} from 'react';
import {POOLING_INTERVAL_DEFAULT} from '@common/constants';

export const usePoolingInterval = (interval?: number):number => {
	const [pollingInterval, setPoolingInterval] = useState<number>(interval || POOLING_INTERVAL_DEFAULT);

	const handleDocumentVisibilityChange = () => {
		switch (document.visibilityState) {
			case 'hidden':
				setPoolingInterval(0);
				break;
			case 'visible':
				setPoolingInterval(interval || POOLING_INTERVAL_DEFAULT);
				break;
		}
	};

	useEffect(() => {
		document.addEventListener('visibilitychange', handleDocumentVisibilityChange);
		return () => {
			setPoolingInterval(0);
			document.removeEventListener('visibilitychange', handleDocumentVisibilityChange);
		}
	}, []);

	return pollingInterval
};

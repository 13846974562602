import React, {FC} from 'react';
import {Accordion} from 'react-bootstrap';
import styles from '../project.module.scss'
import {
	createDataList,
	dataPlaceholder,
	IDataRow
} from '@components/investor/Project/Project';
import {useCashedProject} from '@hooks/useCashedProject';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {generateBorrowerStatisticsData} from '@components/investor/utils';
import {ProjectDetailsSectionsEnum} from '@components/investor/Project/Accordion/ProjectAccordion';
import {IProject} from '@features/projects/types';

const ProfileProjectAccordionBorrower:FC<{projectId: IProject['projectId']}> = ({projectId}) => {

	const	detailCashed = useCashedProject(projectId);

	const borrowerData: IDataRow[] = [
		{
			key: 'Фактический адрес',
			value: detailCashed?.borrower?.businessAddress
		},
		{
			key: 'Юридический адрес',
			value: detailCashed?.borrower?.orgLegalAddress
		},
		{
			key: 'ИНН',
			value: detailCashed?.borrower?.inn
		},
		{
			key: 'ОГРН/ОГРНИП',
			value: detailCashed?.borrower?.ogrn
		}
	];

	return (
		<Accordion.Item className={styles.section} eventKey={ProjectDetailsSectionsEnum.ABOUT_BORROWER+projectId}>
			<Accordion.Header as={'div'}>
				<Heading
					tag={'h4'}
					variant={HeadingVariant.middle}
					className={'mb-0'}
				>О заемщике</Heading>
			</Accordion.Header>
			<Accordion.Body>
				<div className={'row pb-3'}>
					<div className="col-lt-8">
						{createDataList(borrowerData)}
					</div>
				</div>


				<ul className={'row g-3'}>
					{
						detailCashed?.borrower && generateBorrowerStatisticsData(detailCashed.borrower)
							.map(({value, key, icon}) => (
								<li key={key} className={'col-sm-6 col-md-12 col-lg-6 col-xl-12 col-xxl-6 col-lt-4'}>
									<div className={styles.box}>
										<div className="row align-items-center gx-3">
											<div className="col-auto">
												{icon}
											</div>
											<div className="col">
												<p className={'mb-0'}>{key}</p>
											</div>
											<div className="col-2 text-end">
												<strong>{value || dataPlaceholder}</strong>
											</div>
										</div>
									</div>
								</li>
							))
					}
				</ul>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default ProfileProjectAccordionBorrower;

import {FC, ReactElement, useContext, useEffect, useMemo} from 'react';
import {profileContext} from '@context/profileContext';
import {useScrollToTop} from '@hooks/useScrollToTop';

interface IProfileTabsContentProps {
	items: {[k: string]: ReactElement}
}

const ProfileTabsContent:FC<IProfileTabsContentProps> = ({items}) => {

	const
		{tabs, activeTab} = useContext(profileContext),
		scrollToTop = useScrollToTop()
	;

	useEffect(() => {
		scrollToTop()
	}, [activeTab]);

	return useMemo(() => {
		const targetItemKey = Object.keys(items)
			.find(itemKey => itemKey === activeTab?.key);

		return targetItemKey ? items[targetItemKey] : null
	}, [items, tabs]);
};

export default ProfileTabsContent;

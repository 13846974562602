import React, {FC, useContext, useRef} from 'react';
import styles from './profileHeader.module.scss'
import classNames from 'classnames';
import {ADAPTIVE_BREAKPOINTS, TRANSITION_TIMEOUT} from '@common/constants';
import {UserDataKeysEnum} from '@features/user/types';
import {Button} from '@components/ui';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {AuthRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useAppSelector} from '@hooks/useAppSelector';
import {useAppActions} from '@hooks/useAppActions';
import {useHistory} from 'react-router-dom';
import ProfileHeaderNotifications from '@components/profile/Header/Notifications/Notifications';
import {AuthRolesEnum} from '@features/auth/types';
import useWindowSize from '@hooks/useWindowSize';
import ProfileAvatar from '@components/profile/Avatar/Avatar';
import {ReactComponent as LogoIcon} from '@icons/auth-logo.svg';
import {ReactComponent as MenuIcon} from '@icons/burger-menu.svg';
import ProfileHeaderTransitionTitle from '@components/profile/Header/TransitionTitle';
import {profileContext} from '@context/profileContext';
import ProfileTabsControl from '@components/profile/reused/Tabs/ProfileTabsControl';
import {CSSTransition} from 'react-transition-group';
import ProfileHeaderQualification from '@components/profile/Header/Qualification/Qualification';

export const ProfileHeader:FC = () => {

	const
		{setAuthorized, setToken, resetUserData} = useAppActions(),
		{investorType, needQualifyNotification} = useAppSelector(state => state.user[UserDataKeysEnum.II]),
		history = useHistory(),
		{role} = useAppSelector(state => state.auth),
		{width: ww} = useWindowSize(),
		{setMobileMenuIsOpen, tabs} = useContext(profileContext),
		controlsRef = useRef<HTMLDivElement | null>(null)
	;

	return (
		<div className={classNames(styles.root)}>
			<div className={styles.inner}>
				<div className="row align-items-center gx-2 gx-xl-4 py-xl-5">

					<div className={classNames('col-auto', {'me-auto': ww >= ADAPTIVE_BREAKPOINTS.xl})}>
						{
							ww >= ADAPTIVE_BREAKPOINTS.xl
								? <ProfileHeaderTransitionTitle/>
								: <ProfileAvatar/>
						}
					</div>

					{
						ww < ADAPTIVE_BREAKPOINTS.xl && <div className='col-auto mx-auto'>
							<LogoIcon height={40} width={110} className={styles.logo}/>
						</div>
					}
					{
						(investorType && role === AuthRolesEnum.INVESTOR && needQualifyNotification) && <div className={'col-auto d-flex align-items-center'}>
							<ProfileHeaderQualification/>
						</div>
					}


					<div className="col-auto">
						<ProfileHeaderNotifications/>
					</div>

					<div className="col-auto me-5 me-xl-0">
						<Button
							className={styles.logOut}
							variant={ButtonVariant.icon}
							icon={ButtonIconsVariant.logout}
							type={'button'}
							as={ButtonTypesEnum.BUTTON}
							onClick={() => {
								setToken(null);
								resetUserData();
								setAuthorized(false);
								history.push(RouteNamesEnum.AUTH+AuthRouteNamesEnum.CHECK_PHONE)
							}}
						>
							{ww > ADAPTIVE_BREAKPOINTS.xl && 'Выйти'}
						</Button>
					</div>
					{
						ww < ADAPTIVE_BREAKPOINTS.xl && <div className="col-auto">
							<button
								type='button'
								className={styles.menuButton}
								onClick={() => {
									setMobileMenuIsOpen(true)
								}}
							>
								<MenuIcon/>
							</button>
						</div>
					}

				</div>
			</div>
			{
				ww < ADAPTIVE_BREAKPOINTS.xl && <div className='row flex-nowrap py-4 align-items-center justify-content-between'>
					<div className='col-auto flex-shrink-1 flex-sm-shrink-0'>
						<ProfileHeaderTransitionTitle/>
					</div>
					<div className='col-auto'>
						<CSSTransition
							in={tabs.length > 0}
							classNames={'animation--slide-y'}
							timeout={TRANSITION_TIMEOUT}
							unmountOnExit
							nodeRef={controlsRef}
						>
							<ProfileTabsControl/>
						</CSSTransition>
					</div>
					</div>
			}
		</div>
	);
};

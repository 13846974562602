import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQuery } from "@api/utils";
import {
	API_PROJECT_URL,
	API_PROJECTS_PARAMS_URL,
	API_PROJECTS_SEARCH_URL,
	API_PROJECTS_USER_INVEST_URL,
	GET_API_PROJECTS_ACCEPT_URL,
	GET_API_PROJECTS_INVESTOR_CONDITIONS_URL,
	GET_API_PROJECT_PARAMS_URL,
} from "@common/constants";
import { IListResponse, IFilter } from "@common/types";
import {
	DocumentUrlType,
	IProject,
	IProjectDetails,
	IProjectParams,
} from "@features/projects/types";
import { IBorrowerBidsParams } from "@features/borrower/types";

const projectsApi = createApi({
	reducerPath: "projectsApi",
	baseQuery,
	endpoints: (build) => ({
		search: build.mutation<IListResponse, IFilter>({
			query: (body) => ({
				url: API_PROJECTS_SEARCH_URL,
				method: "post",
				body,
			}),
		}),
		searchInvestedByUser: build.mutation<IListResponse, IFilter>({
			query: (body) => ({
				url: API_PROJECTS_USER_INVEST_URL,
				method: "post",
				body,
			}),
		}),
		getById: build.mutation<IProjectDetails, number>({
			query: (projectId) => ({
				url: API_PROJECT_URL + `/${projectId}`,
				method: "get",
			}),
		}),
		getInvestorConditions: build.mutation<
			DocumentUrlType,
			IProject["projectId"]
		>({
			query: (id) => ({
				url: GET_API_PROJECTS_INVESTOR_CONDITIONS_URL(id),
				method: "get",
			}),
		}),
		getAccept: build.mutation<
			DocumentUrlType,
			{ id: IProject["projectId"]; fromModal?: boolean }
		>({
			query: ({ id, fromModal }) => ({
				url: GET_API_PROJECTS_ACCEPT_URL(id),
				method: "get",
				params: { invest: fromModal },
			}),
		}),
		getProjectParams: build.mutation<IProjectParams, IProject["projectId"]>({
			query: (id) => ({
				url: GET_API_PROJECT_PARAMS_URL(id),
				method: "get",
			}),
		}),
		params: build.query<IBorrowerBidsParams, void>({
			query: () => API_PROJECTS_PARAMS_URL,
		}),
	}),
});

export default projectsApi;

import React, {FC, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {withdrawContext} from '@context/withdrawContext';
import styles from '@components/auth/VerificationCode/verificationCode.module.scss';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import operationsApi from '@api/operationsApi';
import {profileContext} from '@context/profileContext';
import userApi from '@api/userApi';
import {useEmitter} from '@hooks/customEvent';
import {CUSTOM_EVENT__SET_MODAL_CODE_FOCUS} from '@common/constants';

const WithdrawConfirmModal:FC = () => {

	const
		[checkCode, {isLoading: codeIsChecking}] = operationsApi.useWithdrawConfirmMutation(),
		[withdrawInit] = operationsApi.useWithdrawInitMutation(),
		[getUserInfo] = userApi.useLazyInfoQuery(),
		{
			confirmModal,
			setConfirmModal,
			setSuccessModal,
			amount,
			bik,
			name,
			account,
			correspondentAccount
		} = useContext(withdrawContext),
		{
			setSmsError
		} = useContext(profileContext),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(() => {
					setSmsError(false);
					setConfirmModal(false);
					setSuccessModal(true);

					getUserInfo()
				})
				.catch(() => setSmsError(true))
		},
		onCodeResend = () => {
			withdrawInit({
				bik,
				bankName: name,
				accountNumber: account,
				correspondentAccount,
				amount
			})
		}
	;

	return (
		<Modal
			show={confirmModal}
			onHide={() => setConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header className={styles.header} closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default WithdrawConfirmModal;

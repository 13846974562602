import {ToastContentProps} from 'react-toastify';
import React, {ReactElement} from 'react';
import {ReactComponent as CloseIcon} from '@icons/close.svg';
import styles from './toast.module.scss';

const ToastCloseButton = ({closeToast}: ToastContentProps): ReactElement => (
	<button type={'button'} onClick={closeToast} className={styles.close}>
		<CloseIcon/>
	</button>
);

export default ToastCloseButton;

import React, { FC, useContext } from "react";
import { FormGroup } from "@components/ui";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import ProfileSection from "@components/profile/Section/ProfileSection";
import styles from "../data.module.scss";
import {
	EMAIL_REGEXP,
	MESSAGE_VALIDATION_INVALID_EMAIL,
} from "@common/constants";
import { validatePhone } from "@common/utils";
import {
	INVESTOR_FIELD_NAME_EMAIL,
	INVESTOR_FIELD_NAME_PHONE,
} from "@components/investor/Views/Data/constants";
import { useFormContext } from "react-hook-form";
import { personalDataContext } from "@context/personalDataContext";

export const ProfileDataContacts: FC = () => {
	const {
			register,
			setValue,
			watch,
			formState: { errors },
		} = useFormContext(),
		{ editMode } = useContext(personalDataContext);

	return (
		<ProfileSection title={"Контактные данные"}>
			<div className='row gx-3'>
				<div className='col-sm-6 col-lg-4 col-xl-6 col-hd-4'>
					<FormGroup
						className={styles.formGroup}
						id={"profileDataPhone"}
						fieldType={FormGroupTypes.PHONE}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setValue(INVESTOR_FIELD_NAME_PHONE, event)
						}
						label={"Телефон"}
						value={watch(INVESTOR_FIELD_NAME_PHONE)}
						placeholder={"8 (999) 999 99-99"}
						readonly={!editMode}
						registerOptions={{ validate: validatePhone }}
						register={register}
						name={INVESTOR_FIELD_NAME_PHONE}
						errorMessage={errors[INVESTOR_FIELD_NAME_PHONE]?.message}
					/>
				</div>
				<div className='col-sm-6 col-lg-4 col-xl-6 col-hd-4'>
					<FormGroup
						className={styles.formGroup}
						id={"profileDataEmail"}
						fieldType={FormGroupTypes.EMAIL}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setValue(INVESTOR_FIELD_NAME_EMAIL, event.target.value)
						}
						label={"E-mail"}
						placeholder={"E-mail"}
						value={watch(INVESTOR_FIELD_NAME_EMAIL)}
						readonly={!editMode}
						name={INVESTOR_FIELD_NAME_EMAIL}
						register={register}
						errorMessage={errors[INVESTOR_FIELD_NAME_EMAIL]?.message}
						registerOptions={{
							pattern: {
								value: EMAIL_REGEXP,
								message: MESSAGE_VALIDATION_INVALID_EMAIL,
							},
						}}
					/>
				</div>
			</div>
		</ProfileSection>
	);
};

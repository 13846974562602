import React, { FC, memo } from 'react';
import BoxIcon from '@components/common/Icon/BoxIcon';
import { ADAPTIVE_BREAKPOINTS, RGB_COLORS } from '@common/constants';
import classNames from 'classnames';
import { IQuestion, IQuestionThemesEnum } from '@common/types';
import useWindowSize from '@hooks/useWindowSize';
import { ReactComponent as ArrowUpIcon } from '@icons/arrow-up.svg';
import { Accordion } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import styles from './questionsControlBox.module.scss';


interface IProfileQuestionsControlBoxProps {
    questions: IQuestion[];
    checked: boolean;
    theme: IQuestionThemesEnum | null;
    icon: JSX.Element;
    onRadioChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checkedClassName?: string;
    name?: string;
    title?: string;
}

const ProfileQuestionsControlBox: FC<IProfileQuestionsControlBoxProps> = (
    {
        questions = [],
        checked,
        theme,
        icon,
        onRadioChange,
        checkedClassName = 'is-checked',
        name = 'investor-common-questions',
        title= ''
    }
) => {

    const
        { width: ww } = useWindowSize(),
        id = uuid()
    ;

    const
        getBoxIconBg = (): string => {
            switch (theme) {
                case IQuestionThemesEnum.INFO:
                    return RGB_COLORS.blue;
                case IQuestionThemesEnum.DANGER:
                    return RGB_COLORS.red;
                case IQuestionThemesEnum.SUCCESS:
                    return RGB_COLORS.green;
                case IQuestionThemesEnum.WARNING:
                    return RGB_COLORS.yellow;
                default:
                    return '';
            }

        }
    ;
    const accordionProps = { alwaysOpen: true };
    return (
        <div
            className={classNames(styles.box, (theme && styles[theme]), { [checkedClassName]: checked && ww > ADAPTIVE_BREAKPOINTS.xl })}>

            {
                ww > ADAPTIVE_BREAKPOINTS.xl
                    ? <label
                        htmlFor={id}
                        className={
                            classNames(styles.boxLabel, 'd-flex flex-column align-items-center')
                        }
                    >
                        <BoxIcon tagName={'span'} className={classNames(styles.boxIcon, 'flex-shrink-0')}
                                 bgColor={getBoxIconBg()}>{icon}</BoxIcon>
                        <input
                            id={id}
                            type={'radio'}
                            className="visually-hidden"
                            onChange={onRadioChange}
                            value={title}
                            name={name}
                        />
                        <span className={classNames(styles.title, 'pt-3 mb-0 flex-grow-1')}>{title}</span>

                    </label>
                    : <div className={styles.boxAccordion}>

                        <Accordion {...accordionProps}>
                            <Accordion.Item key={title} eventKey={title}>
                                <Accordion.Header as={'div'} className={classNames(styles.boxAccordionHeader)}>
                                    <div className="row gx-3">
                                        <div className="col-auto">
                                            <BoxIcon tagName={'span'} className={styles.boxIcon}
                                                     bgColor={getBoxIconBg()}>{icon}</BoxIcon>
                                        </div>
                                        <div className="col">
                                            <p className={classNames(styles.title, 'mb-0 pt-2 mt-xl-2')}>{title}</p>
                                        </div>
                                        <div className="col-auto">
                                            <div className={classNames(styles.arrowUp, styles.arrowUpCircle, 'mt-2')}>
                                                <ArrowUpIcon />
                                            </div>
                                        </div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className={styles.boxAccordionContent}>

                                    <Accordion {...accordionProps}>
                                        {
                                            questions.length > 0 && questions.map(({ question, questionId, answer }) => {
                                                return (
                                                    <Accordion.Item key={questionId} eventKey={questionId.toString()}
                                                                    className={styles.boxAccordionItem}>
                                                        <Accordion.Header as={'div'} className={styles.boxAccordionQuestion}>
                                                            <div className="row gx-3">
                                                                <div className="col">
                                                                    {question}
                                                                </div>

                                                                {
                                                                    Boolean(answer) && <div className="col-auto">
                                                                        <div className={classNames(styles.arrowUp, 'mt-sm-n2')}>
                                                                            <ArrowUpIcon />
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </div>
                                                        </Accordion.Header>
                                                        {
                                                            Boolean(answer) && <Accordion.Body
                                                                className={styles.boxAccordionAnswer}>{answer}</Accordion.Body>
                                                        }
                                                    </Accordion.Item>
                                                );
                                            })
                                        }
                                    </Accordion>

                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
            }
        </div>
    );
};

export default memo(ProfileQuestionsControlBox);

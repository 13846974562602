import type { Element } from '../types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useEmitter = <T>(eventName: string, element: Element = window) => {
  return (data?: T):void => {
	  const event = new CustomEvent(eventName, { detail: data || {} });

	  element.dispatchEvent(event);
  };
};

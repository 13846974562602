import {ISection, SectionsCodeEnum} from '@features/utils/types';
import {useEffect, useState} from 'react';
import {getSection} from '@common/utils';
import {useAppSelector} from '@hooks/useAppSelector';

export const useProfileSection = (code: SectionsCodeEnum): ISection | null => {
	const
		{sections} = useAppSelector(state => state.utils),
		[currentSection, setCurrentSection] = useState<ISection | null>(null)
	;

	useEffect(() => {
		setCurrentSection(getSection(sections, code))
	}, [sections]);


	return currentSection;
};

export enum AuthStageEnum {
	CHECK_PHONE = 'CHECK_PHONE',
	LOGIN = 'LOGIN',
	REGISTRATION_REQUEST = 'REGISTRATION_REQUEST',
	REGISTRATION_CONFIRM = 'REGISTRATION_CONFIRM',
	RECOVER_PASSWORD_REQUEST = 'RECOVER_PASSWORD_REQUEST',
	RECOVER_PASSWORD_CONFIRM = 'RECOVER_PASSWORD_CONFIRM',
	CHANGE_PASSWORD = 'CHANGE_PASSWORD',
	DONE = 'DONE'
}

type token = string;

export enum AuthRolesEnum {
	INVESTOR = 'investor',
	BORROWER = 'borrower'
}

export interface IAuthState {
	isAuthorized: boolean;
	isLoading: boolean;
	role: AuthRolesEnum
}

export interface IAuthLoginRequest {
	login: string,
	password: string
}

export interface IRegisterRequest {
	email: string,
	phone: number,
	isAgreementWithRules: boolean,
	isPersonalDataProcessing: boolean
}

export interface IRegisterConfirmRequest extends IRegisterRequest{
	smsCode: string
}

export interface IRegisterCreatePasswordRequest extends IRegisterConfirmRequest {
	password: string
}

export interface IAuthIsAuthorizedResponse {
	isAuthorized: boolean
}

export interface IAuthCheckPhoneResponse {
	isPhoneRegistered: boolean
	existingUser: null | 'investor' | 'borrower'
}

export interface IRecoverPasswordRequest {
	phone: number
}

export interface IRecoverPasswordCheckRequest {
	phone: number,
	smsCode: string
}

export interface IRecoverPasswordConfirmRequest {
	phone: number,
	smsCode: string,
	newPassword: string
}

export interface IAuthLoginResponse {
	token: token
}

export interface IRegisterConfirmResponse {
	token: token
}

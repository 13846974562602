import React, {createContext, Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {IInvestorProfile, InvestorTypeEnum, VerificationStatusEnum} from '@features/user/types';
import {IAttachment} from '@common/types';
import {PersonalDataViewsEnum} from '@components/investor/Views/Data/types';
import {useAppSelector} from '@hooks/useAppSelector';

type PassportAttachmentType = IAttachment | null;

interface IPersonalDataContext {
	dataChangeRequestModal: boolean
	setDataChangeRequestModal: Dispatch<SetStateAction<boolean>>
	dataChangeConfirmModal: boolean
	setDataChangeConfirmModal:Dispatch<SetStateAction<boolean>>
	dataChangeSuccessModal: boolean
	setDataChangeSuccessModal: Dispatch<SetStateAction<boolean>>
	canBeEdited: boolean

	phoneChangeRequestModal: boolean
	setPhoneChangeRequestModal: Dispatch<SetStateAction<boolean>>
	phoneChangeConfirmModal: boolean
	setPhoneChangeConfirmModal: Dispatch<SetStateAction<boolean>>
	phoneChangeSuccessModal: boolean
	setPhoneChangeSuccessModal: Dispatch<SetStateAction<boolean>>
	lawTypeDataIsEditedNow: boolean
	setLawTypeDataIsEditedNow: Dispatch<SetStateAction<boolean>>

	requestData: Partial<IInvestorProfile>
	setRequestData: Dispatch<SetStateAction<Partial<IInvestorProfile>>>
	passportFirstPage: PassportAttachmentType
	setPassportFirstPage: Dispatch<SetStateAction<PassportAttachmentType>>
	passportSecondPage: PassportAttachmentType
	setPassportSecondPage: Dispatch<SetStateAction<PassportAttachmentType>>

	currentPersonalDataView: PersonalDataViewsEnum
	setCurrentPersonalDataView: Dispatch<SetStateAction<PersonalDataViewsEnum>>

	inn: string
	setInn: Dispatch<SetStateAction<string>>

	submitIsLoading: boolean
	setSubmitIsLoading: Dispatch<SetStateAction<boolean>>

	editMode: boolean
	setEditMode: Dispatch<SetStateAction<boolean>>
}

export const personalDataContext = createContext<IPersonalDataContext>({} as IPersonalDataContext);

export const PersonalDataProvider: FC = ({children}) => {
	const {
		investorType,
		verificationStatus
	} = useAppSelector(state => state.user.investorProfile);

	const
		[requestData, setRequestData] = useState<Partial<IInvestorProfile>>({}),

		[dataChangeRequestModal, setDataChangeRequestModal] = useState<boolean>(false),
		[dataChangeConfirmModal, setDataChangeConfirmModal] = useState<boolean>(false),
		[dataChangeSuccessModal, setDataChangeSuccessModal] = useState<boolean>(false),

		[phoneChangeRequestModal, setPhoneChangeRequestModal] = useState<boolean>(false),
		[phoneChangeConfirmModal, setPhoneChangeConfirmModal] = useState<boolean>(false),
		[phoneChangeSuccessModal, setPhoneChangeSuccessModal] = useState<boolean>(false),
		[lawTypeDataIsEditedNow, setLawTypeDataIsEditedNow] = useState<boolean>(false),
		[submitIsLoading, setSubmitIsLoading] = useState<boolean>(false),
		[editMode, setEditMode] = useState<boolean>(false),
		[canBeEdited, setCanBeEdited] = useState<boolean>(false),

		[passportFirstPage, setPassportFirstPage] = useState<PassportAttachmentType>(null),
		[passportSecondPage, setPassportSecondPage] = useState<PassportAttachmentType>(null),
		[currentPersonalDataView, setCurrentPersonalDataView] = useState<PersonalDataViewsEnum>(PersonalDataViewsEnum.DEFAULT),
		[inn, setInn] = useState<string>('')
	;

	useEffect(() => {
		setCanBeEdited(
			!(investorType === InvestorTypeEnum.ON_CHECK
				|| verificationStatus === VerificationStatusEnum.ON_CHECK
				|| verificationStatus === VerificationStatusEnum.CHANGE_ON_CHECK)
		)
	}, [investorType, verificationStatus]);


	const payload = {
		requestData,
		setRequestData,

		dataChangeRequestModal,
		setDataChangeRequestModal,
		dataChangeConfirmModal,
		setDataChangeConfirmModal,
		dataChangeSuccessModal,
		setDataChangeSuccessModal,

		phoneChangeRequestModal,
		setPhoneChangeRequestModal,
		phoneChangeConfirmModal,
		setPhoneChangeConfirmModal,
		phoneChangeSuccessModal,
		setPhoneChangeSuccessModal,

		passportFirstPage,
		setPassportFirstPage,
		passportSecondPage,
		setPassportSecondPage,

		currentPersonalDataView, setCurrentPersonalDataView,
		lawTypeDataIsEditedNow, setLawTypeDataIsEditedNow,
		inn, setInn,
		submitIsLoading, setSubmitIsLoading,
		editMode, setEditMode,
		canBeEdited
	};

	return <personalDataContext.Provider value={payload}>
		{children}
	</personalDataContext.Provider>
};



import React, {FC, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import dialogStyles from '@components/auth/Dialog/dialog.module.scss';
import {AUTH_RESEND_CODE_TIMEOUT} from '@common/constants';

interface IResendCodeProps {
	onResendCode: () => void
	isLoading?: boolean
	centerAlign?: boolean
}

const ResendCode: FC<IResendCodeProps> = ({onResendCode, isLoading= false, centerAlign = true, ...props}) => {

	const
		[reSendTimeOut, setReSendTimeOut] = useState<number>(AUTH_RESEND_CODE_TIMEOUT),
		reSendTimeoutRef: { current: NodeJS.Timeout | null } = useRef(null)
	;

	const
		onResendClick = (): void => {
			setReSendTimeOut(AUTH_RESEND_CODE_TIMEOUT);
			onResendCode();
		}
	;

	useEffect(() => {
		reSendTimeoutRef.current = setTimeout(() => {
			if (reSendTimeOut > 0) {
				setReSendTimeOut(reSendTimeOut - 1)
			} else {
				clearTimeout(reSendTimeoutRef.current as NodeJS.Timeout);
				return;
			}
		}, 1000);

		return () => clearTimeout(reSendTimeoutRef.current as NodeJS.Timeout)
	}, [reSendTimeOut]);

	return (
		<div

			className={classNames("d-flex flex-column text-dark", {
				'pointer-events-none': isLoading,
				'align-items-center pt-4': centerAlign
			})}
		>
			<small>Не пришел код?</small>
			<small className={classNames(dialogStyles.reSendTimer, 'pb-3 pt-1')}>
				{
					reSendTimeOut > 0
						?
						<span className={dialogStyles.reSendTimerUnActive}><span className={'text-decoration-underline'}>Отправить код повторно</span> через {reSendTimeOut} сек.</span>
						:
						<span
							className={classNames(dialogStyles.reSendTimerActive, 'text-decoration-underline')}
							onClick={onResendClick}
						>Отправить код повторно</span>
				}

			</small>
			{props.children}
		</div>
	);
};

export default ResendCode;

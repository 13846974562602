import {IPersonalDataField} from '@components/investor/Views/Data/types';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {validateInnMinMax} from '@common/utils';
import {KPP_MAX_LENGTH, OGRN_IP_MAX_LENGTH, OGRN_IP_MIN_LENGTH} from '@common/constants';

export const INVESTOR_FIELD_NAME_PHONE = 'phone';
export const INVESTOR_FIELD_NAME_EMAIL = 'email';
export const INVESTOR_FIELD_NAME_FIRST_NAME = 'firstName';
export const INVESTOR_FIELD_NAME_LAST_NAME = 'lastName';
export const INVESTOR_FIELD_NAME_MIDDLE_NAME = 'middleName';
export const INVESTOR_FIELD_NAME_BIRTH_DATE = 'birthDate';
export const INVESTOR_FIELD_NAME_RESIDENCE_CITY = 'residenceCity';
export const INVESTOR_FIELD_NAME_INN = 'inn';
export const INVESTOR_FIELD_NAME_PASSPORT_NUMBER = 'passportNumber';
export const INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY = 'passportIssuedBy';
export const INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN = 'passportIssuedWhen';
export const INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE = 'passportDivisionCode';
export const INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE = 'birthPlace';
export const INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS = 'registrationAddress';
export const INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED = 'investRiskAccepted';
export const INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED = 'platformRulesAccepted';
export const INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED = 'rfResidentAccepted';
export const INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED = 'collectionAssignmentAccepted';
export const INVESTOR_FIELD_NAME_PASSPORT_FIRST_PAGE = 'passportFirstPage';
export const INVESTOR_FIELD_NAME_PASSPORT_SECOND_PAGE = 'passportSecondPage';
export const INVESTOR_FIELD_NAME_LAW_TYPE = 'lawType';

export const INVESTOR_FIELD_NAME_ORG_INN = 'orgInn';
export const INVESTOR_FIELD_NAME_ORGANIZATION_FORM = 'organizationForm';
export const  INVESTOR_FIELD_NAME_ORG_FULL_NAME = 'orgFullName';
export const  INVESTOR_FIELD_NAME_ORG_SHORT_NAME = 'orgShortName';
export const  INVESTOR_FIELD_NAME_ORG_OGRN = 'orgOgrn';
export const  INVESTOR_FIELD_NAME_ORG_KPP = 'orgKpp';
export const  INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION = 'orgDateRegistration';
export const  INVESTOR_FIELD_NAME_HEAD_LAST_NAME = 'orgHeadLastName';
export const  INVESTOR_FIELD_NAME_HEAD_FIRST_NAME = 'orgHeadFirstName';
export const  INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME = 'orgHeadMiddleName';
export const  INVESTOR_FIELD_NAME_HEAD_POST = 'orgHeadGrade';
export const  INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION = 'orgAddressRegistration';
export const  INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION = 'orgAddressLocation';
export const  INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG = 'isAgreementHeadOrg';


export const PROFILE_DATA_DEFAULT_VALUES = {
	[INVESTOR_FIELD_NAME_PHONE]: '',
	[INVESTOR_FIELD_NAME_EMAIL]: '',
	[INVESTOR_FIELD_NAME_FIRST_NAME]: '',
	[INVESTOR_FIELD_NAME_MIDDLE_NAME]: '',
	[INVESTOR_FIELD_NAME_LAST_NAME]: '',
	[INVESTOR_FIELD_NAME_BIRTH_DATE]: '',
	[INVESTOR_FIELD_NAME_RESIDENCE_CITY]: '',
	[INVESTOR_FIELD_NAME_INN]: '',
	[INVESTOR_FIELD_NAME_PASSPORT_NUMBER]: '',
	[INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY]: '',
	[INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN]: '',
	[INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE]: '',
	[INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE]: '',
	[INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS]: '',
	[INVESTOR_FIELD_NAME_LAW_TYPE]: '',
	[INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED]: false,
	[INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED]: false,
	[INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED]: false,
	[INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED]: false,
	/*Default end*/

	[INVESTOR_FIELD_NAME_ORG_INN]: '',
	[INVESTOR_FIELD_NAME_ORGANIZATION_FORM]: '',
	[INVESTOR_FIELD_NAME_ORG_FULL_NAME]: '',
	[INVESTOR_FIELD_NAME_ORG_SHORT_NAME]: '',
	[INVESTOR_FIELD_NAME_ORG_OGRN]: '',
	[INVESTOR_FIELD_NAME_ORG_KPP]: '',
	[INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION]: '',
	[INVESTOR_FIELD_NAME_HEAD_LAST_NAME]: '',
	[INVESTOR_FIELD_NAME_HEAD_FIRST_NAME]: '',
	[INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME]: '',
	[INVESTOR_FIELD_NAME_HEAD_POST]: '',
	[INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION]: '',
	[INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION]: '',
	[INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG]: false,
	/*Entity end*/
};

export const individualEntrepreneurFields: IPersonalDataField[] = [
	{
		label: 'Организационно-правовая форма',
		id: INVESTOR_FIELD_NAME_ORGANIZATION_FORM,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Краткое наименование организации',
		id: INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Полное наименование организации',
		id: INVESTOR_FIELD_NAME_ORG_FULL_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'ОГРНИП',
		id: INVESTOR_FIELD_NAME_ORG_OGRN,
		type: FormGroupTypes.NUMBER,
		registerOptions: {
			validate: value => validateInnMinMax(value, OGRN_IP_MIN_LENGTH, OGRN_IP_MAX_LENGTH)
		}
	},
	{
		label: 'Дата регистрации',
		id: INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
		type: FormGroupTypes.DATE
	}
];

export const entityFields: IPersonalDataField[] = [
	{
		label: 'Организационно-правовая форма',
		id: INVESTOR_FIELD_NAME_ORGANIZATION_FORM,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Краткое наименование организации',
		id: INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Полное наименование организации',
		id: INVESTOR_FIELD_NAME_ORG_FULL_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'ОГРН',
		id: INVESTOR_FIELD_NAME_ORG_OGRN,
		type: FormGroupTypes.NUMBER,
		registerOptions: {
			validate: value => validateInnMinMax(value, OGRN_IP_MIN_LENGTH, OGRN_IP_MAX_LENGTH)
		}
	},
	{
		label: 'КПП',
		id: INVESTOR_FIELD_NAME_ORG_KPP,
		type: FormGroupTypes.NUMBER,
		registerOptions: {
			validate: value => (value.length <= KPP_MAX_LENGTH)
				|| `Не более ${KPP_MAX_LENGTH} символов`
		}
	},
	{
		label: 'Дата регистрации',
		id: INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
		type: FormGroupTypes.DATE
	},
	{
		label: 'Фамилия руководителя',
		id: INVESTOR_FIELD_NAME_HEAD_LAST_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Имя руководителя',
		id: INVESTOR_FIELD_NAME_HEAD_FIRST_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Отчество руководителя',
		id: INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Должность руководителя',
		id: INVESTOR_FIELD_NAME_HEAD_POST,
		type: FormGroupTypes.TEXT
	},
	{
		label: 'Юридический адрес организации',
		id: INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION,
		type: FormGroupTypes.TEXT
	}
];



import React, {FC, useContext} from 'react';
import {ProfileDataContacts, ProfileDataPassport, ProfileDataPersonal} from '@components/investor/Views/Data/Sections';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {
	individualEntrepreneurFields,
	INVESTOR_FIELD_NAME_ORG_INN
} from '@components/investor/Views/Data/constants';
import ProfileDataFields from '@components/investor/Views/Data/Ui/ProfileDataFields';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {useFormContext} from 'react-hook-form';
import styles from '@components/investor/Views/Data/data.module.scss';
import {validateInnMinMax} from '@common/utils';
import {ADAPTIVE_BREAKPOINTS, INN_MAX_LENGTH, INN_MIN_LENGTH} from '@common/constants';
import ProfileDataControls from '@components/investor/Views/Data/Ui/ProfileDataControls';
import {personalDataContext} from '@context/personalDataContext';
import {getDefaultParams} from '@components/investor/Views/Data/utils';
import {useAppSelector} from '@hooks/useAppSelector';
import ProfileDataEditButton from '@components/investor/Views/Data/Ui/EditButton/EditButton';
import useWindowSize from '@hooks/useWindowSize';

const ProfileDataPageIndividualEntrepreneurDefault:FC = () => {

	const
		{
			setValue,
			watch,
			register,
			reset,
			formState: {errors}
		} = useFormContext(),
		{editMode, setEditMode} = useContext(personalDataContext),
		{investorProfile} = useAppSelector(state => state.user),
		{width: ww} = useWindowSize()
	;

	return (
		<div className='position-relative pt-5 pt-sm-0'>
			<ProfileDataEditButton isVisible={ww < ADAPTIVE_BREAKPOINTS.sm}/>
			<ProfileDataContacts/>
			<ProfileDataPersonal/>
			<ProfileDataPassport/>
			<ProfileSection title={'Данные индивидуального предпринимателя'}>
				<ProfileDataFields fields={individualEntrepreneurFields}>
					<div className="col-sm-6 col-md-4">
						<FormGroup
							id={INVESTOR_FIELD_NAME_ORG_INN}
							fieldType={FormGroupTypes.NUMBER}
							label={'ИНН'}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_ORG_INN, event.target.value)}
							value={watch(INVESTOR_FIELD_NAME_ORG_INN)}
							className={styles.formGroup}
							register={register}
							readonly={!editMode}
							name={INVESTOR_FIELD_NAME_ORG_INN}
							errorMessage={errors[INVESTOR_FIELD_NAME_ORG_INN]?.message}
							registerOptions={
								{
									validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
								}
							}
						/>
					</div>
				</ProfileDataFields>
			</ProfileSection>
			<ProfileDataControls
				submitText={'Сохранить изменения'}
				onCancel={() => {
					reset(getDefaultParams(investorProfile));
					setEditMode(false)
				}}
			/>
		</div>
	);
};

export default ProfileDataPageIndividualEntrepreneurDefault;

import {useAppSelector} from '@hooks/useAppSelector';
import {useMemo} from 'react';
import {IProject, IProjectDetails} from '@features/projects/types';

export const useCashedProject = (id: IProject['projectId']): IProjectDetails | null => {

	const {detailedProjects} = useAppSelector(state => state.projects);

	return useMemo(() => {
		return Object.values(detailedProjects)
			.find(project => project.projectId === id) || null;
	}, [detailedProjects]);
};



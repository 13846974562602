import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';
import {authSlice} from '@features/auth/slice';
import {userSlice} from '@features/user/slice';
import {utilsSlice} from '@features/utils/slice';
import {projectsSlice} from '@features/projects/slice';
import {notificationsSlice} from '@features/notifications/slice';
import {borrowerSlice} from '@features/borrower/slice';
import { analyticSlice } from '@features/analytics/slice';
import * as analyticActions from '@features/analytics/actions';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useAppActions = () => {
	const dispatch = useDispatch();

	return bindActionCreators({
		...authSlice.actions,
		...userSlice.actions,
		...utilsSlice.actions,
		...projectsSlice.actions,
		...notificationsSlice.actions,
		...borrowerSlice.actions,
		...analyticSlice.actions,
		...analyticActions,

	}, dispatch)
};

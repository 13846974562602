import React, {FC, HTMLAttributes, ReactElement} from 'react';
import classNames from 'classnames';
import style from './badge.module.scss';

interface IBadgeProps {
	variant?: 'default' | 'success' | 'info',
	icon?: ReactElement,
	content: string | ReactElement,
	size?: 'sm' | 'md'
}

const Badge:FC<IBadgeProps & HTMLAttributes<HTMLDivElement>> = (
	{
		content,
		icon = undefined,
		size = 'sm',
		variant = 'default',
		className
	}
) => {
	return (
		<div
			className={classNames(style.item, className, style[variant], style[size])}
		>
			{
				icon && <span className={'me-2'}>{icon}</span>
			}
			<div className={style.content}>{content}</div>
		</div>
	);
};

export default Badge;

import {IProject} from '@features/projects/types';
import {IBorrowerSimple} from '@features/borrower/types';

export enum operationInvestTypeEnum {
	MANUAL = 'manual',
	AUTO = 'auto'
}

export interface IOperation {
	operationId: number,
	title: string,
	code: string,
	dateCreated: string,
	income: string | null,
	outcome: string | null,
	comment: string,
	isVirtual: boolean,
	investType: operationInvestTypeEnum.MANUAL | operationInvestTypeEnum.AUTO
	borrower: IBorrowerSimple | null
}

export interface IDateRange {
	dateStart?: string | null,
	dateEnd: string | null
}

export interface IRequisitesResponse {
	receiver:	string
	inn:	string
	kpp:	string
	bankName:	string
	bik:	string
	paymentAccount:	string
	correspondentAccount:	string
	paymentPurpose:	string
	qrcode:	string
}

export interface IWithdrawInitRequest {
	amount: number
	correspondentAccount: string
	bik: string
	bankName: string
	accountNumber: string
}

export interface IWithdrawAccount extends Omit<IWithdrawInitRequest, 'amount'> {
	id: number
}

export interface IWithdrawAccountsResponse {
	items: IWithdrawAccount[]
	count: number
}

export interface IInvestRequest {
	projectId: IProject['projectId']
	amount: number
	limitInvestFlag:	boolean
	highRiskFlag:	boolean
	acceptFlag:	boolean
}

import React, { FC, useEffect } from 'react';
import styles from './appWrapper.module.scss';
import { Spinner } from 'react-bootstrap';
import authApi from '@api/authApi';
import { useAppActions } from '@hooks/useAppActions';
import { useHistory, useLocation } from 'react-router-dom';
import { AuthRouteNamesEnum, BorrowerRouteNamesEnum, InvestorRouteNamesEnum, RouteNamesEnum } from '@app/router';
import { useRedirectToHomePage } from '@hooks/useRedirectToHomePage';

const AppWrapper: FC = (props) => {

    const
        [checkAuthorized, { isLoading: authCheckIsLoading }] = authApi.useIsAuthorizedMutation(),
        { setAuthorized, setToken } = useAppActions(),
        history = useHistory(),
        location = useLocation(),
        { baseHomeRoute } = useRedirectToHomePage()
    ;

    useEffect(() => {
        checkAuthorized()
            .unwrap()
            .then(result => {
                const { isAuthorized } = result;

                setAuthorized(isAuthorized);

                if (isAuthorized) {

                    const routePaths: string[] = [
                        ...Object.values(AuthRouteNamesEnum)
                            .map(r => RouteNamesEnum.AUTH + r),
                        RouteNamesEnum.AUTH,

                        ...Object.values(InvestorRouteNamesEnum)
                            .map(r => RouteNamesEnum.INVESTOR + r),
                        RouteNamesEnum.INVESTOR,

                        ...Object.values(BorrowerRouteNamesEnum)
                            .map(r => {
                                return RouteNamesEnum.BORROWER + r;
                            }),
                        RouteNamesEnum.BORROWER
                    ];

                    if (location.pathname === '/') {

                        baseHomeRoute && history.push(baseHomeRoute);
                        return;
                    }

                    const finedRoute = routePaths.find(r => location.pathname.includes(r));

                    switch (finedRoute) {
                        case RouteNamesEnum.AUTH:
                        case RouteNamesEnum.INVESTOR:
                            history.push(RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.QUESTIONS);
                            break;
                        case RouteNamesEnum.BORROWER:
                            history.push(RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT);
                            break;
                        default:
                            finedRoute && history.push(location.pathname);
                    }

                } else {
                    const path = location.pathname.includes('borrower')
                        ? RouteNamesEnum.AUTH + RouteNamesEnum.BORROWER + AuthRouteNamesEnum.CHECK_PHONE
                        : RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE;
                    history.push(path);

                }

            })
            .catch(e => {
                if (e.status === 401) setToken(null);
                history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE);
            });
    }, []);

    return (
        <div {...props} className={styles.root}>
            {
                authCheckIsLoading
                    ? <Spinner className={'spinner--centered'} animation="border" variant="primary" />
                    : props.children
            }
        </div>
    );
};

export default AppWrapper;

import React, { FC, useContext, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { profileContext } from "@context/profileContext";
import { ReactComponent as LogoIcon } from "@icons/auth-logo.svg";
import styles from "./mobileMenu.module.scss";
import { IProfileNavigationItem } from "@common/types";
import ProfileNav from "@components/profile/Nav/ProfileNav";
import { useAppActions } from "@hooks/useAppActions";
import {
	AuthRouteNamesEnum,
	BorrowerRouteNamesEnum,
	InvestorRouteNamesEnum,
	RouteNamesEnum,
} from "@app/router";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { useAppSelector } from "@hooks/useAppSelector";
import { AuthRolesEnum } from "@features/auth/types";
import { ReactComponent as CoinIcon } from "@icons/coin.svg";
import AmountOutput from "@components/common/AmountOutput";
import { Button } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { BorrowerAccountTabsKeys } from "@components/borrower/Views/Account/Account";
import useInvestorStatusCheck from "@hooks/useInvestorStatusCheck";
import { withdrawContext } from "@context/withdrawContext";

const ProfileMobileMenu: FC<{ navItems: IProfileNavigationItem[] }> = ({
	navItems,
}) => {
	const { mobileMenuIsOpen, setMobileMenuIsOpen } = useContext(profileContext),
		{ setToken, resetUserData, setAuthorized } = useAppActions(),
		history = useHistory(),
		{ role } = useAppSelector((state) => state.auth),
		{ balance } = useAppSelector((state) => state.user.investorInfo),
		{ setActiveTabKey } = useContext(profileContext),
		[landingLinkName, setLandingLinkName] = useState<string | null>(null),
		[landingLinkUrl, setLandingLinkUrl] = useState<string | null>(null),
		[accentLinkName, setAccentLinkName] = useState<string | null>(null),
		[accentLinkUrl, setAccentLinkUrl] = useState<string | null>(null),
		{ checkToPersonalDataRedirect } = useInvestorStatusCheck(),
		{ setRequestModal } = useContext(withdrawContext);
	useEffect(() => {
		switch (role) {
			case AuthRolesEnum.INVESTOR:
				setLandingLinkName("Получить инвестиции");
				setLandingLinkUrl("borrower.html");
				setAccentLinkName("Моя учетная запись");
				setAccentLinkUrl(RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.DATA);
				break;
			case AuthRolesEnum.BORROWER:
				setLandingLinkName("Стать инвестором");
				setLandingLinkUrl("investor.html");
				setAccentLinkName("Получить заем");
				setAccentLinkUrl(
					RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
				);
				break;
			default:
				setLandingLinkName(null);
				setAccentLinkName(null);
				setAccentLinkUrl(null);
		}
	}, [role]);

	return (
		<Offcanvas
			show={mobileMenuIsOpen}
			onHide={() => {
				setMobileMenuIsOpen(false);
			}}
			placement={"end"}
			className={styles.dialog}>
			<Offcanvas.Header className={styles.header} closeButton>
				<LogoIcon height={40} width={110} />
			</Offcanvas.Header>
			<Offcanvas.Body>
				{role === AuthRolesEnum.INVESTOR && (
					<div className={styles.balance}>
						<div className='row align-items-center'>
							<div className='col-auto d-flex align-items-center'>
								<CoinIcon width={16} height={16} />
								<p className='text-dark text-weight-medium mb-0 ps-2'>Баланс</p>
							</div>
							<div className='col-auto ms-auto'>
								<strong className={styles.balanceAmount}>
									<AmountOutput amount={balance ? balance : ""} />
								</strong>
							</div>
						</div>
						<div className='row g-2 pt-3 justify-content-end'>
							<div className='col-auto'>
								<Button
									variant={ButtonVariant.outlined}
									as={ButtonTypesEnum.BUTTON}
									className={"py-0"}
									onClick={() => {
										checkToPersonalDataRedirect(() => {
											history.push(
												RouteNamesEnum.INVESTOR +
													InvestorRouteNamesEnum.REQUISITES,
											);
										});

										setMobileMenuIsOpen(false);
									}}>
									Пополнить
								</Button>
							</div>
							<div className='col-auto'>
								<Button
									variant={ButtonVariant.contained}
									as={ButtonTypesEnum.BUTTON}
									className={"py-0"}
									onClick={() => {
										checkToPersonalDataRedirect(() => setRequestModal(true));
										setMobileMenuIsOpen(false);
									}}>
									Вывести
								</Button>
							</div>
						</div>
					</div>
				)}

				<ProfileNav
					onLinkClick={() => setMobileMenuIsOpen(false)}
					items={navItems}
					className={classNames(styles.nav)}
				/>

				<nav className={classNames(styles.footer, "row gx-0 py-3 flex-column")}>
					{accentLinkUrl && (
						<div className='col-12'>
							<button
								type={"button"}
								className='text-dark text-m py-4'
								onClick={() => {
									setMobileMenuIsOpen(false);
									if (accentLinkUrl) {
										history.push(accentLinkUrl);
										setActiveTabKey(BorrowerAccountTabsKeys.CONDITIONS);
									}
								}}>
								{accentLinkName}
							</button>
						</div>
					)}

					<div className='col-12'>
						<a
							href={`${process.env.REACT_APP_ROOT_LANDING_URL}about.html`}
							target={"_blank"}
							className='text-dark text-m py-4'
							rel='noreferrer'>
							О платформе
						</a>
					</div>

					<div className='col-12'>
						<a
							href={`${process.env.REACT_APP_ROOT_LANDING_URL}documents.html`}
							target={"_blank"}
							className='text-dark text-m py-4'
							rel='noreferrer'>
							Документы
						</a>
					</div>

					{landingLinkName && (
						<div className='col-12'>
							<a
								href={`${process.env.REACT_APP_ROOT_LANDING_URL}${landingLinkUrl}`}
								target={"_blank"}
								className='text-dark text-m py-4'
								rel='noreferrer'>
								{landingLinkName}
							</a>
						</div>
					)}

					<div className='col-12'>
						<button
							type={"button"}
							className='text-dark text-m py-4'
							onClick={() => {
								setToken(null);
								resetUserData();
								setAuthorized(false);
								history.push(
									RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE,
								);
							}}>
							Выйти
						</button>
					</div>
				</nav>
			</Offcanvas.Body>
		</Offcanvas>
	);
};

export default ProfileMobileMenu;

import React, { FC } from 'react';
import { ReactComponent as Email } from '@icons/email-circle.svg';
import styled from 'styled-components';
import { LinkVariant } from '@components/ui/Link/Link.props';
import { CustomLink } from '@components/ui';

const { simple } = LinkVariant;

const StyledEmail = styled.div`
  transition: color .25s ease;
  font-size: 1rem;
  color: #5BC1FF;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  svg {
    min-height: 43px;
    min-width: 43px;
  }

  &:hover {
    color: #0087DA;
  }

  small {
    color: #96A6B8;
  }

`;

interface CompanyEmailProps {
    withIcon?: boolean;
    className?: string;
}

const CompanyEmail: FC<CompanyEmailProps> = ({ withIcon = false, className = '' }) => {
    return (
        <StyledEmail className={className}>
            {
                withIcon && <Email width={43} height={43} />
            }
            <CustomLink
                className={withIcon ? 'ps-3' : ''}
                variant={simple}
                target={'_blank'}
                href={'mailto:info@panfilovinvest.ru'}
            >
                info@panfilovinvest.ru
            </CustomLink>
        </StyledEmail>
    );
};

export default CompanyEmail;

import React, { FC, useContext } from 'react';
import {ProfileMain} from '@components/profile/Main/Main';
import WithdrawRequestModal from '@components/investor/WithdrawRequestModal/WithdrawRequestModal';
import WithdrawConfirmModal from '@components/investor/WithdrawConfirmModal/WithdrawConfirmModal';
import SuccessModal from '@components/common/Modal/SuccessModal';
import ProfileVideoModal from '@components/profile/reused/VideoModal/ProfileVideoModal';
import { withdrawContext } from '@context/withdrawContext';

const BorrowerMain: FC = (props) => {
	const {successModal, setSuccessModal} = useContext(withdrawContext);
	return (
		<ProfileMain>
			{props.children}
			<WithdrawRequestModal/>
			<WithdrawConfirmModal/>
			<SuccessModal
				message={'!'}
				setVisible={setSuccessModal}
				visible={successModal}
			/>
			<ProfileVideoModal/>
		</ProfileMain>
	);
};

export default BorrowerMain;

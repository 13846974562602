import React, {FC, useContext, useEffect} from 'react';
import styles from '../account.module.scss';
import BorrowerAccountDataRequisites
	from '@components/borrower/Views/Account/Data/sections/BorrowerAccountDataRequisites';
import BorrowerAccountDataFounder
	from '@components/borrower/Views/Account/Data/sections/BorrowerAccountDataFounder';
import BorrowerAccountDataPassport
	from '@components/borrower/Views/Account/Data/sections/BorrowerAccountDataPassport';
import BorrowerAccountDataContacts
	from '@components/borrower/Views/Account/Data/sections/BorrowerAccountDataContacts';
import {FormProvider, useForm} from 'react-hook-form';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {BorrowerProfileKeysEnum, IBorrowerProfile, UserDataKeysEnum} from '@features/user/types';
import borrowerApi from '@api/borrowerApi';
import {dateToApiFormat, passwordDivisionCodeWithDash} from '@common/utils';
import {useAppActions} from '@hooks/useAppActions';
import {useAppSelector} from '@hooks/useAppSelector';
import {Fade} from '@components/common/Transitions/Fade';
import SuccessModal from '@components/common/Modal/SuccessModal';
import {borrowerContext} from '@context/borrowerContext';
import {BorrowerAccountTabsKeys} from '@components/borrower/Views/Account/Account';
import {profileContext} from '@context/profileContext';
import {Spinner} from 'react-bootstrap';
import {IBorrowerDataFields} from '@components/borrower/Views/Account/types';
import {
	BORROWER_DATA_DEFAULT_VALUES
} from '@components/borrower/Views/Account/constants';
import {getDefaultParams} from '@components/borrower/Views/Account/utils';
import {useHistory} from 'react-router-dom';

const BorrowerAccountData:FC = () => {

	const formHookMethods = useForm<IBorrowerDataFields>({
		mode: 'onBlur',
		defaultValues: BORROWER_DATA_DEFAULT_VALUES
	});

	const
		[patchProfile, {isLoading: profilePatchIsLoading}] = borrowerApi.usePatchProfileMutation(),
		{borrowerProfile} = useAppSelector(state => state.user),
		{setUserData} = useAppActions(),
		{
			profileIsLoading,
			profileChangedSuccessModal,
			setProfileChangedSuccessModal
		} = useContext(borrowerContext),
		{setActiveTabKey} = useContext(profileContext),
		history = useHistory()
	;


	const onSubmit = formHookMethods.handleSubmit(data => {
		history.push({
			state: {
				needRedirect: false
			}
		});

		const writeDateToProfile = (
			k: BorrowerProfileKeysEnum.BIRTH_DATE | BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN
		):string => dateToApiFormat(data[k]) || '';

		patchProfile({
			...data,
			[BorrowerProfileKeysEnum.BIRTH_DATE]: writeDateToProfile(BorrowerProfileKeysEnum.BIRTH_DATE),
			[BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN]: writeDateToProfile(BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN),
			[BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE]: passwordDivisionCodeWithDash(data[BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE])
		})
			.unwrap()
			.then((profile: IBorrowerProfile) => {
				setProfileChangedSuccessModal(true);

				setUserData({
					data: profile,
					key: UserDataKeysEnum.BP
				});
			})
	});

	useEffect(() => {
		if (borrowerProfile.hasOwnProperty(BorrowerProfileKeysEnum.USER_ID)) {
			formHookMethods.reset(getDefaultParams(borrowerProfile));
		}
	}, [borrowerProfile]);

	useEffect(() => {
		const clearErrors = ():void => formHookMethods.clearErrors();

		window.addEventListener('focus', clearErrors);
		window.addEventListener('blur', clearErrors);
		return () => {
			window.removeEventListener('focus', clearErrors);
			window.removeEventListener('blur', clearErrors);
		}
	}, []);

	return (
		<div className={styles.wrap}>
			{
				profileIsLoading && <div className='text-center py-4'>
					<Spinner animation={'border'} variant={'primary'}/>
				</div>
			}
			<Fade inProp={!profileIsLoading}>
				<FormProvider {...formHookMethods}>
					<form onSubmit={onSubmit}>
						<BorrowerAccountDataRequisites/>
						<BorrowerAccountDataFounder/>
						<BorrowerAccountDataPassport/>
						<BorrowerAccountDataContacts/>
						<Button
							variant={ButtonVariant.contained}
							as={ButtonTypesEnum.BUTTON}
							type={'submit'}
							className={'mt-5'}
							wrapperClassName={'text-end text-sm-start'}
							isLoading={profilePatchIsLoading}
						>
							Далее
						</Button>
					</form>
				</FormProvider>
			</Fade>

			<SuccessModal
				message={'Данные успешно изменены!'}
				visible={profileChangedSuccessModal}
				setVisible={setProfileChangedSuccessModal}
				onHide={() => {
					setActiveTabKey(BorrowerAccountTabsKeys.DOCUMENTS)
				}}
			/>
		</div>
	);
};

export default BorrowerAccountData;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IUserAnalyticsInfoResponse } from '@features/user/types';
import { fetchAnalyticsInfo } from '@features/analytics/actions';
import { AxiosError } from 'axios';

interface IAnalyticsState {
    data: IUserAnalyticsInfoResponse | Record<string, never>;
    loading: boolean;
    error: null | AxiosError;
}

const initialState: IAnalyticsState = {
    data: {},
    loading: false,
    error: null
};

export const analyticSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder => {
        builder
            .addCase(fetchAnalyticsInfo.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchAnalyticsInfo.rejected, (state, { payload }: PayloadAction<any>) => {
                state.error = payload;
                state.loading = false;

            })
            .addCase(fetchAnalyticsInfo.fulfilled, (state, { payload }: PayloadAction<any>) => {
                state.data = payload;
                state.error = null;
                state.loading = false;
            });
    })
});

export default analyticSlice.reducer;






import React, {FC, useContext} from 'react';
import styles from './profileBalance.module.scss';
import classNames from 'classnames';
import {ReactComponent as CoinIcon} from '@icons/coin.svg';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {Button, Heading} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {useAppSelector} from '@hooks/useAppSelector';
import AmountOutput from '@components/common/AmountOutput';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {withdrawContext} from '@context/withdrawContext';
import useInvestorStatusCheck from '@hooks/useInvestorStatusCheck';
import {useHistory} from 'react-router-dom';

const InvestorProfileBalance: FC<{className?: string}> = ({className}) => {
	const
		{balance} = useAppSelector(state => state.user.investorInfo),
		{setRequestModal} = useContext(withdrawContext),
		history = useHistory(),
		{checkToPersonalDataRedirect} = useInvestorStatusCheck()
	;

	return (
		<div className={classNames(styles.root, className, 'd-sm-inline-block d-xl-block')}>
			<div className={classNames(styles.inner)}>
				<div className='row gx-3 align-items-sm-center align-items-xl-start'>
					<div className='col-auto'>
						<CoinIcon/>
					</div>
					<div className="col">
						<div className='d-flex flex-column flex-sm-row align-items-end align-items-sm-center align-items-xl-start flex-xl-column'>
							<p className='text-dark py-1 mb-0 d-none d-xl-block'><small>Баланс</small></p>
							<Heading
								tag={'h2'}
								role='heading'
								className={classNames(styles.title, 'mb-0 mb-xl-3 me-sm-5 pe-sm-4 pe-xl-0 me-xl-0')}
								variant={HeadingVariant.large}
							>
								<span className='d-flex'>
									<span className='text-dark mb-0 d-sm-none me-auto'><small>Баланс</small></span>
									<AmountOutput amount={balance ? balance : ''}/>
								</span>
							</Heading>

							<div className={classNames(styles.buttons, 'd-flex flex-nowrap pt-xl-2')}>
								<div className='w-50 pe-2'>
									<Button
										className={styles.button}
										variant={ButtonVariant.outlined}
										type={'button'}
										as={ButtonTypesEnum.BUTTON}
										onClick={() => {
											checkToPersonalDataRedirect(() => setRequestModal(true))
										}}
									>Вывести</Button>
								</div>
								<div className='w-50 ps-2'>
									<Button
										role='button'
										className={styles.button}
										variant={ButtonVariant.contained}
										type={'button'}
										as={ButtonTypesEnum.BUTTON}
										onClick={() => {
											checkToPersonalDataRedirect(() => {
												history.push(RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.REQUISITES)
											});
										}}
									>Пополнить</Button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default InvestorProfileBalance;

import React, {FC, useEffect, useRef, useState} from 'react';
import {ReactComponent as LogoIcon} from '@icons/logo.svg';
import styles from './asideHeader.module.scss';
import classNames from 'classnames';
import {LinkVariant} from '@components/ui/Link/Link.props';
import {CustomLink, Paragraph} from '@components/ui';
import {useAppSelector} from '@hooks/useAppSelector';
import {Link} from 'react-router-dom';
import {CSSTransition} from 'react-transition-group';
import {TRANSITION_TIMEOUT} from '@common/constants';
import {IProfileAsideHeaderPropsPick} from '@components/profile/Aside/Aside';
import ProfileAvatar from '@components/profile/Avatar/Avatar';
import {useRedirectToHomePage} from '@hooks/useRedirectToHomePage';

const AsideHeader: FC<IProfileAsideHeaderPropsPick> = (
	{
		userInfoStateKey,
		toPersonalDataLink
	}) => {

	const
		{
			firstName,
			middleName,
			lastName,
			email
		} = useAppSelector(state => state.user[userInfoStateKey]),
		{
			orgShortName
		} = useAppSelector(state => state.user.investorInfo),
		animatedLinkRef = useRef(null),
		{baseHomeRoute} = useRedirectToHomePage(),
		[name, setName] = useState<string>('')
	;

	useEffect(() => {
		if (orgShortName) {
			setName(orgShortName)
		} else if ([lastName, firstName, middleName].some(Boolean)) {
			setName(`${lastName} ${firstName} ${middleName}`.trim())
		}
	}, [lastName, firstName, middleName, orgShortName]);

	return (
		<div className={classNames(styles.root)}>
			<div className={'pb-5 mb-4'}>
				{baseHomeRoute && <Link className='d-inline-block' to={baseHomeRoute}><LogoIcon/></Link>}
			</div>
			<div className="row align-items-center">
				<div className="col-auto">
					<ProfileAvatar/>
				</div>
				{
					toPersonalDataLink && <div className="col-auto ms-auto">
						<CSSTransition
							in={toPersonalDataLink.inProp}
							classNames={'animation--slide-x'}
							timeout={TRANSITION_TIMEOUT}
							unmountOnExit
							nodeRef={animatedLinkRef}
						>
							<div ref={animatedLinkRef}>
								<CustomLink
									to={toPersonalDataLink.to}
									href={'#'}
									variant={LinkVariant.arrow}
								>
									<small>Учетная запись</small>
								</CustomLink>
							</div>
						</CSSTransition>
					</div>
				}
			</div>
			<div className="pt-4">
				<Paragraph className='mb-1'>{name}</Paragraph>
				<span	className={'text-dark'}>{email}</span>
			</div>
		</div>
	);
};

export default AsideHeader;

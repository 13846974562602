import React, {FC, useContext, useEffect} from 'react';
import {IProfileTab, profileContext} from '@context/profileContext';
import ProfileQuestionsCommon from '@components/investor/Views/Questions/tabs/QuestionsCommon/QuestionsCommon';
import ProfileQuestionsMy from '@components/investor/Views/Questions/tabs/QuestionsMy/QuestionsMy';
import ProfileTabsContent from '@components/profile/reused/Tabs/ProfileTabsContent';
import ProfileQuestionsAskModal from '@components/investor/Views/Questions/components/ask/QuestionsAskModal';
import {ProfilePagesTitleEnum} from '@common/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import ProfileQuestionsSearchModal from '@components/investor/Views/Questions/components/search/QuestionsSearchModal';

export enum ProjectQuestionsTabsKeysEnum {
	COMMON = 'questionsCommon',
	MY = 'questionsMy'
}

export const questionsTabs:IProfileTab[] = [
	{
		isActive: true,
		key: ProjectQuestionsTabsKeysEnum.COMMON,
		name: 'Общие'
	},
	{
		isActive: false,
		key: ProjectQuestionsTabsKeysEnum.MY,
		name: 'Мои'
	}
];

export const ProfileQuestions:FC = () => {
	const {setTabs} = useContext(profileContext);

	useEffect(() => {
		setTabs(questionsTabs, 0);
		return () => {
			setTabs([])
		}
	}, []);

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.QUESTIONS}>
			<ProfileTabsContent
				items={{
					 [ProjectQuestionsTabsKeysEnum.COMMON]: <ProfileQuestionsCommon/>,
					 [ProjectQuestionsTabsKeysEnum.MY]: <ProfileQuestionsMy/>
				}}
			/>
			<ProfileQuestionsAskModal/>
			<ProfileQuestionsSearchModal/>
		</ProfileContainer>
	);
};


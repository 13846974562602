import React, {FC, useEffect, useState} from 'react';
import Slider from 'rc-slider';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import classNames from 'classnames';
import styles from './filter.module.scss';
import {NUMBER_REGEXP} from '@common/constants';
import {trimZero} from '@common/utils';
import {RangeNumbersType} from '@common/types';


interface IProfileProjectFilterSectionProps {
	title: string,
	min?: number,
	max: number,
	value: RangeNumbersType,
	postfix?: string,
	onRangeChange?: (value: RangeNumbersType) => void
}

const ProfileProjectsFilterSection: FC<IProfileProjectFilterSectionProps> = (
	{
		title,
		min = 0,
		max,
		postfix,
		value= [0, 0],
		onRangeChange
	}
) => {

	const [fromVal, setFromVal] = useState<number>(value[0]);
	const [toVal, setToVal] = useState<number>(value[1]);

	useEffect(() => {
		const [from, to] = value;

		setFromVal(from);
		setToVal(to);
	},[value]);

	const handleInputsChange  = (value: string, dir: 'Min' | 'Max'):void => {

		if (NUMBER_REGEXP.test(value) || value === '') {
			const
				numVal = parseInt(value),
				newVal = value === '' ? 0 : (numVal <= max ? numVal : max)
			;

			let validVal;

			switch (dir) {
				case 'Min':
					validVal = newVal < toVal ? newVal : toVal;

					setFromVal(validVal);
					onRangeChange && onRangeChange([validVal, toVal]);
					break;
				case 'Max':
					const getLength = (value: number): number => value.toString().length;
					validVal = (getLength(newVal) >= getLength(fromVal) && newVal < fromVal) ? fromVal : newVal;

					setToVal(validVal);
					onRangeChange && onRangeChange([fromVal, validVal]);
					break;
			}
		}
	};

	const preventEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) =>{
		if(e.key == 'Enter') e.preventDefault();
	} ;

	return (
		<section className={styles.section}>
			<Heading
				tag={'h3'}
				variant={HeadingVariant.middle}
				className={classNames('pb-3')}
			>{title}</Heading>
			<div className="px-2 pt-4 pb-3">
				<Slider.Range
					marks={{[min]: `${min} ${postfix}`, [max]: `${max} ${postfix}`}}
					min={min}
					max={max}
					defaultValue={[min, max]}
					value={[fromVal, toVal]}
					onChange={value => {
						setFromVal(value[0]);
						setToVal(value[1]);

						onRangeChange && onRangeChange(value as RangeNumbersType);
					}}
					pushable
				/>
			</div>
			<p className={'text-dark'}>От {fromVal} до {toVal} {postfix}</p>
			<div className="row pt-3">
				<div className="col-auto">
					<div className={styles.fields}>
						<div className="row align-items-center gx-3">
							<div className="col-auto">
								<p className={'mb-0 text-dark'}>От</p>
							</div>
							<div className="col-auto">
								<div className={styles.field}>
									<input
										type={'number'}
										value={trimZero(fromVal)}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											handleInputsChange(event.target.value, 'Min')
										}}
										onKeyPress={preventEnterKeyPress}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="col-auto">
					<div className={styles.fields}>
						<div className="row align-items-center gx-3">
							<div className="col-auto">
								<p className={'mb-0 text-dark'}>До</p>
							</div>
							<div className="col-auto">
								<div className={styles.field}>
									<input
										type={'number'}
										value={trimZero(toVal)}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											handleInputsChange(event.target.value, 'Max')
										}}
										onKeyPress={preventEnterKeyPress}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ProfileProjectsFilterSection;





import {useAppSelector} from '@hooks/useAppSelector';
import {InvestorTypeEnum, VerificationStatusEnum} from '@features/user/types';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useCallback, useContext, useEffect, useState} from 'react';
import {profileContext} from '@context/profileContext';
import { useHistory } from 'react-router-dom';

interface IInvestorStatusCheckReturned {
	investorStatusUrl: string
	continuationIsAllowed: boolean
	checkToPersonalDataRedirect: (allowedAction: () => void) => void
}

function useInvestorStatusCheck():IInvestorStatusCheckReturned {
	const
		{
			verificationStatus,
			investorType
		} = useAppSelector(state => state.user.investorInfo)
	;

	const [investorStatusUrl, setInvestorStatusUrl] = useState<string>('');
	const [continuationIsAllowed, setContinuationIsAllowed] = useState<boolean>(false);
	const {setVerifyErrorIsShown} = useContext(profileContext);
	const history = useHistory();

	useEffect(() => {
		if (verificationStatus && investorType) {
			switch (verificationStatus) {
				case VerificationStatusEnum.NOT_VERIFIED:
				case VerificationStatusEnum.ON_CHECK:
				case VerificationStatusEnum.CHANGE_ON_CHECK:
					setInvestorStatusUrl(RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.DATA);
					setContinuationIsAllowed(false);
					break;
				case VerificationStatusEnum.VERIFIED:
					setContinuationIsAllowed(true);
					setInvestorStatusUrl('');
					break;
			}
		}

	}, [verificationStatus, investorType]);

	useEffect(() => {
		if (continuationIsAllowed && investorType && investorType === InvestorTypeEnum.NOT_CHOSEN) {
			setContinuationIsAllowed(false);
			setInvestorStatusUrl(RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.QUALIFICATION);
		}
	}, [continuationIsAllowed, investorType]);

	const checkToPersonalDataRedirect = useCallback((allowedAction: () => void) => {
		if (continuationIsAllowed) {
			allowedAction()
		} else {
			history.push(investorStatusUrl);
			setVerifyErrorIsShown(true);
		}
	}, [continuationIsAllowed, investorStatusUrl]);

	return {investorStatusUrl, continuationIsAllowed, checkToPersonalDataRedirect}
}

export default useInvestorStatusCheck;

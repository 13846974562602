import React, {FC, useEffect} from 'react';
import {BorrowerPagesTitleEnum} from '@common/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import borrowerApi from '@api/borrowerApi';
import BorrowerCabinetIndicators from '@components/borrower/Views/Cabinet/Indicators/BorrowerCabinetIndicators';
import {useAppActions} from '@hooks/useAppActions';
import {BorrowerBidStatusEnum, BorrowerProjectStatusEnum, IBorrowerLoans} from '@features/borrower/types';
import {Fade} from '@components/common/Transitions/Fade';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import BorrowerCabinetLoans from '@components/borrower/Views/Cabinet/Loans/BorrowerCabinetLoans';
import BorrowerCabinetBids from '@components/borrower/Views/Cabinet/Bids/BorrowerCabinetBids';
import styles from './cabinet.module.scss';
import isEmpty from 'lodash.isempty';
import {Spinner} from 'react-bootstrap';

export const defineStatusContent = (key: BorrowerProjectStatusEnum | BorrowerBidStatusEnum):JSX.Element => {

	let text = '', styleModifier = '';

	switch (key) {
		case BorrowerProjectStatusEnum.COLLECTION_FINISH:
			text = 'Сбор завершён';
			styleModifier = 'green';
			break;
		case BorrowerProjectStatusEnum.COLLECTION:
			text = 'Идет сбор';
			styleModifier = 'yellow';
			break;
		case BorrowerProjectStatusEnum.ISSUED:
			text = 'Выдан';
			styleModifier = 'green';
			break;
		case BorrowerProjectStatusEnum.PAID:
			text = 'Возвращен';
			styleModifier = 'green';
			break;
		case BorrowerProjectStatusEnum.COLLECTED:
			text = 'Собран';
			styleModifier = 'green';
			break;
		case BorrowerProjectStatusEnum.REQUEST_CANCELLED:
			text = 'Отменён';
			styleModifier = 'danger';
			break;
		case BorrowerProjectStatusEnum.EXPIRED:
			text = 'Истёк';
			styleModifier = 'danger';
			break;
		case BorrowerBidStatusEnum.APPROVED:
			text = 'Одобрена';
			styleModifier = 'green';
			break;
		case BorrowerBidStatusEnum.CONSIDERATION:
			text = 'На рассмотрении';
			styleModifier = 'yellow';
			break;
		case BorrowerBidStatusEnum.REJECTED:
			text = 'Отклонена';
			styleModifier = 'danger';
			break;
		default:
			text = key
	}

	return <p className={styleModifier && `text-${styleModifier}`}>{text}</p>;
};

const BorrowerCabinet:FC = () => {

	const
		{
			data: loansData = {},
			isLoading: loansDataIsLoading,
			isError: loansDataIsError,
		} = borrowerApi.useGetProjectsQuery(),
		{
			data: bidsData = [],
			isLoading: bidsDataIsLoading,
			isError: bidsDataIsError,
		} = borrowerApi.useGetBidsQuery(),
		{setLoans, setBids} = useAppActions()
	;

	useEffect(() => {
		!isEmpty(loansData) && setLoans(loansData as IBorrowerLoans);
	}, [loansData]);

	useEffect(() => {
		!isEmpty(bidsData) &&	setBids(bidsData)
	}, [bidsData]);

	return (
		<ProfileContainer title={BorrowerPagesTitleEnum.CABINET} className={styles.container}>
			{(loansDataIsError || bidsDataIsError) && <Heading variant={HeadingVariant.middle} tag={'h3'}>Произошла ошибка загрузки даннных</Heading>}
			{
				(loansDataIsLoading || bidsDataIsLoading) && <div className='text-center py-4'>
					<Spinner animation={'border'} variant={'primary'}/>
				</div>
			}
			<Fade inProp={!loansDataIsLoading && !loansDataIsError && !bidsDataIsLoading && !bidsDataIsError}>
				<BorrowerCabinetIndicators/>
				<BorrowerCabinetLoans/>
				<BorrowerCabinetBids/>
			</Fade>

		</ProfileContainer>

	);
};

export default BorrowerCabinet;

import {
	BorrowerSectionsCodeEnum,
	SectionsCodeEnum,
} from "@features/utils/types";
import { FC } from "react";

export interface IDefaultResponse {
	code: string;
	data: string;
	message: string | null;
	errors?: string[];
}

export type PhoneNumberType = number | null;

export enum ProfilePagesTitleEnum {
	CABINET = "Мой кабинет",
	PROJECTS = "Проекты",
	PROJECT = "О Проекте",
	CLUB = "Клуб инвесторов",
	QUESTIONS = "Вопросы",
	ABOUT = "О компании",
	DATA = "Личные данные",
	HISTORY = "История операций",
	REQUISITES = "Реквизиты для пополнения баланса",
	ANALYTICS = "Аналитика",
	QUALIFICATION = "Квалификация инвестора",
}

export enum BorrowerPagesTitleEnum {
	ACCOUNT = "Учетная запись",
	CABINET = "Кабинет",
}

export interface IAttachment {
	path: string;
	uid: string | null;
	extension: string;
	filename?: string;
	mimetype?: string;
	size?: number;
}

export enum FilterOptionsEnum {
	EQ = "eq",
	NE = "ne",
	GE = "ge",
	GT = "gt",
	LE = "le",
	LT = "lt",
	LIKE = "like",
}

type FilterOptionType =
	| FilterOptionsEnum.EQ
	| FilterOptionsEnum.NE
	| FilterOptionsEnum.GE
	| FilterOptionsEnum.GT
	| FilterOptionsEnum.LE
	| FilterOptionsEnum.LT
	| FilterOptionsEnum.LIKE;

export type OrderType = "ASC" | "DESC";

export interface ISort {
	orders: {
		[key: string]: OrderType;
	};
}
export interface IFilters {
	filters: {
		[key: string]: {
			[O in FilterOptionType]?: string | number;
		};
	};
}

export interface IFilter extends IFilters {
	limit: number;
	offset: number;
}

export interface ISearch extends ISort, IFilter {}

export interface IListResponse {
	items: [];
	count: number;
}

export interface IVideo {
	preview: IAttachment;
	url: string;
}

export type RangeNumbersType = [number, number];

export enum IQuestionThemesEnum {
	WARNING = "warning",
	SUCCESS = "success",
	DANGER = "danger",
	INFO = "info",
}

export enum IQuestionIconsEnum {
	INFO = "info",
	ACTIVITY = "activity",
	CHART = "chart",
	TIME = "time",
	CHECK = "check",
}

export interface IQuestion {
	questionId: string;
	question: string;
	answer: string;
}

export interface IQuestionTheme {
	themeId: string;
	title: string;
	questions: IQuestion[];
	icon: IQuestionIconsEnum;
	type: IQuestionThemesEnum;
}

export interface IUserQuestion {
	questionId: string;
	question: string;
	answer: string;
	theme: IQuestionTheme;
	dateCreated: string;
	dateReplied: string;
}

export interface IQuestionAsk {
	question: string;
	themeId: number;
}

export enum QualificationAttachmentTypeEnum {
	SECURITY_DOC = "security_doc",
	EMPLOYMENT_DOC = "employment_doc",
	DEAL_DOC = "deal_doc",
	EDUCATION_DOC = "education_doc",
	DEPOSIT_DOC = "deposit_doc",
}

export interface IQualificationAttachment {
	type: QualificationAttachmentTypeEnum;
	uid: IAttachment["uid"];
}

export interface IProfileNavigationItem {
	Icon: FC;
	name: string;
	path: string;
	code: SectionsCodeEnum | BorrowerSectionsCodeEnum;
}

export interface IProfilePage {
	pageTitle?: string;
}

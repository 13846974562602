import React, {FC, useContext} from 'react';
import {Button} from '@components/ui';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import InfoTooltip from '@components/common/InfoTooltip/InfoTooltip';
import {ReactComponent as DangerIcon} from '@icons/danger-circle-colored.svg';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import styles from './qualification.module.scss';
import classNames from 'classnames';
import {profileContext} from '@context/profileContext';
import {useDetectClickOutside} from 'react-detect-click-outside/dist';


const ProfileHeaderQualification:FC<{message?:string}> = ({message = 'Вы превысили лимит в 600 000 рублей за год. Чтобы продолжить инвестировать, Вам необходимо пройти квалификацию.'}) => {

	const {width: ww} = useWindowSize();
	const {setMainIsOutOfFocus, mainIsOutOfFocus} = useContext(profileContext);
	const clickOutsideRef = useDetectClickOutside({ onTriggered() {setMainIsOutOfFocus(false)} });

	return (
		<div className={classNames(styles.root, 'd-flex align-items-center', {
			'position-relative': ww < ADAPTIVE_BREAKPOINTS.xl
		})} ref={clickOutsideRef}>
			{
				ww >= ADAPTIVE_BREAKPOINTS.xl
					? <>
						<Button
							variant={ButtonVariant.danger}
							icon={ButtonIconsVariant.arrowRight}
							className={'ms-4'}
							as={ButtonTypesEnum.LINK}
							to={RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.QUALIFICATION}
						>Пройдите квалификацию</Button>
						<InfoTooltip
							content={message}
							className={'ms-2'}
						/>
					</>
					: <>
						<button type={'button'} onClick={() => {
							setMainIsOutOfFocus(true)
						}}><DangerIcon/></button>
						<div className={classNames(styles.mobileTooltip, 'text-center', {'is-visible': mainIsOutOfFocus})}>
							<p className={classNames(styles.mobileTooltipMessage)}><small>{message}</small></p>
							<Button
								variant={ButtonVariant.contained}
								wrapperClassName='d-inline-block pt-2'
								as={ButtonTypesEnum.LINK}
								to={RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.QUALIFICATION}
								onClick={() => {
									setMainIsOutOfFocus(false)
								}}
							>Пройдите квалификацию</Button>
						</div>
					</>
			}


		</div>
	);
};

export default ProfileHeaderQualification;

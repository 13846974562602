import React, {FC, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {personalDataContext} from '@context/personalDataContext';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import userApi from '@api/userApi';
import {profileContext} from '@context/profileContext';
import {useAppActions} from '@hooks/useAppActions';
import {InvestorLawTypeEnum, UserDataKeysEnum} from '@features/user/types';
import {PersonalDataViewsEnum} from '@components/investor/Views/Data/types';
import {useScrollToTop} from '@hooks/useScrollToTop';
import {useEmitter} from '@hooks/customEvent';
import {CUSTOM_EVENT__SET_MODAL_CODE_FOCUS} from '@common/constants';

const PersonalDataChangeConfirm: FC = () => {

	const
		{
			dataChangeConfirmModal,
			setDataChangeConfirmModal,
			setDataChangeSuccessModal,
			requestData,
			setPhoneChangeRequestModal,
			setCurrentPersonalDataView,
			setEditMode
		} = useContext(personalDataContext),
		{setSmsError} = useContext(profileContext),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	const
		[checkCode, {isLoading: codeIsChecking}] = userApi.useProfileChangeConfirmMutation(),
		[profileChangeRequest] = userApi.useProfileChangeRequestMutation(),
		{setUserData} = useAppActions(),
		[getUserInfo] = userApi.useLazyInfoQuery(),
		scrollToTop = useScrollToTop()
	;

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(({profile, changedPhone}) => {
					setSmsError(false);

					setUserData({
						data: profile,
						key: UserDataKeysEnum.IP
					});
					setDataChangeConfirmModal(false);

					if (changedPhone) {
						setPhoneChangeRequestModal(true);
					} else {
						setDataChangeSuccessModal(true);
						getUserInfo();

						switch (profile.lawType) {
							case InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR:
								setCurrentPersonalDataView(PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_DEFAULT);
								break;
							case InvestorLawTypeEnum.ENTITY:
								setCurrentPersonalDataView(PersonalDataViewsEnum.ENTITY_DEFAULT);
								break;
						}
					}

					scrollToTop();
					setEditMode(false)
				})
				.catch(() => setSmsError(true))

		},
		onCodeResend = () => {
			profileChangeRequest({body: requestData})
		}
	;

	return (
		<Modal
			show={dataChangeConfirmModal}
			onHide={() => setDataChangeConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>
		</Modal>
	);
};

export default PersonalDataChangeConfirm;

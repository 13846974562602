import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ISuggestion} from '@features/daData/types';

type ReturnType = {
	result: ISuggestion | null
	setResult: Dispatch<SetStateAction<ISuggestion | null>>
}

export const useSearchValidation = ():ReturnType => {
	const [currentResult, setCurrentResult] = useState<ISuggestion | null>(null);

	return {
		result: currentResult,
		setResult: setCurrentResult
	}
};

export const useInnErrors = (errors?: {type: 'validate' | 'required', message: string}):string => {
	const [message, setMessage] = useState<string>('');

	useEffect(() => {
		if ((errors && errors.type && errors.message) && errors.type === 'validate') {
			setMessage(errors.message)
		} else {
			setMessage('')
		}
	}, [errors]);

	return message
};

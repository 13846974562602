import React, {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useEffect,
	useState,
} from "react";
import {
	CUSTOM_EVENT__RESET_PROJECTS_PAGINATION_OFFSET,
	INVEST_AMOUNT_MIN,
} from "@common/constants";
import { IProject, ParameterizedDocumentsEnum } from "@features/projects/types";
import { useEmitter } from "@hooks/customEvent";
import projectsApi from "@api/projectsApi";
import borrowerApi from "@api/borrowerApi";
import { useAppActions } from "@hooks/useAppActions";
import userApi from "@api/userApi";
import { IInvestorInfo, UserDataKeysEnum } from "@features/user/types";
import { BorrowerProjectStatusEnum } from "@features/borrower/types";

type CurrentProjectType = IProject["projectId"] | null;

export enum InvestmentPlaceEnum {
	LIST = "list",
	DETAILED = "detailed",
}

type InvestmentPlaceType = InvestmentPlaceEnum | null;

interface IInvestContext {
	requestModal: boolean;
	setRequestModal: Dispatch<SetStateAction<boolean>>;

	requestConfirmModal: boolean;
	setRequestConfirmModal: Dispatch<SetStateAction<boolean>>;

	amount: number;
	updateAmount: Dispatch<SetStateAction<number>>;

	limitInvestAccepted: boolean;
	setLimitInvestAccepted: Dispatch<SetStateAction<boolean>>;

	highRiskAccepted: boolean;
	setHighRiskAccepted: Dispatch<SetStateAction<boolean>>;

	acceptInvestmentProposal: boolean;
	setAcceptInvestmentProposal: Dispatch<SetStateAction<boolean>>;

	requestSuccessModal: boolean;
	setRequestSuccessModal: Dispatch<SetStateAction<boolean>>;

	currentProjectId: CurrentProjectType;
	setCurrentProjectId: Dispatch<SetStateAction<CurrentProjectType>>;

	currentProjectTitle: IProject["title"];
	setCurrentProjectTitle: Dispatch<SetStateAction<IProject["title"]>>;

	cancelModal: boolean;
	setCancelModal: Dispatch<SetStateAction<boolean>>;

	cancelConfirmModal: boolean;
	setCancelConfirmModal: Dispatch<SetStateAction<boolean>>;

	cancelSuccessModal: boolean;
	setCancelSuccessModal: Dispatch<SetStateAction<boolean>>;

	investmentPlace: InvestmentPlaceType;
	setInvestmentPlace: Dispatch<SetStateAction<InvestmentPlaceType>>;

	resetOffsetFromModalEvent: (data: unknown) => void;

	updateDetailedProject: (projectId: IProject["projectId"]) => void;

	isInvestDisabled: (
		status: BorrowerProjectStatusEnum,
		investAmount: IProject["investAmount"],
	) => boolean;
}

export const investContext = createContext<IInvestContext>(
	{} as IInvestContext,
);

export const InvestProvider: FC = (props) => {
	const [requestModal, setRequestModal] = useState<boolean>(false),
		[requestConfirmModal, setRequestConfirmModal] = useState<boolean>(false),
		[limitInvestAccepted, setLimitInvestAccepted] = useState<boolean>(false),
		[highRiskAccepted, setHighRiskAccepted] = useState<boolean>(false),
		[acceptInvestmentProposal, setAcceptInvestmentProposal] =
			useState<boolean>(false),
		[requestSuccessModal, setRequestSuccessModal] = useState<boolean>(false),
		[cancelModal, setCancelModal] = useState<boolean>(false),
		[cancelConfirmModal, setCancelConfirmModal] = useState<boolean>(false),
		[cancelSuccessModal, setCancelSuccessModal] = useState<boolean>(false),
		[currentProjectTitle, setCurrentProjectTitle] =
			useState<IProject["title"]>(""),
		[amount, updateAmount] = useState<number>(INVEST_AMOUNT_MIN),
		[currentProjectId, setCurrentProjectId] =
			useState<CurrentProjectType>(null),
		[investmentPlace, setInvestmentPlace] = useState<InvestmentPlaceType>(null),
		[getProjectById] = projectsApi.useGetByIdMutation(),
		[getOfferDocument] = borrowerApi.useInvestOfferDocumentMutation(),
		[getConditionsDocument] = projectsApi.useGetInvestorConditionsMutation(),
		[getAcceptDocument] = projectsApi.useGetAcceptMutation(),
		{ setProjectDetails, addParameterizedDocument, setUserData } =
			useAppActions(),
		[getUserInfo, { data: userInfo }] = userApi.useLazyInfoQuery();
	const resetOffsetFromModalEvent = useEmitter(
		CUSTOM_EVENT__RESET_PROJECTS_PAGINATION_OFFSET,
	);

	const updateDetailedProject = (projectId: IProject["projectId"]) => {
		getProjectById(projectId)
			.unwrap()
			.then((response) => {
				response.bidId &&
					getOfferDocument(response.bidId)
						.unwrap()
						.then((document) => {
							addParameterizedDocument({
								id: projectId,
								document,
								name: ParameterizedDocumentsEnum.OFFER,
							});
						});

				setProjectDetails({ key: projectId, value: response });

				getUserInfo();
			});

		getConditionsDocument(projectId)
			.unwrap()
			.then((document) => {
				addParameterizedDocument({
					id: projectId,
					document,
					name: ParameterizedDocumentsEnum.CONDITIONS,
				});
			});

		getAcceptDocument({ id: projectId })
			.unwrap()
			.then((document) => {
				addParameterizedDocument({
					id: projectId,
					document,
					name: ParameterizedDocumentsEnum.ACCEPT,
				});
			});
	};

	useEffect(() => {
		userInfo &&
			setUserData({
				data: userInfo as IInvestorInfo,
				key: UserDataKeysEnum.II,
			});
	}, [userInfo]);

	const payload = {
		requestModal,
		setRequestModal,
		requestConfirmModal,
		setRequestConfirmModal,
		limitInvestAccepted,
		setLimitInvestAccepted,
		highRiskAccepted,
		setHighRiskAccepted,
		acceptInvestmentProposal,
		setAcceptInvestmentProposal,
		cancelConfirmModal,
		setCancelConfirmModal,
		amount,
		updateAmount,
		requestSuccessModal,
		setRequestSuccessModal,
		currentProjectId,
		setCurrentProjectId,
		cancelModal,
		setCancelModal,
		currentProjectTitle,
		setCurrentProjectTitle,
		cancelSuccessModal,
		setCancelSuccessModal,
		investmentPlace,
		setInvestmentPlace,
		resetOffsetFromModalEvent,
		isInvestDisabled: (
			status: BorrowerProjectStatusEnum,
			investAmount: IProject["investAmount"],
		) =>
			!(
				(status === BorrowerProjectStatusEnum.COLLECTION ||
					status === BorrowerProjectStatusEnum.LOAN_COLLECTED_MIN) &&
				parseFloat(investAmount) <= 0
			),
		updateDetailedProject,
	};

	return (
		<investContext.Provider value={payload}>
			{props.children}
		</investContext.Provider>
	);
};

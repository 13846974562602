import React, {FC, useRef} from 'react';
import classNames from 'classnames';
import styles from '@components/profile/Header/Notifications/notifications.module.scss';
import {CSSTransition} from 'react-transition-group';
import {TRANSITION_TIMEOUT} from '@common/constants';
import {ReactComponent as ReadIcon} from '@icons/read-default.svg';
import {ReactComponent as CloseIcon} from '@icons/close.svg';
import {INotificationTypeCodeEnum} from '@features/notifications/types';
import {ReactComponent as SuccessIcon} from '@icons/success.svg';
import {ReactComponent as InfoIcon} from '@icons/info-colored.svg';
import {ReactComponent as ErrorIcon} from '@icons/error.svg';

interface IProfileNotificationItemProps {
	tagName?: keyof JSX.IntrinsicElements
	viewed: boolean
	typeCode: INotificationTypeCodeEnum
	message: string
	className?: string
	onMouseEnter?: () => void
	onMouseLeave?: () => void
	onDelete?: () => void
}

const ProfileNotificationItem:FC<IProfileNotificationItemProps> = (
	{
		tagName = 'div',
		viewed,
		typeCode,
		message,
		className,
		onMouseEnter,
		onMouseLeave,
		onDelete
	}
) => {
	const
		Tag = tagName as keyof JSX.IntrinsicElements,
		readRef = useRef(null)
	;
	const generateIcon = (typeCode: INotificationTypeCodeEnum): JSX.Element => {
		switch (typeCode) {
			case INotificationTypeCodeEnum.SUCCESS:
				return <SuccessIcon className={styles.icon}/>;
			case INotificationTypeCodeEnum.INFO:
				return <InfoIcon className={styles.icon}/>;
			case INotificationTypeCodeEnum.WARNING:
				return <ErrorIcon className={styles.icon}/>;
			default: return <></>
		}
	};

	return (
		<Tag
			className={classNames(styles.item, {
				'is-viewed': viewed
			}, className)}
			onMouseEnter={onMouseEnter && onMouseEnter}
			onMouseLeave={onMouseLeave && onMouseLeave}
		>
			<div className="row w-100 align-items-center flex-nowrap gx-3">
				<div className="col-auto">
					{generateIcon(typeCode)}
				</div>
				<div className="col">
					<div className={styles.message}>
						<p
							dangerouslySetInnerHTML={{__html: message}}
							className={classNames(styles.text, 'mb-0')}
						/>
						<CSSTransition
							in={viewed}
							classNames={'animation--fade-in'}
							timeout={TRANSITION_TIMEOUT}
							unmountOnExit
							nodeRef={readRef}
						>
							<div ref={readRef}>
								<span className={styles.read}><ReadIcon/></span>
							</div>
						</CSSTransition>

					</div>
				</div>
				<div className="col-auto text-end">
					<button
						type={'button'}
						className={classNames(styles.remove, 'me-sm-2')}
						onClick={onDelete}
					>
						<CloseIcon/>
					</button>
				</div>
			</div>
		</Tag>
	);
};

export default ProfileNotificationItem;

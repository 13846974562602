import { configureStore } from '@reduxjs/toolkit';
import authReducer from '@features/auth/slice';
import userReducer from '@features/user/slice';
import utilsReducer from '@features/utils/slice';
import projectsReducer from '@features/projects/slice';
import notificationsReducer from '@features/notifications/slice';
import borrowerReducer from '@features/borrower/slice';
import analyticReducer  from '@features/analytics/slice';
import authApi from '@api/authApi';
import { apiErrorHandler } from '@api/errorHandler';
import userApi from '@api/userApi';
import operationsApi from '@api/operationsApi';
import notificationsApi from '@api/notificationsApi';
import utilsApi from '@api/utilsApi';
import daDataApi from '@api/daDataApi';
import clubApi from '@api/clubApi';
import projectsApi from '@api/projectsApi';
import analyticsApi from '@api/analyticsApi';
import questionsApi from '@api/questionsApi';
import borrowerApi from '@api/borrowerApi';


export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        utils: utilsReducer,
        projects: projectsReducer,
        notifications: notificationsReducer,
        borrower: borrowerReducer,
        analytics: analyticReducer,
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [operationsApi.reducerPath]: operationsApi.reducer,
        [utilsApi.reducerPath]: utilsApi.reducer,
        [notificationsApi.reducerPath]: notificationsApi.reducer,
        [daDataApi.reducerPath]: daDataApi.reducer,
        [clubApi.reducerPath]: clubApi.reducer,
        [utilsApi.reducerPath]: utilsApi.reducer,
        [projectsApi.reducerPath]: projectsApi.reducer,
        [analyticsApi.reducerPath]: analyticsApi.reducer,
        [questionsApi.reducerPath]: questionsApi.reducer,
        [borrowerApi.reducerPath]: borrowerApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware()
        .concat(apiErrorHandler)
        .concat(authApi.middleware)
        .concat(userApi.middleware)
        .concat(operationsApi.middleware)
        .concat(utilsApi.middleware)
        .concat(notificationsApi.middleware)
        .concat(daDataApi.middleware)
        .concat(clubApi.middleware)
        .concat(projectsApi.middleware)
        .concat(analyticsApi.middleware)
        .concat(questionsApi.middleware)
        .concat(borrowerApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

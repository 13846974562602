import React, {FC, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import borrowerApi from '@api/borrowerApi';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {Fade} from '@components/common/Transitions/Fade';
import {Spinner} from 'react-bootstrap';
import Table from '@components/common/Table/Table';
import moment from 'moment';
import {APP_DATE_FORMAT, MONTHS_WORDS, RGB_COLORS} from '@common/constants';
import AmountOutput from '@components/common/AmountOutput';
import {configureAssetAnchorParams, convertRate, declensionOfNumber} from '@common/utils';
import {defineStatusContent} from '@components/borrower/Views/Cabinet/Cabinet';
import styles from './borrowerLoan.module.scss';
import BoxIcon from '@components/common/Icon/BoxIcon';
import {ReactComponent as DocumentIcon} from '@icons/document-colored.svg';
import ProfileContainer from '@components/profile/Views/Container/Container';

const BorrowerLoan:FC = () => {
	const
		{id} = useParams<{id: string}>(),
		{
			data: loan = null,
			isLoading: loanIsLoading,
			isError: loanIsError
		} = borrowerApi.useGetProjectQuery(Number(id)),
		[getOfferDocument, {data: offerDocument = null, isLoading: offerDocumentIsLoading}] = borrowerApi.useInvestOfferDocumentMutation(),
		[getConditionsDocument, {data: conditionsDocument = null, isLoading: conditionsDocumentIsLoading}] = borrowerApi.useLazyGetConditionsDocumentQuery()
	;

	useEffect(() => {
		if (loan && Boolean(loan?.bidId.toString())) {
			getOfferDocument(loan.bidId);
			getConditionsDocument(loan.bidId)
		}
	}, [loan]);

	return (
		<ProfileContainer title={''}>
			{loanIsLoading && <Spinner className={'spinner--centered'} animation='border' variant='primary'/>}
			<Fade inProp={!loanIsLoading && !loanIsError}>

				{
					loan && <>
						<ProfileSection title={`Займ №${loan?.projectId}`} isTableInside>
							{
								<Table
									horizontallyScrolled
									thCells={[
										'Дата выдачи',
										'Сумма займа',
										'Процентная ставка',
										'Срок',
										'Статус'
									]}
									tdCells={
										[[
											moment(loan.dateCreated).format(APP_DATE_FORMAT) || '',
											<AmountOutput className='text-green' key={loan.projectId} amount={loan.goalAmount.toString()}/>,
											convertRate(loan.rate),
											`${loan.term} ${declensionOfNumber(loan.term, MONTHS_WORDS)}`,
											defineStatusContent(loan.status),
										]]
									}
								/>

							}
						</ProfileSection>
						<ProfileSection title={'Документы'}>
							<div className="row g-3 g-lt-5">
								<div className='col-sm-6 col-lg-4 col-xl-6 col-xxl-4'>
									<div className={styles.document}>
										{
											offerDocumentIsLoading
												? <Spinner className={'spinner--centered'} size={'sm'} animation='grow' variant='primary'/>
												: offerDocument && <a className={'d-flex align-items-center'} {...configureAssetAnchorParams(null, offerDocument.url)}>
												<BoxIcon className='flex-shrink-0' bgColor={RGB_COLORS.blue} size={'md'}>
													<DocumentIcon/>
												</BoxIcon>
												<p className='text-sm text-clamp text-clamp--2 ps-3 mb-0'>Инвестиционное предложение</p>
											</a>
										}
									</div>

								</div>
								<div className='col-sm-6 col-lg-4 col-xl-6 col-xxl-4'>
									<div className={styles.document}>
										{
											conditionsDocumentIsLoading
												? <Spinner className={'spinner--centered'} size={'sm'} animation='grow' variant='primary'/>
												: conditionsDocument && <a
												className={'d-flex align-items-center'}
												{...configureAssetAnchorParams(null, conditionsDocument.url)}
											>
												<BoxIcon className='flex-shrink-0' bgColor={RGB_COLORS.blue} size={'md'}>
													<DocumentIcon/>
												</BoxIcon>
												<p className='text-sm text-clamp text-clamp--2 ps-3 mb-0'>Договор инвестирования (займа) для заемщика</p>
											</a>
										}
									</div>
								</div>

							</div>
						</ProfileSection>
						<ProfileSection title={'График платежей'} isTableInside>
							<Table
								horizontallyScrolled
								className={styles.graphic}
								thCells={[
									'Дата',
									'Сумма основного долга',
									'Сумма процентов (без НДФЛ)',
									'Сумма НДФЛ',
									'Итого за период'
								]}
								tdCells={
									[
										...loan.graphic.map(({date, commonSum, amtPercentWithoutNdfl, amountWithoutNdfl, percentAmountTax}) => {

											return [
												moment(date).format(APP_DATE_FORMAT),
												<AmountOutput key={loan.bidId} amount={commonSum}/>,
												<AmountOutput key={loan.bidId} amount={amtPercentWithoutNdfl}/>,
												<AmountOutput key={loan.bidId} amount={percentAmountTax}/>,
												<AmountOutput key={loan.bidId} amount={amountWithoutNdfl}/>,
											]
										}),
										[
											<span className='text-dark' key={loan.bidId}>Всего</span>,
											<AmountOutput key={loan.bidId} amount={loan.graphicTotal.commonSum}/>,
											<AmountOutput key={loan.bidId} amount={loan.graphicTotal.percents}/>,
											<AmountOutput key={loan.bidId} amount={loan.graphicTotal.amountNdfl}/>,
											<AmountOutput key={loan.bidId} amount={loan.graphicTotal.withPercents}/>
										]
									]
								}
							/>
						</ProfileSection>
					</>
				}
			</Fade>
		</ProfileContainer>
	);
};


export default BorrowerLoan;

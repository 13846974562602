import React, { FC, useContext } from "react";
import ProfileDocumentBox from "@components/profile/reused/DocumentBox/DocumentBox";
import {
	IProjectDetails,
	ParameterizedDocumentsEnum,
} from "@features/projects/types";
import { projectsContext } from "@context/projectsContext";
import { parameterizedDocumentIsValid } from "@common/utils";
import ProfileSection from "@components/profile/Section/ProfileSection";
import { investContext, InvestmentPlaceEnum } from "@context/investContext";
import InvestorInvestmentButton from "@components/investor/reused/InvestmentButton";
import { Heading } from "@components/ui";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import classNames from "classnames";
import { ADAPTIVE_BREAKPOINTS } from "@common/constants";
import useWindowSize from "@hooks/useWindowSize";

const ProjectDetailDocuments: FC<{ details: IProjectDetails }> = ({
	details,
}) => {
	const { borrower, projectId, status, investAmount, title } = details;

	const { getParameterizedDocument, isParameterizedDocumentCashed } =
			useContext(projectsContext),
		offerDocument = getParameterizedDocument(
			projectId,
			ParameterizedDocumentsEnum.OFFER,
		),
		conditionsDocument = getParameterizedDocument(
			projectId,
			ParameterizedDocumentsEnum.CONDITIONS,
		),
		acceptDocument = getParameterizedDocument(
			projectId,
			ParameterizedDocumentsEnum.ACCEPT,
		),
		{ setInvestmentPlace } = useContext(investContext),
		{ width: ww } = useWindowSize();
	return (
		<ProfileSection title={borrower.shortName}>
			<div className='row'>
				<div className='col-md-6'>
					<Heading
						tag={"h3"}
						variant={HeadingVariant.large}
						className={classNames(
							{ "text-uppercase": ww >= ADAPTIVE_BREAKPOINTS.xxl },
							"pb-2",
						)}>
						{title}
					</Heading>
				</div>
			</div>
			<p className='text-dark text-uppercase text-md'>{borrower.orgName}</p>

			{(details.documents && details.documents.length > 0) ||
			isParameterizedDocumentCashed(
				projectId,
				ParameterizedDocumentsEnum.OFFER,
			) ||
			isParameterizedDocumentCashed(
				projectId,
				ParameterizedDocumentsEnum.CONDITIONS,
			) ||
			isParameterizedDocumentCashed(
				projectId,
				ParameterizedDocumentsEnum.ACCEPT,
			) ? (
				<ul className={"row g-3 mt-4"}>
					{offerDocument && parameterizedDocumentIsValid(offerDocument) && (
						<li className='col-md-6 col-lg-4 col-lt-3'>
							<ProfileDocumentBox
								className={"h-100"}
								link={offerDocument.url}
								title={"Инвестиционное предложение"}
							/>
						</li>
					)}

					{conditionsDocument &&
						parameterizedDocumentIsValid(conditionsDocument) && (
							<li className='col-md-6 col-lg-4 col-lt-3'>
								<ProfileDocumentBox
									className={"h-100"}
									link={conditionsDocument.url}
									title={"Индивидуальные условия договора займа инвестора"}
								/>
							</li>
						)}

					{acceptDocument && parameterizedDocumentIsValid(acceptDocument) && (
						<li className='col-md-6 col-lg-4 col-lt-3'>
							<ProfileDocumentBox
								className={"h-100"}
								link={acceptDocument.url}
								title={"Акцепт инвестиционного предложения"}
							/>
						</li>
					)}

					{details.documents.map(({ documentId, attachment, link, title }) => {
						return (
							<div key={documentId} className='col-md-6 col-lg-4 col-lt-3'>
								<ProfileDocumentBox
									className={"h-100"}
									title={title}
									link={link}
									attachment={attachment}
								/>
							</div>
						);
					})}
				</ul>
			) : (
				<p className={"pt-4"}>Документы не найдены</p>
			)}

			<InvestorInvestmentButton
				status={status}
				onClick={() => {
					setInvestmentPlace(InvestmentPlaceEnum.DETAILED);
				}}
				wrapperClassName={"mt-5 pt-3"}
				projectId={projectId}
				investAmount={investAmount}
			/>
		</ProfileSection>
	);
};

export default ProjectDetailDocuments;

import React, {FC} from 'react';
import classNames from 'classnames';
import {ReactComponent as ExclamationIcon} from '@icons/exclamation.svg';
import styles from '../qualification.module.scss';

interface IProfileQualificationConfirmationNoteProps {
	message: string,
	className?: string
}

const ProfileQualificationConfirmationNote:FC<IProfileQualificationConfirmationNoteProps> = ({message, className}) => {
	return (
		<p className={classNames(styles.note, 'mb-0 text-dark', className)}>
			<ExclamationIcon width='18px' height='18px'/>
			<span className={'ps-2'}>{message}</span>
		</p>
	);
};

export default ProfileQualificationConfirmationNote;

import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import styles from './verificationCode.module.scss';
import {
	BASIC_SMS_CODE_ARR,
	CUSTOM_EVENT__SET_MODAL_CODE_FOCUS,
	SMS_CODE_INCORRECT_MESSAGE
} from '@common/constants';
import classNames from 'classnames';
import {profileContext} from '@context/profileContext';
import ResendCode from '@components/common/ResendCode/ResendCode';
import {Spinner} from 'react-bootstrap';
import {useCustomEvent} from '@hooks/customEvent';

interface IVerificationCodeProps {
	isLoading?: boolean
	label?: string
	onComplete?: (code: string) => void,
	onResend?: () => void,
	withResend?: boolean
}

const VerificationCode:FC<IVerificationCodeProps> = (
	{
		isLoading = false,
		label = 'Введите код из СМС',
		onComplete,
		withResend = true,
		onResend
	}
) => {

	const [code, setCode] = useState<string[]>(BASIC_SMS_CODE_ARR);

	const inputs = useRef<(HTMLInputElement | null)[]>([]);
	const {smsCode, changeSmsCode, smsError, setSmsError} = useContext(profileContext);
	let updatedCode;

	const codeLengthIsCorrect = (code: string[]):boolean => code.every(symbol => symbol !== '');

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
		const value = event.target.value;
		if (/[^0-9]/.test(value)) return;

		updatedCode = [...code];
		updatedCode[index] = value;
		setCode(updatedCode);

		if (index !== length - 1) inputs.current[index + 1]?.focus();

		if (codeLengthIsCorrect(updatedCode)) onComplete && onComplete(updatedCode.join(''));
	};

	const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
		if (event.key.toLowerCase() === 'backspace' && !code[index] && index !== 0) {
			updatedCode = [...code];
			updatedCode[index - 1] = '';
			setCode(updatedCode);
			inputs.current[index - 1]?.focus()
		}
	};

	const setFocus = useCallback((withDelay?: boolean):void => {
		if (!withDelay) {
			inputs.current[0]?.focus();
		} else {
			const tm = setTimeout(() => {
				inputs.current[0]?.focus();
				clearTimeout(tm);
			}, 250)
		}
	}, [inputs]);

	useEffect(() => {
		changeSmsCode(code);
		if (code.filter(c => c !== '').length > 0)
			setSmsError(false);
	}, [code]);

	useEffect(() => {
		if (smsError) {
			setCode(BASIC_SMS_CODE_ARR);
			setFocus()
		}
	}, [smsError]);

	useCustomEvent({
		eventName: CUSTOM_EVENT__SET_MODAL_CODE_FOCUS,
		onSignal() {
			if (inputs.current) setFocus(true)
		}
	});

	return (
		<>
			<div className={'d-block text-center position-relative'}>
				{label && <p className={'pb-2'}>{label}</p>}
				{isLoading && <Spinner className={styles.spinner} size={'sm'} animation='border' variant='primary'/>}
				<div className="position-relative d-inline-block pb-2">
					<ul
						className={styles.list}
					>
						{code.map((value, index) => {
							return (
								<li key={index} className={styles.item}>
									<input
										className={styles.input}
										type='text'
										inputMode={'numeric'}
										maxLength={1}
										value={value}
										autoFocus={!smsCode[0].length && index === 0}
										readOnly={isLoading}
										onChange={event => handleInputChange(event, index)}
										onKeyDown={event => handleInputKeyDown(event, index)}
										ref={ref => inputs.current.push(ref)}
									/>
								</li>
							)
						})}
					</ul>
					{
						(smsError) && <p className={classNames(styles.error, 'mb-0 text-danger')}>
							<small>{SMS_CODE_INCORRECT_MESSAGE}</small>
						</p>
					}
				</div>
				{
					withResend && <ResendCode isLoading={isLoading} onResendCode={() => {
						setCode(BASIC_SMS_CODE_ARR);
						setSmsError(false);
						if (inputs.current) setFocus(true);
						onResend && onResend()
					}}/>
				}
			</div>

		</>
	);
};

export default VerificationCode;









import React, {Dispatch, FC, SetStateAction} from 'react';
import {Modal} from 'react-bootstrap';
import styles from '@components/auth/VerificationCode/verificationCode.module.scss';
import {ReactComponent as SuccessIcon} from '@icons/check-square.svg';
import Button from '@components/ui/Button/Button';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';

interface ISuccessModalProps {
	visible: boolean
	setVisible: Dispatch<SetStateAction<boolean>>,
	message: string
	onHide?: () => void
}

const SuccessModal:FC<ISuccessModalProps> = ({visible, setVisible, message, onHide}) => {

	return (
		<Modal
			show={visible}
			onHide={() => {
				setVisible(false);
				onHide && onHide();
			}}
			centered
		>
			<Modal.Header className={styles.header} closeButton/>
			<Modal.Body>
				<div className="d-flex flex-column align-items-center">
					<p>{message}</p>
					<SuccessIcon className={'mt-4 mb-5'}/>
					<Button
						variant={ButtonVariant.contained}
						as={ButtonTypesEnum.BUTTON}
						onClick={() => {
							setVisible(false);
							onHide && onHide()
						}}
					>
						Закрыть
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default SuccessModal;

import React, {
	createContext,
	Dispatch,
	FC,
	RefObject,
	SetStateAction,
	useEffect,
	useRef,
	useState,
} from "react";
import { BASIC_SMS_CODE_ARR } from "@common/constants";
import { useAppSelector } from "@hooks/useAppSelector";
import { useUserInfoKey } from "@hooks/useUserInfoKey";
import { IAsideInfoSource } from "@features/user/types";
import { configureAssetPath } from "@common/utils";

export interface IProfileTab {
	name: string;
	key: string;
	isActive: boolean;
}

type ModalSrcType = string | null;

type TabType = IProfileTab | null;

interface ISmsCode {
	[key: number]: string;
}

export interface IProfileContext {
	viewTitle: string;
	setViewTitle: Dispatch<SetStateAction<string>>;
	videoModal: boolean;
	setVideoModal: Dispatch<SetStateAction<boolean>>;
	modalVideoSrc: ModalSrcType;
	setModalVideoSrc: Dispatch<SetStateAction<ModalSrcType>>;

	tabs: IProfileTab[];
	setTabs: (tabs: IProfileTab[], initialTab?: number | string) => void;
	activeTab: TabType;
	activeTabKey: string;
	setActiveTabKey: Dispatch<SetStateAction<string>>;

	scrollableContentRef: RefObject<HTMLDivElement>;

	verifyErrorIsShown: boolean;
	setVerifyErrorIsShown: Dispatch<SetStateAction<boolean>>;

	smsCode: ISmsCode;
	changeSmsCode: (codeArr: string[]) => void;

	smsError: boolean;
	setSmsError: Dispatch<SetStateAction<boolean>>;

	userAvatarSrc: string | null;
	setUserAvatarSrc: Dispatch<SetStateAction<string | null>>;
	userAvatarIsLoading: boolean;
	setUserAvatarIsLoading: Dispatch<SetStateAction<boolean>>;

	investorInfoIsLoading: boolean;
	setInvestorInfoIsLoading: Dispatch<SetStateAction<boolean>>;

	investorInfoIsError: boolean;
	setInvestorInfoIsError: Dispatch<SetStateAction<boolean>>;

	mobileMenuIsOpen: boolean;
	setMobileMenuIsOpen: Dispatch<SetStateAction<boolean>>;

	mobileNotificationsShown: boolean;
	setMobileNotificationsShown: Dispatch<SetStateAction<boolean>>;

	mainIsOutOfFocus: boolean;
	setMainIsOutOfFocus: Dispatch<SetStateAction<boolean>>;
	userPhone: string;
	isAccess: boolean;
}

export interface IRedirectToBorrowerConditionsState {
	needRedirect: boolean;
}

export const profileContext = createContext<IProfileContext>(
	{} as IProfileContext,
);

export const ProfileProvider: FC = ({ children }) => {
	const basicCode = BASIC_SMS_CODE_ARR.reduce((acc, item, index) => {
			acc[index] = item;
			return acc;
		}, {} as ISmsCode),
		userInfoKey = useUserInfoKey(),
		{ user } = useAppSelector((state) => state),
		[viewTitle, setViewTitle] = useState<string>(""),
		[videoModal, setVideoModal] = useState<boolean>(false),
		[modalVideoSrc, setModalVideoSrc] = useState<ModalSrcType>(null),
		[tabs, setTabs] = useState<IProfileTab[]>([]),
		[verifyErrorIsShown, setVerifyErrorIsShown] = useState<boolean>(false),
		[smsCode, setSmsCode] = useState<IProfileContext["smsCode"]>(basicCode),
		[smsError, setSmsError] = useState<boolean>(false),
		[userAvatarSrc, setUserAvatarSrc] = useState<string | null>(null),
		[userAvatarIsLoading, setUserAvatarIsLoading] = useState<boolean>(false),
		[investorInfoIsLoading, setInvestorInfoIsLoading] =
			useState<boolean>(false),
		[investorInfoIsError, setInvestorInfoIsError] = useState<boolean>(false),
		[mobileMenuIsOpen, setMobileMenuIsOpen] = useState<boolean>(false),
		[mobileNotificationsShown, setMobileNotificationsShown] =
			useState<boolean>(false),
		[mainIsOutOfFocus, setMainIsOutOfFocus] = useState<boolean>(false),
		scrollableContentRef = useRef<HTMLDivElement>(null),
		[activeTab, setActiveTab] = useState<TabType>(null),
		[activeTabKey, setActiveTabKey] = useState<string>(""),
		[userPhone, setUserPhone] = useState<string>(""),
		[isAccess, setIsAccess] = useState<boolean>(true);
	const updateTabs = (tabs: IProfileTab[], initialTab?: number | string) => {
		if (typeof initialTab === "undefined") {
			setTabs(tabs);
		} else {
			const currentTabs = tabs.map((tab, index) => ({
				...tab,
				isActive:
					(typeof initialTab === "number" ? index : tab.key) === initialTab,
			}));
			const activeTab = currentTabs.find((t) => t.isActive) || null;

			if (activeTab) {
				setTabs(currentTabs);
				setActiveTabKey(activeTab.key);
				setActiveTab(activeTab);
			}
		}
	};

	useEffect(() => {
		const profile = user[userInfoKey as keyof IAsideInfoSource];
		if (profile) {
			if (profile.hasOwnProperty("avatar")) {
				const { avatar } = profile;
				setUserAvatarSrc(configureAssetPath(avatar));
			}
			if (profile.hasOwnProperty("phone")) {
				const { phone } = profile;
				setUserPhone(phone);
			}
		}
	}, [user]);

	useEffect(() => {
		if (activeTabKey) updateTabs(tabs, activeTabKey);
	}, [activeTabKey]);

	const payload = {
		viewTitle,
		setViewTitle,
		videoModal,
		setVideoModal,
		modalVideoSrc,
		setModalVideoSrc,
		tabs,
		activeTab,
		activeTabKey,
		setActiveTabKey,
		setTabs: updateTabs,
		verifyErrorIsShown,
		setVerifyErrorIsShown,
		smsCode,
		changeSmsCode: (codeArr: string[]) => {
			setSmsCode(
				codeArr.reduce((acc, item, index) => {
					acc[index] = item;

					return acc;
				}, {} as ISmsCode),
			);
		},
		smsError,
		setSmsError,
		userAvatarSrc,
		setUserAvatarSrc,
		userAvatarIsLoading,
		setUserAvatarIsLoading,
		investorInfoIsLoading,
		setInvestorInfoIsLoading,
		investorInfoIsError,
		setInvestorInfoIsError,
		mobileMenuIsOpen,
		setMobileMenuIsOpen,
		mobileNotificationsShown,
		setMobileNotificationsShown,
		mainIsOutOfFocus,
		setMainIsOutOfFocus,
		scrollableContentRef,
		userPhone,
		isAccess,
	};

	return (
		<profileContext.Provider value={payload}>
			{children}
		</profileContext.Provider>
	);
};

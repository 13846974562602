import React, {FC, useContext, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import {investContext, InvestmentPlaceEnum} from '@context/investContext';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import operationsApi from '@api/operationsApi';
import {profileContext} from '@context/profileContext';
import {
	CODES_RESPONSE_FAILED,
	CODES_RESPONSE_SUCCESS, CUSTOM_EVENT__SET_MODAL_CODE_FOCUS,
	INVEST_AMOUNT_MIN,
	TOAST_AUTO_HIDE_DELAY
} from '@common/constants';
import projectsApi from '@api/projectsApi';
import {useAppActions} from '@hooks/useAppActions';
import userApi from '@api/userApi';
import {projectsContext} from '@context/projectsContext';
import {searchWithParams} from '@common/utils';
import {IProjectDetails} from '@features/projects/types';
import ToastErrorMessage from '@components/common/Toast/ToastErrorMessage';
import {toast} from 'react-toastify';
import {IInvestorInfo, UserDataKeysEnum} from '@features/user/types';
import {useEmitter} from '@hooks/customEvent';


const InvestRequestConfirmModal:FC = () => {

	const
		{
			setRequestModal,
			requestConfirmModal, setRequestConfirmModal,
			currentProjectId,
			limitInvestAccepted,
			highRiskAccepted,
			acceptInvestmentProposal,
			amount, updateAmount,
			investmentPlace,
			resetOffsetFromModalEvent,
			updateDetailedProject,
			setRequestSuccessModal
		} = useContext(investContext),
		[checkCode, {isLoading: codeIsChecking}] = operationsApi.useInvestConfirmMutation(),
		{setSmsError} = useContext(profileContext),
		[makeInvestRequest, {isLoading: investRequestIsLoading}] = operationsApi.useInvestRequestMutation(),
		[searchProjects] = projectsApi.useSearchMutation(),
		{setProjects} = useAppActions(),
		{setProjectDetails, setUserData} = useAppActions(),
		{
			orders,
			filters,
		} = useContext(projectsContext),
		[getUserInfo, {data: userInfo}] = userApi.useLazyInfoQuery(),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	useEffect(() => {
		userInfo && setUserData({
			data: userInfo as IInvestorInfo,
			key: UserDataKeysEnum.II
		});
	}, [userInfo]);

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(({code, errors}) => {
					if (currentProjectId && Boolean(currentProjectId.toString())) {
						switch (code) {
							case CODES_RESPONSE_SUCCESS:
								setRequestModal(false);
								setRequestConfirmModal(false);
								setRequestSuccessModal(true);

								switch (investmentPlace) {
									case InvestmentPlaceEnum.LIST:

										searchProjects(searchWithParams({
											orders,
											filters
										}))
											.unwrap()
											.then(({items}) => {
												setProjects([]);
												setProjects(items);
												resetOffsetFromModalEvent({});
												getUserInfo()
											})
										;

										setProjectDetails({key: currentProjectId, value: {} as IProjectDetails});

										break;
									case InvestmentPlaceEnum.DETAILED:
										updateDetailedProject(currentProjectId);
										break;
								}
								updateAmount(INVEST_AMOUNT_MIN);
								break;
							case CODES_RESPONSE_FAILED:
								errors && toast.error(<ToastErrorMessage message={errors.join(', ')}/>, {
									autoClose: TOAST_AUTO_HIDE_DELAY,
									position: toast.POSITION.TOP_LEFT
								});
								break;
						}
					}
				})
				.catch(() => setSmsError(true))
		},
		onCodeResend = () => {
			currentProjectId && makeInvestRequest({
				projectId: currentProjectId,
				amount,
				acceptFlag: acceptInvestmentProposal,
				highRiskFlag: highRiskAccepted,
				limitInvestFlag: limitInvestAccepted
			})
		}
	;


	return (
		<Modal
			show={requestConfirmModal}
			onHide={() => setRequestConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking || investRequestIsLoading}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>
		</Modal>
	)
};

export default InvestRequestConfirmModal;

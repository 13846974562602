import React, {FC, HTMLAttributes, MutableRefObject, useEffect, useRef, useState} from 'react';
import {usePopper} from 'react-popper';
import {Placement} from '@popperjs/core';
import styles from './tooltip.module.scss';
import classNames from 'classnames';
import {CSSTransition} from 'react-transition-group';
import {TRANSITION_TIMEOUT} from '@common/constants';

interface ITooltipProps {
	targetRef: MutableRefObject<null>,
	isVisible: boolean,
	content: JSX.Element | string,
	arrow?: boolean,
	placement?: Placement,
	offset?: [number, number]
}

const Tooltip:FC<ITooltipProps & HTMLAttributes<HTMLDivElement>> = (
	{
		targetRef,
		isVisible,
		content,
		className,
		arrow = true,
		placement = 'auto',
		offset= [0, 5]
	}
) => {
	const popperRef = useRef(null);
	const [arrowRef, setArrowRed] = useState<HTMLSpanElement | null>(null);
	const popperModifiers = [];

	if (arrow) {
		popperModifiers.push({
			name: 'arrow',
			options: {
				element: arrowRef
			}
		})
	}

	const {
		styles: popperStyles,
		attributes: popperAttributes,
		update: updatePopper
	} = usePopper(
		targetRef.current,
		popperRef.current,
		{
			placement,
			modifiers: [
				...popperModifiers,
				{name: 'offset', options: {offset}}
			]
		}
	);

	useEffect(() => {
		updatePopper && updatePopper();
	}, [isVisible]);

	return (
		<CSSTransition
			in={isVisible}
			classNames={'animation--fade-in'}
			timeout={TRANSITION_TIMEOUT}
			unmountOnExit
			nodeRef={popperRef}
		>
			<span
				ref={popperRef}
				className={classNames(styles.container, className)}
				style={popperStyles.popper}
				{...popperAttributes.popper}
			>
				{arrow && <span ref={setArrowRed} style={popperStyles.arrow} className="arrow"/>}
				{content}
			</span>
		</CSSTransition>
	);
};

export default Tooltip;

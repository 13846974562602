import React, {FC, HTMLAttributes, useRef, useState} from 'react';
import {ReactComponent as InfoIcon} from '@icons/info-filled.svg';
import Tooltip from '@components/common/Tooltip/Tooltip';
import styles from './infoTooltip.module.scss';
import classNames from 'classnames';

interface IInfoTooltipProps {
	content: string | JSX.Element
}

const InfoTooltip:FC<IInfoTooltipProps & HTMLAttributes<HTMLDivElement>> = (
	{
		content,
		className
	}
) => {
	const tooltipTriggerRef = useRef(null);
	const [tooltipIsVisible, setTooltipIsVisible] = useState<boolean>(false);

	return (
		<span className={classNames(styles.container, className)}>
			<span
				className={styles.trigger}
				ref={tooltipTriggerRef}
				onMouseEnter={() => setTooltipIsVisible(true)}
				onMouseLeave={() => setTooltipIsVisible(false)}
			>
				<InfoIcon className={'info-icon'}/>
			</span>
			<Tooltip
				isVisible={tooltipIsVisible}
				targetRef={tooltipTriggerRef}
				className={'tooltip text-dark'}
				placement={'bottom-end'}
				content={content}
			/>
		</span>
	);
};

export default InfoTooltip;

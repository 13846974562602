import React, {FC} from 'react';
import {Accordion} from 'react-bootstrap';
import ProfileProjectAccordionAbout from '@components/investor/Project/Accordion/ProjectAccordionAbout';
import {useCashedProject} from '@hooks/useCashedProject';
import ProfileProjectAccordionBorrower from '@components/investor/Project/Accordion/ProjectAccordionBorrower';
import ProfileProjectAccordionDocuments from '@components/investor/Project/Accordion/ProjectAccordionDocuments';
import {IProject} from '@features/projects/types';

export enum ProjectDetailsSectionsEnum {
	ABOUT_PROJECT = 'project-details-section-about-project',
	ABOUT_BORROWER = 'project-details-section-about-borrower',
	DOCUMENTS = 'project-details-section-documents'
}

const ProfileProjectAccordion:FC<{projectId: IProject['projectId']}> = ({projectId}) => {

	const detailCashed = useCashedProject(projectId);

	return (
		detailCashed
			? <Accordion.Collapse eventKey={detailCashed.projectId.toString()}>
				<div className={'pt-4'}>
					<Accordion>
						<ProfileProjectAccordionAbout projectId={projectId}/>
						<ProfileProjectAccordionBorrower projectId={projectId}/>
						<ProfileProjectAccordionDocuments projectId={projectId}/>
					</Accordion>
				</div>
			</Accordion.Collapse>
			: <></>
	);
};

export default ProfileProjectAccordion;

import React, { FC, useContext, useState } from "react";
import styles from "./project.module.scss";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Button, Heading } from "@components/ui";
import FundraisingProgress from "@components/common/FundraisingProgress/FundraisingProgress";
import classNames from "classnames";
import Badge from "@components/common/Badge/Badge";
import moment from "moment";
import { ReactComponent as DiscountIcon } from "@icons/discount-colored.svg";
import { ReactComponent as CalendarIcon } from "@icons/calendar-colored.svg";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { profileContext } from "@context/profileContext";
import { AccordionContext, useAccordionButton } from "react-bootstrap";
import projectsApi from "@api/projectsApi";
import { useAppActions } from "@hooks/useAppActions";
import { Link } from "react-router-dom";
import { InvestorRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { defineProjectMedia } from "@components/investor/utils";
import { convertRate, declinationOfNum } from "@common/utils";
import {
	ADAPTIVE_BREAKPOINTS,
	MONTHS_WORDS,
	RGB_COLORS,
} from "@common/constants";
import { useCashedProject } from "@hooks/useCashedProject";
import InvestedBox from "@components/profile/reused/InvestedBox/InvestedBox";
import ProjectExpiredMessage from "@components/profile/reused/ExpiredMessage/ProjectExpiredMessage";
import { ReactComponent as CoinIcon } from "@icons/coin.svg";
import ProfileProjectAccordion from "@components/investor/Project/Accordion/ProjectAccordion";
import borrowerApi from "@api/borrowerApi";
import { IProject, ParameterizedDocumentsEnum } from "@features/projects/types";
import { ConditionalWrapper } from "@components/common/ConditionalWrapper";
import useWindowSize from "@hooks/useWindowSize";
import InvestorInvestmentButton from "@components/investor/reused/InvestmentButton";
import { investContext, InvestmentPlaceEnum } from "@context/investContext";
import { useProjectExpired } from "@hooks/useProjectExpired";
import { BorrowerProjectStatusEnum } from "@features/borrower/types";
import { useNumAmount } from "@hooks/useNumAmount";

export interface IDataRow {
	key: string;
	value: string | undefined;
}

export const dataPlaceholder = "-";
export const createDataList = (
	list: IDataRow[],
	placeholder = dataPlaceholder,
): JSX.Element => (
	<ul className={"d-flex flex-column mb-0"}>
		{list.map(({ key, value }) => (
			<li key={key} className={"py-1"}>
				<span className={"text-dark"}>{key}:&nbsp;</span>
				<span>{value || placeholder}</span>
			</li>
		))}
	</ul>
);

const ProfileProject: FC<IProject> = ({
	title,
	description,
	goalAmount,
	goalCurrentAmount,
	projectId,
	goalEndDate,
	rate,
	term,
	objectVideo,
	objectImage,
	status,
	investAmount,
	alreadyInvested,
}) => {
	const { setProjectDetails } = useAppActions(),
		{ setModalVideoSrc, setVideoModal } = useContext(profileContext),
		[getOfferDocument] = borrowerApi.useInvestOfferDocumentMutation(),
		[getConditionsDocument] = projectsApi.useGetInvestorConditionsMutation(),
		[getAcceptDocument] = projectsApi.useGetAcceptMutation(),
		[getProjectById] = projectsApi.useGetByIdMutation(),
		detailCashed = useCashedProject(projectId),
		{ addParameterizedDocument } = useAppActions(),
		{ width: ww } = useWindowSize(),
		{ setInvestmentPlace } = useContext(investContext),
		[projectDetailIsLoading, setProjectDetailIsLoading] =
			useState<boolean>(false),
		expired = useProjectExpired(status),
		numInvestAmount = useNumAmount(investAmount);
	const ToggleProjectDetails: FC<{ eventKey: IProject["projectId"] }> = ({
			eventKey,
		}) => {
			const stringEventKey = eventKey.toString(),
				decoratedOnClick = useAccordionButton(stringEventKey),
				{ activeEventKey } = useContext(AccordionContext);
			return (
				<Button
					variant={ButtonVariant.outlined}
					as={ButtonTypesEnum.BUTTON}
					isLoading={projectDetailIsLoading}
					onClick={(event) => {
						if (detailCashed) {
							decoratedOnClick(event);
						} else {
							const id = projectId;
							setProjectDetailIsLoading(true);

							getProjectById(id)
								.unwrap()
								.then((response) => {
									setProjectDetails({ key: id, value: response });

									const documentsPromises = [
										getConditionsDocument(id)
											.unwrap()
											.then((document) => {
												addParameterizedDocument({
													id,
													document,
													name: ParameterizedDocumentsEnum.CONDITIONS,
												});
											}),

										getAcceptDocument({ id })
											.unwrap()
											.then((document) => {
												addParameterizedDocument({
													id,
													document,
													name: ParameterizedDocumentsEnum.ACCEPT,
												});
											}),
									];

									if (response.bidId)
										documentsPromises.unshift(
											getOfferDocument(response.bidId)
												.unwrap()
												.then((document) => {
													addParameterizedDocument({
														id,
														document,
														name: ParameterizedDocumentsEnum.OFFER,
													});
												}),
										);

									Promise.all(documentsPromises).finally(() => {
										decoratedOnClick(event);
										setProjectDetailIsLoading(false);
									});
								});
						}
					}}>
					{activeEventKey === stringEventKey ? "Скрыть" : "Подробнее"}
				</Button>
			);
		},
		dateBadge = (): JSX.Element | null => {
			return Boolean(goalEndDate) && moment(goalEndDate).isValid() ? (
				<Badge
					className={styles.dateBadge}
					content={moment(goalEndDate).format("[До ] Do MMMM")}
				/>
			) : null;
		},
		investedBox = (className?: string): JSX.Element | null => {
			return numInvestAmount > 0 && !expired ? (
				<InvestedBox
					amount={investAmount}
					className={classNames("d-flex w-100", className, {
						"bg-light": ww < ADAPTIVE_BREAKPOINTS.sm,
					})}
					iconSize={"m"}
					icon={CoinIcon}
					iconBg={RGB_COLORS.pear}
					label={"Инвестировано:"}
				/>
			) : null;
		},
		investButton = (withoutWrap?: boolean): JSX.Element | null => {
			// numInvestAmount < numMinLoan
			return (
				<ConditionalWrapper
					condition={!withoutWrap}
					wrapper={(children) => {
						return <div className='col-auto'>{children}</div>;
					}}>
					<InvestorInvestmentButton
						status={status}
						onClick={() => {
							setInvestmentPlace(InvestmentPlaceEnum.LIST);
						}}
						projectId={projectId}
						investAmount={investAmount}
					/>
				</ConditionalWrapper>
			);
		};
	return (
		<div className={styles.card}>
			<div className='row gx-4 gx-lt-5'>
				<div className='mt-5 mt-md-0 order-2 order-md-1 col-md-4  col-lt-3'>
					{defineProjectMedia({
						image: objectImage,
						video: objectVideo,
						imageClassName: styles.mediaImage,
						placeholderClassName: styles.mediaPlaceholder,
						linkTo: Boolean(objectVideo)
							? ""
							: RouteNamesEnum.INVESTOR +
							  InvestorRouteNamesEnum.PROJECTS +
							  `/${projectId}`,
						onVideoPlay: (video) => {
							setVideoModal(true);
							setModalVideoSrc(video.url);
						},
					})}
				</div>
				<div className='order-1 order-md-2 col-md-8  col-lt-5 d-flex flex-column'>
					<ConditionalWrapper
						condition={ww < ADAPTIVE_BREAKPOINTS.lt}
						wrapper={(children) => {
							return (
								<div className='row g-3'>
									<div className='col pe-4'>{children}</div>
									<div className='col-auto'>{dateBadge()}</div>
								</div>
							);
						}}>
						<Heading
							tag={"h3"}
							variant={HeadingVariant.large}
							className={classNames(styles.title, "pt-lt-2 mb-0")}>
							<Link
								to={
									RouteNamesEnum.INVESTOR +
									InvestorRouteNamesEnum.PROJECTS +
									`/${projectId}`
								}>
								{title}
							</Link>
						</Heading>
					</ConditionalWrapper>

					<p className={classNames(styles.description, "my-3")}>
						{description}
					</p>

					<ProjectExpiredMessage
						alreadyInvested={alreadyInvested}
						status={status}
					/>

					{!expired &&
						status !== BorrowerProjectStatusEnum.REQUEST_CANCELLED && (
							<FundraisingProgress
								className={"py-4"}
								total={goalAmount}
								current={goalCurrentAmount}
							/>
						)}

					<div className='mt-auto pt-3'>
						<div
							className={classNames("row g-3", {
								"pt-3": expired,
							})}>
							<div className='col-auto'>
								<Badge
									content={
										<>
											Ставка: <strong>{convertRate(rate)}</strong>
										</>
									}
									icon={<DiscountIcon />}
									variant={"success"}
									size={"md"}
								/>
							</div>
							<div className='col-auto'>
								<Badge
									content={
										<>
											Срок:{" "}
											<strong>
												{term} {declinationOfNum(term, MONTHS_WORDS)}
											</strong>
										</>
									}
									icon={<CalendarIcon />}
									variant={"info"}
									size={"md"}
								/>
							</div>
						</div>
					</div>

					<div
						className={classNames("d-lt-none", {
							"pt-4":
								(numInvestAmount > 0 && !expired) ||
								ww >= ADAPTIVE_BREAKPOINTS.md,
						})}>
						<div className='row g-3 align-items-center'>
							<div className='col-auto d-none d-md-block'>
								<div className='row g-3'>
									<div className='col-auto'>
										<ToggleProjectDetails eventKey={projectId} />
									</div>
									{investButton()}
								</div>
							</div>

							{numInvestAmount > 0 && !expired && (
								<div className='col-auto'>{investedBox()}</div>
							)}
						</div>
					</div>
				</div>
				<div className='order-md-3 col-4 d-none d-lt-block ms-auto'>
					<div className='d-flex flex-column align-items-end h-100'>
						{dateBadge()}
						{investedBox("mb-4 mt-auto")}
						<div
							className={classNames("row gx-3 d-flex", {
								"mt-auto": numInvestAmount <= 0 || expired,
							})}>
							<div className='col-auto'>
								<ToggleProjectDetails eventKey={projectId} />
							</div>

							{investButton()}
						</div>
					</div>
				</div>
			</div>
			<div className='row gx-4 gx-lt-5'>
				<div className='col-md-8 ms-auto ms-lt-0'>
					<ProfileProjectAccordion projectId={projectId} />
				</div>
			</div>

			<div className='row gx-3 justify-content-end d-md-none pt-4 mt-2'>
				<div className='col-auto'>
					<ToggleProjectDetails eventKey={projectId} />
				</div>
				{investButton()}
			</div>
		</div>
	);
};

export default ProfileProject;

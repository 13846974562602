import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import BorrowerAccountDocument, {
	DocumentWhomEnum,
	IBorrowerAccountDocumentProps
} from '@components/borrower/Views/Account/Documents/Document/AccountDocument';
import {
	BorrowerDocumentsKeysEnum,
	BorrowerDocumentStatus,
	IBorrowerDocument,
	IBorrowerDocuments,
	UserDataKeysEnum
} from '@features/user/types';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import utilsApi from '@api/utilsApi';
import {fileListToFormData} from '@common/utils';
import {useAppSelector} from '@hooks/useAppSelector';
import {useAppActions} from '@hooks/useAppActions';
import {borrowerContext} from '@context/borrowerContext';
import borrowerApi from '@api/borrowerApi';
import merge from 'lodash.merge'
import classNames from 'classnames';
import {profileContext} from '@context/profileContext';
import {BorrowerAccountTabsKeys} from '@components/borrower/Views/Account/Account';


interface IBorrowerDocumentsFields {
	[BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE]: string
	[BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE]: string
	[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: string
	[BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE]: string
	[BorrowerDocumentsKeysEnum.INCOME_DECLARATION]: string
}

type SubmitHandlerPayloadType = {
	[key in BorrowerDocumentsKeysEnum]: FileList
}

interface IDocumentBox extends Pick<IBorrowerAccountDocumentProps, 'title' | 'documentKey' | 'required' | 'fileType' | 'forWhom'>{}

interface IDocumentValidationItem {
	loaded: boolean,
	marked: boolean
}

interface IDocumentsValidation {
	message: string | null,
	state: {
		[K in keyof IBorrowerDocuments]?: IDocumentValidationItem
	}
}

const documentsBoxes: IDocumentBox[] = [
	{
		title: 'Скан-копия второй и третьей страниц паспорта',
		documentKey: BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE,
		fileType: 'picture'
	},
	{
		title: 'Скан-копия страницы паспорта с адресом регистрации',
		documentKey: BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE,
		fileType: 'picture'
	},
	{
		title: 'Бухгалтерский баланс за прошедший календарный год',
		documentKey: BorrowerDocumentsKeysEnum.BALANCE_YEAR,
		required: false,
		fileType: 'document',
		forWhom: DocumentWhomEnum.O
	},
	{
		title: 'Бухгалтерский баланс за последнюю отчетную дату',
		documentKey: BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE,
		required: false,
		fileType: 'document',
		forWhom: DocumentWhomEnum.O
	},
	{
		title: 'Декларация о доходах',
		documentKey: BorrowerDocumentsKeysEnum.INCOME_DECLARATION,
		required: false,
		fileType: 'document',
		forWhom: DocumentWhomEnum.I
	}
];

const BorrowerAccountDocuments:FC = () => {
	const formHookMethods = useForm<IBorrowerDocumentsFields>({
		mode: 'all',
		defaultValues: {
			[BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE]: '',
			[BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE]: '',
			[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: '',
			[BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE]: '',
			[BorrowerDocumentsKeysEnum.INCOME_DECLARATION]: ''
		}
	});

	const
		[fileUtility] = utilsApi.useFileMutation(),
		[uploadDocuments, {isLoading: documentsIsUploading}] = borrowerApi.useUploadDocumentsMutation(),
		{documents, documentsIsLoading, updateDocumentsIsLoading} = useContext(borrowerContext),
		{borrowerProfile} = useAppSelector(state => state.user),
		{setUserData} = useAppActions(),
		{setActiveTabKey} = useContext(profileContext),
		[validation, setValidation] = useState<IDocumentsValidation>({
			message: null,
			state: {
				[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: {
					loaded: false,
					marked: false
				},
				[BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE]: {
					loaded: false,
					marked: false
				},
				[BorrowerDocumentsKeysEnum.INCOME_DECLARATION]: {
					loaded: false,
					marked: false
				}
			}
		})
	;

	const
		generateDocs = (excludeBalance?: boolean):Partial<IBorrowerDocuments> => {

			let target = Object.keys(documents);

			if (excludeBalance)
				target = target.filter(k => {
					return k !== BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE
						&& k !== BorrowerDocumentsKeysEnum.BALANCE_YEAR
				});


			return target
				.filter(key => Boolean(documents[key as keyof BorrowerDocumentsKeysEnum]))
				.reduce((acc, key) => {

					const doc = documents[key as keyof BorrowerDocumentsKeysEnum];

					if (doc) acc[key as keyof IBorrowerDocuments] = doc;

					return acc
				}, {} as Partial<IBorrowerDocuments>)
		},
		onSubmit: SubmitHandler<SubmitHandlerPayloadType> = async () => {

			const
				setValidationMarks = (params: {message: string, marked: ((loaded: boolean, key: keyof IBorrowerDocuments) => boolean) | boolean}) => {
					const {message, marked} = params;

					setValidation(prevState => {
						return merge(prevState, {
							message,
							state: {
								...Object.keys(prevState.state)
									.map(key => {
										const loaded = Boolean(prevState.state[key as keyof IBorrowerDocuments]?.loaded);

										return {
											[key]: {
												loaded,
												marked: typeof marked === 'boolean' ? marked : marked(loaded, key as keyof IBorrowerDocuments)
											}
										}
									})
									.reduce((acc, item) => ({...acc, ...item}), {} as Partial<IDocumentValidationItem>)
							}
						})
					})
				},
				checkLoaded = (key: keyof IBorrowerDocuments): boolean => Boolean(validation.state[key]?.loaded),
				docs = generateDocs(false)
			;

			if (Object.values(validation.state).every(i => !i.loaded)) {
				setValidationMarks({
					message: 'Пожалуйста, загрузите документы по бухгалтерскому балансу (для ООО) или декларацию о доходах (для ИП)',
					marked: true
				});

				return
			}

			if (!checkLoaded(BorrowerDocumentsKeysEnum.INCOME_DECLARATION) && (
				(
					checkLoaded(BorrowerDocumentsKeysEnum.BALANCE_YEAR)
					&& !checkLoaded(BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE)
				)
				||
				(
					!checkLoaded(BorrowerDocumentsKeysEnum.BALANCE_YEAR)
					&& checkLoaded(BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE)
				)
			)) {
				setValidationMarks({
					message: 'Пожалуйста, загрузите ещё один документ по бухгалтерскому балансу',
					marked: (loaded, key) => !loaded && key !== BorrowerDocumentsKeysEnum.INCOME_DECLARATION
				});

				return;
			}


			uploadDocuments(docs)
				.unwrap()
				.then(() => setActiveTabKey(BorrowerAccountTabsKeys.CONDITIONS))
		},
		handleFileChange = (files: FileList | null, key: BorrowerDocumentsKeysEnum) => {
			resetValidationFromUserData();
			setValidation(prevState => merge(prevState, {message: null}));

			if (files && files.length > 0) {

				updateDocumentsIsLoading(key, true);

				fileUtility({
					formData: fileListToFormData(files, key),
					isPrivate: false
				})
					.unwrap()
					.then(([fileFromApi]) => {
						setUserData({
							data: {
								...borrowerProfile,
								[key]: {
									attachment: fileFromApi,
									status: BorrowerDocumentStatus.WAITING
								} as IBorrowerDocument
							},
							key: UserDataKeysEnum.BP
						})
					})
					.finally(() => updateDocumentsIsLoading(key, false))
			}
		},
		setValidationItemParams = (callback: (key: keyof IBorrowerDocuments) => void):void => {

			const profileKeys = Object.keys(borrowerProfile);

			if (profileKeys.length > 0) {
				profileKeys
					.forEach((key) => {

						switch (key) {
							case BorrowerDocumentsKeysEnum.BALANCE_YEAR:
							case BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE:
							case BorrowerDocumentsKeysEnum.INCOME_DECLARATION:
								callback(key);
								break;
						}
					})
			}
		},
		resetValidationFromUserData = (): void => {
			setValidationItemParams(key => {
				setValidation(prevState => ({
					...prevState,
					state: {
						...prevState.state,
						[key]: {
							loaded: Boolean(borrowerProfile[key]),
							marked: false
						}
					}
				}));
			});
		}
	;

	useEffect(() => {
		resetValidationFromUserData()
	}, [borrowerProfile]);

	const isLoading = useMemo(() => Object.values(documentsIsLoading).some(Boolean) || documentsIsUploading, [documentsIsLoading, documentsIsUploading]);

	return (
		<ProfileSection title={'Документы'} style={{cursor: isLoading ? 'wait' : undefined}}>
			<FormProvider {...formHookMethods}>
				<form
					onSubmit={formHookMethods.handleSubmit(onSubmit)}
					className='row align-items-end g-3 g-sm-4 g-lt-6'
					style={{
						pointerEvents: isLoading ? 'none' : undefined
					}}
				>
					{
						documentsBoxes.map(box => (
							<div className='col-6 col-sm-4 col-lg-3 col-lt-auto' key={box.documentKey}>
								<BorrowerAccountDocument
									{...box}
									onAttachmentDelete={() => {
										setUserData({
											data: {
												...borrowerProfile,
												[box.documentKey]: null
											},
											key: UserDataKeysEnum.BP
										});

										resetValidationFromUserData();
									}}
									className={classNames({
										'is-marked': validation.state[box.documentKey]?.marked
									})}
									onChange={files => handleFileChange(files, box.documentKey)}
								/>
							</div>
						))
					}
					<div className='col-12 pt-5'>
						<Button
							as={ButtonTypesEnum.BUTTON}
							type={'submit'}
							variant={ButtonVariant.contained}
							disabled={isLoading}
							isLoading={documentsIsUploading}
						>
							Далее
						</Button>
						{validation.message && <p className={'text-danger text-sm pb-4 pt-5 mb-0'}>{validation.message}</p>}
					</div>
				</form>
			</FormProvider>
		</ProfileSection>
	);
};

export default BorrowerAccountDocuments;

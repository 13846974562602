import React, {FC, useContext, useEffect, useState} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import BorrowerAccountConditionsFormGroup
	from '@components/borrower/Views/Account/Conditions/FormGroup/BorrowerAccountConditionsFormGroup';
import {ReactComponent as StarIcon} from '@icons/star-bold-colored.svg';
import {ReactComponent as RubleIcon} from '@icons/ruble-colored.svg';
import {ReactComponent as ArrowDownIcon} from '@icons/arrow-down-colored.svg';
import {
	BASIC_SMS_CODE_ARR,
	CODES_RESPONSE_SUCCESS, DAYS_WORDS,
	EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES, MONTHS_WORDS,
	RGB_COLORS
} from '@common/constants';
import BoxIcon, {IBoxIconProps} from '@components/common/Icon/BoxIcon';
import projectsApi from '@api/projectsApi';
import {Fade} from '@components/common/Transitions/Fade';
import {FormGroup, Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import NumberFormat from 'react-number-format';
import {Spinner} from 'react-bootstrap';
import styles from './accountConditions.module.scss';
import {declensionOfNumber} from '@common/utils';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormProvider, useForm} from 'react-hook-form';
import borrowerApi from '@api/borrowerApi';
import {BorrowerBidRequestKeysEnum, IBorrowerBidsParams, IBorrowerBidRequest} from '@features/borrower/types';
import {useAppActions} from '@hooks/useAppActions';
import {profileContext} from '@context/profileContext';
import {useAppSelector} from '@hooks/useAppSelector';
import {BorrowerProfileKeysEnum} from '@features/user/types';
import BorrowerSmsConfirm, {BorrowerSmsConfirmViews} from '@components/borrower/reused/SmsConfirm/BorrowerSmsConfirm';
import {BorrowerRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useHistory} from 'react-router-dom'
import classNames from 'classnames';
import isEmpty from 'lodash.isempty';

const iconProps:{
	bgAlpha: IBoxIconProps['bgAlpha'],
	size: IBoxIconProps['size']
} = {
	bgAlpha: .2,
	size: 'm'
};

const BorrowerAccountConditions:FC = () => {

	const formHookMethods = useForm<IBorrowerBidRequest>({
		mode: 'onBlur',
		defaultValues: {
			[BorrowerBidRequestKeysEnum.PURPOSE]: '',
			[BorrowerBidRequestKeysEnum.GOAL_AMOUNT]: '',
			[BorrowerBidRequestKeysEnum.TERM]: 0,
			[BorrowerBidRequestKeysEnum.PRIVACY_POLICY]: true,
			[BorrowerBidRequestKeysEnum.PUBLIC_OFFER]: true,
		}
	});

	const
		{
			data: projectsParams = null,
			isLoading: projectsParamsIsLoading,
			isError: projectsParamsIsError
		} = projectsApi.useParamsQuery(),
		[
			bidRequest,
			{
				isLoading: bidRequestIsLoading
			}
		] = borrowerApi.useBidRequestMutation(),
		[
			checkCode,
			{
				isLoading: checkCodeIsLoading
			}
		] = borrowerApi.useBidConfirmMutation(),
		{
			data: creditHistoryDocument = null,
			isLoading: creditHistoryDocumentIsLoading,
			isError: creditHistoryDocumentIsError
		} = borrowerApi.useGetCreditHistoryDocumentQuery(),
		[getProfileData] = borrowerApi.useLazyGetProfileQuery()
	;

	const
		history = useHistory(),
		[term, setTerm] = useState<number | null>(null),
		[smsMode, setSmsMode] = useState<BorrowerSmsConfirmViews>('request'),
		{setBidsParams} = useAppActions(),
		{borrowerProfile} = useAppSelector(state => state.user),
		[ableNewBid, setAbleNewBid] = useState<boolean>(false),
		{changeSmsCode, setSmsError} = useContext(profileContext),
		[requestData, setRequestData] = useState<IBorrowerBidRequest>({} as IBorrowerBidRequest)
	;

	useEffect(() => {
		if (!isEmpty(borrowerProfile)) setAbleNewBid(borrowerProfile[BorrowerProfileKeysEnum.ABLE_NEW_BID]);
	}, [borrowerProfile]);

	const
		onSubmit = formHookMethods.handleSubmit(data => {
			setRequestData(data);
		}),
		handleBidConfirm = (value: string) => {
			setSmsError(false);

			checkCode(value)
				.unwrap()
				.then(response => {
					if (response.code === CODES_RESPONSE_SUCCESS) {
						formHookMethods.reset();
						setSmsMode('request');
						getProfileData();
						history.push(RouteNamesEnum.BORROWER+BorrowerRouteNamesEnum.CABINET)
					}

					setSmsError(false);
				})
				.catch(() => setSmsError(true))
				.finally(() => changeSmsCode(BASIC_SMS_CODE_ARR))
		}
	;

	useEffect(() => {
		projectsParams && setTerm(projectsParams.termMax / 2);

		if (projectsParams) {
			setBidsParams(projectsParams);
			formHookMethods.setValue(BorrowerBidRequestKeysEnum.TERM, projectsParams.termMax / 2);
		} else {
			setBidsParams({} as IBorrowerBidsParams)
		}

	}, [projectsParams]);

	useEffect(() => {
		if (!isEmpty(requestData)) {
			bidRequest(requestData)
				.unwrap()
				.then(response => {
					if (response.code === CODES_RESPONSE_SUCCESS) setSmsMode('confirm');
				})
				.catch(() => {
					setSmsMode('request')
				})
		}
	}, [requestData]);

	return (
		<ProfileSection title={'Условия займа'} className={styles.section}>
			{projectsParamsIsLoading && <Spinner className={'spinner--centered'} animation='border' variant='primary'/>}

			<FormProvider {...formHookMethods}>

				<form	onSubmit={onSubmit}>
					<Fade inProp={!projectsParamsIsLoading && !projectsParamsIsError}>
						{
							projectsParamsIsError
								? <Heading tag={'h4'} variant={HeadingVariant.middle}>Ошибка загрузки</Heading>
								: projectsParams && <div className={classNames('row', {'pt-3': ableNewBid})}>
								{
									!ableNewBid && <div className="col-12">
										<p className="text-danger pb-5">
											{`*Повторную заявку вы сможете подать через ${borrowerProfile[BorrowerProfileKeysEnum.REMAIN_DAYS]} ${declensionOfNumber(borrowerProfile[BorrowerProfileKeysEnum.REMAIN_DAYS], DAYS_WORDS)}`}
										</p>
									</div>
								}
								<div className="col-lt-10">
									<div className="row gx-4 gx-lt-5 gy-5">
										<div className="order-1 order-xxl-1 col-md-6 col-xxl-4">
											<BorrowerAccountConditionsFormGroup
												title={'Цель займа'}
												icon={<BoxIcon {...iconProps} bgColor={RGB_COLORS.pear}><StarIcon/></BoxIcon>}
												type={'text'}
												note={'Например: Капитальный ремонт кафе'}
												fieldKey={BorrowerBidRequestKeysEnum.PURPOSE}
												readonly={!ableNewBid}
											/>
										</div>
										<div className="order-3 order-xxl-2 col-md-6 col-xxl-4">
											<BorrowerAccountConditionsFormGroup
												title={'Желаемая сумма'}
												icon={<BoxIcon {...iconProps} bgColor={RGB_COLORS.green}><RubleIcon/></BoxIcon>}
												type={'number'}
												fieldKey={BorrowerBidRequestKeysEnum.GOAL_AMOUNT}
												readonly={!ableNewBid}
												note={
													<>
														от&nbsp;
														<NumberFormat
															displayType={'text'}
															thousandSeparator={' '}
															value={parseFloat(projectsParams.goalAmountMin)}
														/>
														&nbsp;до&nbsp;
														<NumberFormat
															displayType={'text'}
															thousandSeparator={' '}
															value={parseFloat(projectsParams.goalAmountMax)}
														/>
													</>
												}
											/>
										</div>
										{
											term && <div className="order-2 order-xxl-3 col-md-6 col-xxl-4">
												<BorrowerAccountConditionsFormGroup
													className={'h-100 d-flex flex-column'}
													title={'Желаемый срок'}
													icon={<BoxIcon {...iconProps} bgColor={RGB_COLORS.blue}><ArrowDownIcon/></BoxIcon>}
													type={'range'}
													fieldKey={BorrowerBidRequestKeysEnum.TERM}
													rangeEdges={{
														min: projectsParams.termMin,
														max: projectsParams.termMax
													}}
													rangeValue={term}
													onRangeChange={setTerm}
													note={
														<span className='d-flex w-100 justify-content-between'>
															<span>{projectsParams.termMin} {declensionOfNumber(projectsParams.termMin, MONTHS_WORDS)}</span>
															<span>{projectsParams.termMax} {declensionOfNumber(projectsParams.termMax, MONTHS_WORDS)}</span>
														</span>
													}
												/>
											</div>
										}
									</div>
								</div>
							</div>
						}
					</Fade>

					<Fade inProp={!creditHistoryDocumentIsLoading && !creditHistoryDocumentIsError}>
						<div className="row pt-sm-5">
							<div className="col-sm-10">

								<section className='pt-5'>
									<h3 className={'text-md pb-4'}>Подпишите документы</h3>
									<FormGroup
										fieldType={FormGroupTypes.CHECKBOX}
										id={'AccountConditionsPlatformRules'}
										label={
											<span className='text-default'>
											  Присоединяюсь к <a className='text-primary text-decoration-underline' href={EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES} target='_blank' rel='noreferrer'>Правилам платформы</a>
										  </span>
										}
										checked={formHookMethods.watch(BorrowerBidRequestKeysEnum.PUBLIC_OFFER)}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => formHookMethods.setValue(BorrowerBidRequestKeysEnum.PUBLIC_OFFER, event.target.checked)}
										register={formHookMethods.register}
										errorMessage={formHookMethods.formState.errors[BorrowerBidRequestKeysEnum.PUBLIC_OFFER]?.message}
										name={BorrowerBidRequestKeysEnum.PUBLIC_OFFER}
										className={classNames({'is-disabled': !ableNewBid})}
									/>
									{
										creditHistoryDocument && <FormGroup
											fieldType={FormGroupTypes.CHECKBOX}
											id={'AccountConditionsCreditHistory'}
											label={
												<span className='text-default'>
													Даю <a className='text-primary text-decoration-underline' href={creditHistoryDocument.url} target='_blank' rel='noreferrer'>Согласие на получение кредитной истории</a>
									      </span>
											}
											checked={formHookMethods.watch(BorrowerBidRequestKeysEnum.PRIVACY_POLICY)}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => formHookMethods.setValue(BorrowerBidRequestKeysEnum.PRIVACY_POLICY, event.target.checked)}
											register={formHookMethods.register}
											errorMessage={formHookMethods.formState.errors[BorrowerBidRequestKeysEnum.PRIVACY_POLICY]?.message}
											name={BorrowerBidRequestKeysEnum.PRIVACY_POLICY}
											className={classNames({'is-disabled': !ableNewBid})}
										/>
									}

									{
										ableNewBid && <BorrowerSmsConfirm
											view={smsMode}
											onSubmitClick={onSubmit}
											onResend={() => {
												if (!isEmpty(requestData)) bidRequest(requestData)
											}}
											isLoading={bidRequestIsLoading || checkCodeIsLoading}
											codeChecking={checkCodeIsLoading}
											disabled={!ableNewBid}
											note={'*Для оформления заявки мы отправим СМС-код'}
											onConfirm={handleBidConfirm}
										/>
									}
								</section>
							</div>
						</div>
					</Fade>

				</form>

			</FormProvider>


		</ProfileSection>
	);
};

export default BorrowerAccountConditions;

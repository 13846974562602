import {useContext} from 'react';
import {profileContext} from '@context/profileContext';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import useWindowSize from '@hooks/useWindowSize';

export const useScrollToTop = (behavior?: ScrollBehavior):() => void => {

	const {scrollableContentRef} = useContext(profileContext);
	const {width: ww} = useWindowSize();

	return () => {

		const customScrollEl = ww >= ADAPTIVE_BREAKPOINTS.xl
			? ((scrollableContentRef && scrollableContentRef.current) ? scrollableContentRef.current : null)
			: document.documentElement;

		customScrollEl && customScrollEl
			.scrollTo({
				top: 0,
				left: 0,
				behavior: behavior || 'auto'
			})
	}
};

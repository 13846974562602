export enum INotificationTypeCodeEnum {
	'SUCCESS' = 'success',
	'INFO' = 'info',
	'WARNING' = 'warning'
}

export interface INotification {
	subject: string,
	notificationId: string,
	dateCreate: string,
	message: string,
	viewed: boolean,
	typeCode: INotificationTypeCodeEnum
}

export interface INotificationsResponse {
	items: INotification[],
	count: number
}

export interface INotificationState {
	notifications: INotification[]
}

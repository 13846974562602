import React, {FC} from 'react';
import {Fade} from '@components/common/Transitions/Fade';
import styles from './projects.module.scss';
import {Accordion} from 'react-bootstrap';
import ProfileProject from '@components/investor/Project/Project';
import {useAppSelector} from '@hooks/useAppSelector';

const ProfileProjectsList:FC = () => {
	const {projects} = useAppSelector(state => state.projects);

	if (projects.length > 0)
		return <Fade inProp={projects.length > 0}>
			<div className={styles.wrap}>
				{
					<Accordion flush>
						{projects.map(project => <ProfileProject key={project.projectId} {...project}/>)}
					</Accordion>
				}
			</div>
		</Fade>;

	return null;
};

export default ProfileProjectsList;

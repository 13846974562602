import React, {DetailedHTMLProps, FC, HTMLAttributes} from 'react';
import {Container, Row} from 'react-bootstrap';
import styles from './main.module.scss';
import classNames from 'classnames';
import AuthDialog from '@components/auth/Dialog/Dialog';
import {ReactComponent as LogoIcon} from '@icons/auth-logo.svg';
import InvestorIllustration from '@images/auth-illustration-investor.svg';
import BorrowerIllustration from '@images/auth-illustration-borrower.svg';
import AuthFooter from '@components/auth/Footer/Footer';
import {useAppSelector} from '@hooks/useAppSelector';
import {AuthRolesEnum} from '@features/auth/types';
import LazyImage from '@components/common/LazyImage/LazyImage';
import AuthAboutModal from '@components/auth/AboutModal/AboutModal';

const AuthMain: FC<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>> = (props): JSX.Element => {

	const {role} = useAppSelector(state => state.auth);


	return (
		<div className={classNames(styles.root, 'd-flex flex-column')} {...props}>
			<div className={styles.content}>
				<LogoIcon className={styles.icon}/>
				<Container fluid={true}>
					<Row className={'justify-content-center py-5'}>
						<div className={'col-sm-8 col-lg-6 col-xl-5 col-lt-4'}>
							<AuthDialog/>
						</div>
					</Row>
				</Container>
				<div className={styles.illustration}>
					<LazyImage
						src={role === AuthRolesEnum.INVESTOR
							? InvestorIllustration
							: BorrowerIllustration}
						hideLoader
						className='h-100'
					/>
				</div>
			</div>
			<AuthFooter/>
			<AuthAboutModal/>
		</div>
	);
};

export default AuthMain;

import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {API_NOTIFICATIONS_USER_URL, API_VERSION} from '@common/constants';
import {INotification, INotificationsResponse} from '@features/notifications/types';
import {baseQuery} from '@api/utils';
import {IDefaultResponse} from '@common/types';

const notificationsApi = createApi({
	reducerPath: 'notificationsApi',
	baseQuery,
	endpoints: build => ({
		user: build.query<INotification[], void>({
			query: () => API_NOTIFICATIONS_USER_URL,
			transformResponse: (response:INotificationsResponse) => response.items
		}),
		markAsViewed: build.mutation<IDefaultResponse, string>({
			query: id => ({
				url: `${API_VERSION}/notifications/${id}/mark-as-viewed`,
				method: 'post'
			})
		}),
		delete: build.mutation<IDefaultResponse, string>({
			query: id => ({
				url: API_NOTIFICATIONS_USER_URL+`/${id}`,
				method: 'delete'
			})
		})
	})
});

export default notificationsApi;

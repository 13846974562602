import { IAttachment, ISearch, PhoneNumberType } from "@common/types";
import { RefObject } from "react";
import moment from "moment";
import {
	API_DATE_FORMAT,
	APP_DATE_FORMAT,
	MESSAGE_VALIDATION_INVALID_PASSPORT_DIVISION_CODE,
	MESSAGE_VALIDATION_INVALID_PASSPORT_NUMBER,
	MESSAGE_VALIDATION_REQUIRED_FIELD,
	PASSPORT_DIVISION_CODE_LENGTH,
	PASSPORT_NUMBER_LENGTH,
	REQUIRED_PHONE_LENGTH,
	SEARCH_LIMIT,
} from "@common/constants";
import { ISection, SectionsCodeEnum } from "@features/utils/types";
import merge from "lodash.merge";
import { DocumentUrlType } from "@features/projects/types";

export const addZero = (num: number): string => ("0" + num).slice(-2);

export const getCurrentTime = (): string => {
	const currentDate = new Date(),
		hours = addZero(currentDate.getHours()),
		minutes = addZero(currentDate.getMinutes()),
		seconds = addZero(currentDate.getSeconds());
	return `${hours}:${minutes}:${seconds}`;
};

export const phoneToString = (phone: PhoneNumberType): string => {
	if (typeof phone === "number") {
		return phone.toString();
	}
	return "";
};

export const optimizePhone = (phone: string | null | number): string => {
	if (phone === null) return "";

	if (typeof phone === "number") phone = phone.toString();

	return phone.startsWith("7") ? phone.slice(1) : phone;
};

export const validatePhoneAsString = (
	value: string,
	lengthCheck = true,
): PhoneNumberType => {
	let currentValue = value.replace(/[^0-9]/g, "");

	if (lengthCheck) {
		if (currentValue.length > REQUIRED_PHONE_LENGTH) {
			currentValue = currentValue.slice(0, REQUIRED_PHONE_LENGTH);
		}
	}

	const valueAsNum = Number(currentValue);

	if (isNaN(valueAsNum)) return null;

	return valueAsNum;
};

export const testEmail = (email: string): boolean => {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email.toLowerCase());
};

export const withRootUrl = (url: string): string =>
	process.env.REACT_APP_ROOT_URL + url;

export const getRefElement = <T>(
	element?: RefObject<Element> | T,
): Element | T | undefined | null => {
	if (element && "current" in element) {
		return element.current;
	}

	return element;
};

export const formatDate = (
	date: Date | null | string = null,
	format: string = APP_DATE_FORMAT,
): string => {
	return moment(date).format(format);
};

export const configureAssetPath = (a?: IAttachment | null): string | null => {
	if (!a) return null;

	if (
		Object.keys(a).length > 0 &&
		a.hasOwnProperty("uid") &&
		a.hasOwnProperty("path") &&
		a.hasOwnProperty("extension")
	)
		return `${a.path}/${a.uid}${a.extension}`;

	return null;
};

export const getSection = (
	sections: ISection[] = [],
	code: SectionsCodeEnum,
): ISection | null => {
	return sections.find((section) => section.code === code) || null;
};

interface IAnchorDownloadParams {
	download: boolean;
	href: string;
	target: string;
}

interface IAnchorExternalParams {
	rel: "noreferrer";
	href: string;
	target: "_blank";
}

export const configureAssetAnchorParams = (
	attachment: IAttachment | null | undefined,
	externalLink: string,
): IAnchorDownloadParams | IAnchorExternalParams => {
	return attachment
		? {
				download: false,
				href: withRootUrl(configureAssetPath(attachment) || "") || "",
				target:
					attachment.extension.toLowerCase() === ".pdf" ? "_blank" : "_self",
		  }
		: {
				rel: "noreferrer",
				href: externalLink,
				target: "_blank",
		  };
};

export const searchWithParams = (settings: Partial<ISearch> = {}): ISearch => {
	const defaultSettings = {
		filters: {},
		orders: {},
		offset: 0,
		limit: SEARCH_LIMIT,
	} as ISearch;

	return merge(defaultSettings, settings);
};

export const declensionOfNumber = (
	value: number,
	words: string[] = new Array(4).fill(""),
): string => {
	value = Math.abs(value) % 100;
	const num = value % 10;
	if (value > 10 && value < 20) return words[2];
	if (num > 1 && num < 5) return words[1];
	if (num == 1) return words[0];
	return words[2];
};

export const trimZero = (value: number): string => {
	const valString = value.toString();

	if (valString.length > 1) {
		return valString.replace(/^0+/, "");
	}

	return valString;
};

export const isNum = (value: string): boolean => !isNaN(+value);

type SearchFilterByFieldType = Record<string, { [key: string]: string }>;

export const searchFiltersByFields = (
	fields: string[] = [],
	fieldValue: string,
): SearchFilterByFieldType => {
	return fields.reduce((acc: SearchFilterByFieldType, val) => {
		acc[`|${val}`] = { like: fieldValue };
		return acc;
	}, {});
};

export const fileListToFormData = (
	fileList: FileList,
	attachmentNameKey: string,
): FormData => {
	const formData = new FormData();
	if (fileList && fileList.length > 0)
		formData.append(attachmentNameKey, fileList[0]);

	return formData;
};

export const convertRate = (r: number): string => {
	return parseFloat((r * 100).toString()).toFixed(2) + "%";
};

export const dateToApiFormat = (date: string): string | null => {
	if (moment(date).isValid()) return moment(date).format(API_DATE_FORMAT);

	return null;
};

export const parameterizedDocumentIsValid = (
	document: DocumentUrlType | null,
): boolean => {
	if (document === null) return false;

	return document.hasOwnProperty("url") && Boolean(document.url);
};

export const checkStorage = (key: string, value: string | null): void => {
	if (typeof value === "string" && Boolean(value)) {
		localStorage.setItem(key, value);
	} else {
		localStorage.removeItem(key);
	}
};

export const validatePassportNumber = (value: string): string | boolean => {
	return (value && value.length) === PASSPORT_NUMBER_LENGTH
		? true
		: MESSAGE_VALIDATION_INVALID_PASSPORT_NUMBER;
};

export const validatePassportDivisionCode = (
	value: string,
): string | boolean => {
	return (value && value.length) === PASSPORT_DIVISION_CODE_LENGTH
		? true
		: MESSAGE_VALIDATION_INVALID_PASSPORT_DIVISION_CODE;
};

export const validatePhone = (value: string): string | boolean => {
	const vPhone = validatePhoneAsString(value);

	return vPhone && vPhone.toString().length === REQUIRED_PHONE_LENGTH
		? true
		: MESSAGE_VALIDATION_REQUIRED_FIELD;
};

export const validateDate = (date: string): string => {
	const mDate = moment(date);

	if (!mDate.isValid()) return "";
	return mDate.format(API_DATE_FORMAT);
};

export const declinationOfNum = (num: number, words: string[]): string => {
	const n = Math.abs(num) % 100,
		n1 = n % 10;

	if (n > 10 && n < 20) {
		return words[2];
	}
	if (n1 > 1 && n1 < 5) {
		return words[1];
	}
	if (n1 == 1) {
		return words[0];
	}
	return words[2];
};

export const validateInnMinMax = (
	value: string,
	min: number,
	max: number,
): string | boolean => {
	return (
		value.length === min ||
		value.length === max ||
		`Значение может состоять из ${min} или ${max} символов`
	);
};

export const passwordDivisionCodeWithDash = (code: string): string => {
	return [
		...code.slice(0, 3).split(""),
		"-",
		...code.slice(3, 6).split(""),
	].join("");
};

import { useEffect } from 'react';

const useSession = ({ logout, expireTime = 1800000 }: { logout: () => void; expireTime: number }): void => {

    const checkForInactivity = () => {
        const expireTime = localStorage.getItem('expireTime');
        const now = Date.now();
        if (expireTime && +expireTime < now) {
            logout();
        }
    };

    const updateExpireTime = () => {
        const time = Date.now() + expireTime;
        localStorage.setItem('expireTime', time.toString());
    };

    useEffect(() => {
        updateExpireTime();
        window.addEventListener('click', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('keypress', updateExpireTime);
        window.addEventListener('mousemove', updateExpireTime);
        return () => {
            window.removeEventListener('click', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('keypress', updateExpireTime);
            window.removeEventListener('mousemove', updateExpireTime);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    return;
};

export default useSession;

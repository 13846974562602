import React, {FC, useContext, useEffect} from 'react';
import {Modal} from 'react-bootstrap';
import ProfileProjectsFilterSection from '@components/investor/Views/Projects/Filter/FilterSection';
import styles from './filter.module.scss';
import {PROJECT_PARAM_GOAL_TOTAL_MAX, PROJECT_PARAM_RATE_MAX, PROJECT_PARAM_TERM_MAX} from '@common/constants';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {FilterOptionsEnum, IFilters, IListResponse, RangeNumbersType} from '@common/types';
import {useAppActions} from '@hooks/useAppActions';
import classNames from 'classnames';
import projectsApi from '@api/projectsApi';
import {searchWithParams} from '@common/utils';
import {projectsContext} from '@context/projectsContext';
import {profileContext} from '@context/profileContext';
import {ProjectsTabsKeysEnum} from '@components/investor/Views/Projects/Projects';

const ProfileProjectsFilter:FC<{
	onFilterReset?: (response: IListResponse) => void,
	onFilterSubmit?: (response: IListResponse) => void
}> = ({onFilterReset, onFilterSubmit}) => {

	const
		[searchAllProjects, {isLoading: projectsAllIsLoading}] = projectsApi.useSearchMutation(),
		[searchUserProjects, {isLoading: projectsUserIsLoading}] = projectsApi.useSearchInvestedByUserMutation(),
		{setProjects} = useAppActions(),
		{
			filters,
			orders,
			setFilters,
			filterIsOpen,
			setFilterIsOpen,
			filterIsInvolved,
			setFilterTerm,
			filterTerm,
			filterTermDefault,
			filterTermDebounced,
			filterGoalAmount,
			setFilterGoalAmount,
			filterGoalAmountDefault,
			filterGoalAmountDebounced,
			setFilterRate,
			filterRate,
			filterRateDefault,
			filterRateDebounced
		} = useContext(projectsContext),
		{activeTab} = useContext(profileContext)
	;

	const
		reset = ():void => {
			setFilterTerm(filterTermDefault);
			setFilterGoalAmount(filterGoalAmountDefault);
			setFilterRate(filterRateDefault);
			setFilters({});

			const action = (response: IListResponse) => {
				setProjects(response.items);
				onFilterReset && onFilterReset(response);
				setFilterIsOpen(false)
			};

			switch (activeTab?.key) {
				case ProjectsTabsKeysEnum.ALL:
					searchAllProjects(searchWithParams({}))
						.unwrap()
						.then(action);
					break;
				case ProjectsTabsKeysEnum.USER:
					searchUserProjects(searchWithParams({}))
						.unwrap()
						.then(action);
					break;
			}
		},
		setupFilter = (fieldName: string, value: RangeNumbersType): IFilters['filters'] => {
			return {
				[fieldName]: {
					[FilterOptionsEnum['GE']]: value[0],
					[FilterOptionsEnum['LE']]: value[1]
				}
			}
		},
		convertGoalAmount = (): RangeNumbersType => filterGoalAmountDebounced.map(n => n * 1000000) as RangeNumbersType,
		convertRate = (): RangeNumbersType => filterRateDebounced.map(n => n / 100) as RangeNumbersType,
		checkFilterValue = (installed: IFilters['filters'], filterKey: string):void => {
			if (filterIsInvolved) {
				setFilters({
					...filters,
					...installed
				})
			} else {
				delete filters[filterKey];
				setFilters(filters)
			}
		}
	;


	useEffect(() => {
		checkFilterValue(setupFilter('term', filterTermDebounced), 'term');
	}, [filterTermDebounced]);

	useEffect(() => {
		checkFilterValue(setupFilter('goalAmount', convertGoalAmount()), 'goalAmount');
	}, [filterGoalAmountDebounced]);

	useEffect(() => {
		checkFilterValue(setupFilter('rate', convertRate()), 'rate');
	}, [filterRateDebounced]);

	useEffect(() => {
		filterIsInvolved && setFilters({
			...setupFilter('term', filterTermDebounced),
			...setupFilter('goalAmount', convertGoalAmount()),
			...setupFilter('rate', convertRate())
		});
	}, []);

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();

		const searchParams = searchWithParams({
			filters,
			orders
		});

		const action = (response: IListResponse):void => {
			setProjects(response.items);
			setFilterIsOpen(false);
			onFilterSubmit && onFilterSubmit(response);
		};

		switch (activeTab?.key) {
			case ProjectsTabsKeysEnum.ALL:
				searchAllProjects(searchParams)
					.unwrap()
					.then(action);
				break;
			case ProjectsTabsKeysEnum.USER:
				searchUserProjects(searchParams)
					.unwrap()
					.then(action);
				break;
		}
	};

	return (
		<Modal
			show={filterIsOpen}
			onHide={() => setFilterIsOpen(false)}
			centered
		>
			<Modal.Header className={''} closeButton/>
			<Modal.Body>
				<form className={styles.main} onSubmit={handleSubmit}>
					<p className='text-center pb-5'>Задайте параметры</p>
					<ProfileProjectsFilterSection
						title={'Срок возврата инвестиций'}
						max={PROJECT_PARAM_TERM_MAX}
						postfix={'дней'}
						onRangeChange={setFilterTerm}
						value={filterTerm}
					/>
					<ProfileProjectsFilterSection
						title={'Сумма инвестиций'}
						max={PROJECT_PARAM_GOAL_TOTAL_MAX}
						postfix={'млн ₽'}
						onRangeChange={setFilterGoalAmount}
						value={filterGoalAmount}
					/>
					<ProfileProjectsFilterSection
						title={'Ставка'}
						max={PROJECT_PARAM_RATE_MAX}
						postfix={'%'}
						onRangeChange={setFilterRate}
						value={filterRate}
					/>
					<div className="mt-5 text-center">
						<Button
							variant={ButtonVariant.contained}
							as={ButtonTypesEnum.BUTTON}
							type={'submit'}
							disabled={!filterIsInvolved || projectsAllIsLoading || projectsUserIsLoading}
							isLoading={projectsAllIsLoading || projectsUserIsLoading}
						>
							Применить
						</Button>
						<button
							type={'reset'}
							className={classNames(styles.reset, 'text-dark text-decoration-underline')}
							style={{
								opacity: filterIsInvolved ? '1' : '0',
								visibility: filterIsInvolved ? 'visible' : 'hidden'
							}}
							onClick={reset}
						>
							<small>Сбросить настройки</small>
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default ProfileProjectsFilter;

import React, {FC, useContext} from 'react';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import ProfileSection from '@components/profile/Section/ProfileSection';
import styles from '../data.module.scss';
import {INN_MAX_LENGTH, INN_MIN_LENGTH, MESSAGE_VALIDATION_REQUIRED_FIELD} from '@common/constants';
import {
	INVESTOR_FIELD_NAME_BIRTH_DATE,
	INVESTOR_FIELD_NAME_FIRST_NAME, INVESTOR_FIELD_NAME_INN,
	INVESTOR_FIELD_NAME_LAST_NAME,
	INVESTOR_FIELD_NAME_MIDDLE_NAME, INVESTOR_FIELD_NAME_RESIDENCE_CITY
} from '@components/investor/Views/Data/constants';
import {Controller, useFormContext} from 'react-hook-form';
import {validateInnMinMax} from '@common/utils';
import {personalDataContext} from '@context/personalDataContext';

export const ProfileDataPersonal:FC = () => {

	const
		{
			register,
			setValue,
			watch,
			formState: {errors},
			control
		} = useFormContext(),
		{editMode} = useContext(personalDataContext)
	;

	return (
		<ProfileSection
			title={'Персональные данные'}
		>
			<div className="row gx-3 gy-sm-4">
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataLastName'}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_LAST_NAME, event.target.value)}
						label={'Фамилия'}
						placeholder={'Фамилия'}
						value={watch(INVESTOR_FIELD_NAME_LAST_NAME)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_LAST_NAME}
						errorMessage={errors[INVESTOR_FIELD_NAME_LAST_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataFirstName'}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_FIRST_NAME, event.target.value)}
						label={'Имя'}
						placeholder={'Имя'}
						value={watch(INVESTOR_FIELD_NAME_FIRST_NAME)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_FIRST_NAME}
						errorMessage={errors[INVESTOR_FIELD_NAME_FIRST_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataFirstName'}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_MIDDLE_NAME, event.target.value)}
						label={'Отчество'}
						placeholder={'Отчество'}
						value={watch(INVESTOR_FIELD_NAME_MIDDLE_NAME)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_MIDDLE_NAME}
						errorMessage={errors[INVESTOR_FIELD_NAME_MIDDLE_NAME]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<Controller
						name={INVESTOR_FIELD_NAME_BIRTH_DATE}
						control={control}
						rules={{
							required: MESSAGE_VALIDATION_REQUIRED_FIELD
						}}
						render={(
							{
								field: { onChange, onBlur, value },
								fieldState: {error},
							}
						) => (
							<FormGroup
								id={'profileDataBirthDate'}
								fieldType={FormGroupTypes.DATE}
								onDateChange={onChange}
								onBlur={onBlur}
								label={'Дата рождения'}
								placeholder={'Дата рождения'}
								selected={value ? new Date(value) : null}
								readonly={!editMode}
								className={styles.formGroup}
								errorMessage={error?.message}
							/>
						)}
					/>

				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataCity'}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_RESIDENCE_CITY, event.target.value)}
						label={'Город проживания'}
						placeholder={'Город проживания'}
						value={watch(INVESTOR_FIELD_NAME_RESIDENCE_CITY)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_RESIDENCE_CITY}
						errorMessage={errors[INVESTOR_FIELD_NAME_RESIDENCE_CITY]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataTaxpayerIdentificationNumber'}
						fieldType={FormGroupTypes.NUMBER}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_INN, event.target.value)}
						label={'ИНН'}
						placeholder={'ИНН'}
						value={watch(INVESTOR_FIELD_NAME_INN)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_INN}
						errorMessage={errors[INVESTOR_FIELD_NAME_INN]?.message}
						registerOptions={
							{
								validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
							}
						}
					/>
				</div>
			</div>
		</ProfileSection>
	);
};

import React, {FC, useEffect, useState} from 'react';
import {useAppSelector} from '@hooks/useAppSelector';
import styles from './personalManager.module.scss';
import classNames from 'classnames';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {ReactComponent as DefaultAvatarIcon} from '@icons/default-avatar.svg';
import {ReactComponent as PhoneIcon} from '@icons/phone-thin.svg';
import {ReactComponent as EmailIcon} from '@icons/email-thin.svg';
import LazyImage from '@components/common/LazyImage/LazyImage';
import {configureAssetPath, withRootUrl} from '@common/utils';
import {Ratio} from 'react-bootstrap';

const BorrowerPersonalManager:FC<{className?: string}> = ({className}) => {

	const
		{borrowerManager} = useAppSelector(state => state.user),
		[avatarSrc, setAvatarSrc] = useState<string | null>(null)
	;

	useEffect(() => {
		(borrowerManager?.avatar && borrowerManager.avatar?.uid) && setAvatarSrc(configureAssetPath(borrowerManager.avatar))
	}, [borrowerManager]);

	return (
		<div className={classNames(styles.box, className)}>
			<div className="row gx-2">
				<div className='col-3'>
					<div className="pe-4">
						<Ratio aspectRatio={'1x1'}>
							<figure className={styles.avatar}>
								{
									avatarSrc
										? <LazyImage
											src={withRootUrl(avatarSrc)}
											alt='User avatar'
										/>
										: <DefaultAvatarIcon/>
								}
							</figure>
						</Ratio>
					</div>
				</div>
				<div className='col-9'>
					<Heading className={'mb-1 py-1'} tag={'h4'} variant={HeadingVariant.small}>{borrowerManager?.fullName}</Heading>
					<small className={'text-dark'}>Ваш персональный менеджер</small>

					{
						(borrowerManager?.phone || borrowerManager?.email) && <ul className={'row mb-0 g-2 flex-column pt-2'}>
							{
								borrowerManager?.phone && <li className={'col'}>
									<a href={`tel:${borrowerManager.phone}`}>
										<PhoneIcon width={15} height={15}/>
										<small className={'ps-2'}>{borrowerManager.phone}</small>
									</a>
								</li>
							}

							{
								borrowerManager?.email && <li className={'col'}>
									<a href={`mailto:${borrowerManager.email}`}>
										<EmailIcon width={15} height={15}/>
										<small className={'ps-2'}>{borrowerManager.email}</small>
									</a>
								</li>
							}
						</ul>
					}
				</div>
			</div>
		</div>
	);
};

export default BorrowerPersonalManager;

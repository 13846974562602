import {IInvestorProfile} from '@features/user/types';
import {
	INVESTOR_FIELD_NAME_BIRTH_DATE, INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED,
	INVESTOR_FIELD_NAME_EMAIL,
	INVESTOR_FIELD_NAME_FIRST_NAME,
	INVESTOR_FIELD_NAME_HEAD_FIRST_NAME,
	INVESTOR_FIELD_NAME_HEAD_LAST_NAME,
	INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME,
	INVESTOR_FIELD_NAME_HEAD_POST,
	INVESTOR_FIELD_NAME_INN, INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED, INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG,
	INVESTOR_FIELD_NAME_LAST_NAME, INVESTOR_FIELD_NAME_LAW_TYPE,
	INVESTOR_FIELD_NAME_MIDDLE_NAME,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION, INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_FULL_NAME,
	INVESTOR_FIELD_NAME_ORG_INN,
	INVESTOR_FIELD_NAME_ORG_KPP,
	INVESTOR_FIELD_NAME_ORG_OGRN,
	INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
	INVESTOR_FIELD_NAME_ORGANIZATION_FORM,
	INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE,
	INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE,
	INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY, INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN,
	INVESTOR_FIELD_NAME_PASSPORT_NUMBER,
	INVESTOR_FIELD_NAME_PHONE, INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED,
	INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS,
	INVESTOR_FIELD_NAME_RESIDENCE_CITY, INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED
} from '@components/investor/Views/Data/constants';
import {validateDate} from '@common/utils';

export const getDefaultParams = (profileData: Partial<IInvestorProfile>): Partial<IInvestorProfile> => {
	return profileData
		? Object.keys(profileData)
			.reduce((acc , currentValue, ) => {
				const a = acc as Partial<IInvestorProfile>;

				switch (currentValue) {
					case INVESTOR_FIELD_NAME_PHONE:
					case INVESTOR_FIELD_NAME_EMAIL:
					case INVESTOR_FIELD_NAME_FIRST_NAME:
					case INVESTOR_FIELD_NAME_MIDDLE_NAME:
					case INVESTOR_FIELD_NAME_LAST_NAME:
					case INVESTOR_FIELD_NAME_RESIDENCE_CITY:
					case INVESTOR_FIELD_NAME_INN:
					case INVESTOR_FIELD_NAME_PASSPORT_NUMBER:
					case INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE:
					case INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY:
					case INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE:
					case INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS:
					case INVESTOR_FIELD_NAME_ORG_INN:
					case INVESTOR_FIELD_NAME_ORG_FULL_NAME:
					case INVESTOR_FIELD_NAME_ORG_SHORT_NAME:
					case INVESTOR_FIELD_NAME_ORG_OGRN:
					case INVESTOR_FIELD_NAME_ORG_KPP:
					case INVESTOR_FIELD_NAME_ORGANIZATION_FORM:
					case INVESTOR_FIELD_NAME_HEAD_LAST_NAME:
					case INVESTOR_FIELD_NAME_HEAD_FIRST_NAME:
					case INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME:
					case INVESTOR_FIELD_NAME_HEAD_POST:
					case INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION:
					case INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION:
						a[currentValue] = profileData[currentValue];
						break;
					case INVESTOR_FIELD_NAME_BIRTH_DATE:
					case INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN:
					case INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION:
						a[currentValue] = validateDate(profileData[currentValue] || '');

						break;
					case INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED:
					case INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED:
					case INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED:
					case INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED:
					case INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG:
						a[currentValue] = profileData[currentValue];
						break;
					case INVESTOR_FIELD_NAME_LAW_TYPE:
						a[currentValue] = profileData[currentValue];
						break;
				}

				return acc;
			}, {})
		: {}
};

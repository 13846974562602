import React, {FC} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {useAppSelector} from '@hooks/useAppSelector';
import Table from '@components/common/Table/Table';
import moment from 'moment';
import {ADAPTIVE_BREAKPOINTS, APP_DATE_FORMAT, MONTHS_WORDS} from '@common/constants';
import AmountOutput from '@components/common/AmountOutput';
import {convertRate, declensionOfNumber} from '@common/utils';
import {defineStatusContent} from '@components/borrower/Views/Cabinet/Cabinet';
import {BorrowerProjectStatusEnum} from '@features/borrower/types';
import {Link} from 'react-router-dom';
import {BorrowerRouteNamesEnum, RouteNamesEnum} from '@app/router';
import styles from '../cabinet.module.scss';
import classNames from 'classnames';
import SimpleBar from 'simplebar-react';
import {ConditionalWrapper} from '@components/common/ConditionalWrapper';
import useWindowSize from '@hooks/useWindowSize';

const BorrowerCabinetLoans:FC = () => {

	const
		{projects = [], indicators} = useAppSelector(state => state.borrower.loans),
		{width: ww} = useWindowSize()
	;

	return (
		projects.length > 0
			? <ProfileSection title={'Займы'} isTableInside>

				<ConditionalWrapper
					condition={ww > ADAPTIVE_BREAKPOINTS.lg && ww < ADAPTIVE_BREAKPOINTS.lt}
					wrapper={children => {
						return <SimpleBar
							className={styles.simplebar}
							autoHide={false}
						>
							{children}
						</SimpleBar>
					}}
				>
					<Table
						className={classNames(styles.table, styles.tableLoans)}
						horizontallyScrolled
						thCells={[
							'№',
							'Дата выдачи',
							'Сумма займа',
							'Собранная сумма',
							'Процентная ставка',
							'Срок',
							'Дата ближайшего платежа',
							'Ближайший платеж по займу',
							'Статус'
						]}
						tdCells={
							projects.map(({bidId, dateCreated, goalAmount, collectedAmount, rate, term, nextDate, nextPayment, status, projectId}) => {

								const
									k = projectId+bidId,
									link = ():JSX.Element | string => {
										if (status === BorrowerProjectStatusEnum.ISSUED || status === BorrowerProjectStatusEnum.PAID) {
											return <Link
												to={RouteNamesEnum.BORROWER+BorrowerRouteNamesEnum.LOANS+`/${projectId}`}
												key={k}
												className='text-primary text-decoration-underline'
											>{projectId.toString()}</Link>
										} else {
											return projectId.toString()
										}
									};

								return [
									link(),
									moment(dateCreated).format(APP_DATE_FORMAT),
									<AmountOutput className='text-jade' key={bidId} amount={goalAmount.toString()}/>,
									<AmountOutput key={k} amount={collectedAmount.toString()}/>,
									convertRate(rate),
									`${term} ${declensionOfNumber(term, MONTHS_WORDS)}`,
									moment(nextDate).isValid() ? moment(nextDate).format(APP_DATE_FORMAT): '-',
									<AmountOutput key={k} amount={status === BorrowerProjectStatusEnum.ISSUED ? indicators?.currentDebt : nextPayment.toString()}/>,
									defineStatusContent(status)
								]
							})
						}
					/>
				</ConditionalWrapper>

			</ProfileSection>
			: <></>
	);
};

export default BorrowerCabinetLoans;

import {fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react'
import {RootState} from '@app/store';

export const baseQuery = fetchBaseQuery({
	baseUrl: process.env.REACT_APP_API_ROOT_URL,
	prepareHeaders: (headers, { getState }) => {
		const token = (getState() as RootState).user.token;

		if (token) headers.set('authorization', `Bearer ${token}`);

		return headers
	}
});

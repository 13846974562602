import React, { FC, useContext, useEffect, useState } from 'react';
import { CustomLink } from '@components/ui';
import { LinkVariant } from '@components/ui/Link/Link.props';
import { Spinner } from 'react-bootstrap';
import AnalyticsBox, { IProfileAnalyticsBoxProps } from '@components/profile/reused/AnalyticsBox/AnalyticsBox';
import { ReactComponent as WorkIcon } from '@icons/work-colored.svg';
import { ReactComponent as SwapIcon } from '@icons/swap-colored.svg';
import { ReactComponent as WalletIcon } from '@icons/wallet-colored-green.svg';
import ProfileSection from '@components/profile/Section/ProfileSection';
import { IOperation } from '@features/operations/types';
import AmountOutput from '@components/common/AmountOutput';
import styles from './cabinet.module.scss';
import { InvestorRouteNamesEnum, RouteNamesEnum } from '@app/router';
import operationsApi from '@api/operationsApi';
import ProfileContainer from '@components/profile/Views/Container/Container';
import { IProfilePage, ProfilePagesTitleEnum } from '@common/types';
import { ADAPTIVE_BREAKPOINTS, RGB_COLORS } from '@common/constants';
import Table from '@components/common/Table/Table';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';
// import analyticsApi from '@api/analyticsApi';
import { searchWithParams } from '@common/utils';
import useWindowSize from '@hooks/useWindowSize';
import isEmpty from 'lodash.isempty';
import AsideBalance from '@components/investor/Balance/ProfileBalance';
import { profileContext } from '@context/profileContext';
import { useAppActions } from '@hooks/useAppActions';
import { useAppSelector } from '@hooks/useAppSelector';

export const ProfileCabinet: FC<IProfilePage> = () => {

    const { fetchAnalyticsInfo } = useAppActions();

    const { data: analyticsData, error: analyticsError, loading: analyticsDataIsLoading } = useAppSelector(state => state.analytics);
    const analyticsDataIsFetching = analyticsDataIsLoading;

    // const
    //     {
    //         data: analyticsData,
    //         isLoading: analyticsDataIsLoading,
    //         isFetching: analyticsDataIsFetching,
    //         error: analyticsError,
    //     } = analyticsApi.useInfoQuery();

    const [
        searchOperationRequest,
        {
            data: operationsData,
            isLoading: operationsDataIsLoading
        }
    ] = operationsApi.useSearchMutation();

    const
        [analyticBoxes, setAnalyticBoxes] = useState<IProfileAnalyticsBoxProps[]>([]),
        { width: ww } = useWindowSize(),
        {
            investorInfoIsError,
            investorInfoIsLoading
        } = useContext(profileContext)
    ;

    useEffect(() => {
        fetchAnalyticsInfo();
    }, []);

    useEffect(() => {
        searchOperationRequest({
            body: searchWithParams({
                orders: {
                    dateCreated: 'DESC'
                },
                limit: 5
            })
        });
    }, []);

    useEffect(() => {

        if (!isEmpty(analyticsData)) {
            setAnalyticBoxes([
                {
                    Icon: WorkIcon,
                    title: 'Всего инвестировано',
                    amount: analyticsData?.investmentTotal,
                    rgbColor: RGB_COLORS.blue
                },
                {
                    Icon: SwapIcon,
                    title: 'Сейчас в проектах',
                    amount: analyticsData?.investmentInProjects,
                    rgbColor: RGB_COLORS.yellow
                },
                {
                    Icon: WalletIcon,
                    title: 'Получено прибыли',
                    amount: analyticsData?.profitTotal,
                    rgbColor: RGB_COLORS.green,
                    bgAlpha: 0.11
                }
            ]);
        }

    }, [analyticsData]);

    return (
        <ProfileContainer title={ProfilePagesTitleEnum.CABINET}>
            <div className="row">
                <div className="col-hd-8">

                    {
                        (ww < ADAPTIVE_BREAKPOINTS.xl) && <ProfileSection
                            title={'Баланс'}
                            hideTitle={'sm'}
                            isLoading={investorInfoIsLoading}
                        >
                            {
                                investorInfoIsLoading
                                    ? <div className="text-center"><Spinner animation="border" variant="primary" /></div>
                                    : !investorInfoIsError && <AsideBalance />
                            }
                            {
                                investorInfoIsError && <p className="text-danger text-md pb-3">
                                    Ошибка загрузки данных профиля
                                </p>
                            }
                        </ProfileSection>
                    }

                    <ProfileSection
                        title={'Аналитика'}
                        link={analyticsError ? null : <CustomLink to={RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.ANALYTICS}
                                          variant={LinkVariant.arrow}>Подробнее</CustomLink>}
                        isLoading={(analyticsDataIsLoading || analyticsDataIsFetching) && !analyticsError}
                    >
                        {
                            analyticsDataIsLoading || analyticsDataIsFetching
                                ? <div className="text-center"><Spinner animation="border" variant="primary" /></div>
                                : !!analyticBoxes.length && (
                                <ul className={'row g-3 g-xl-4'}>
                                    {analyticBoxes.map(box => (
                                        <li key={box.title + box.amount} className="col-md-4 col-xl-6 col-xxl-4">
                                            <AnalyticsBox {...box} />
                                        </li>
                                    ))}
                                </ul>
                            )
                        }
                        {
                            analyticsError && (
                                <Table
                                    variant={'simple'}
                                    className={classNames(styles.table)}
                                    isLoading={(analyticsDataIsLoading || analyticsDataIsFetching) && !analyticsError}
                                    tdCells={[]}
                                />
                            )
                        }

                    </ProfileSection>

                    <ProfileSection
                        title={'История операций'}
                        link={<CustomLink to={RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.HISTORY}
                                          variant={LinkVariant.arrow}>Детализация</CustomLink>}
                        isLoading={operationsDataIsLoading}
                    >

                        <Table
                            variant={'simple'}
                            className={classNames(styles.table)}
                            isLoading={operationsDataIsLoading}
                            tdCells={operationsData?.items.map(({ title, income, outcome }: IOperation) => {

                                let amount = '';

                                if (income !== null) amount = income;
                                if (outcome !== null) amount = outcome;

                                return [
                                    title,
                                    <AmountOutput amount={amount} key={uuidv4()} />
                                ];
                            })}
                        />

                    </ProfileSection>


                </div>
            </div>
        </ProfileContainer>
    );
};


import React, {FC, useContext, useMemo} from 'react';
import ProfileProjectsList from '@components/investor/Views/Projects/ProjectsList';
import {useAppActions} from '@hooks/useAppActions';
import projectsApi from '@api/projectsApi';
import {projectsContext} from '@context/projectsContext';
import {ADAPTIVE_BREAKPOINTS, CUSTOM_EVENT__RESET_PROJECTS_PAGINATION_OFFSET} from '@common/constants';
import {Spinner} from 'react-bootstrap';
import useWindowSize from '@hooks/useWindowSize';
import ProfileLoader from '@components/common/ProfileLoader';
import {useAppSelector} from '@hooks/useAppSelector';
import {usePagination} from '@hooks/usePagination';
import {searchWithParams} from '@common/utils';
import {IListResponse} from '@common/types';
import useMounted from '@hooks/useMounted';
import ProfileProjectsHeader from '@components/investor/Views/Projects/Header/ProjectsHeader';
import {useCustomEvent} from '@hooks/customEvent';
import ProfileProjectsFilter from '@components/investor/Views/Projects/Filter/Filter';

const ProfileProjectsAll:FC = () => {

	const
		{setProjects} = useAppActions(),
		{
			orders,
			filters,
			setSort
		} = useContext(projectsContext),
		{projects} = useAppSelector(state => state.projects),
		{
			setDefaultPaginationOffset,
			resetPaginationOffset,
			increasePaginationOffset,
			paginationOffset,
			setPaginationCount,
			showUploadMoreButton
		} = usePagination(projects),
		[
			searchAllProjects,
			{
				isLoading: projectsAllIsLoading,
				isError: projectsAllIsError
			}
		] = projectsApi.useSearchMutation(),
		{width: ww} = useWindowSize(),
		searchParams = useMemo(() => {
			return searchWithParams({
				orders,
				filters,
				offset: paginationOffset
			})
		}, [orders,	filters, paginationOffset])
	;

	useMounted({
		onResolve(isMounted) {
			searchAllProjects(searchParams)
				.unwrap()
				.then((response: IListResponse) => {
					if (isMounted) {
						setProjects(response.items);
						setPaginationCount(response.count);
						setDefaultPaginationOffset();
					}
				});
		},
		onDestroy() {
			setProjects([]);
		},
		dependencies: [orders]
	});

	useCustomEvent({
		eventName: CUSTOM_EVENT__RESET_PROJECTS_PAGINATION_OFFSET,
		onSignal() {
			resetPaginationOffset()
		}
	});

	return (
		<>
			<ProfileProjectsHeader
				onSortChange={sortOption => {
					resetPaginationOffset();
					setSort(sortOption);
				}}
			/>
			{(projectsAllIsLoading && ww < ADAPTIVE_BREAKPOINTS.xl) && <Spinner className={'spinner--centered'} variant={'primary'} animation={'border'}/>}
			<ProfileProjectsList/>
			{(projects.length === 0 && !projectsAllIsLoading) && <p>Проекты не найдены</p>}
			<ProfileLoader
				isError={projectsAllIsError}
				isLoading={projectsAllIsLoading}
				errorMessage={'Ошибка при попытке загрузки списка проектов.'}
				onClick={
					() => {
						searchAllProjects(searchParams)
							.unwrap()
							.then((response: IListResponse) => {
								setProjects([...projects, ...response.items]);
								setPaginationCount(response.count);
								increasePaginationOffset();
							})
					}
				}
				needToShown={showUploadMoreButton}
			/>
			<ProfileProjectsFilter
				onFilterSubmit={({count}) => {
					setDefaultPaginationOffset();
					setPaginationCount(count);
				}}
				onFilterReset={({count}) => {
					setDefaultPaginationOffset();
					setPaginationCount(count);
				}}
			/>
		</>
	);
};

export default ProfileProjectsAll;

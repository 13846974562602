import React, {FC} from 'react';
import NumberFormat from "react-number-format";
import classNames from 'classnames';

interface IAmountOutputProps {
	amount: string,
	className?: string,
	isSimple?: boolean
	decimalScale?: number
}

const AmountOutput :FC<IAmountOutputProps> = (
	{
		amount,
		className,
		isSimple = false,
		decimalScale = 2
	}
) => {
	return (
		<span className={classNames('d-inline-block', className)}>
		{
			amount
				?	<NumberFormat
					title={parseInt(amount).toString()}

					value={isSimple ? Number(amount) : amount}
					displayType={'text'}
					thousandSeparator={isSimple ? '' : ' '}
					suffix={' '}
					fixedDecimalScale
					decimalScale={decimalScale}
				/>
				: 0 + ' '
		}
			<span> &#x20bd;</span>
		</span>
	);
};

export default AmountOutput;

import {useEffect} from 'react';

interface IUseMountedProps {
	onResolve: (isMounted: boolean) => void
	onDestroy?: () => void
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	dependencies?: any[]
	flag?: boolean
}

function useMounted(
	{
		onResolve,
		onDestroy,
		dependencies = [],
		flag= true,
	}: IUseMountedProps):void {

	useEffect(() => {
		let isMounted = true; // Can't perform a React state update on an unmounted component error

		if (isMounted && flag) onResolve(isMounted);

		return () => {
			isMounted = false;
			onDestroy && onDestroy();
		}
	}, dependencies);
}

export default useMounted;

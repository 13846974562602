import {RefObject, useEffect, useMemo} from 'react';
import {getRefElement} from '@common/utils';


interface IMutationObserverProps {
	target?: RefObject<Element> | Element | Node | null;
	options?: MutationObserverInit;
	callback?: MutationCallback;
}

export const useMutationObserver = (
	{
		target,
		options = {},
		callback
	}: IMutationObserverProps): void => {

	const observer = useMemo(
		() => new MutationObserver((mutationRecord, mutationObserver) => {
			callback?.(mutationRecord, mutationObserver);
		}),
		[callback]
	);

	useEffect(() => {
		const element = getRefElement(target);

		if (observer && element) {
			observer.observe(element, options);
			return () => observer.disconnect();
		}
	}, [target, observer, options]);
};

import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import ProfileDataControls from '@components/investor/Views/Data/Ui/ProfileDataControls';
import {personalDataContext} from '@context/personalDataContext';
import {PersonalDataViewsEnum} from '@components/investor/Views/Data/types';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {
	individualEntrepreneurFields,
	INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG,
	INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_FULL_NAME,
	INVESTOR_FIELD_NAME_ORG_INN,
	INVESTOR_FIELD_NAME_ORG_OGRN,
	INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
	INVESTOR_FIELD_NAME_ORGANIZATION_FORM
} from '@components/investor/Views/Data/constants';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {validateInnMinMax} from '@common/utils';
import {
	INN_MAX_LENGTH,
	INN_MIN_LENGTH,
} from '@common/constants';
import ProfileDataFields from '@components/investor/Views/Data/Ui/ProfileDataFields';
import {FormGroup} from '@components/ui';
import {useFormContext} from 'react-hook-form';
import daDataApi from '@api/daDataApi';
import {ISuggestion, ISuggestionSearchResult} from '@features/daData/types';
import useDebounce from '@hooks/useDebounce';
import {useInnErrors, useSearchValidation} from '@components/investor/Views/Data/hooks';


const ProfileDataPageIndividualEntrepreneurEdit:FC = () => {

	const {
		watch,
		register,
		setValue,
		clearErrors,
		formState
	} = useFormContext();

	const {errors} = formState;
	const
		{
			setCurrentPersonalDataView,
			inn,
			setInn,
			dataChangeRequestModal,
			dataChangeConfirmModal,
			dataChangeSuccessModal
		} = useContext(personalDataContext),
		innDebounced = useDebounce<string>(inn),
		[entitySearch, {isLoading: entityResultsIsLoading}] = daDataApi.useOrganisationSearchMutation(),
		[suggestionIsOpen, setSuggestionOpen] = useState<boolean>(false),
		[searchResult, setSearchResults] = useState<ISuggestion[]>([]),
		{
			setResult: setCurrentResult,
			result: currentResult
		} = useSearchValidation(),
		processedSearchResult = useMemo(() => {
			return searchResult.map((result):Partial<ISuggestionSearchResult> => ({
				name: result.value,
				hid: result.data.hid,
				address: result.data.address.value,
				inn: result.data.inn
			}))
		}, [searchResult]),
		orgInnError = useInnErrors(errors[INVESTOR_FIELD_NAME_ORG_INN])
	;


	const handleSuggestionChoice = (choice:Partial<ISuggestionSearchResult>) => {
			const suggestion = searchResult.find(result => result.data.hid === choice.hid);

			if (suggestion) {
				setCurrentResult(suggestion);
				const {opf, name, ogrn, ogrn_date, inn} = suggestion.data;
				clearErrors();
				setValue(INVESTOR_FIELD_NAME_ORGANIZATION_FORM, opf?.full || '');
				setValue(INVESTOR_FIELD_NAME_ORG_SHORT_NAME, name?.short_with_opf || '');
				setValue(INVESTOR_FIELD_NAME_ORG_FULL_NAME, name?.full_with_opf || '');
				setValue(INVESTOR_FIELD_NAME_ORG_OGRN, ogrn || '');
				setValue(INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION, ogrn_date || '');
				setValue(INVESTOR_FIELD_NAME_ORG_INN, inn || '');
			} else {
				setCurrentResult(null)
			}

			setSuggestionOpen(false);
		}
	;

	useEffect(() => {
		if (innDebounced) {
			entitySearch(innDebounced)
				.unwrap()
				.then((response: ISuggestion[]) => {
					setSearchResults(response);

					if (response.length >= 1 && !Boolean(currentResult))
						setSuggestionOpen(true);
				});
		}
	}, [innDebounced]);

	return (
		<div>
			<ProfileSection title={'Введите ИНН индивидуального предпринимателя'}>
				<div className="row">
					<div className="col-sm-6 col-md-4">
						<FormGroup
							id={INVESTOR_FIELD_NAME_ORG_INN}
							fieldType={FormGroupTypes.SUGGESTIONS}
							label={'ИНН'}
							value={watch(INVESTOR_FIELD_NAME_ORG_INN)}
							register={register}
							name={INVESTOR_FIELD_NAME_ORG_INN}
							onValueChange={({value}) => {
								(Boolean(currentResult) && value.length < INN_MAX_LENGTH) && setCurrentResult(null);
								setValue(INVESTOR_FIELD_NAME_ORG_INN, value);
								setInn(value)
							}}
							isLoading={entityResultsIsLoading}
							suggestionsSearchResult={processedSearchResult}
							suggestionIsOpen={suggestionIsOpen}
							handleSuggestionChoice={handleSuggestionChoice}
							errorMessage={orgInnError}
							registerOptions={
								{
									validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
								}
							}
						/>
					</div>
				</div>
			</ProfileSection>
			<ProfileSection title={'Данные индивидуального предпринимателя'}>
				<ProfileDataFields fields={individualEntrepreneurFields}/>
				<div className="row py-4">
					<div className="col-md-6">
						<FormGroup
							label={'Подтверждаю, что являюсь высшим единоличным руководителем организации. Права распоряжения расчетным счетом не ограничены'}
							id={INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG}
							fieldType={FormGroupTypes.CHECKBOX}
							checked={watch(INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG, event.target.checked)}
							register={register}
							errorMessage={errors[INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG]?.message}
							name={INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG}
						/>
					</div>
				</div>
			</ProfileSection>

			<ProfileDataControls
				onCancel={() => setCurrentPersonalDataView(PersonalDataViewsEnum.DEFAULT)}
				cancelText={'Назад'}
				submitText={'Сохранить'}
				submitIsDisabled={dataChangeRequestModal || dataChangeConfirmModal ||	dataChangeSuccessModal}
			/>
		</div>
	);
};

export default ProfileDataPageIndividualEntrepreneurEdit;

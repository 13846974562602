import React, {FC, useContext, useEffect} from 'react';
import {IProfilePage, ProfilePagesTitleEnum} from '@common/types';
import styles from './data.module.scss';
import classNames from 'classnames';
import ProfileContainer from '@components/profile/Views/Container/Container';
import {useAppSelector} from '@hooks/useAppSelector';
import {
	IInvestorProfile,
	InvestorLawTypeEnum,
	InvestorTypeEnum, IStatement,
	UserDataKeysEnum
} from '@features/user/types';
import {personalDataContext} from '@context/personalDataContext';
import {Spinner} from 'react-bootstrap';
import {useAppActions} from '@hooks/useAppActions';
import userApi from '@api/userApi';
import {PersonalDataChangeConfirm, PersonalDataChangeRequest} from '@components/investor/Views/Data/Modals';
import SuccessModal from '@components/common/Modal/SuccessModal';
import {
	INVESTOR_FIELD_NAME_LAW_TYPE,
	PROFILE_DATA_DEFAULT_VALUES
} from '@components/investor/Views/Data/constants';
import {FormProvider, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import {dateToApiFormat, passwordDivisionCodeWithDash} from '@common/utils';
import PersonalDataPhoneChangeRequest from '@components/investor/Views/Data/Modals/PersonalDataPhoneChangeRequest';
import PersonalDataPhoneChangeConfirm from '@components/investor/Views/Data/Modals/PersonalDataPhoneChangeConfirm';
import isEmpty from 'lodash.isempty';
import {IProfileDataFields, PersonalDataViewsEnum} from '@components/investor/Views/Data/types';
import ProfileDataPageDefault from '@components/investor/Views/Data/Pages/ProfileDataPageDefault';
import {InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import ProfileDataPageEntityEdit from '@components/investor/Views/Data/Pages/ProfileDataPageEntityEdit';
import ProfileDataPageIndividualEntrepreneurEdit
	from '@components/investor/Views/Data/Pages/ProfileDataPageIndividualEntrepreneurEdit';
import ProfileDataPageIndividualEntrepreneurDefault
	from '@components/investor/Views/Data/Pages/ProfileDataPageIndividualEntrepreneurDefault';
import ProfileDataPageEntityDefault from '@components/investor/Views/Data/Pages/ProfileDataPageEntityDefault';
import {useScrollToTop} from '@hooks/useScrollToTop';
import {getDefaultParams} from '@components/investor/Views/Data/utils';
import ProfileDataStatementDocument from '@components/investor/Views/Data/Ui/StatementDocument/StatementDocument';
import InvestorStatus from '@components/investor/InvestorStatus/InvestorStatus';
import {profileContext} from '@context/profileContext';
import useMounted from '@hooks/useMounted';

export const ProfileData: FC<IProfilePage> = () => {
	const formHookMethods = useForm<IProfileDataFields>({
		mode: 'onBlur',
		defaultValues: PROFILE_DATA_DEFAULT_VALUES
	});

	const
		{
			setDataChangeRequestModal,
			setRequestData,
			setDataChangeSuccessModal,
			dataChangeSuccessModal,
			setPassportFirstPage,
			passportFirstPage,
			setPassportSecondPage,
			passportSecondPage,
			phoneChangeSuccessModal,
			setPhoneChangeSuccessModal,
			currentPersonalDataView,
			setCurrentPersonalDataView,
			lawTypeDataIsEditedNow,
			setLawTypeDataIsEditedNow,
			setSubmitIsLoading,
		} = useContext(personalDataContext),
		{
			data: profileData,
			isLoading: userDataIsLoading
		} = userApi.useProfileQuery(),
		{setUserData} = useAppActions(),
		{
			investorType,
			lawType
		} = useAppSelector(state => state.user.investorProfile),
		history = useHistory(),
		[
			profileChangeRequest,
			{isLoading: profileChangeRequestIsLoading}
		] = userApi.useProfileChangeRequestMutation(),
		scrollToTop = useScrollToTop(),

		{
			data: joiningStatementData
		} = userApi.useJoiningStatementQuery(),
		[
			getQualificationStatement,
			{
				data: qualificationStatementData
			}
		] = userApi.useLazyQualificationStatementQuery(),
		{setVerifyErrorIsShown} = useContext(profileContext)
	;

	const
		onSubmit = formHookMethods.handleSubmit(data => {
			const {
				birthDate,
				orgDateRegistration,
				passportIssuedWhen,
				passportDivisionCode
			} = data;

			const requestData = {
				...data,
				passportFirstPage,
				passportSecondPage,
				birthDate: dateToApiFormat(birthDate),
				orgDateRegistration: dateToApiFormat(orgDateRegistration),
				passportIssuedWhen: dateToApiFormat(passportIssuedWhen),
				passportDivisionCode: passwordDivisionCodeWithDash(passportDivisionCode)
			} as IInvestorProfile;

			const runDataChangeRequest = ():void => {
				setRequestData(requestData);
				setDataChangeRequestModal(true);
			};

			const checkLawTypeAction = (lawTypeKey: InvestorLawTypeEnum, lawViewKey: PersonalDataViewsEnum) => {
				lawType === lawTypeKey
					? runDataChangeRequest()
					: setCurrentPersonalDataView(lawViewKey);
			};

			if (lawTypeDataIsEditedNow) {
				runDataChangeRequest()
			} else {
				switch (formHookMethods.watch(INVESTOR_FIELD_NAME_LAW_TYPE)) {
					case InvestorLawTypeEnum.INDIVIDUAL:
						switch (investorType) {
							case InvestorTypeEnum.NOT_CHOSEN:
								profileChangeRequest({
									body: requestData,
									qualification: true
								})
									.unwrap()
									.then(() => {
										history.push(RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.QUALIFICATION)
									});

								break;
							case InvestorTypeEnum.QUALIFIED:
							case InvestorTypeEnum.NOT_QUALIFIED:
								setRequestData(requestData);
								setDataChangeRequestModal(true);
								break;
						}
						break;
					case InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR:
						checkLawTypeAction(
							InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR,
							PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_EDIT
						);
						break;
					case InvestorLawTypeEnum.ENTITY:
						checkLawTypeAction(
							InvestorLawTypeEnum.ENTITY,
							PersonalDataViewsEnum.ENTITY_EDIT
						);
						break;
				}
			}

		}),
		defineDataPage = ():JSX.Element => {
			switch (currentPersonalDataView) {
				case PersonalDataViewsEnum.DEFAULT:
					return <ProfileDataPageDefault/>;
				case PersonalDataViewsEnum.ENTITY_EDIT:
					return <ProfileDataPageEntityEdit/>;
				case PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_EDIT:
					return <ProfileDataPageIndividualEntrepreneurEdit/>;
				case PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_DEFAULT:
					return <ProfileDataPageIndividualEntrepreneurDefault/>;
				case PersonalDataViewsEnum.ENTITY_DEFAULT:
					return <ProfileDataPageEntityDefault/>;
				default: return <></>
			}
		},
		documentIsValid = (doc: IStatement | undefined):boolean => (Boolean(doc) && !isEmpty(doc) && Boolean(doc?.url))
	;

	useMounted({
		onResolve() {
			getQualificationStatement()
		},
		dependencies: [investorType],
		flag: investorType === InvestorTypeEnum.QUALIFIED || investorType === InvestorTypeEnum.NOT_QUALIFIED
	});

	useEffect(() => {
		if (profileData && !isEmpty(profileData)) {
			formHookMethods.reset(getDefaultParams(profileData));

			setUserData({
				data: profileData,
				key: UserDataKeysEnum.IP
			});

			setPassportFirstPage(profileData.passportFirstPage);
			setPassportSecondPage(profileData.passportSecondPage);
		}

	}, [profileData]);

	useEffect(() => {
		setLawTypeDataIsEditedNow(
			currentPersonalDataView === PersonalDataViewsEnum.ENTITY_EDIT
			||
			currentPersonalDataView === PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_EDIT
		);

		scrollToTop();
		formHookMethods.clearErrors();
	}, [currentPersonalDataView]);

	useEffect(() => {
		switch (lawType) {
			case InvestorLawTypeEnum.INDIVIDUAL:
				setCurrentPersonalDataView(PersonalDataViewsEnum.DEFAULT);
				break;
			case InvestorLawTypeEnum.INDIVIDUAL_ENTREPRENEUR:
				setCurrentPersonalDataView(PersonalDataViewsEnum.INDIVIDUAL_ENTREPRENEUR_DEFAULT);
				break;
			case InvestorLawTypeEnum.ENTITY:
				setCurrentPersonalDataView(PersonalDataViewsEnum.ENTITY_DEFAULT);
				break;
		}
	}, [lawType]);

	useEffect(() => {
		setSubmitIsLoading(profileChangeRequestIsLoading)
	}, [profileChangeRequestIsLoading]);

	useEffect(() => {
		scrollToTop();

		const clearErrors = ():void => formHookMethods.clearErrors();

		window.addEventListener('focus', clearErrors);
		window.addEventListener('blur', clearErrors);
		return () => {
			setVerifyErrorIsShown(false);
			window.removeEventListener('focus', clearErrors);
			window.removeEventListener('blur', clearErrors);
		}
	}, []);

	return (
		<>
			{
				userDataIsLoading
					? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
					: <ProfileContainer title={ProfilePagesTitleEnum.DATA} className={classNames(styles.root)}>

						<div className="row pb-3 pb-sm-5">
							<div className="col-lt-11">
								<div className="row">
									<div className="col-md-6 col-lg-5 col-xl-6 col-hd-4">
										<InvestorStatus/>
									</div>
								</div>
							</div>
						</div>

						<FormProvider {...formHookMethods}>
							<form onSubmit={onSubmit} className={classNames(styles.content, 'mt-4 mt-md-5 position-relative')}>
								<div className="row pb-3 pb-sm-5">
									<div className="col-lt-11">
										{defineDataPage()}
									</div>
								</div>
							</form>
						</FormProvider>

						{
							(documentIsValid(joiningStatementData) && documentIsValid(qualificationStatementData)) && <div className="row pt-4 g-4 align-items-center">
								{
									documentIsValid(joiningStatementData) && <div className="col-auto">
										<ProfileDataStatementDocument document={joiningStatementData}/>
									</div>
								}
								{
									(documentIsValid(qualificationStatementData) && lawType === InvestorLawTypeEnum.INDIVIDUAL) && <div className="col-auto">
										<ProfileDataStatementDocument document={qualificationStatementData}/>
									</div>
								}
							</div>
						}
					</ProfileContainer>
			}
			<PersonalDataChangeRequest/>
			<PersonalDataChangeConfirm/>
			<SuccessModal
				message={'Данные успешно отправлены на проверку!'}
				visible={dataChangeSuccessModal}
				setVisible={setDataChangeSuccessModal}
			/>

			<PersonalDataPhoneChangeRequest/>
			<PersonalDataPhoneChangeConfirm/>
			<SuccessModal
				message={'Номер телефона успешно изменен!'}
				visible={phoneChangeSuccessModal}
				setVisible={setPhoneChangeSuccessModal}
			/>
		</>
	);
};


import React, {FC} from 'react';
import styles from './AnalyticsBox.module.scss'
import classNames from 'classnames';
import AmountOutput from '@components/common/AmountOutput';
import BoxIcon from '@components/common/Icon/BoxIcon';

export interface IProfileAnalyticsBoxProps {
	title: string,
	amount: string | undefined,
	Icon: FC,
	rgbColor: string,
	bgAlpha?: number
	iconAlpha?: number
}

const ProfileAnalyticsBox: FC<IProfileAnalyticsBoxProps> = (
	{
		title,
		amount,
		Icon,
		rgbColor,
		bgAlpha = 0.08,
		iconAlpha = 0.15
	}
) => {

	return (
		<div className={classNames(styles.main)} style={{backgroundColor: `rgba(${rgbColor}, ${bgAlpha})`}}>
			<div className="row gx-3 gx-xl-4">
				<div className="col-auto">
					<BoxIcon
						bgAlpha={iconAlpha}
						bgColor={rgbColor}
					>
						<Icon/>
					</BoxIcon>
				</div>
				<div className="col">
					<p className={classNames(styles.title, 'mb-1')}><small>{title}</small></p>
					{amount && <div className={styles.amount}>
						<AmountOutput decimalScale={0} amount={parseInt(amount).toString()}/>
					</div>}
				</div>
			</div>
		</div>
	);
};

export default ProfileAnalyticsBox;

import React, { FC } from 'react';
import { BorrowerAside } from '@components/borrower/Aside/Aside';
import { BorrowerContent } from '@components/borrower/Content/Content';
import BorrowerMain from '@components/borrower/Main/Main';
import { ProfileProvider } from '@context/profileContext';
import { BorrowerProvider } from '@context/borrowerContext';
import { WithdrawProvider } from '@context/withdrawContext';
import { QuestionsProvider } from '@context/questionsContext';

const Borrower: FC = () => {

    return (
        <ProfileProvider>
            <WithdrawProvider>
                <BorrowerProvider>
                    <QuestionsProvider>
                        <BorrowerMain>
                            <BorrowerAside />
                            <BorrowerContent />
                        </BorrowerMain>
                    </QuestionsProvider>
                </BorrowerProvider>
            </WithdrawProvider>
        </ProfileProvider>
    );
};

export default Borrower;

import { Transition } from 'react-transition-group';
import React, {FC, useRef} from 'react';

const duration = 300;

const defaultStyle = {
	transition: `opacity ${duration}ms ease-in-out`,
	opacity: 0,
};

const transitionStyles = {
	entering: { opacity: 1 , Visibility: 'visible' },
	entered:  { opacity: 1 , Visibility: 'visible' },
	exiting:  { opacity: 0, Visibility: 'hidden' },
	exited:  { opacity: 0, Visibility: 'hidden' },
	unmounted: { opacity: 0, Visibility: 'hidden' },
};

export const Fade:FC<{inProp: boolean}> =  ({inProp, ...props}) => {

	const trRef = useRef(null);

	return (
		<Transition in={inProp} timeout={duration} nodeRef={trRef}>
			{state => (
				<div style={{
					...defaultStyle,
					...transitionStyles[state]
				}}>
					{props.children}
				</div>
			)}
		</Transition>
	)
};

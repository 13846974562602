import React, {FC, useContext} from 'react';
import styles from '@components/investor/Views/Data/data.module.scss';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {personalDataContext} from '@context/personalDataContext';
import {TRANSITION_TIMEOUT} from '@common/constants';
import AnimateHeight from 'react-animate-height';
import {useFormContext} from 'react-hook-form';
import isEmpty from 'lodash.isempty';
import classNames from 'classnames';

interface IProfileDataControlsProps {
	submitText?: string
	cancelText?: string
	submitIsDisabled?: boolean
	submitIsLoading?: boolean
	onCancel?: () => void
}

const ProfileDataControls:FC<IProfileDataControlsProps> = (
	{
		submitText,
		cancelText,
		submitIsDisabled,
		onCancel
	}
) => {

	const {submitIsLoading, editMode} = useContext(personalDataContext);
	const {formState: {errors}} = useFormContext();

	return (
		<AnimateHeight
			duration={TRANSITION_TIMEOUT}
			height={editMode ? 'auto' : 0}
		>
		<div className={classNames(styles.buttonsRow, 'text-center text-sm-start')}>
			<div className='row pt-5 g-3 align-items-center justify-content-center justify-content-sm-start'>
				<div className="col-auto">
					<Button
						variant={ButtonVariant.outlined}
						as={ButtonTypesEnum.BUTTON}
						type={'button'}
						onClick={() => onCancel && onCancel()}
					>
						{cancelText || 'Отменить'}
					</Button>
				</div>
				<div className='col-auto'>
					<Button
						variant={ButtonVariant.contained}
						as={ButtonTypesEnum.BUTTON}
						type={'submit'}
						isLoading={submitIsLoading}
						disabled={submitIsDisabled}
					>
						{submitText || 'Отправить'}
					</Button>
				</div>
				<div className="col-sm-6 col-md-3">
					{!isEmpty(errors) && <small className='text-danger'>Не все поля заполнены</small>}
				</div>
			</div>
		</div>
		</AnimateHeight>
	);
};

export default ProfileDataControls;

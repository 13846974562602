import React, { FC, useContext, useEffect, useState } from "react";
import { Button, FormGroup, Paragraph } from "@components/ui";
import classNames from "classnames";
import dialogStyles from "../Dialog/dialog.module.scss";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { phoneToString } from "@common/utils";
import {
	AUTH_PASSWORD_MAX_LENGTH,
	REQUIRED_PHONE_LENGTH,
} from "@common/constants";
import { useAppSelector } from "@hooks/useAppSelector";
import { authContext } from "@context/authContext";
import { useAppActions } from "@hooks/useAppActions";
import authApi from "@api/authApi";
import { useHistory } from "react-router-dom";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { useRedirectToHomePage } from "@hooks/useRedirectToHomePage";

export const Login: FC = (props): JSX.Element => {
	const { phone, updatePhone } = useContext(authContext),
		{ isLoading } = useAppSelector((store) => store.auth),
		{ setToken, setAuthLoading, setAuthorized } = useAppActions(),
		[logIn] = authApi.useLogInMutation(),
		history = useHistory(),
		[password, setPassword] = useState<string>(""),
		[buttonDisabled, setButtonDisabled] = useState<boolean>(true),
		{ baseHomeRoute } = useRedirectToHomePage();
	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			setAuthLoading(true);

			logIn({
				login: phoneToString(phone),
				password,
			})
				.unwrap()
				.then((data) => {
					setToken(data.token);
					setAuthorized(true);

					baseHomeRoute && history.push(baseHomeRoute);
				})
				.catch((e) => {
					console.log(e);
				})
				.finally(() => setAuthLoading(false));
		},
		handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			const { value } = event.target;
			if (value.length <= AUTH_PASSWORD_MAX_LENGTH) setPassword(value);
		};
	useEffect(() => {
		setButtonDisabled(
			!(Boolean(phone) && Boolean(password)) ||
				phoneToString(phone).length !== REQUIRED_PHONE_LENGTH ||
				isLoading,
		);
	}, [phone, password, isLoading]);

	return (
		<form {...props} onSubmit={handleSubmit}>
			<Paragraph className={classNames(dialogStyles.title)}>Вход</Paragraph>
			<FormGroup
				id={"authLoginPhone"}
				fieldType={FormGroupTypes.PHONE}
				onChange={(
					event: React.ChangeEvent<HTMLInputElement>, // eslint-disable-next-line
				): void => // @ts-ignore
					updatePhone(event)
				}
				label={"Номер телефона"}
				value={phoneToString(phone)}
				placeholder={"8 (999) 999 99-99"}
			/>

			<FormGroup
				id={"authLoginPass"}
				fieldType={FormGroupTypes.PASSWORD}
				label={"Пароль"}
				value={password}
				onChange={handlePasswordChange}
				placeholder={"Введите пароль"}
			/>
			<div className='text-center pt-5 mt-2'>
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={"submit"}
					as={ButtonTypesEnum.BUTTON}>
					Далее
				</Button>
			</div>

			<div className='text-center text-dark pt-5'>
				<small
					className={classNames(
						dialogStyles.reSendTimerActive,
						"text-decoration-underline",
					)}
					onClick={() =>
						history.push(
							RouteNamesEnum.AUTH + AuthRouteNamesEnum.RECOVER_PASSWORD_REQUEST,
						)
					}>
					Забыли пароль?
				</small>
			</div>
		</form>
	);
};

export default Login;

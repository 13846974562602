import React, {FC, useEffect, useState} from 'react';
import borrowerApi from '@api/borrowerApi';
import {useParams} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {Fade} from '@components/common/Transitions/Fade';
import ProfileSection from '@components/profile/Section/ProfileSection';
import Table from '@components/common/Table/Table';
import moment from 'moment';
import {APP_DATE_FORMAT, CODES_RESPONSE_SUCCESS, EXTERNAL_LINK_DOCUMENT_INCASSO, MONTHS_WORDS} from '@common/constants';
import AmountOutput from '@components/common/AmountOutput';
import {convertRate, declensionOfNumber} from '@common/utils';
import {defineStatusContent} from '@components/borrower/Views/Cabinet/Cabinet';
import FormGroup from '@components/ui/FormGroup/FormGroup';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import BorrowerSmsConfirm, {BorrowerSmsConfirmViews} from '@components/borrower/reused/SmsConfirm/BorrowerSmsConfirm';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom'
import {BorrowerRouteNamesEnum, RouteNamesEnum} from '@app/router';
import ProfileContainer from '@components/profile/Views/Container/Container';

enum BorrowerBidDocuments {
	CESSION = 'cession',
	INVEST_OFFER = 'investOffer',
	CONDITIONS = 'conditions'
}

const BorrowerCabinetBid:FC = () => {

	const
		{id} = useParams<{id: string}>(),
		numBidId = Number(id),
		{
			data: bid = null,
			isLoading: bidIsLoading
		} = borrowerApi.useGetBidQuery(numBidId),
		[
			getOfferDocument,
			{
				data: offerDocument = null,
				isError: offerDocumentIsError,
				isLoading: offerDocumentIsLoading
			}
		] = borrowerApi.useInvestOfferDocumentMutation(),
		[
			bidsSignRequest,
			{
				isLoading: bidsSignRequestIsLoading
			}
		] = borrowerApi.useBidsSignRequestMutation(),
		[
			bidsSignConfirm,
			{
				isLoading: bidsSignConfirmIsLoading
			}
		] = borrowerApi.useBidsSignConfirmMutation(),
		{
			watch,
			setValue,
			register,
			formState: {errors, isValid},
			handleSubmit
		} = useForm({
			mode: 'all',
			defaultValues: {
				[BorrowerBidDocuments.CESSION]: false,
				[BorrowerBidDocuments.INVEST_OFFER]: false,
				[BorrowerBidDocuments.CONDITIONS]: false
			}
		})
	;

	const
		[smsMode, setSmsMode] = useState<BorrowerSmsConfirmViews>('request'),
		history = useHistory()
	;


	const
		onSubmit = handleSubmit(() => {
			bid?.bidId && bidsSignRequest(bid.bidId)
				.unwrap()
				.then(response => {
					if (response.code === CODES_RESPONSE_SUCCESS)	setSmsMode('confirm');
				})
				.catch(() => setSmsMode('request'))
		}),
		onBidConfirm = (code: string) => {

			bidsSignConfirm(code)
				.unwrap()
				.then(response => {
					if (response.code === CODES_RESPONSE_SUCCESS) {
						setSmsMode('request');
						history.push(RouteNamesEnum.BORROWER+BorrowerRouteNamesEnum.CABINET)
					}
				})
		}
	;

	useEffect(() => {
		getOfferDocument(Number(id))
	}, []);


	return (
		<ProfileContainer title={''}>
			{
				bidIsLoading
					? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
					: <>
						<ProfileSection title={`Заявка № ${bid?.bidId}`} isTableInside>
							{
								bid && <Table
									horizontallyScrolled
									thCells={[
										'Дата заявки',
										'Сумма займа',
										'Процентная ставка',
										'Срок',
										'Статус'
									]}
									tdCells={
										[[
											moment(bid.dateCreated).format(APP_DATE_FORMAT),
											<AmountOutput className='text-green' key={bid.bidId} amount={bid.goalAmount.toString()}/>,
											convertRate(bid.rate),
											`${bid.term} ${declensionOfNumber(bid.term, MONTHS_WORDS)}`,
											defineStatusContent(bid.status)
										]]
									}
								/>
							}
						</ProfileSection>

						<ProfileSection title={'Подпишите документы'}>
							{(offerDocumentIsLoading) && <Spinner className={'m-5'} animation='grow' variant='primary'/>}


							<Fade inProp={!offerDocumentIsLoading && !offerDocumentIsError}>
								<form onSubmit={onSubmit}>
									<FormGroup
										fieldType={FormGroupTypes.CHECKBOX}
										id={'BorrowerCabinetBidDocumentCession'}
										label={
											<a
												href={EXTERNAL_LINK_DOCUMENT_INCASSO}
												target='_blank'
												rel='noreferrer'
												className='text-default text-decoration-underline text-primary'
											>Договор инкассо-цессии</a>
										}
										checked={watch(BorrowerBidDocuments.CESSION)}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerBidDocuments.CESSION, event.target.checked)}
										register={register}
										errorMessage={errors[BorrowerBidDocuments.CESSION]?.message}
										name={BorrowerBidDocuments.CESSION}
										readonly={smsMode !== 'request'}
									/>
									{
										offerDocument && <FormGroup
											fieldType={FormGroupTypes.CHECKBOX}
											id={'BorrowerCabinetBidDocumentInvestOffer'}
											label={
												<a
													href={offerDocument.url}
													target='_blank'
													rel='noreferrer'
													className='text-default text-decoration-underline text-primary'
												>Инвестиционное предложение</a>
											}
											checked={watch(BorrowerBidDocuments.INVEST_OFFER)}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerBidDocuments.INVEST_OFFER, event.target.checked)}
											register={register}
											errorMessage={errors[BorrowerBidDocuments.INVEST_OFFER]?.message}
											name={BorrowerBidDocuments.INVEST_OFFER}
											readonly={smsMode !== 'request'}
										/>
									}
									<BorrowerSmsConfirm
										view={smsMode}
										onSubmitClick={onSubmit}
										note={'*Для оформления заявки мы отправим СМС-код'}
										disabled={!isValid || !bid?.bidId}
										isLoading={bidsSignRequestIsLoading || bidsSignConfirmIsLoading}
										onConfirm={onBidConfirm}
									/>
								</form>

							</Fade>

						</ProfileSection>
					</>
			}
		</ProfileContainer>
	);
};

export default BorrowerCabinetBid;

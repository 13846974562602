import React, {FC, useContext} from 'react';
import styles from '@components/investor/Views/Questions/questions.module.scss';
import classNames from 'classnames';
import {Button} from '@components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {ReactComponent as QuestionIcon} from '@icons/question-large.svg';
import {questionsContext} from '@context/questionsContext';

const ProfileQuestionsAsk:FC<{title: string, className?: string}> = ({title, className}) => {
	const {setAskQuestionModal} = useContext(questionsContext);

	return (
		<div className={classNames(styles.ask, className, 'mt-sm-4 mt-xxl-0')}>
			<div className="row align-items-center align-items-xl-start">
				<div className="col col-xl-7 order-2 order-xl-1">
					<div className="row g-4 align-items-center align-items-xl-start justify-content-between flex-xl-column flex-nowrap flex-sm-wrap">
						<div className="col-auto flex-shrink-1 flex-sm-shrink-0">
							<p className={classNames(styles.askTitle, 'pb-xl-3 mb-0 text-md')}>{title}</p>
						</div>
						<div className="col-auto">
							<Button
								variant={ButtonVariant.contained}
								as={ButtonTypesEnum.BUTTON}
								className={'text-sm'}
								onClick={() => setAskQuestionModal(true)}
							>Задать вопрос</Button>
						</div>
					</div>
				</div>
				<div className="col-auto col-xl-5 order-1 order-xl-2 d-none d-sm-block">
					<div className={styles.askIcon}><QuestionIcon/></div>
				</div>
			</div>
		</div>
	);
};

export default ProfileQuestionsAsk;

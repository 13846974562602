import React, {FC} from 'react';
import ProfileAnalyticsBox, {IProfileAnalyticsBoxProps} from '@components/profile/reused/AnalyticsBox/AnalyticsBox';
import {ReactComponent as WalletIcon} from '@icons/wallet-colored-blue.svg';
import {ReactComponent as FlameIcon} from '@icons/flame-colored.svg';
import {ReactComponent as CheckIcon} from '@icons/check-square-colored.svg';
import {ReactComponent as GraphIcon} from '@icons/graph-colored.svg';
import {RGB_COLORS} from '@common/constants';
import {useAppSelector} from '@hooks/useAppSelector';
import ProfileSection from '@components/profile/Section/ProfileSection';

const BorrowerCabinetIndicators:FC = () => {

	const {indicators} = useAppSelector(state => state.borrower.loans);

	const indicatorsBoxes: IProfileAnalyticsBoxProps[] = [
		{
			Icon: WalletIcon,
			title: 'Остаток суммы основного долга',
			amount: (indicators?.principal_debt || indicators?.earlyReturn || 0).toString(),
			rgbColor: RGB_COLORS.blue
		},
		{
			Icon: FlameIcon,
			title: 'Просроченная задолженность',
			amount: indicators?.overdueDebt.toString(),
			rgbColor: RGB_COLORS.red
		},
		{
			Icon: CheckIcon,
			title: 'Предодобренный займ',
			amount: indicators?.preApprovedLoan.toString(),
			rgbColor: RGB_COLORS.green,
			bgAlpha: 0.11
		},
		{
			Icon: GraphIcon,
			title: 'Текущая задолженность ',
			amount: indicators?.currentDebt.toString(),
			rgbColor: RGB_COLORS.grayCrayola,
			bgAlpha: 0.11
		}
	];

	return (
		<ProfileSection title={'Показатели'}>
			<ul className={'row g-3 g-sm-4'}>
				{indicatorsBoxes.map(box => (
					<li key={box.title+box.amount} className="col-sm-6 col-lt-4 col-hd-3">
						<ProfileAnalyticsBox {...box}/>
					</li>
				))}
			</ul>
		</ProfileSection>
	);
};

export default BorrowerCabinetIndicators;

import React, {FC, ReactElement, useEffect, useState} from 'react';
import {ProfilePagesTitleEnum} from '@common/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {ReactComponent as WorkIcon} from '@icons/work-colored.svg';
import {ReactComponent as SwapIcon} from '@icons/swap-colored.svg';
import {ReactComponent as WalletIcon} from '@icons/wallet-colored-green.svg';
import {ReactComponent as ArrowDownSquareIcon} from '@icons/arrow-down-square-colored.svg';
import {ReactComponent as TimeCircleIcon} from '@icons/time-circle-colored.svg';
import {ReactComponent as DangerCircleIcon} from '@icons/danger-circle-colored.svg';
import {ReactComponent as CheckSquareIcon} from '@icons/check-square-colored.svg';
import BoxIcon from '@components/common/Icon/BoxIcon';
import {
	ADAPTIVE_BREAKPOINTS,
//	APP_DATE_FORMAT,
	RGB_COLORS
} from '@common/constants';
import Table from '@app/components/common/Table/Table';
import { v4 as uuidv4 } from 'uuid';
import styles from './analytics.module.scss';
import classNames from 'classnames';
import analyticsApi from '@api/analyticsApi';
import AmountOutput from '@components/common/AmountOutput';
import {Spinner} from 'react-bootstrap';
import userApi from '@api/userApi';
import {IUserInvestmentItem} from '@features/user/types';
// import moment from 'moment';
import {ConditionalWrapper} from '@components/common/ConditionalWrapper';
import useWindowSize from '@hooks/useWindowSize';
import {Paragraph} from '@components/ui';

interface IAnalyticsData {
	key: string,
	value: number | undefined,
	icon: ReactElement
}

export const ProfileAnalytics: FC = () => {

	const {
		data: financesData,
		isLoading: financesDataIsLoading
	} = analyticsApi.useFinancesQuery();

	const {
		data: projectsData,
		isLoading: projectsDataIsLoading
	} = analyticsApi.useProjectsQuery();

	const [
		searchInvestments,
		{
			isLoading: investmentsDataIsLoading,
			isError: investmentsDataIsError
		}
	] = userApi.useInvestmentsMutation();

	const
		[investmentsItems, setInvestmentsItems] = useState<IUserInvestmentItem[]>([]),
		{width: ww} = useWindowSize()
	;

	const financesTemplateData: IAnalyticsData[] = [
		{
			key: 'Всего инвестировано',
			value: financesData?.investmentTotal,
			icon: <BoxIcon bgColor={RGB_COLORS.blue} size={'sm'}><WorkIcon/></BoxIcon>
		},
		{
			key: 'Сейчас в проектах',
			value: financesData?.investmentInProjects,
			icon: <BoxIcon bgColor={RGB_COLORS.yellow} size={'sm'}><SwapIcon/></BoxIcon>
		},
		{
			key: 'Получено прибыли',
			value: financesData?.profitTotal,
			icon: <BoxIcon bgColor={RGB_COLORS.green} size={'sm'}><WalletIcon/></BoxIcon>
		},
		{
			key: 'Выведено',
			value: financesData?.withdrawTotal,
			icon: <BoxIcon bgColor={RGB_COLORS.grayCrayola} bgAlpha={.25} size={'sm'}><ArrowDownSquareIcon/></BoxIcon>
		},
		{
			key: 'Ожидаемая прибыль',
			value: financesData?.profitExpected,
			icon: <BoxIcon bgColor={RGB_COLORS.turquoise} bgAlpha={.21} size={'sm'}><TimeCircleIcon/></BoxIcon>
		},
		{
			key: 'Неустойки',
			value: financesData?.penaltiesTotal,
			icon: <BoxIcon bgColor={RGB_COLORS.red} size={'sm'}><DangerCircleIcon/></BoxIcon>
		}
	];

	const projectsTemplateData: (IAnalyticsData & {bgColor: string, bgAlpha: number})[] = [
		{
			key: 'Всего проектов',
			value: projectsData?.projectsTotal,
			icon: <BoxIcon bgColor={RGB_COLORS.blue}><WorkIcon/></BoxIcon>,
			bgColor: RGB_COLORS.blue,
			bgAlpha: .08
		},
		{
			key: 'Завершенных проектов',
			value: projectsData?.projectsCompleted,
			icon: <BoxIcon bgColor={RGB_COLORS.green}><CheckSquareIcon/></BoxIcon>,
			bgColor: RGB_COLORS.green,
			bgAlpha: .1
		},
		{
			key: 'Ожидаемых проектов',
			value: projectsData?.projectsExpected,
			icon: <BoxIcon bgColor={RGB_COLORS.turquoise} bgAlpha={.21}><TimeCircleIcon/></BoxIcon>,
			bgColor: RGB_COLORS.green,
			bgAlpha: .07
		},
		{
			key: 'Проектов с просрочкой',
			value: projectsData?.projectsOverdue,
			icon: <BoxIcon bgColor={RGB_COLORS.red}><DangerCircleIcon/></BoxIcon>,
			bgColor: RGB_COLORS.red,
			bgAlpha: .07
		}
	];

	const financesTableCells = financesTemplateData.map(({icon, value, key}) => {
		return [
			<div key={uuidv4()} className={'d-inline-flex align-items-center'}>
				{icon}
				<span className={'ps-3'}>{key}</span>
			</div>,
			<AmountOutput key={uuidv4()}  amount={value ? value.toString(): ''}/>
		]
	});


	const loadAnalytics = ():void => {
		searchInvestments()
			.unwrap()
			.then(({items}) => {
				setInvestmentsItems(prevState => [...prevState, ...items]);
			})
		;
	};

	useEffect(() => {
		loadAnalytics()
	}, []);

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.ANALYTICS}>
			<ConditionalWrapper
				condition={ww >= ADAPTIVE_BREAKPOINTS.xl}
				wrapper={children => (
					<div className='row'>
						{
							children
						}
					</div>
				)}
			>
				<>
					<ConditionalWrapper
						condition={ww >= ADAPTIVE_BREAKPOINTS.hd}
						wrapper={children => (
							<div className='col-6'>
								{
									children
								}
							</div>
						)}
					>
						<ProfileSection title={'Финансы'}>
							<Table
								variant={'simple'}
								className={styles.table}
								isLoading={financesDataIsLoading}
								tdCells={financesTableCells}
							/>
						</ProfileSection>
					</ConditionalWrapper>

					<ConditionalWrapper
						condition={ww >= ADAPTIVE_BREAKPOINTS.hd}
						wrapper={children => (
							<div className='col-6'>
								{
									children
								}
							</div>
						)}
					>
						<ProfileSection
							title={'Проекты'}
						>
							{
								projectsDataIsLoading
									? <Spinner className={'d-block mx-auto'} animation='border' variant='primary'/>
									: <div className="row g-4">
										{
											projectsTemplateData.map(({value, icon, key, bgAlpha, bgColor}) => (
												<div key={uuidv4()} className="col-sm-6">
													<div
														style={{
															backgroundColor: `rgba(${bgColor}, ${bgAlpha})`
														}}
														className={classNames(styles.project, 'd-flex flex-nowrap align-items-center')}
													>
														{icon}
														<span className={'key px-4 mw-50'}>{key}</span>
														<strong className={'value ms-auto'}>{value}</strong>
													</div>

												</div>
											))
										}
									</div>
							}
						</ProfileSection>
					</ConditionalWrapper>

					<ConditionalWrapper
						condition={ww >= ADAPTIVE_BREAKPOINTS.xl}
						wrapper={children => (
							<div className='col-12'>
								{
									children
								}
							</div>
						)}
					>
						<ProfileSection
							className={'mt-5'}
							title={'Ожидаемые поступления'}
							isTableInside
							isLoading={investmentsDataIsLoading}
						>
							{
								investmentsDataIsError
									? <Paragraph className='text-danger'>Ошибка загрузки данных по платежам</Paragraph>
									: <Table
										horizontallyScrolled
										className={'mt-4'}
										thCells={[
											'№ проекта',
											'Сумма инвестиций',
											'Сумма возврата',
											'Уже получено',
											'Остаток',
											'Ближайшее поступление',
											'Дата'
										]}
										tdCells={

											investmentsItems.length > 0
												? investmentsItems.map((item: IUserInvestmentItem) => {
													const {
														investmentAmount,
														returnAmount,
														returnReceived,
														returnLeft,
														nextPayment,
														nextPaymentDate,
														project
													} = item;

												//	const momentNextPaymentDate = moment(nextPaymentDate);

													return [
														project.projectId.toString(),
														investmentAmount.toString(),
														returnAmount.toString(),
														returnReceived.toString(),
														returnLeft.toString(),
														nextPayment.toString(),
														// (
														// 	momentNextPaymentDate.isValid()
														// 		? momentNextPaymentDate.format(APP_DATE_FORMAT)
														// 		: ''
														// )
														nextPaymentDate.split('.').reverse().join('.')

													]
												})
												: []
										}
									/>
							}
						</ProfileSection>
					</ConditionalWrapper>
				</>

			</ConditionalWrapper>

		</ProfileContainer>
	)
};



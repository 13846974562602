import React, {FC, useContext, useEffect} from 'react';
import {profileContext} from '@context/profileContext';
import classNames from 'classnames';
import styles from './container.module.scss';

interface IContainerProps {
	title: string
}

const ProfileContainer:FC<IContainerProps & React.HTMLAttributes<HTMLDivElement>> = ({title, className, ...props}) => {
	const {setViewTitle} = useContext(profileContext);

	useEffect(() => {
		setViewTitle(title);

		return () => setViewTitle('');
	}, [title]);

	return (
		<div className={classNames(styles.holder, className)} {...props}>
			{props.children}
		</div>
	);
};

export default ProfileContainer;

import React, {FC} from 'react';
import ProfileQualificationFileUpload from '@components/investor/Views/Qualification/Components/FileUpload';
import {IAttachment} from '@common/types';
import ProfileQualificationDocument from '@components/investor/Views/Qualification/Components/Document';
import styles from '@components/investor/Views/Qualification/qualification.module.scss';
import classNames from 'classnames';

interface IProfileQualificationUploadedDocumentsProps {
	isLoading?: boolean
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
	onDelete?: (uid: string) => void
	documents?: IAttachment[],
	disabled?: boolean,
	errorMessage?: string
}

const ProfileQualificationUploadedDocuments:FC<IProfileQualificationUploadedDocumentsProps> = (
	{
		isLoading = false,
		onChange,
		onDelete,
		documents= [],
		disabled,
		errorMessage = ''
	}
) => {


	return (
		<div className={classNames(styles.uploadedDocuments, (errorMessage && styles.uploadedDocumentsIsInvalid))}>
			<div className="py-4">
				<ProfileQualificationFileUpload
					onChange={onChange}
					isLoading={isLoading}
					disabled={disabled}
				/>
			</div>
			{
				documents.length > 0 &&
				<ul className="row g-4">
					{
						documents.map((document) => (
							<li className="col-auto" key={document.uid}>
								<ProfileQualificationDocument
									attachment={document}
									onDelete={uid => {
										onDelete && onDelete(uid);
									}}
								/>
							</li>
						))
					}
				</ul>
			}
			{errorMessage && <small className={styles.uploadedDocumentsError}>{errorMessage}</small>}
		</div>
	);
};

export default ProfileQualificationUploadedDocuments;

import React, {FC} from 'react';
import BoxIcon, {IBoxIconProps} from '@components/common/Icon/BoxIcon';
import styles from './investedBox.module.scss';
import classNames from 'classnames';
import AmountOutput from '@components/common/AmountOutput';
import {IProject} from '@features/projects/types';
import InfoTooltip from '@components/common/InfoTooltip/InfoTooltip';

interface IInvestedBoxProps {
	amount: IProject['investAmount'],
	icon: FC,
	iconSize: IBoxIconProps['size'],
	iconBg: string,
	label: string,
	className?: string,
	bgColor?: 'white' | 'pear' | 'turquoise'
	tooltipInfo?: string
}

const InvestedBox:FC<IInvestedBoxProps> = (
	{
		amount,
		icon,
		iconSize,
		iconBg,
		label,
		className,
		bgColor = 'white',
		tooltipInfo
	}
) => {

	const Icon = icon;

	return (
		<div className={classNames(styles.root, styles[bgColor], 'd-flex align-items-center', className)}>
			{tooltipInfo && <InfoTooltip className={styles.info} content={tooltipInfo}/>}
			<div className='col-auto'>
				<BoxIcon className='flex-shrink-0' bgColor={iconBg} size={iconSize}><Icon/></BoxIcon>
			</div>
			<div className='col ps-3'>
				<div className={'row gx-3 align-items-center'}>
					<div className='col-auto'>
						<p className='text-sm mb-0'>{label}</p>
					</div>
					<div className='col-auto'>
						<b className='text-md d-flex'>
							<AmountOutput
								isSimple
								className={classNames(styles.output, 'me-1 text-overflow')}
								amount={amount.toString()}
							/>
						</b>
					</div>
				</div>
			</div>
		</div>
	);
};


export default InvestedBox;

import React, {FC} from 'react';
import styles from '@components/investor/Views/History/history.module.scss';
import {isNum} from '@common/utils';
import classNames from 'classnames';

export type countAllType = 'all';

export type MonthCountType = number | countAllType;

export interface IMonthRange {
	count: MonthCountType,
	title: string
}

const monthRanges: IMonthRange[] = [
	{
		count: 1,
		title: '1 месяц'
	},
	{
		count: 2,
		title: '2 месяца'
	},
	{
		count: 3,
		title: '3 месяца'
	},
	{
		count: 6,
		title: '6 месяцев'
	},
	{
		count: 'all',
		title: 'Все время'
	}
];

const InvestorHistoryMonths:FC<{
	name: string
	currentValue: MonthCountType
	onChange: (value: MonthCountType) => void
	className?: string
}> = (
	{
		name,
		currentValue,
		onChange,
		className
	}
) => {
	const
		handleMonthRangeChange = ({value}: HTMLInputElement) => {
			const	nValue = Number(value);
			onChange(isNum(value) ? nValue : 'all');
		}
	;

	return (
		<div className={classNames(styles.months, className)}>
			{
				monthRanges.length > 0 && <div className={classNames('text-dark row g-2 g-md-3')}>
					{
						monthRanges.map(({title, count}) => {
							const id = 'investorHistoryMonthRange-'+name+'--'+count;
							return (
								<div key={count} className={classNames('col-auto')}>
									<label htmlFor={id} className={styles.radio}>
										<input
											id={id}
											type='radio'
											value={count}
											name={'investorHistoryMonthRange--'+name}
											className={'visually-hidden'}
											checked={currentValue === count}
											onChange={({target}) => {
												handleMonthRangeChange(target);
											}}
										/>
										<span className={styles.month}>{title}</span>
									</label>
								</div>
							)
						})
					}
				</div>
			}
		</div>

	);
};

export default InvestorHistoryMonths;

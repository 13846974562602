import React, {DetailedHTMLProps, LinkHTMLAttributes} from 'react';

export enum LinkIconsVariant {
	vk = 'vk',
	instagram = 'instagram',
	telegram = 'telegram',
	plus = 'plus',
}

export enum LinkVariant {
	icon = 'icon',
	simple = 'simple',
	arrow = 'arrow'
}

export interface LinkProps extends DetailedHTMLProps<LinkHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
	variant: LinkVariant
	icon?: LinkIconsVariant
	target?: string
	href?: string
	to?: string
	onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

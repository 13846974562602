import React, {FC} from 'react';
import {useAppSelector} from '@hooks/useAppSelector';
import {
	CheckPhone,
	Login,
	RegistrationRequest,
	RegistrationConfirm,
	RecoverPasswordRequest,
	RecoverPasswordConfirm,
	ChangePassword,
	RegistrationPassword
} from '@components/auth/Steps';
import styles from '../Dialog/dialog.module.scss';
import classNames from 'classnames';
import {Spinner} from 'react-bootstrap';
import {Redirect, Route, Switch, useRouteMatch} from 'react-router-dom';
import {AuthRouteNamesEnum, IRoute, RouteNamesEnum} from '@router/index';

const AuthDialog: FC = () => {
	const {path: dialogPath} = useRouteMatch();

	const
		{isLoading} = useAppSelector(store => store.auth)
	;

	const AuthRoutes: IRoute[] = [
		{
			path: dialogPath+AuthRouteNamesEnum.CHECK_PHONE,
			Component: CheckPhone
		},
		{
			path: dialogPath+AuthRouteNamesEnum.LOGIN,
			Component: Login
		},
		{
			path: dialogPath+AuthRouteNamesEnum.REGISTRATION_REQUEST,
			Component: RegistrationRequest
		},
		{
			path: dialogPath+AuthRouteNamesEnum.REGISTRATION_CONFIRM,
			Component: RegistrationConfirm
		},
		{
			path: dialogPath+AuthRouteNamesEnum.REGISTRATION_PASSWORD,
			Component: RegistrationPassword
		},
		{
			path: dialogPath+AuthRouteNamesEnum.RECOVER_PASSWORD_REQUEST,
			Component: RecoverPasswordRequest
		},
		{
			path: dialogPath+AuthRouteNamesEnum.RECOVER_PASSWORD_CONFIRM,
			Component: RecoverPasswordConfirm
		},
		{
			path: dialogPath+AuthRouteNamesEnum.CHANGE_PASSWORD,
			Component: ChangePassword
		}
	];

	return (
		<div className={styles.wrap}>
			<Switch>
				{AuthRoutes.map(({ path, Component: AuthComponent }) => (
					<Route key={path} exact path={path}>
						<div className={classNames(styles.root)}>
							{
								isLoading && <Spinner
									className={styles.spinner}
									animation='border'
									variant='primary'
									size='sm'
								/>
							}
							<div className={styles.formHolder}>
								<AuthComponent/>
							</div>
						</div>
					</Route>
				))}
				<Redirect to={RouteNamesEnum.AUTH+AuthRouteNamesEnum.CHECK_PHONE}/>
			</Switch>
		</div>
	)
};

export default AuthDialog;

import React, {FC, HTMLAttributes} from 'react';
import classNames from 'classnames';
import styles from '@components/investor/ProjectDetail/projectDetail.module.scss';
import {IProjectDetailInfoBlock} from '@components/investor/ProjectDetail/ProjectDetails.props';

const ProjectDetailBox:FC<IProjectDetailInfoBlock & HTMLAttributes<HTMLDivElement> & {isPhone?: boolean}> = (
	{
		Icon,
		title,
		data,
		isPhone = false,
		...props}
	) => {

	return (
		<div className={classNames(styles.box, 'h-100', props.className)}>
			<p className={classNames(styles.boxTitle, 'd-flex align-items-center mb-3 mb-sm-4')}>
				<span className={'flex-shrink-0 me-3'}><Icon/></span>
				<span className={'pt-sm-1'}>{title}</span>
			</p>

			<p className={'mb-0 pe-4'}>
				{
					isPhone
						? <a href={`tel:${title}`}>{data}</a>
						: <span>{data}</span>
				}
			</p>

		</div>
	);
};

export default ProjectDetailBox;

import React, {FC, useEffect, useReducer, useState} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import questionsApi, {IQuestionsUserSearchParams} from '@api/questionsApi';
import {searchFiltersByFields, searchWithParams} from '@common/utils';
import profileSectionStyles from '@components/profile/Section/profileSection.module.scss';
import ProfileQuestionsAsk from '@components/investor/Views/Questions/components/ask/QuestionsAsk';
import {IFilter, IQuestionThemesEnum, IUserQuestion} from '@common/types';
import {ReactComponent as CheckIcon} from '@icons/check-square-default.svg';
import {ReactComponent as TimeIcon} from '@icons/time.svg';
import ProfileQuestionsSearch from '@components/investor/Views/Questions/components/search/QuestionsSearch';
import useDebounce from '@hooks/useDebounce';
import ProfileQuestionsControlBox from '@components/investor/Views/Questions/components/controlBox/QuestionsControlBox';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import styles from '../../questions.module.scss';
import classNames from 'classnames';
import ProfileQuestionsMyThemeOutput
	from '@components/investor/Views/Questions/tabs/QuestionsMy/QuestionsMyThemeOutput';

enum QuestionsMyCategoriesEnum {
	PUBLISHED = 'published',
	AWAITING = 'awaiting'
}

interface ICategoryControl {
	theme: IQuestionThemesEnum,
	id: string,
	icon: JSX.Element,
	title: string,
	value: QuestionsMyCategoriesEnum,
	adaptiveQuestions: IUserQuestion[]
}

type CategoriesState = {
	controls: ICategoryControl[]
}

enum CategoriesActionsTypes {
	setControls = 'SET_CONTROLS'
}

type CategoryControlsAction = {
	type: CategoriesActionsTypes,
	payload: ICategoryControl[]
}

const categoriesState: CategoriesState = {
	controls: [
		{
			theme: IQuestionThemesEnum.SUCCESS,
			id: `profileQuestionsMyControlBox--`+QuestionsMyCategoriesEnum.PUBLISHED.toUpperCase(),
			icon: <CheckIcon/>,
			title: 'Опубликованные',
			value: QuestionsMyCategoriesEnum.PUBLISHED,
			adaptiveQuestions: []
		},
		{
			theme: IQuestionThemesEnum.WARNING,
			id: `profileQuestionsMyControlBox--`+QuestionsMyCategoriesEnum.AWAITING.toUpperCase(),
			icon: <TimeIcon/>,
			title: 'Ожидают ответа',
			value: QuestionsMyCategoriesEnum.AWAITING,
			adaptiveQuestions: []
		}
	]
};

function categoryControlsReducer(state: CategoriesState, action: CategoryControlsAction) {
	const {type, payload} = action;

	if (type === CategoriesActionsTypes.setControls) {
		return {
			...state,
			controls: payload
		};
	}

	return state;
}

export const userQuestionsSearchFields: string[] = ['answer', 'question'];

const ProfileQuestionsMy:FC = () => {

	const
		[
			getUserQuestions,
			{
				data: userQuestionsData = [],
				isLoading: userQuestionsIsLoading
			}
		] = questionsApi.useUserSearchMutation(),
		[searchQuery, setSearchQuery] = useState<string>(''),
		debouncedSearchQuery = useDebounce(searchQuery),
		{width: ww} = useWindowSize(),
		[categoryControlsState, categoryControlsDispatch] = useReducer(categoryControlsReducer, categoriesState),
		defaultCategory = categoryControlsState.controls[0],
		[currentCategory, setCurrentCategory] = useState<ICategoryControl | null>(defaultCategory),
		[adaptiveSearchMode, setAdaptiveSearchMode] = useState<boolean>(false)
	;

	const getQuestions = (withIsAnswered?: boolean):void => {

		const settings = {
			filter: {} as IFilter
		} as IQuestionsUserSearchParams;

		if (debouncedSearchQuery.length > 0) {
			settings.filter = searchWithParams({
				limit: -1,
				filters: searchFiltersByFields(userQuestionsSearchFields, debouncedSearchQuery)
			})
		}

		if (withIsAnswered) {
			settings.isAnswered = currentCategory?.value === defaultCategory.value
		}

		getUserQuestions(settings)
			.unwrap()
			.then(questions => {

				if (ww < ADAPTIVE_BREAKPOINTS.xl) {

					if (questions.length > 0) {
						const dividedByAnswered = questions.reduce((acc: [IUserQuestion[], IUserQuestion[]], item:IUserQuestion) => {
							acc[Boolean(item.answer) ? 1 : 0].push(item);
							return acc
						}, [[], []]); // isAnswered false true

						categoryControlsDispatch({
							type: CategoriesActionsTypes.setControls,
							payload: categoryControlsState.controls.map(c => {
								c.adaptiveQuestions = dividedByAnswered[c.value === QuestionsMyCategoriesEnum.PUBLISHED ? 1 : 0];
								return c
							})
						})
					}
				} else {
					categoryControlsDispatch({
						type: CategoriesActionsTypes.setControls,
						payload: categoryControlsState.controls.map(c => {
							c.adaptiveQuestions = [];
							return c
						})
					})
				}

			});
	};


	useEffect(() => {
		getQuestions(ww >= ADAPTIVE_BREAKPOINTS.xl);
		setAdaptiveSearchMode(debouncedSearchQuery.length > 0);
	}, [currentCategory, debouncedSearchQuery]);


	useEffect(() => {

		let cleanup = false;

		if (!cleanup) {
			if (ww < ADAPTIVE_BREAKPOINTS.xl) {
				getQuestions();
			} else {
				getQuestions(true);
				setAdaptiveSearchMode(false)
			}

			if (ww < ADAPTIVE_BREAKPOINTS.sm) {
				setSearchQuery('')
			}
		}

		return () => {
			cleanup = true
		}
	}, [ww]);

	return (
		<div>
			<ProfileSection
				title={'Мои вопросы'}
				nearbyTitleContent={
					<ProfileQuestionsSearch
						value={searchQuery}
						onSearch={query => setSearchQuery(query)}
						onReset={() => {
							setSearchQuery('');
							setCurrentCategory(defaultCategory);
						}}
					/>
				}
			>

				{

					(adaptiveSearchMode && ww < ADAPTIVE_BREAKPOINTS.xl)
						? <ProfileQuestionsMyThemeOutput
							userQuestions={userQuestionsData}
							isLoading={userQuestionsIsLoading}
						/>
						: <div className="row g-4">
							{
								categoryControlsState.controls.map((categoryControl) => {
									const {id, ...boxParams} = categoryControl;

									return (
										<div className='col-xl-6 col-xxl-4 col-lt-3' key={id}>
											<ProfileQuestionsControlBox
												questions={categoryControl.adaptiveQuestions}
												onRadioChange={() => {
													setCurrentCategory(categoryControl);
												}}
												checked={currentCategory?.id === id}
												{...boxParams}
											/>
										</div>
									)
								})
							}
						</div>
				}

			</ProfileSection>

			{
				!(adaptiveSearchMode && ww < ADAPTIVE_BREAKPOINTS.xl) && <ProfileSection
					hideTitle={'xl'}
					title={currentCategory?.title || 'Результаты поиска'}
					className={classNames(profileSectionStyles.item, styles.searchSection)}
					limitMinHeight={false}
				>
					<div className="row g-xl-4">
						<div className="col-xxl-6 d-none d-xl-block">

							<ProfileQuestionsMyThemeOutput
								userQuestions={userQuestionsData}
								isLoading={userQuestionsIsLoading}
							/>

						</div>
						<div className="col-xxl-6">
							<ProfileQuestionsAsk className='' title={'Задать еще вопрос'}/>
						</div>
					</div>
				</ProfileSection>
			}


		</div>
	);
};

export default ProfileQuestionsMy;

import React, { FC } from "react";
import { ProfileContent } from "@components/profile/Content/Content";
import { BorrowerRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { BorrowerAccount } from "@components/borrower/Views";
import BorrowerCabinet from "@components/borrower/Views/Cabinet/Cabinet";
import BorrowerLoan from "@components/borrower/Views/Cabinet/Loans/Loan/BorrowerLoan";
import BorrowerCabinetBid from "@components/borrower/Views/Cabinet/Bids/Bid/BorrowerCabinetBid";
import { ADAPTIVE_BREAKPOINTS } from "@common/constants";
import ProfileMobileMenu from "@components/profile/MobileMenu/ProfileMobileMenu";

import useWindowSize from "@hooks/useWindowSize";
import { BORROWER_NAVIGATION } from "@components/borrower/Aside/Aside";
import { ProfileAbout, ProfileQuestions } from "@components/investor/Views";

const BorrowerRoutes = [
	{
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ACCOUNT,
		Component: BorrowerAccount,
	},
	{
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.CABINET,
		Component: BorrowerCabinet,
		exact: true,
	},
	{
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.LOAN,
		Component: BorrowerLoan,
	},
	{
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.BID,
		Component: BorrowerCabinetBid,
	},
	{
		path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.QUESTIONS,
		Component: ProfileQuestions,
	},
	// {
	//     path: RouteNamesEnum.BORROWER + BorrowerRouteNamesEnum.ABOUT,
	//     Component: ProfileAbout
	// },
];

export const BorrowerContent: FC = () => {
	const { width: ww } = useWindowSize();

	return (
		<ProfileContent routes={BorrowerRoutes}>
			{ww < ADAPTIVE_BREAKPOINTS.xl && (
				<ProfileMobileMenu navItems={BORROWER_NAVIGATION} />
			)}
		</ProfileContent>
	);
};

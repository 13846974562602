import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IParameterizedDocumentsPayload, IProject, IProjectDetails, IProjectsState} from '@features/projects/types';

const initialState: IProjectsState = {
	projects: [],
	detailedProjects: {},
	parameterizedDocuments: {}
};

export const projectsSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		setProjects: (state, {payload}: PayloadAction<IProject[]>) => {
			state.projects = [...payload];
		},
		setProjectDetails: (state, {payload}: PayloadAction<{key: IProject['projectId'], value: IProjectDetails}>) => {
			const {key, value} = payload;

			if (!state.detailedProjects.hasOwnProperty(key)) state.detailedProjects[key] = {} as IProjectDetails;
			state.detailedProjects[key] = value;

		},
		addParameterizedDocument: (state, {payload}: PayloadAction<IParameterizedDocumentsPayload>) => {
			const {id, name, document} = payload;

			if (!state.parameterizedDocuments.hasOwnProperty(id)) state.parameterizedDocuments[id] = {};
			state.parameterizedDocuments[id][name] = document;
		},
		resetParameterizedDocuments: (state) => {
			state.parameterizedDocuments = {}
		}
	}
});


export default projectsSlice.reducer;

import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { investContext } from "@context/investContext";
import AmountInput from "@components/common/AmountInput/AmountInput";
import {
	CODES_RESPONSE_SUCCESS,
	INVEST_AMOUNT_MAX,
	INVEST_AMOUNT_MIN,
	PHONE_NUMBER_FORMAT,
} from "@common/constants";
import NumberFormat from "react-number-format";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import { Button, FormGroup } from "@components/ui";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { useAppSelector } from "@hooks/useAppSelector";
import projectsApi from "@api/projectsApi";
import InfoTooltip from "@components/common/InfoTooltip/InfoTooltip";
import { ConditionalWrapper } from "@components/common/ConditionalWrapper";
import styles from "@components/investor/WithdrawRequestModal/withdrawModal.module.scss";
import { optimizePhone } from "@common/utils";
import operationsApi from "@api/operationsApi";
import { InvestorLawTypeEnum, InvestorTypeEnum } from "@features/user/types";

const InvestRequestModal: FC = () => {
	const {
			requestModal,
			setRequestModal,
			setRequestConfirmModal,
			amount,
			updateAmount,
			setCurrentProjectId,
			currentProjectId,
			limitInvestAccepted,
			setLimitInvestAccepted,
			highRiskAccepted,
			setHighRiskAccepted,
			acceptInvestmentProposal,
			setAcceptInvestmentProposal,
		} = useContext(investContext),
		{ setInvestmentPlace } = useContext(investContext),
		[makeInvestRequest, { isLoading: investRequestIsLoading }] =
			operationsApi.useInvestRequestMutation(),
		[amountMax, setAmountMax] = useState<number>(INVEST_AMOUNT_MAX),
		[amountMin, setAmountMin] = useState<number>(INVEST_AMOUNT_MIN),
		{ balance, phone, lawType, investorType } = useAppSelector(
			(state) => state.user.investorInfo,
		),
		[getProjectAccept, { data: projectAcceptDocument = null }] =
			projectsApi.useGetAcceptMutation(),
		[getProjectParams] = projectsApi.useGetProjectParamsMutation(),
		[submitDisabled, setSubmitDisabled] = useState<boolean>(false),
		limitInvestAcceptNeeded = useMemo(() => {
			return (
				lawType === InvestorLawTypeEnum.INDIVIDUAL &&
				investorType == InvestorTypeEnum.NOT_QUALIFIED
			);
		}, [lawType, investorType]);
	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		currentProjectId &&
			makeInvestRequest({
				projectId: currentProjectId,
				amount,
				acceptFlag: acceptInvestmentProposal,
				highRiskFlag: highRiskAccepted,
				limitInvestFlag: limitInvestAccepted,
			})
				.unwrap()
				.then((response) => {
					if (response.code === CODES_RESPONSE_SUCCESS) {
						setRequestModal(false);
						setRequestConfirmModal(true);
					}
				});
	};
	const changeAmount = ({ floatValue }: { floatValue: number | undefined }) => {
		if (floatValue && Boolean(floatValue.toString())) {
			updateAmount(amountMax < floatValue ? amountMax : floatValue);
		}
	};

	useEffect(() => {
		if (balance !== null) {
			const pBalance = parseFloat(balance);
			if (pBalance < amountMax) setAmountMax(pBalance);
		}
	}, [balance]);

	useEffect(() => {
		setSubmitDisabled(
			(limitInvestAcceptNeeded ? !limitInvestAccepted : false) ||
				!highRiskAccepted ||
				!acceptInvestmentProposal,
		);
	}, [limitInvestAccepted, highRiskAccepted, acceptInvestmentProposal]);

	useEffect(() => {
		if (requestModal) {
			if (currentProjectId)
				getProjectParams(currentProjectId)
					.unwrap()
					.then((res) => {
						const { amountToInvestMin, amountToInvestMax } = res;

						if (amountToInvestMin && parseFloat(amountToInvestMin)) {
							setAmountMin(parseFloat(amountToInvestMin));
						}
						if (amountToInvestMax && parseFloat(amountToInvestMax)) {
							setAmountMax(parseFloat(amountToInvestMax));
						}
					});
		} else {
			setAmountMin(INVEST_AMOUNT_MIN);
			setAmountMax(INVEST_AMOUNT_MAX);
		}
	}, [requestModal]);

	return (
		<Modal
			show={requestModal}
			onHide={() => {
				setRequestModal(false);
				setCurrentProjectId(null);
				setInvestmentPlace(null);

				setLimitInvestAccepted(false);
				setHighRiskAccepted(false);
				setAcceptInvestmentProposal(false);
			}}
			centered
			onEnter={() => {
				currentProjectId &&
					getProjectAccept({ id: currentProjectId, fromModal: true });
			}}>
			<Modal.Header closeButton />
			<Modal.Body>
				<form onSubmit={handleFormSubmit}>
					<AmountInput
						sliderMinVal={amountMin}
						sliderMaxVal={amountMax}
						textOutput={amount.toString()}
						amount={amount}
						title={"Введите сумму, которую хотите инвестировать"}
						sliderOnChange={updateAmount}
						textOnValueChange={changeAmount}
						textOnBlur={() => amount < amountMin && updateAmount(amountMin)}
						subTitle={
							<small>
								<span>Минимальная сумма инвестиций </span>
								<NumberFormat
									displayType={"text"}
									thousandSeparator={" "}
									value={amountMin}
									suffix={" ₽"}
								/>
							</small>
						}
					/>

					<div className='text-dark pt-5'>
						<div className='row gy-4'>
							{limitInvestAcceptNeeded && (
								<div className='col-12'>
									<FormGroup
										className={"pb-0"}
										id={"limitInvestAccepted"}
										fieldType={FormGroupTypes.CHECKBOX}
										label={
											<>
												Заверяю о соблюдении лимита инвестирования
												<InfoTooltip
													className='ms-2'
													content={
														"Сумма моих инвестиций, сделанных в течение текущего календарного года на всех инвестиционных платформах, включая данное инвестирование, составит не более 600 000 рублей."
													}
												/>
											</>
										}
										checked={limitInvestAccepted}
										onChange={(event) =>
											setLimitInvestAccepted(event.target.checked)
										}
									/>
								</div>
							)}

							<div className='col-12'>
								<FormGroup
									className={"pb-0"}
									id={"highRiskAccepted"}
									fieldType={FormGroupTypes.CHECKBOX}
									label={
										<>
											Понимаю, что инвестирование в займы является
											высокорискованным
											<InfoTooltip
												className='ms-2'
												content={
													"Инвестирование в займы позволяет инвесторам получать высокую прибыль, однако является высокорискованным финансовым инструментом. В частности, существует риск, связанный с потерей инвестиций (полностью или частично) и (или) невозможностью продажи имущества, в том числе имущественных прав, приобретенных в результате инвестирования."
												}
											/>
										</>
									}
									checked={highRiskAccepted}
									onChange={(event) =>
										setHighRiskAccepted(event.target.checked)
									}
								/>
							</div>
							<div className='col-12'>
								<FormGroup
									className={"pb-0"}
									id={"acceptInvestmentProposal"}
									fieldType={FormGroupTypes.CHECKBOX}
									label={
										<>
											Подписываю&nbsp;
											<ConditionalWrapper
												condition={
													Boolean(projectAcceptDocument) &&
													Boolean(projectAcceptDocument?.url)
												}
												wrapper={(children) => (
													<a
														className='text-decoration-underline'
														target='_blank'
														rel='noreferrer'
														href={projectAcceptDocument?.url}>
														{children}
													</a>
												)}>
												<>Акцепт инвестиционного предложения</>
											</ConditionalWrapper>
										</>
									}
									checked={acceptInvestmentProposal}
									onChange={(event) =>
										setAcceptInvestmentProposal(event.target.checked)
									}
								/>
							</div>
						</div>
					</div>
					<div className='text-center pt-5'>
						<p className='text-dark text-m'>Код будет выслан на номер</p>
						{phone && (
							<NumberFormat
								{...PHONE_NUMBER_FORMAT}
								className={styles.phoneNumber}
								displayType={"text"}
								value={optimizePhone(phone)}
							/>
						)}
						<Button
							as={ButtonTypesEnum.BUTTON}
							variant={ButtonVariant.contained}
							type={"submit"}
							className={"mt-4 px-5"}
							disabled={submitDisabled}
							isLoading={investRequestIsLoading}>
							Инвестировать
						</Button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default InvestRequestModal;

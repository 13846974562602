import React, {FC} from 'react';
import styles from '../qualification.module.scss';
import classNames from 'classnames';
import {ReactComponent as DocumentIcon} from '@icons/document-corner.svg';
import {ReactComponent as DeleteIcon} from '@icons/delete.svg';
import {IAttachment} from '@common/types';

interface IProfileQualificationDocumentProps {
	attachment: IAttachment,
	className?: string,
	onDelete?: (uid: string) => void
}

const ProfileQualificationDocument:FC<IProfileQualificationDocumentProps> = (
	{
		className,
		attachment,
		onDelete
	}
) => {

	return (
		<div title={attachment?.filename} className={classNames(styles.document, 'd-inline-flex align-items-center', className)}>
			<span className={
				classNames(styles.documentIcon, 'd-flex align-items-center justify-content-center flex-shrink-0')
			}>
				<DocumentIcon/>
			</span>
			<span className={classNames(styles.fileName, 'px-3 mb-n1')}>{attachment?.filename}</span>

			<button
				type={'button'}
				className={styles.documentDelete}
				onClick={() => {
					if (onDelete && attachment.uid) onDelete(attachment.uid)
				}}
			>
				<DeleteIcon/>
			</button>
		</div>
	);
};


export default ProfileQualificationDocument;

import React from 'react';
import {Button, CustomLink, FormGroup, Heading, Paragraph} from '@components/ui';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {ReactComponent as VKIcon} from '@icons/vk.svg';
import {ReactComponent as InstagramIcon} from '@icons/instagram.svg';
import {ReactComponent as TelegramIcon} from '@icons/telegram.svg';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {ParagraphSize} from '@components/ui/Paragraph/Paragraph.props';
import {LinkIconsVariant, LinkVariant} from '@components/ui/Link/Link.props';

const Ui = (): JSX.Element => {
	return (
		<Container className={'py-5'}>

			<section className={'pb-5'}>
				<Heading tag={'h3'} variant={HeadingVariant.large}>Loaders</Heading>
				<Row className={'gx-3 pb-3'}>
					<Col xs={'auto'}><Spinner animation="border" variant="primary" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="secondary" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="success" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="danger" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="warning" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="info" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="light" /></Col>
					<Col xs={'auto'}><Spinner animation="border" variant="dark" /></Col>
				</Row>
				<Row className={'gx-3'}>
					<Col xs={'auto'}><Spinner animation="grow" variant="primary" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="secondary" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="success" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="danger" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="warning" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="info" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="light" /></Col>
					<Col xs={'auto'}><Spinner animation="grow" variant="dark" /></Col>
				</Row>
			</section>

			<section className={'pb-5'}>
				<Heading tag={'h3'} variant={HeadingVariant.large}>Icons</Heading>
				<Row className="row align-items-center">
					<Col className="col-auto"><VKIcon/></Col>
					<Col className="col-auto"><InstagramIcon/></Col>
					<Col className="col-auto"><TelegramIcon/></Col>
				</Row>
			</section>

			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Headings</Heading>
				<Heading tag={'h2'} variant={HeadingVariant.large}>H1 Roboto, 24 px, medium</Heading>
				<Heading tag={'h3'} variant={HeadingVariant.middle}>H2 Roboto, 15 px, meduim</Heading>
			</section>

			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Text</Heading>
				<Paragraph>P1 Roboto, 16 px, regular</Paragraph>
				<Paragraph size={ParagraphSize.small}>P2 Roboto, 14 px, regular</Paragraph>
			</section>

			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Buttons</Heading>
				<Row className={'pb-3'}>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Button contained as button</p>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.contained} type={'button'}>Далее</Button>
					</Col>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Button contained as button with arrow</p>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.contained} type={'button'} icon={ButtonIconsVariant.arrowRight}>Далее</Button>
					</Col>
				</Row>
				<Row>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Button outlined as button</p>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.outlined} type={'button'}>Войти как заемщик</Button>
					</Col>
				</Row>
				<Row>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Button success as button</p>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.success} type={'button'}>Получить заём</Button>
					</Col>
				</Row>
			</section>
			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Icon Buttons</Heading>
				<Row className={'pb-3'}>
					<Col xs={'auto'}>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.icon} icon={ButtonIconsVariant.logout} type={'button'}>Выйти</Button>
						<Button as={ButtonTypesEnum.BUTTON} variant={ButtonVariant.icon} icon={ButtonIconsVariant.notify} type={'button'}/>
					</Col>
				</Row>
			</section>

			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Links</Heading>
				<Row className={'pb-3'}>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Link with icon</p>
						<Row className={'gx-3 pb-3'}>
							<Col xs={'auto'}><CustomLink href={'#'} target={'_blank'} variant={LinkVariant.icon} icon={LinkIconsVariant.vk}/></Col>
							<Col xs={'auto'}><CustomLink href={'#'} variant={LinkVariant.icon} icon={LinkIconsVariant.instagram}/></Col>
							<Col xs={'auto'}><CustomLink href={'#'} variant={LinkVariant.icon} icon={LinkIconsVariant.telegram}/></Col>
						</Row>
					</Col>
				</Row>
				<Row className={'pb-3'}>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Simple link</p>
						<Row className={'gx-3 pb-3'}>
							<Col xs={'auto'}><CustomLink href={'#'} target={'_blank'} variant={LinkVariant.simple}>Вопросы</CustomLink></Col>
							<Col xs={'auto'}><CustomLink href={'#'} target={'_blank'} variant={LinkVariant.simple}>О компании</CustomLink></Col>
							<Col xs={'auto'}><CustomLink href={'#'} target={'_blank'} variant={LinkVariant.simple}>Документы</CustomLink></Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col xs={'auto'}>
						<p className={'pb-2'}>Link with arrow</p>
						<Row className={'gx-3 pb-3'}>
							<Col xs={'auto'}><CustomLink href={'#'} variant={LinkVariant.arrow}>Учетная запись</CustomLink></Col>
						</Row>
					</Col>
				</Row>
			</section>

			<section className="pb-5">
				<Heading tag={'h2'} variant={HeadingVariant.large}>Inputs</Heading>
				<Row className={'mb-4'}>
					<Col className={'col-4'}>
						<FormGroup
							id={'exampleFormGroupText'}
							fieldType={FormGroupTypes.TEXT}
							placeholder={'Имя'}
							label={'Введите Ваше имя'}
						/>
					</Col>
				</Row>
				<section className="pb-3">
					<Heading tag={'h2'} variant={HeadingVariant.middle}>Checkboxes/Radios with content</Heading>
					<Row className={'mb-4'}>
						<Col className={'col-auto'}>
							<FormGroup
								id={'exampleFormGroupCheckboxFirst'}
								fieldType={FormGroupTypes.CHECKBOX}
								label={'First Checkbox'}
							/>
						</Col>
						<Col className={'col-auto'}>
							<FormGroup
								id={'exampleFormGroupCheckboxSecond'}
								fieldType={FormGroupTypes.CHECKBOX}
								label={'Second Checkbox'}
							/>
						</Col>
					</Row>
					<Row className={'mb-4'}>
						<Col className="col-auto">
							<FormGroup
								id={'exampleFormGroupRadioFirst'}
								fieldType={FormGroupTypes.RADIO}
								label={'First Radio'}
								name={'ExampleRadio'}
								value={'ExampleRadioValueFist'}
							/>
						</Col>
						<Col className="col-auto">
							<FormGroup
								id={'exampleFormGroupRadioSecond'}
								fieldType={FormGroupTypes.RADIO}
								label={'Second Radio'}
								name={'ExampleRadio'}
								value={'ExampleRadioValueSecond'}
							/>
						</Col>
						<Col className="col-auto">
							<FormGroup
								id={'exampleFormGroupPhone'}
								fieldType={FormGroupTypes.PHONE}
								label={'Phone field'}
							/>
						</Col>
					</Row>
				</section>
				<section className="pb-3">
					<Heading tag={'h2'} variant={HeadingVariant.middle}>Checkboxes/Radios without content</Heading>
					<Row className={'mb-4'}>
						<Col className={'col-auto'}>
							<FormGroup
								id={'exampleFormGroupCheckboxThird'}
								fieldType={FormGroupTypes.CHECKBOX}
							/>
						</Col>
						<Col className="col-auto">
							<FormGroup
								id={'exampleFormGroupRadioThird'}
								fieldType={FormGroupTypes.RADIO}
								name={'ExampleRadio'}
								value={'ExampleRadioValueFist'}
							/>
						</Col>
						<Col className="col-auto">
							<FormGroup
								id={'exampleFormGroupRadioFourth'}
								fieldType={FormGroupTypes.RADIO}
								name={'ExampleRadio'}
								value={'ExampleRadioValueSecond'}
							/>
						</Col>
					</Row>
				</section>
			</section>
		</Container>
	);
};

export default Ui;

import React, {FC} from 'react';
import styles from './borrowerAccountConditionsFormGroup.module.scss';
import classNames from 'classnames';
import {FormGroup} from '@components/ui';
import {FormGroupTypes, IFormGroupProps} from '@components/ui/FormGroup/FormGroup.props';
import Slider from 'rc-slider';
import {declensionOfNumber} from '@common/utils';
import {Controller, useFormContext} from 'react-hook-form';
import {IBorrowerBidRequest} from '@features/borrower/types';
import {useAppSelector} from '@hooks/useAppSelector';
import {MONTHS_WORDS} from '@common/constants';

interface IBorrowerAccountConditionGroup {
	title: string
	icon: JSX.Element
	type?: 'text' | 'number' | 'range'
	note?: string | JSX.Element
	fieldKey: keyof IBorrowerBidRequest
	rangeValue?: number
	onRangeChange?: (value: number) => void
	rangeEdges?: {min?: number, max: number}
	className?: string
	onNumberChange?: (value: string) => void
	onTextChange?: (value: string) => void
	readonly?: IFormGroupProps['readonly']
}

const BorrowerAccountConditionsFormGroup:FC<IBorrowerAccountConditionGroup> = (
	{
		title,
		icon,
		type = 'text',
		note,
		fieldKey,
		rangeValue,
		onRangeChange,
		rangeEdges,
		onNumberChange,
		onTextChange,
		className,
		readonly = false
	}
) => {

	const
		id = `conditions-group--${type}`,
		{
			setValue,
			watch,
			register,
			formState: {errors},
			control
		} = useFormContext(),
		{bidsParams} = useAppSelector(state => state.borrower)
	;

	const isValidGoal = (val: number, min: number, max: number):boolean => val >= min && val <= max;

	const defineInput = ():JSX.Element => {

		switch (type) {
			case 'text':
				return (
					<FormGroup
						id={id}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event) => {
							onTextChange && onTextChange(event.target.value);
							setValue(fieldKey, event.target.value);
						}}
						value={watch(fieldKey)}
						register={register}
						name={fieldKey}
						errorMessage={errors[fieldKey]?.message}
						readonly={readonly}
					/>
				);
			case 'number':
				return (
					<FormGroup
						id={id}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						thousandSeparator={' '}
						onValueChange={({value}) => {
							onNumberChange && onNumberChange(value);
							setValue(fieldKey, value);
						}}
						value={watch(fieldKey)}
						register={register}
						name={fieldKey}
						errorMessage={errors[fieldKey]?.message}
						readonly={readonly}
						registerOptions={
							{
								validate: value => {

									return isValidGoal(
										parseInt(value),
										parseInt(bidsParams.goalAmountMin),
										parseInt(bidsParams.goalAmountMax)
									) ? true : 'Некорректная сумма'
								}
							}
						}
					/>
				);
			case 'range':
				return (
					<div className='pb-3'>
						<p className={styles.rangeOutput}>
							<strong>{rangeValue} </strong>
							<span>{declensionOfNumber(rangeValue || 0, MONTHS_WORDS)}</span>
						</p>


						<Controller
							name={fieldKey}
							control={control}
							render={(
								{
									field: { onChange, onBlur, value },
									fieldState: {error},
								}
							) => {
								return <>
									<Slider
										min={rangeEdges?.min || 0}
										max={rangeEdges?.max || 99000000}
										value={value}
										onChange={(num) => {
											onRangeChange && onRangeChange(num);
											onChange(num);
										}}
										onBlur={onBlur}
									/>
									{error?.message && <p className='text-danger text-sm mb-0'>{error.message}</p>}
								</>
							}}
						/>



					</div>
				);
		}

		return <></>
	};


	return (
		<section className={classNames(styles.main, className)}>
			<header className={classNames(styles.header, 'row gx-3 align-items-center')}>
				<div className="col-auto">{icon}</div>
				<div className="col-auto">
					<h3 className={classNames(styles.title, 'text-md mb-0')}>{title}</h3>
				</div>
			</header>
			<div className={classNames('pt-3', type === 'range' ? 'mt-auto mb-2' : 'mt-2')}>
				{defineInput()}
			</div>
			{note && <p className='text-sm text-dark mb-0'>{note}</p>}
		</section>
	);
};

export default BorrowerAccountConditionsFormGroup;

import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {
	IDateRange, IInvestRequest,
	IRequisitesResponse, IWithdrawAccount, IWithdrawAccountsResponse,
	IWithdrawInitRequest
} from '@features/operations/types';
import {
	API_OPERATIONS_REQUISITES_URL,
	API_OPERATIONS_WITHDRAW_CONFIRM_URL,
	API_OPERATIONS_WITHDRAW_INIT_URL, API_OPERATIONS_WITHDRAW_REQUEST_URL, API_USER_OPERATIONS_INVEST_CANCEL_CONFIRM_URL,
	API_USER_OPERATIONS_INVEST_CANCEL_REQUEST_URL,
	API_USER_OPERATIONS_INVEST_CONFIRM_URL,
	API_USER_OPERATIONS_INVEST_REQUEST_URL,
	API_USER_OPERATIONS_SEARCH_URL
} from '@common/constants';
import {IListResponse, IFilter, IDefaultResponse} from '@common/types';
import {IProject} from '@features/projects/types';


const configureDateRangeParams = ({dateStart, dateEnd}: IDateRange): IDateRange => {
	const resultObj = {} as IDateRange;

	Boolean(dateStart) && (resultObj['dateStart'] = dateStart);
	Boolean(dateEnd) && (resultObj['dateEnd'] = dateEnd);

	return resultObj
};

const operationsApi = createApi({
	reducerPath: 'operationsApi',
	refetchOnMountOrArgChange: true,
	tagTypes: ['Operations', 'Profile'],
	baseQuery,
	endpoints: build => ({
		search: build.mutation<IListResponse, {body:Partial<IFilter>, params?: IDateRange}>({
			query: ({body, params}) => ({
				url: API_USER_OPERATIONS_SEARCH_URL,
				method: 'post',
				body,
				params: params && configureDateRangeParams(params)
			}),
			invalidatesTags: ['Operations']
		}),
		requisites: build.query<IRequisitesResponse, void>({
			query: () => API_OPERATIONS_REQUISITES_URL
		}),
		withdrawRequest: build.mutation<IWithdrawAccount[], void>({
			query: () => ({
				url: API_OPERATIONS_WITHDRAW_REQUEST_URL,
				method: 'GET'
			}),
			transformResponse: (response:IWithdrawAccountsResponse) => response.items
		}),
		withdrawInit: build.mutation<IDefaultResponse, IWithdrawInitRequest>({
			query: body => ({
				url: API_OPERATIONS_WITHDRAW_INIT_URL,
				method: 'post',
				body
			})
		}),
		withdrawConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_OPERATIONS_WITHDRAW_CONFIRM_URL,
				method: 'post',
				body: {code}
			}),
			invalidatesTags: ['Profile']
		}),
		investRequest: build.mutation<IDefaultResponse, IInvestRequest>({
			query: body => ({
				url: API_USER_OPERATIONS_INVEST_REQUEST_URL,
				method: 'post',
				body
			})
		}),
		investConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_USER_OPERATIONS_INVEST_CONFIRM_URL,
				method: 'post',
				body: {code}
			})
		}),
		cancelInvestRequest: build.mutation<IDefaultResponse, IProject['projectId']>({
			query: projectId => ({
				url: API_USER_OPERATIONS_INVEST_CANCEL_REQUEST_URL,
				method: 'post',
				body: {projectId}
			})
		}),
		cancelInvestConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_USER_OPERATIONS_INVEST_CANCEL_CONFIRM_URL,
				method: 'post',
				body: {code}
			})
		})
	})
});

export default operationsApi;





import React, {FC, useContext} from 'react';
import {IWithdrawAccount} from '@features/operations/types';
import styles from './withdrawSavedAccounts.module.scss';
import classNames from 'classnames';
import {Button} from '@app/components/ui';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {withdrawContext} from '@context/withdrawContext';

const WithdrawSavedAccounts:FC<{counts: IWithdrawAccount[], onAccountChoose: (account: IWithdrawAccount) => void}> = ({counts, onAccountChoose}) => {

	const {setSavedAccountsDisplayed} = useContext(withdrawContext);

	return (
		<div className={styles.wrap}>
			<p className={'pb-4'}>Выберите счет, на который необходимо вывести деньги:</p>

			<ul className={classNames(styles.list, 'text-dark')}>
				{
					counts.map((account, i) => {
						const {accountNumber, id, bankName, bik} = account;

						return (
							<li key={id} className={classNames({'mb-4': i+1 !== counts.length})}>
								<p className={classNames(styles.label, 'mb-2')}>Счёт № {i+1}</p>
								<button
									type={'button'}
									className={classNames(styles.account)}
									onClick={() => onAccountChoose(account)}
								>
									<span className={'row gx-3'}>
										<span className={'col-4'}>
											<span title={bankName} className={classNames(styles.value, styles.bankName)}>{bankName}</span>
										</span>
										<span className={'col-4'}>
											<span title={bik} className={classNames(styles.value, styles.bik)}>{bik}</span>
										</span>
										<span className={'col-4'}>
											<span title={accountNumber} className={classNames(styles.value, styles.accountNumber)}>{accountNumber}</span>
										</span>
									</span>
								</button>
							</li>
						)
					})
				}
			</ul>

			<div className="row mt-6">
				<div className="col-auto mx-auto">
					<Button
						variant={ButtonVariant.outlined}
						as={ButtonTypesEnum.BUTTON}
						onClick={() => {
							setSavedAccountsDisplayed(false)
						}}
					>
						Добавить новый счет
					</Button>
				</div>
			</div>
		</div>
	);
};

export default WithdrawSavedAccounts;

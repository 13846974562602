import React, {FC} from 'react';
import classNames from 'classnames';
import styles from '../qualification.module.scss';
import {ReactComponent as PlusIcon} from '@icons/plus-thin.svg';
import {Spinner} from 'react-bootstrap';

interface IQualificationFileUploadProps {
	label?: string,
	className?: string,
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
	isLoading?: boolean,
	disabled?: boolean
}

const ProfileQualificationFileUpload:FC<IQualificationFileUploadProps> = (
	{
		label = 'Загрузить документ (pdf, docx)',
		onChange,
		className,
		isLoading = false,
		disabled
	}
) => {
	return (
		<label className={classNames(styles.upload, 'd-inline-flex align-items-center', className, {
			[styles.uploadIsLoading]: isLoading
		})}>
			<input
				className='visually-hidden'
				onChange={event => {
					onChange && onChange(event);
				}}
				type='file'
				accept={'.pdf, .doc, .docx'}
				disabled={disabled}
			/>
			<PlusIcon className={classNames(styles.uploadIcon, 'flex-shrink-0')} width='32px' height='32px'/>
			<span className={classNames(styles.uploadLabel, 'ps-3 mb-n1')}>{label}</span>
			{isLoading && <Spinner className={'spinner--centered'} size={'sm'} animation='grow' variant='primary'/>}
		</label>
	);
};

export default ProfileQualificationFileUpload;

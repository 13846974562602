import React, {createContext, Dispatch, FC, SetStateAction, useState} from 'react';

interface IWithdrawContext {
	bik: string
	updateBik: Dispatch<SetStateAction<string>>
	name: string
	updateName: Dispatch<SetStateAction<string>>
	account: string
	updateAccount: Dispatch<SetStateAction<string>>
	amount: number
	updateAmount: Dispatch<SetStateAction<number>>
	correspondentAccount: string
	updateCorrespondentAccount: Dispatch<SetStateAction<string>>

	requestModal: boolean
	setRequestModal: Dispatch<SetStateAction<boolean>>

	confirmModal: boolean
	setConfirmModal: Dispatch<SetStateAction<boolean>>

	successModal: boolean
	setSuccessModal: Dispatch<SetStateAction<boolean>>

	savedAccountsDisplayed: boolean
	setSavedAccountsDisplayed: Dispatch<SetStateAction<boolean>>
}

export const withdrawContext = createContext<IWithdrawContext>({} as IWithdrawContext);

export const WithdrawProvider: FC = (props) => {

	const
		[withdrawModalRequest, setWithdrawModalRequest] = useState(false),
		[withdrawModalConfirm, setWithdrawModalConfirm] = useState(false),
		[withdrawModalSuccess, setWithdrawModalSuccess] = useState(false),

		[withdrawBik, setWithdrawBik] = useState<IWithdrawContext['bik']>(''),
		[withdrawName, setWithdrawName] = useState<IWithdrawContext['name']>(''),
		[withdrawAccount, setWithdrawAccount] = useState<IWithdrawContext['account']>(''),
		[correspondentAccount, setCorrespondentAccount] = useState<IWithdrawContext['account']>(''),
		[withdrawAmount, setWithdrawAmount] = useState<IWithdrawContext['amount']>(0),

		[savedAccountsDisplayed, setSavedAccountsDisplayed] = useState<boolean>(false)
	;

	const payload = {
		account: withdrawAccount,
		updateAccount: setWithdrawAccount,
		correspondentAccount,
		updateCorrespondentAccount: setCorrespondentAccount,

		amount: withdrawAmount,
		updateAmount: setWithdrawAmount,
		bik: withdrawBik,
		updateBik: setWithdrawBik,
		name: withdrawName,
		updateName: setWithdrawName,

		requestModal: withdrawModalRequest,
		setRequestModal: setWithdrawModalRequest,

		confirmModal: withdrawModalConfirm,
		setConfirmModal: setWithdrawModalConfirm,

		successModal: withdrawModalSuccess,
		setSuccessModal: setWithdrawModalSuccess,
		savedAccountsDisplayed, setSavedAccountsDisplayed
	};


	return (
		<withdrawContext.Provider value={payload}>
			{props.children}
		</withdrawContext.Provider>
	)
};

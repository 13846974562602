import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {
	API_USER_INFO_URL,
	API_USER_INVESTMENTS_SEARCH_URL,
	API_USER_PROFILE_CHANGE_CONFIRM_URL,
	API_USER_PROFILE_CHANGE_REQUEST_URL,
	API_USER_PROFILE_JOINING_STATEMENT_URL, API_USER_PROFILE_PHONE_CHANGE_CONFIRM, API_USER_PROFILE_PHONE_CHANGE_REQUEST,
	API_USER_PROFILE_QUALIFICATION_CONFIRM_URL,
	API_USER_PROFILE_QUALIFICATION_REQUEST_URL, API_USER_PROFILE_QUALIFICATION_STATEMENT_URL,
	API_USER_PROFILE_URL
} from '@common/constants';
import {
	IStatement,
	IInvestorInfo, IInvestorProfile
} from '@features/user/types';
import {baseQuery} from '@api/utils';
import {IDefaultResponse, IListResponse, IQualificationAttachment} from '@common/types';

interface IQualificationRequest {
	attachments: IQualificationAttachment[],
	withVerification?: boolean
}

const userApi = createApi({
	reducerPath: 'userApi',
	refetchOnMountOrArgChange: true,
	tagTypes: ['Profile'],
	baseQuery,
	endpoints: build => ({
		profile: build.query<IInvestorProfile, void>({
			query: () => API_USER_PROFILE_URL
		}),
		info: build.query<IInvestorInfo, void>({
			query: () => API_USER_INFO_URL
		}),
		patchProfile: build.mutation<IInvestorInfo, Partial<IInvestorInfo>>({
			query: patch => ({
				url: API_USER_PROFILE_URL,
				method: 'PATCH',
				body: patch
			}),
			invalidatesTags: ['Profile']
		}),
		investments: build.mutation<IListResponse, void>({
			query: () => ({
				url: API_USER_INVESTMENTS_SEARCH_URL,
				method: 'GET'
			})
		}),
		profileChangeRequest: build.mutation<IDefaultResponse, {body: Partial<IInvestorProfile>, qualification?: boolean}>({
			query: ({body, qualification}) => ({
				url: API_USER_PROFILE_CHANGE_REQUEST_URL,
				method: 'POST',
				body,
				params: {
					qualification: Boolean(qualification)
				}
			})
		}),
		profileChangeConfirm: build.mutation<{profile: IInvestorProfile, changedPhone: boolean}, string>({
			query: code => ({
				url: API_USER_PROFILE_CHANGE_CONFIRM_URL,
				method: 'POST',
				body: {code}
			})
		}),
		qualificationRequest: build.mutation<IDefaultResponse, IQualificationRequest>({
			query: ({attachments, withVerification}) => {
				return {
					url: API_USER_PROFILE_QUALIFICATION_REQUEST_URL,
					method: 'POST',
					body: {attachments},
					params: {
						withVerification: Boolean(withVerification)
					}
				}
			}
		}),
		qualificationConfirm: build.mutation<{profile: IInvestorProfile, changedPhone: boolean}, string>({
			query: code => ({
				url: API_USER_PROFILE_QUALIFICATION_CONFIRM_URL,
				method: 'POST',
				body: {code}
			})
		}),
		joiningStatement: build.query<IStatement, void>({
			query: () => API_USER_PROFILE_JOINING_STATEMENT_URL
		}),
		qualificationStatement: build.query<IStatement, void>({
			query: () => API_USER_PROFILE_QUALIFICATION_STATEMENT_URL
		}),
		phoneChangeRequest: build.mutation<IDefaultResponse, void>({
			query: () => ({
				url: API_USER_PROFILE_PHONE_CHANGE_REQUEST,
				method: 'POST'
			})
		}),
		phoneChangeConfirm: build.mutation<IDefaultResponse, string>({
			query: code => ({
				url: API_USER_PROFILE_PHONE_CHANGE_CONFIRM,
				method: 'POST',
				body: {code}
			})
		})
	})
});

export default userApi;



import {INotification, INotificationState} from '@features/notifications/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState: INotificationState = {
	notifications: []
};

export const notificationsSlice = createSlice({
	name: 'notifications',
	initialState,
	reducers: {
		setNotifications: (state, {payload}: PayloadAction<INotification[]>) => {
			state.notifications = payload
		},
		deleteNotification: (state, {payload}: PayloadAction<string>) => {
			state.notifications = state.notifications.filter(notification => {
				return notification.notificationId !== payload
			})
		}
	}
});

export default notificationsSlice.reducer;

import React, {FC} from 'react';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {Heading} from '@components/ui';
import {ProfilePagesTitleEnum} from '@common/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import clubApi from '@api/clubApi';
import {Spinner} from 'react-bootstrap';
import {configureAssetPath, withRootUrl} from '@common/utils';
import LazyImage from '@components/common/LazyImage/LazyImage';
import {useProfileSection} from '@hooks/useProfileSection';
import {SectionsCodeEnum} from '@features/utils/types';
import styles from './club.module.scss';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';
import ProfileSection from '@components/profile/Section/ProfileSection';

export const ProfileClub: FC = () => {

	const
		clubSection = useProfileSection(SectionsCodeEnum.INVESTOR_CLUB),
		{data: proposals = [], isLoading: proposalsIsLoading} = clubApi.useProposalsQuery(),
		{width: ww} = useWindowSize()
	;

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.CLUB}>
			<ProfileSection limitMinHeight>
				{
					proposalsIsLoading
						? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
						: <div className="row">
							<div className="col-xl-6">
								{ww > ADAPTIVE_BREAKPOINTS.sm && <Heading tag={'h2'} variant={HeadingVariant.large}>{clubSection?.title}</Heading>}
								<p className={'pb-4'}><strong>{clubSection?.description}</strong></p>
							</div>
							<div className="col-12">
								{
									proposals.length > 0
										? <ul className={styles.proposals}>
											{
												proposals?.map(({banner, link}) => {
													const {uid} = banner;
													return (
														<li key={uid} className={styles.proposalsItem}>
															<a href={link} className='d-block' target='_blank' rel="noreferrer">
																<LazyImage
																	src={withRootUrl(configureAssetPath(banner) || '') || ''}
																	alt="Proposal item"
																	aspectRatio={'4x20'}
																/>
															</a>
														</li>
													)
												})
											}
										</ul>
										: <p>Спецпредложения отсутствуют</p>
								}
							</div>
						</div>
				}
			</ProfileSection>
		</ProfileContainer>
	);
};

import React, { FC } from 'react';
import classNames from 'classnames';
import styles from '@components/profile/Aside/aside.module.scss';
import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg';
import { useHistory } from 'react-router-dom';

const AsideReverseEntityLink: FC<{
    to: string,
    title: string
    external?: boolean;
}> = ({ to, title, external = false }) => {

    const history = useHistory();

    return (
        <a
            target={external ? '_blank' : '_self'}
            rel="noreferrer"
            href={external ? to : ''}
            onClick={external ? () => null : (event: React.MouseEvent<HTMLAnchorElement>) => {
                event.preventDefault();
                history.push(to);
            }} className={classNames(styles.link, 'text-dark')}>
            <span className={styles.linkName}>{title}</span>
            <ArrowRightIcon className={styles.linkIcon} />
        </a>
    );
};

export default AsideReverseEntityLink;

import React, {FC, useContext, useEffect, useMemo, useRef} from 'react';
import {SwitchTransition, CSSTransition} from 'react-transition-group';
import {BASIC_SMS_CODE_ARR, CODE_NUMBER_FORMAT, REQUIRED_CODE_LENGTH, TRANSITION_TIMEOUT} from '@common/constants';
import Button from '@components/ui/Button/Button';
import {ButtonIconsVariant, ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import FormGroup from '@components/ui/FormGroup/FormGroup';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {profileContext} from '@context/profileContext';
import classNames from 'classnames';
import ResendCode from '@components/common/ResendCode/ResendCode';

export type BorrowerSmsConfirmViews = 'request' | 'confirm';

interface IBorrowerSmsConfirmProps {
	view: BorrowerSmsConfirmViews
	isLoading?: boolean
	disabled?: boolean
	codeChecking?: boolean
	note?: string
	onSubmitClick?: () => void
	onResend?: () => void
	onConfirm?: (value: string) => void
	className?: string
}

const BorrowerSmsConfirm:FC<IBorrowerSmsConfirmProps> = (
	{
		view,
		isLoading = false,
		disabled = false,
		codeChecking = false,
		note,
		onSubmitClick,
		onResend,
		className,
		onConfirm
	}
) => {
	const
		animatedRef = useRef(null),
		{smsCode, changeSmsCode, smsError} = useContext(profileContext),
		smsCodeAsString = useMemo(() => Object.values(smsCode).join(''), [smsCode]),
		smsGroupRef = useRef<HTMLDivElement | null>(null)
	;

	const switchContent = ():JSX.Element => {

		switch (view) {
			case 'confirm':

				return (
					<div className='row gx-3 gx-xl-4 gx-lt-5 align-items-end'>
						<div className='col-sm-6 col-lg-4'>
							<FormGroup
								id={'BorrowerSmsCodeConfirm'}
								fieldType={FormGroupTypes.NUMBER_FORMAT}
								numberFormat={CODE_NUMBER_FORMAT}
								label={'Введите код из СМС'}
								value={smsCodeAsString}
								onValueChange={({value}) => {
									changeSmsCode(
										[...value.split(''),	...BASIC_SMS_CODE_ARR]
											.filter((_, ind) => (ind+1) <= REQUIRED_CODE_LENGTH)
									)
								}}
								readonly={isLoading}
								rootRef={smsGroupRef}
							/>
						</div>
						<div className='col-auto pb-1'>
							<Button
								variant={ButtonVariant.contained}
								as={ButtonTypesEnum.BUTTON}
								type={'button'}
								isLoading={isLoading}
								className={'mb-4'}
								icon={ButtonIconsVariant.arrowRight}
								onClick={() => {
									onConfirm && onConfirm(smsCodeAsString)
								}}
							>
								Продолжить
							</Button>
						</div>
						<div className='col-12'>
							<ResendCode
								centerAlign={false}
								onResendCode={() => {
									onResend && onResend()
								}}
								isLoading={codeChecking}
							/>
						</div>
					</div>
				);
			case 'request':
				return (
					<div className={'text-end text-sm-start'}>
						<Button
							variant={ButtonVariant.contained}
							as={ButtonTypesEnum.BUTTON}
							type={'submit'}
							onClick={onSubmitClick}
							isLoading={isLoading}
							disabled={disabled}
						>

							Отправить СМС
						</Button>
						{note && 	<p className='text-dark text-m mt-4'>{note}</p>}
					</div>
				)
		}

		return <></>
	};

	useEffect(() => {
		if (smsError) {
			changeSmsCode(BASIC_SMS_CODE_ARR);
			smsGroupRef.current?.querySelector('input')?.focus()
		}
	}, [smsError]);

	useEffect(() => {
		const listener = (event: KeyboardEvent) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				event.preventDefault();

				switch (view) {
					case 'request':
						(onSubmitClick && !isLoading) && onSubmitClick();
						break;

					case 'confirm':
						(onConfirm && !isLoading) && onConfirm(smsCodeAsString);
						break;
				}
			}
		};


		window.addEventListener('keydown', listener);
		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, [view, smsCodeAsString]);

	useEffect(() => {
		return () => {
			changeSmsCode(BASIC_SMS_CODE_ARR);
		}
	}, [])

	return (
		<div className={classNames('pt-4 pt-sm-5', className)}>
			<SwitchTransition>
				<CSSTransition
					nodeRef={animatedRef}
					key={view}
					timeout={TRANSITION_TIMEOUT / 2}
					classNames={'animation--slide-x'}
					onEntered={() => {
						if (smsGroupRef.current && view === 'confirm') {
							smsGroupRef.current?.querySelector('input')?.focus()
						}
					}}
				>
					<div ref={animatedRef}>
						{switchContent()}
					</div>
				</CSSTransition>
			</SwitchTransition>
		</div>
	);
};

export default BorrowerSmsConfirm;

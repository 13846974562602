import React, {FC, HTMLAttributes, ReactElement} from 'react';
import classNames from 'classnames';
import styles from './table.module.scss';
import {Spinner} from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '@hooks/useWindowSize';
import {ADAPTIVE_BREAKPOINTS} from '@common/constants';

interface ITableProps {
	thCells?: (string | ReactElement)[]
	tdCells?: (string | ReactElement)[][]
	isLoading?: boolean
	horizontallyScrolled?: boolean
	emptyMessage?: string
	variant?: 'simple' | ''
}

const Table: FC<ITableProps & HTMLAttributes<HTMLDivElement>> = (
	{
		thCells ,
		tdCells = [],
		isLoading = false,
		horizontallyScrolled = false,
		className,
		emptyMessage = 'Результат отсутствует',
		variant = '',
		...props
	}
) => {

	const {width: ww} = useWindowSize();

	return (
		<div className={classNames(styles.wrap, styles[variant], {
			'is-horizontally-scrolled': horizontallyScrolled && ww <= ADAPTIVE_BREAKPOINTS.lg
		}, className)} {...props}>
			{
				isLoading
					? <Spinner className={'d-block mx-auto'} animation='border' variant='primary'/>
					: tdCells.length > 0
					? <table className={classNames('table', styles.main)}>

						{thCells && <thead className={'text-dark'}><tr>{thCells.map(th => (<th key={uuidv4()}>{th}</th>))}</tr></thead>}

						<tbody>
						{
							tdCells.map(row => {
								return (
									<tr key={uuidv4()}>
										{row.map(cell => (<td key={uuidv4()}>{cell}</td>))}
									</tr>
								)
							})
						}
						</tbody>
					</table>
					: <p className={'mb-0'}>{emptyMessage}</p>
			}
		</div>
	)
};


export default Table;

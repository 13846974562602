import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { AuthRouteNamesEnum, RouteNamesEnum } from './router';
import { useAppSelector } from '@hooks/useAppSelector';
import AppWrapper from '@components/appWrapper/AppWrapper';
import Auth from './pages/Auth';
import ProtectedRoute from '@components/common/ProtectedRoute';
import Ui from './pages/Ui';
import { ToastContainer } from 'react-toastify';
import ToastCloseButton from '@components/common/Toast/ToastCloseButton';
import Investor from '@app/pages/Investor';
import Borrower from '@app/pages/Borrower';
import NotFound from '@app/pages/notFound/NotFound';
import useSession from '@hooks/useSession';
import { useAppActions } from '@hooks/useAppActions';
import { EXPIRE_TIME } from '@common/constants';

function App(): JSX.Element {
    const history = useHistory();
    const { isAuthorized } = useAppSelector(state => state.auth);
    const { setAuthorized, setToken, resetUserData } = useAppActions();
    const logout = () => {
        setToken(null);
        resetUserData();
        setAuthorized(false);
        history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHECK_PHONE);
    };

    useSession({ logout, expireTime: EXPIRE_TIME });

    return (
        <AppWrapper>
            <ToastContainer closeButton={ToastCloseButton} />
            <Switch>
                <Route path={RouteNamesEnum.AUTH + RouteNamesEnum.BORROWER} component={Auth} />
                <Route path={RouteNamesEnum.AUTH} component={Auth} />
                <Route exact path={RouteNamesEnum.UI_KIT} component={Ui} />
                <ProtectedRoute isAuthorized={isAuthorized} path={RouteNamesEnum.INVESTOR} component={Investor} />
                <ProtectedRoute isAuthorized={isAuthorized} path={RouteNamesEnum.BORROWER} component={Borrower} />
                <Route>
                    <NotFound />
                </Route>
            </Switch>
        </AppWrapper>
    );
}

export default App;

import React, {FC, useContext, useEffect} from 'react';
import {useAppActions} from '@hooks/useAppActions';
import {ProfilePagesTitleEnum} from '@common/types';
import ProfileContainer from '@components/profile/Views/Container/Container';
import {IProfileTab, profileContext} from '@context/profileContext';
import ProfileTabsContent from '@components/profile/reused/Tabs/ProfileTabsContent';
import ProfileProjectsAll from '@components/investor/Views/Projects/ProjectsAll';
import ProfileProjectsUser from '@components/investor/Views/Projects/ProjectsUser';

export enum ProjectsTabsKeysEnum {
	ALL = 'all',
	USER = 'user'
}

const projectsTabs:IProfileTab[] = [
	{
		key: ProjectsTabsKeysEnum.ALL,
		isActive: false,
		name: 'Все'
	},
	{
		key: ProjectsTabsKeysEnum.USER,
		isActive: false,
		name: 'Мои'
	}
];

export const ProfileProjects: FC = () => {

	const
		{setProjects} = useAppActions(),
		{setTabs} = useContext(profileContext)
	;

	useEffect(() => {
		setTabs(projectsTabs, 0);
		setProjects([]);

		return () => {
			setTabs([])
		}
	}, []);

	return (
		<ProfileContainer title={ProfilePagesTitleEnum.PROJECTS}>
			<ProfileTabsContent
				items={{
					[ProjectsTabsKeysEnum.ALL]: <ProfileProjectsAll/>,
					[ProjectsTabsKeysEnum.USER]: <ProfileProjectsUser/>
				}}
			/>
		</ProfileContainer>
	);
};

import React, {FC} from 'react';
import {IQuestionTheme} from '@common/types';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import classNames from 'classnames';
import styles from '../../questions.module.scss';
import {defineIcon} from '@components/investor/Views/Questions/utils';
import {Accordion} from 'react-bootstrap';

interface IProfileQuestionsThemeOutputProps {
	currentTheme: IQuestionTheme
}

const ProfileQuestionsCommonThemeOutput:FC<IProfileQuestionsThemeOutputProps> = ({currentTheme}) => {
	const {type, title, icon, questions} = currentTheme;

	return (
		<div>
			<Heading
				tag={'h3'}
				variant={HeadingVariant.large}
				className={
					classNames(
						'pb-3 d-flex',
						styles.resultsTitle,
						type && `theme--${type}`
					)
				}
			>
				{defineIcon(icon)}
				<span>{title}</span>
			</Heading>
			<div className={styles.accordion}>
				<Accordion>
					{
						questions.map(({question, questionId, answer}) => {
							return (
								<Accordion.Item key={questionId} eventKey={questionId.toString()}>
									<Accordion.Header className={'text-md'} as={'div'}>{question}</Accordion.Header>
									<Accordion.Body className={'text-sm text-dark'}>{answer}</Accordion.Body>
								</Accordion.Item>
							)
						})
					}
				</Accordion>
			</div>
		</div>
	);
};

export default ProfileQuestionsCommonThemeOutput;

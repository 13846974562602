import React, {FC, useContext, useState} from 'react';
import {ProfileDataContacts, ProfileDataPassport, ProfileDataPersonal} from '@components/investor/Views/Data/Sections';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {
	entityFields,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION, INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_INN
} from '@components/investor/Views/Data/constants';
import ProfileDataFields from '@components/investor/Views/Data/Ui/ProfileDataFields';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import styles from '@components/investor/Views/Data/data.module.scss';
import {validateInnMinMax} from '@common/utils';
import {ADAPTIVE_BREAKPOINTS, INN_MAX_LENGTH, INN_MIN_LENGTH} from '@common/constants';
import {FormGroup} from '@components/ui';
import {useFormContext} from 'react-hook-form';
import ProfileDataControls from '@components/investor/Views/Data/Ui/ProfileDataControls';
import {personalDataContext} from '@context/personalDataContext';
import {getDefaultParams} from '@components/investor/Views/Data/utils';
import {useAppSelector} from '@hooks/useAppSelector';
import ProfileDataEditButton from '@components/investor/Views/Data/Ui/EditButton/EditButton';
import useWindowSize from '@hooks/useWindowSize';

const ProfileDataPageEntityDefault:FC = () => {

	const
		{
			watch,
			setValue,
			getValues,
			clearErrors,
			register,
			reset,
			formState: {errors}
		} = useFormContext(),
		{editMode, setEditMode} = useContext(personalDataContext),
		{investorProfile} = useAppSelector(state => state.user),
		{width: ww} = useWindowSize()
	;

	const
		[innFieldPlaceIndex] = useState<number>(3),
		handleAddressMatchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
			setValue(
				INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION,
				event.target.checked
					? getValues(INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION)
					: ''
			);

			clearErrors(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION)
		}
	;

	return (
		<div className='position-relative pt-5 pt-sm-0'>
			<ProfileDataEditButton isVisible={ww < ADAPTIVE_BREAKPOINTS.sm}/>
			<ProfileDataContacts/>
			<ProfileDataPersonal/>
			<ProfileDataPassport/>
			<ProfileSection title={'Данные организации'}>
				<ProfileDataFields fields={[
					...entityFields.slice(0, innFieldPlaceIndex),
					{
						label: 'ИНН',
						id: INVESTOR_FIELD_NAME_ORG_INN,
						type: FormGroupTypes.NUMBER,
						registerOptions: {
							validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
						}
					},
					...entityFields.slice(innFieldPlaceIndex)
				]}>
					<div className="col-sm-6 col-md-4">
						<FormGroup
							id={INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION}
							fieldType={FormGroupTypes.TEXT}
							label={'Фактический адрес организации'}
							value={watch(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION)}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION, event.target.value)}
							className={styles.formGroup}
							register={register}
							readonly={!editMode}
							errorMessage={errors[INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION]?.message}
							name={INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION}
						/>
					</div>
					<div className="col-sm-6 col-md-4">
						<FormGroup
							label={'Фактический адрес совпадает с юридическим'}
							id={'is-registration-match-location-address'}
							fieldType={FormGroupTypes.CHECKBOX}
							className={'pb-0'}
							readonly={!editMode}
							onChange={handleAddressMatchChange}
							checked={getValues(INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION) === getValues(INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION)}
						/>
					</div>
				</ProfileDataFields>
			</ProfileSection>
			<ProfileDataControls
				submitText={'Сохранить изменения'}
				onCancel={() => {
					reset(getDefaultParams(investorProfile));
					setEditMode(false)
				}}
			/>
		</div>
	);
};

export default ProfileDataPageEntityDefault;

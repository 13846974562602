import React, {FC, useContext} from 'react';
import {Modal} from 'react-bootstrap';
import VerificationCode from '@components/auth/VerificationCode/VerificationCode';
import {investContext} from '@context/investContext';
import operationsApi from '@api/operationsApi';
import {CODES_RESPONSE_SUCCESS, CUSTOM_EVENT__SET_MODAL_CODE_FOCUS} from '@common/constants';
import {useEmitter} from '@hooks/customEvent';
import {profileContext} from '@context/profileContext';

const InvestCancelConfirmModal:FC = () => {

	const
		{
			cancelConfirmModal, setCancelConfirmModal,
			updateDetailedProject,
			currentProjectId,
			setCancelSuccessModal
		} = useContext(investContext),
		{setSmsError} = useContext(profileContext),
		[cancelInvestRequest, {isLoading: cancelInvestIsLoading}] = operationsApi.useCancelInvestRequestMutation(),
		[checkCode, {isLoading: codeIsChecking}] = operationsApi.useCancelInvestConfirmMutation(),
		setModalCodeFocus = useEmitter(CUSTOM_EVENT__SET_MODAL_CODE_FOCUS)
	;

	const
		onCodeEntered = (code: string) => {
			checkCode(code)
				.unwrap()
				.then(response => {
					if (response.code === CODES_RESPONSE_SUCCESS && currentProjectId && Boolean(currentProjectId.toString())) {

						setCancelConfirmModal(false);
						setCancelSuccessModal(true);
						updateDetailedProject(currentProjectId)
					}
				})
				.catch(() => setSmsError(true))
		},
		onCodeResend = () => {
			currentProjectId && cancelInvestRequest(currentProjectId)
		}
	;

	return (
		<Modal
			show={cancelConfirmModal}
			onHide={() => setCancelConfirmModal(false)}
			centered
			onEntered={() => setModalCodeFocus()}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<VerificationCode
					isLoading={codeIsChecking || cancelInvestIsLoading}
					onComplete={onCodeEntered}
					onResend={onCodeResend}
				/>
			</Modal.Body>
		</Modal>
	)
};

export default InvestCancelConfirmModal;

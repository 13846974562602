import React, {FC, ReactNode} from 'react';
import styles from './link.module.scss';
import classNames from 'classnames';
import {LinkIconsVariant, LinkProps, LinkVariant} from '@components/ui/Link/Link.props';
import {ReactComponent as VKIcon} from '@icons/vk.svg';
import {ReactComponent as InstagramIcon} from '@icons/instagram.svg';
import {ReactComponent as TelegramIcon} from '@icons/telegram.svg';
import {ReactComponent as PlusIcon} from '@icons/plus.svg';
import {ReactComponent as ArrowRightIcon} from '@icons/arrow-right.svg';
import {Link} from 'react-router-dom';

const CustomLink: FC<LinkProps> = (
	{
		variant,
		children,
		className,
		icon ,
		href = '#',
		target,
		to,
		onClick,
		...props
	}
): JSX.Element => {

	const generateIcon = (): JSX.Element => {
		switch (icon) {
			case LinkIconsVariant.instagram:
				return <VKIcon/>;
			case LinkIconsVariant.telegram:
				return <InstagramIcon/>;
			case LinkIconsVariant.vk:
				return <TelegramIcon/>;
			case LinkIconsVariant.plus:
				return <PlusIcon/>;
			default: return <></>
		}
	};

	const generateContent = (): ReactNode => {
		switch (variant) {
			case LinkVariant.arrow:
				return (
					<>
						{children}
						<span className={classNames(styles.arrowCircle)}>
							<ArrowRightIcon/>
						</span>
					</>
				);
			case LinkVariant.icon:
				return generateIcon();
			case LinkVariant.simple:
				return children;
			default: return <></>
		}
	};

	return (
		to
			? <Link className={classNames(styles.root, styles[variant], className)} to={to}>{generateContent()}</Link>
			: <a onClick={onClick} href={href} className={classNames(styles.root, styles[variant], className)}{...props} target={target}>{generateContent()}</a>
	)
};

export default CustomLink

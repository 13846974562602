import React, {FC, useContext} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {authContext} from '@context/authContext';
import styles from './aboutModal.module.scss';
import aboutStyles from '../../investor/Views/About/about.module.scss';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import classNames from 'classnames';
import {Heading} from '@components/ui';
import LazyImage from '@components/common/LazyImage/LazyImage';
import FounderPhoto from '@images/founder-photo-small.jpg';
import utilsApi from '@api/utilsApi';
import ProfileDocumentBox from '@components/profile/reused/DocumentBox/DocumentBox';
import {ABOUT_MESSAGES, ABOUT_TITLE} from '@common/constants';

const AuthAboutModal:FC = () => {
	const
		{aboutModalShown, setAboutModalShown} = useContext(authContext),
		[
			getCompanyDocuments,
			{data: companyDocuments = [], isLoading: companyDocumentsIsLoading}
		] = utilsApi.useLazyCompanyDocumentsQuery()
	;

	return (
		<Modal
			show={aboutModalShown}
			onHide={() => {
				setAboutModalShown(false);
			}}
			dialogClassName={classNames(styles.dialog, 'is-wide')}
			centered
			onEntered={() => {
				getCompanyDocuments()
			}}
		>
			<Modal.Header closeButton/>
			<Modal.Body>
				<div className='row pt-lg-4'>
					<div className='col-lg-7'>
						<Heading
							tag={'h3'}
							variant={HeadingVariant.large}
							className={classNames(styles.title, 'pt-2 pb-4')}
						>
							{ABOUT_TITLE}
						</Heading>
						{ABOUT_MESSAGES.map((m, i) => (<p className={styles.description} key={i}>{m}</p>))}
					</div>
					<div className='col-lg-4 ms-auto text-lg-center pt-4 pt-lg-0'>
						<div className={classNames(aboutStyles.founderImage, 'd-inline-block')}>
							<LazyImage
								src={FounderPhoto}
								alt='Founder photo'
							/>
						</div>
						<figcaption className={'pt-sm-4'}>
							<p className={aboutStyles.founderName}>Дмитрий Панфилов</p>
							<p className={aboutStyles.founderPost}>Основатель краудлендинговой платформы PANFILOV</p>
						</figcaption>
					</div>
				</div>
				<section className={classNames(styles.documents, 'pt-5')}>
					<Heading
						tag={'h2'}
						variant={HeadingVariant.large}
						className={'pb-4'}
					>Документы</Heading>

					{
						companyDocumentsIsLoading
							? <Spinner className={'spinner--centered'} animation='border' variant='primary'/>
							: <div className="row g-3 g-sm-4">
								{
									companyDocuments.map(({documentId, attachment, link, title}) => (
										<div key={documentId} className="col-md-6 col-lg-4">
											<ProfileDocumentBox link={link} title={title} attachment={attachment} className={'h-100'}/>
										</div>
									))
								}
							</div>
					}
				</section>
			</Modal.Body>
		</Modal>
	);
};

export default AuthAboutModal;

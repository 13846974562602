import React, { FC, useContext } from "react";
import AuthCreatePassword from "@components/auth/CreatePassword/CreatePassword";
import authApi from "@api/authApi";
import { authContext } from "@context/authContext";
import { useAppActions } from "@hooks/useAppActions";
import { useHistory } from "react-router-dom";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";

const RegistrationPassword: FC = (): JSX.Element => {
	const [createPassword] = authApi.useRegisterCreatePasswordMutation(),
		{ phone, email, rulesAgreement, personalDataAgreement, code, setCode } =
			useContext(authContext),
		{ setAuthLoading, setToken } = useAppActions(),
		history = useHistory();
	const handlePasswordChange = (password: string) => {
		setAuthLoading(true);

		if (phone) {
			createPassword({
				phone,
				email,
				isAgreementWithRules: rulesAgreement,
				isPersonalDataProcessing: personalDataAgreement,
				smsCode: code,
				password,
			})
				.unwrap()
				.then((payload) => {
					setToken(payload.token);
					setCode("");

					if ('ym' in window) {
						// @ts-expect-error YM call
						window.ym(process.env.REACT_APP_YM_COUNTER_ID, 'reachGoal', 'after-register-success')
					}

					history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.LOGIN);
				})
				.finally(() => setAuthLoading(false));
		}
	};

	return (
		<AuthCreatePassword
			title={"Придумайте пароль"}
			onPasswordSubmit={handlePasswordChange}
		/>
	);
};

export default RegistrationPassword;

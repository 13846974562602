import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {
	IUserAnalyticsFinancesResponse,
	IUserAnalyticsInfoResponse,
	IUserAnalyticsProjectsResponse
} from '@features/user/types';
import {
	API_USER_ANALYTIC_FINANCES_URL,
	API_USER_ANALYTIC_INFO_URL,
	API_USER_ANALYTIC_PROJECTS_URL
} from '@common/constants';


const analyticsApi = createApi({
	reducerPath: 'analyticsApi',
	refetchOnMountOrArgChange: true,
	baseQuery,
	endpoints: build => ({
		info: build.query<IUserAnalyticsInfoResponse, void>({
			query: () => API_USER_ANALYTIC_INFO_URL
		}),
		finances: build.query<IUserAnalyticsFinancesResponse, void>({
			query: () => API_USER_ANALYTIC_FINANCES_URL
		}),
		projects: build.query<IUserAnalyticsProjectsResponse, void>({
			query: () => API_USER_ANALYTIC_PROJECTS_URL
		})
	})
});

export default analyticsApi;

import React, {Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import styles from '@components/investor/Views/Data/data.module.scss';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {
	MESSAGE_VALIDATION_REQUIRED_FIELD,
	PASSPORT_DIVISION_CODE_FORMAT,
	PASSPORT_NUMBER_FORMAT
} from '@common/constants';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {personalDataContext} from '@context/personalDataContext';
import {
	INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE,
	INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE,
	INVESTOR_FIELD_NAME_PASSPORT_FIRST_PAGE,
	INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY,
	INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN,
	INVESTOR_FIELD_NAME_PASSPORT_NUMBER,
	INVESTOR_FIELD_NAME_PASSPORT_SECOND_PAGE,
	INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS
} from '@components/investor/Views/Data/constants';
import {Controller, useFormContext} from 'react-hook-form';
import utilsApi from '@api/utilsApi';
import {fileListToFormData, validatePassportDivisionCode, validatePassportNumber} from '@common/utils';
import {IAttachment} from '@common/types';
import classNames from 'classnames';

export const ProfileDataPassport:FC = () => {

	const
		[passportFirstPageIsLoading, setPassportFirstPageIsLoading] = useState<boolean>(false),
		[passportSecondPageIsLoading, setPassportSecondPageIsLoading] = useState<boolean>(false)
	;

	const
		{
			passportFirstPage,
			setPassportFirstPage,
			passportSecondPage,
			setPassportSecondPage,
			editMode
		} = useContext(personalDataContext),
		[fileUtility] = utilsApi.useFileMutation(),
		{
			register,
			setValue,
			watch,
			formState: {errors},
			control
		} = useFormContext()
	;

	const
		handlePassportScanChange = (
			files: FileList | null,
			key: string,
			setter: Dispatch<SetStateAction<IAttachment | null>>,
			onStart: () => void,
			onEnd: () => void
		) => {
			onStart();

			if (files && files.length > 0) {
				fileUtility({
					formData: fileListToFormData(files, key)
				})
					.unwrap()
					.then(response => setter(response[0]))
					.catch(() => setter(null))
					.finally(onEnd)
			}
		}
	;

	return (
		<ProfileSection
			title={'Паспортные данные'}
			className={styles.editPassword}
		>
			<div className="row gx-3 gy-sm-4">
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataPassportNumber'}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						numberFormat={PASSPORT_NUMBER_FORMAT}
						onValueChange={({value}) => {
							setValue(INVESTOR_FIELD_NAME_PASSPORT_NUMBER, value);
						}}
						label={'Серия, номер'}
						placeholder={'0000 000000'}
						value={watch(INVESTOR_FIELD_NAME_PASSPORT_NUMBER)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_PASSPORT_NUMBER}
						errorMessage={errors[INVESTOR_FIELD_NAME_PASSPORT_NUMBER]?.message}
						registerOptions={{validate: validatePassportNumber}}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataPassportIssuedBy'}
						fieldType={FormGroupTypes.TEXT}
						onChange={event => {
							setValue(INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY, event.target.value)
						}}
						label={'Кем выдан'}
						value={watch(INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY}
						errorMessage={errors[INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<Controller
						name={INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN}
						control={control}
						rules={{
							required: MESSAGE_VALIDATION_REQUIRED_FIELD
						}}
						render={(
							{
								field: { onChange, onBlur, value },
								fieldState: {error},
							}
						) => (
							<FormGroup
								id={'profileDataPassportIssuedWhen'}
								fieldType={FormGroupTypes.DATE}
								onDateChange={onChange}
								onBlur={onBlur}
								label={'Когда выдан'}
								placeholder={'Выберите дату'}
								selected={value ? new Date(value) : null}
								readonly={!editMode}
								className={styles.formGroup}
								errorMessage={error?.message}
							/>
						)}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataPassportDivisionCode'}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						numberFormat={PASSPORT_DIVISION_CODE_FORMAT}
						onValueChange={({value}) => {
							setValue(INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE, value);
						}}
						label={'Код подразделения'}
						value={watch(INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE).replace('-', '')}
						placeholder={'000 000'}
						name={INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						errorMessage={errors[INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE]?.message}
						registerOptions={{validate: validatePassportDivisionCode}}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataPassportBirthPlace'}
						fieldType={FormGroupTypes.TEXT}
						onChange={event => {
							setValue(INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE, event.target.value)
						}}
						label={'Место рождения'}
						value={watch(INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE}
						errorMessage={errors[INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4 col-xl-6 col-hd-4">
					<FormGroup
						id={'profileDataRegistrationAddress'}
						fieldType={FormGroupTypes.TEXT}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS, event.target.value)}
						label={'Адрес по прописке'}
						value={watch(INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS)}
						readonly={!editMode}
						className={styles.formGroup}
						register={register}
						name={INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS}
						errorMessage={errors[INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS]?.message}
					/>
				</div>
			</div>

			<div className={'mt-4'}>
				<div className="row gx-3">
					<div className="col-sm-6 col-lg-4 col-lt-3 mb-4 mb-sm-0">
						<Controller
							name={INVESTOR_FIELD_NAME_PASSPORT_FIRST_PAGE}
							control={control}
							rules={{
								required: passportFirstPage ? false : MESSAGE_VALIDATION_REQUIRED_FIELD
							}}
							render={
								({
									 field: {onChange},
									 fieldState: {error},
								 }) => {
									return (
										<FormGroup
											id={'passportFirstPageScan'}
											fieldType={FormGroupTypes.FILE}
											label={'Скан паспорта'}
											readonly={!editMode}
											className={classNames(styles.formGroup, 'mb-0 h-100')}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												onChange(event);
												handlePassportScanChange(
													event.target.files,
													INVESTOR_FIELD_NAME_PASSPORT_FIRST_PAGE,
													setPassportFirstPage,
													() => setPassportFirstPageIsLoading(true),
													() => setPassportFirstPageIsLoading(false)
												)
											}}
											acceptedFiles={['image/*']}
											isLoading={passportFirstPageIsLoading}
											fileAttachment={passportFirstPage}
											onAttachmentDelete={() => {
												setPassportFirstPage(null);
												setValue(INVESTOR_FIELD_NAME_PASSPORT_FIRST_PAGE, null)
											}}
											errorMessage={error?.message}
										/>
									)
								}
							}
						/>
					</div>
					<div className="col-sm-6 col-lg-4 col-lt-3">
						<Controller
							name={INVESTOR_FIELD_NAME_PASSPORT_SECOND_PAGE}
							control={control}
							rules={{
								required: passportSecondPage ? false : MESSAGE_VALIDATION_REQUIRED_FIELD
							}}
							render={
								({
									 field: { onChange},
									 fieldState: {error},
								 }) => {
									return (
										<FormGroup
											id={'passportSecondPageScan'}
											fieldType={FormGroupTypes.FILE}
											label={'Скан прописки'}
											readonly={!editMode}
											className={classNames(styles.formGroup, 'mb-0 h-100')}
											acceptedFiles={['image/*']}
											onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
												onChange(event);
												handlePassportScanChange(
													event.target.files,
													INVESTOR_FIELD_NAME_PASSPORT_SECOND_PAGE,
													setPassportSecondPage,
													() => setPassportSecondPageIsLoading(true),
													() => setPassportSecondPageIsLoading(false)
												)
											}}
											isLoading={passportSecondPageIsLoading}
											fileAttachment={passportSecondPage}
											onAttachmentDelete={() => {
												setPassportSecondPage(null);
												setValue(INVESTOR_FIELD_NAME_PASSPORT_SECOND_PAGE, undefined)
											}}
											errorMessage={error?.message}
										/>
									)
								}
							}
						/>
					</div>
				</div>
			</div>
		</ProfileSection>
	);
};








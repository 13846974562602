import React, { FC } from "react";
import styles from "./profileNav.module.scss";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "@hooks/useAppSelector";
import { IProfileNavigationItem } from "@common/types";

const ProfileNav: FC<{
	items: IProfileNavigationItem[];
	className?: string;
	onLinkClick?: () => void;
}> = ({ items, className, onLinkClick }) => {
	const { sections } = useAppSelector((state) => state.utils);

	return (
		<div className={classNames(styles.root, className)}>
			<nav className={classNames(styles.nav, "text-dark")}>
				{items.map(({ name, Icon, path, code }) => {
					const currentSection = sections.find(
						(section) => section.code === code,
					);

					return currentSection?.enabled ? (
						<NavLink
							key={path}
							to={path}
							className={classNames(styles.link)}
							activeClassName={"is-active"}
							onClick={onLinkClick}>
							<span className={"icon"}>
								<Icon />
							</span>
							<span className={"name"}>{name}</span>
						</NavLink>
					) : null;
				})}
			</nav>
		</div>
	);
};

export default ProfileNav;

import React, { FC, useContext, useEffect, useState } from "react";
import { Button, FormGroup, Paragraph } from "@components/ui";
import classNames from "classnames";
import dialogStyles from "@components/auth/Dialog/dialog.module.scss";
import {
	AUTH_PASSWORD_CREATE_RULES_NOTE,
	AUTH_PASSWORD_REGEXP,
	PASSWORD_INCORRECT_MESSAGE,
	PASSWORD_MISMATCH_MESSAGE,
} from "@common/constants";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { useAppSelector } from "@hooks/useAppSelector";
import { useAppActions } from "@hooks/useAppActions";
import { authContext } from "@context/authContext";

interface ICreatePasswordProps {
	onPasswordSubmit: (password: string) => void;
	title: string;
}

const AuthCreatePassword: FC<ICreatePasswordProps> = ({
	onPasswordSubmit,
	title,
}) => {
	const { isLoading } = useAppSelector((state) => state.auth),
		{ setAuthLoading } = useAppActions(),
		{ updateError } = useContext(authContext),
		[password, setPassword] = useState<string>(""),
		[rePassword, setRePassword] = useState<string>(""),
		[buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	const handlePasswordChange = (
			event: React.ChangeEvent<HTMLInputElement>,
		): void => setPassword(event.target.value),
		handleRePasswordChange = (
			event: React.ChangeEvent<HTMLInputElement>,
		): void => setRePassword(event.target.value);
	useEffect(() => {
		setButtonDisabled(
			(!Boolean(rePassword) && !Boolean(rePassword)) || isLoading,
		);
	}, [password, rePassword, isLoading]);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (rePassword === password) {
			if (AUTH_PASSWORD_REGEXP.test(password)) {
				onPasswordSubmit(password);
			} else {
				updateError(PASSWORD_INCORRECT_MESSAGE);
				setAuthLoading(false);
			}
		} else {
			updateError(PASSWORD_MISMATCH_MESSAGE);
			setAuthLoading(false);
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<div className='text-center pb-4 mb-3'>
				<Paragraph className={classNames(dialogStyles.title)}>
					{title}
				</Paragraph>
				<Paragraph className={"text-dark px-xl-4"}>
					<small>{AUTH_PASSWORD_CREATE_RULES_NOTE}</small>
				</Paragraph>
			</div>

			<FormGroup
				id={"authLoginPass"}
				fieldType={FormGroupTypes.PASSWORD}
				label={"Новый пароль"}
				value={password}
				onChange={handlePasswordChange}
				placeholder={"Введите пароль"}
			/>
			<FormGroup
				id={"authLoginPass"}
				fieldType={FormGroupTypes.PASSWORD}
				label={"Повторите пароль"}
				value={rePassword}
				onChange={handleRePasswordChange}
				placeholder={"Повторите пароль"}
			/>

			<div className='text-center pt-5 mt-2'>
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={"submit"}
					as={ButtonTypesEnum.BUTTON}>
					Сохранить пароль
				</Button>
			</div>
		</form>
	);
};

export default AuthCreatePassword;

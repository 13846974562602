import {BorrowerProfileKeysEnum, IBorrowerProfile} from '@features/user/types';
import {validateDate} from '@common/utils';

export const getDefaultParams = (profileData: IBorrowerProfile):Partial<IBorrowerProfile> => {
	return profileData
		? Object.keys(profileData).reduce((acc , currentValue, ) => {
			const a = acc as IBorrowerProfile;

			switch (currentValue) {
				case BorrowerProfileKeysEnum.ORG_NAME:
				case BorrowerProfileKeysEnum.INN:
				case BorrowerProfileKeysEnum.OGRN:
				case BorrowerProfileKeysEnum.LEGAL_ADDRESS:
				case BorrowerProfileKeysEnum.ACTUAL_ADDRESS:
				case BorrowerProfileKeysEnum.LAST_NAME:
				case BorrowerProfileKeysEnum.FIRST_NAME:
				case BorrowerProfileKeysEnum.MIDDLE_NAME:
				case BorrowerProfileKeysEnum.GENITIVE_FIO:
				case BorrowerProfileKeysEnum.POST:
				case BorrowerProfileKeysEnum.PASSPORT_NUMBER:
				case BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY:
				case BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE:
				case BorrowerProfileKeysEnum.BIRTH_PLACE:
				case BorrowerProfileKeysEnum.REGISTRATION_ADDRESS:
				case BorrowerProfileKeysEnum.PHONE:
				case BorrowerProfileKeysEnum.EMAIL:
				case BorrowerProfileKeysEnum.SNILS:
					a[currentValue] = profileData[currentValue];
					break;

				case BorrowerProfileKeysEnum.BIRTH_DATE:
				case BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN:
					a[currentValue] = validateDate(profileData[currentValue]);
					break;
			}
			return acc
		}, {})
		: {}
};

import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import {Button, FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {REQUIRED_CODE_LENGTH, CODE_NUMBER_FORMAT,} from '@common/constants';
import {useAppSelector} from '@hooks/useAppSelector';
import {authContext} from '@context/authContext';
import RecoveryNote from '@components/common/RecoveryNote/RecoveryNote';
import ResendCode from '@components/common/ResendCode/ResendCode';
import {useAppActions} from '@hooks/useAppActions';
import authApi from '@api/authApi';
import {useHistory} from 'react-router-dom';
import {AuthRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {phoneToString} from '@common/utils';

const RegistrationConfirm: FC = (props): JSX.Element => {
	const
		{
			phone,
			email,
			rulesAgreement,
			personalDataAgreement,
			code,
			updateCode,
			registrationRequestHandler,
			clearCode,
			setCode,
			smsError, setSmsError
		} = useContext(authContext),
		{setAuthLoading} = useAppActions(),
		[confirmRegistration] = authApi.useRegisterConfirmMutation(),
		history = useHistory(),
		{isLoading} = useAppSelector(store => store.auth),
		[buttonDisabled, setButtonDisabled] = useState<boolean>(true),
		codeGroupRef = useRef<HTMLDivElement>(null)
	;

	const
		handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
			event.preventDefault();
			if (typeof phone === 'number') {
				setSmsError(false);
				setAuthLoading(true);

				confirmRegistration({
					email,
					phone,
					isAgreementWithRules: rulesAgreement,
					isPersonalDataProcessing: personalDataAgreement,
					smsCode: code
				})
					.unwrap()
					.then(() => {
						history.push(RouteNamesEnum.AUTH+AuthRouteNamesEnum.REGISTRATION_PASSWORD);
						setSmsError(false)
					})
					.catch(() => setSmsError(true))
					.finally(() => setAuthLoading(false))
			}
		}
	;

	useEffect(() => {
		setButtonDisabled(isLoading || code.length < REQUIRED_CODE_LENGTH)
	}, [code, isLoading]);

	useEffect(() => {
		if (smsError) {
			setCode('');
			codeGroupRef.current?.querySelector('input')?.focus();
		}
	}, [smsError]);

	useEffect(() => {
		codeGroupRef.current?.querySelector('input')?.focus()
	}, []);

	return (
		<form {...props} onSubmit={handleSubmit}>
			<RecoveryNote
				phone={phoneToString(phone)}
				message={'Для регистрации на платформе мы отправили СМС с кодом на номер'}
			/>

			<FormGroup
				id={'authRegistrationSmsCode'}
				numberFormat={CODE_NUMBER_FORMAT}
				fieldType={FormGroupTypes.NUMBER_FORMAT}
				label={'Код из СМС'}
				onValueChange={updateCode}
				value={code}
				rootRef={codeGroupRef}
			/>

			<ResendCode onResendCode={() => {
				registrationRequestHandler();
				clearCode();
			}}>
				<a
					href={'#'}
					className={'text-decoration-underline'}
					onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
						event.preventDefault();
						history.push(RouteNamesEnum.AUTH+AuthRouteNamesEnum.REGISTRATION_REQUEST)
					}}
				>
					<small>Указать другой номер</small>
				</a>
			</ResendCode>


			<div className="text-center pt-5 mt-2">
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={'submit'}
					as={ButtonTypesEnum.BUTTON}
				>Зарегистрироваться</Button>
			</div>
		</form>
	);
};

export default RegistrationConfirm;

import React, {FC, useContext} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {ReactComponent as LogoIcon} from '@icons/auth-logo.svg';
import {profileContext} from '@context/profileContext';
import {useAppSelector} from '@hooks/useAppSelector';
import classNames from 'classnames';
import notificationsRootStyles from '@components/profile/Header/Notifications/notifications.module.scss';
import ProfileNotificationItem from '@components/profile/Header/Notifications/NotificationItem';
import {CODES_RESPONSE_SUCCESS} from '@common/constants';
import notificationsApi from '@api/notificationsApi';
import {useAppActions} from '@hooks/useAppActions';
import styles from './notificationsModal.module.scss';


const ProfileNotificationsModal:FC = () => {

	const
		{mobileNotificationsShown, setMobileNotificationsShown} = useContext(profileContext),
		{notifications} = useAppSelector(state => state.notifications),
		[deleteNotificationApi, {isLoading: notificationIsDeleting}] = notificationsApi.useDeleteMutation(),
		{deleteNotification} = useAppActions()
	;

	return (
		<Modal
			show={mobileNotificationsShown}
			onHide={() => {
				setMobileNotificationsShown(false);
			}}
			centered
		>
			<Modal.Header closeButton>
				<LogoIcon className={'modal-header-logo'}/>
			</Modal.Header>
			<Modal.Body className={styles.body}>
				<div className='position-relative mt-2 mb-4'>
					<p className='text-weight-medium text-dark mb-0'>Уведомления</p>
					{notificationIsDeleting && <Spinner className={styles.loader} size={'sm'} animation='border' variant='primary'/>}
				</div>

				<ul className={classNames(notificationsRootStyles.list, styles.list, 'mb-0')}>
					{
						notifications.map((notification) => {

							const {
								notificationId,
								typeCode,
								message,
								viewed
							} = notification;

							return (
								<ProfileNotificationItem
									key={notificationId}
									viewed={viewed}
									typeCode={typeCode}
									message={message}
									className={styles.listItem}
									onDelete={() => {
										deleteNotificationApi(notificationId)
											.unwrap()
											.then(response => {
												if (response.code === CODES_RESPONSE_SUCCESS) {
													deleteNotification(notificationId);
												}
											})
									}}
								/>
							)
						})
					}
				</ul>
			</Modal.Body>
		</Modal>
	);
};

export default ProfileNotificationsModal;

import {IQuestionIconsEnum, IQuestionThemesEnum} from '@common/types';
import {ReactComponent as InfoIcon} from '@icons/info-filled.svg';
import {ReactComponent as ActivityIcon} from '@icons/activity.svg';
import {ReactComponent as ChartIcon} from '@icons/chart.svg';
import {ReactComponent as TimeIcon} from '@icons/time.svg';
import {ReactComponent as CheckIcon} from '@icons/check-square-default.svg';
import React from 'react';

const
	defineIcon = (iconName: string): JSX.Element => {
		switch (iconName) {
			case IQuestionIconsEnum.INFO: return <InfoIcon/>;
			case IQuestionIconsEnum.ACTIVITY: return <ActivityIcon/>;
			case IQuestionIconsEnum.CHART: return <ChartIcon/>;
			case IQuestionIconsEnum.TIME: return <TimeIcon/>;
			case IQuestionIconsEnum.CHECK: return <CheckIcon/>;
			default: return <></>;
		}
	},
	defineBoxTheme = (iconName: string, themeName: string):{icon: JSX.Element, theme: IQuestionThemesEnum | null} => {
		const icon = defineIcon(iconName);
		let theme = null;

		switch (themeName) {
			case IQuestionThemesEnum.INFO: theme = IQuestionThemesEnum.INFO; break;
			case IQuestionThemesEnum.DANGER: theme = IQuestionThemesEnum.DANGER; break;
			case IQuestionThemesEnum.SUCCESS: theme = IQuestionThemesEnum.SUCCESS; break;
			case IQuestionThemesEnum.WARNING: theme = IQuestionThemesEnum.WARNING; break;
		}

		return {icon, theme}
	}
;

export {defineIcon, defineBoxTheme}

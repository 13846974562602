import React, {FC, useContext, useEffect} from 'react';
import styles from '@components/investor/Project/project.module.scss';
import {Accordion} from 'react-bootstrap';
import {Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {IDocument} from '@features/utils/types';
import {useCashedProject} from '@hooks/useCashedProject';
import useBoolean from '@hooks/useBoolean';
import {projectsContext} from '@context/projectsContext';
import {IProject, ParameterizedDocumentsEnum} from '@features/projects/types';
import ProfileProjectAccordionDocument from '@components/investor/Project/Accordion/ProjectAccordionDocument';
import {ProjectDetailsSectionsEnum} from '@components/investor/Project/Accordion/ProjectAccordion';
import {parameterizedDocumentIsValid} from '@common/utils';

const ProfileProjectAccordionDocuments:FC<{projectId: IProject['projectId']}> = ({projectId}) => {

	const
		detailCashed = useCashedProject(projectId),
		{isParameterizedDocumentCashed, getParameterizedDocument} = useContext(projectsContext)
	;

	const {
		value: cashedDocumentsIsExist,
		setValue: setCashedDocumentsExist
	} = useBoolean(false);

	useEffect(() => {
		const documents = detailCashed?.documents;
		setCashedDocumentsExist(Boolean(documents && documents.length > 0))
	}, [detailCashed]);

	const
		offerDocument = getParameterizedDocument(projectId, ParameterizedDocumentsEnum.OFFER),
		conditionsDocument = getParameterizedDocument(projectId, ParameterizedDocumentsEnum.CONDITIONS),
		acceptDocument = getParameterizedDocument(projectId, ParameterizedDocumentsEnum.ACCEPT)
	;

	return (
		<Accordion.Item className={styles.section} eventKey={ProjectDetailsSectionsEnum.DOCUMENTS+projectId}>
			<Accordion.Header as={'div'}>
				<Heading
					tag={'h4'}
					variant={HeadingVariant.middle}
					className={'mb-0'}
				>Документы</Heading>
			</Accordion.Header>
			<Accordion.Body>
				<div className={'py-3'}>

					{
						(
							cashedDocumentsIsExist
							|| isParameterizedDocumentCashed(projectId, ParameterizedDocumentsEnum.OFFER)
							|| isParameterizedDocumentCashed(projectId, ParameterizedDocumentsEnum.CONDITIONS)
							|| isParameterizedDocumentCashed(projectId, ParameterizedDocumentsEnum.ACCEPT)
						)
							? <ul className="row mb-0 g-3">
								{
									(offerDocument && parameterizedDocumentIsValid(offerDocument)) && <li className='col-sm-6 col-lt-4'>
										<ProfileProjectAccordionDocument
											className='h-100'
											title={'Инвестиционное предложение'}
											attachment={null}
											link={offerDocument.url}
										/>
									</li>
								}

								{
									(conditionsDocument && parameterizedDocumentIsValid(conditionsDocument)) && <li className='col-sm-6 col-lt-4'>
										<ProfileProjectAccordionDocument
											className='h-100'
											title={'Индивидуальные условия договора займа инвестора '}
											attachment={null}
											link={conditionsDocument.url}
										/>
									</li>
								}

								{
									(acceptDocument && parameterizedDocumentIsValid(acceptDocument)) && <li className='col-sm-6 col-lt-4'>
										<ProfileProjectAccordionDocument
											className='h-100'
											title={' Акцепт инвестиционного предложения'}
											attachment={null}
											link={acceptDocument.url}
										/>
									</li>
								}


								{
									detailCashed?.documents.map(({attachment, link, title, documentId}: IDocument) => {
										return <li key={documentId} className='col-sm-6 col-lt-4'>
											<ProfileProjectAccordionDocument
												className='h-100'
												title={title}
												attachment={attachment}
												link={link}
											/>
										</li>
									})
								}
							</ul>
							: <p className={'mb-0 pb-2'}>Документы не найдены</p>
					}

				</div>
			</Accordion.Body>
		</Accordion.Item>
	);
};

export default ProfileProjectAccordionDocuments;

import {DetailedHTMLProps, HTMLAttributes, ReactNode} from 'react';

export enum ParagraphSize {
	small = 'small',
	default = 'default'
}

export interface ParagraphProps extends DetailedHTMLProps<HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement> {
	children: ReactNode,
	size?: ParagraphSize
}

import {BorrowerProfileKeysEnum} from '@features/user/types';

export const BORROWER_DATA_DEFAULT_VALUES = {
	[BorrowerProfileKeysEnum.ORG_NAME]: '',
	[BorrowerProfileKeysEnum.INN]: '',
	[BorrowerProfileKeysEnum.OGRN]: '',
	[BorrowerProfileKeysEnum.LEGAL_ADDRESS]: '',
	[BorrowerProfileKeysEnum.ACTUAL_ADDRESS]: '',
	[BorrowerProfileKeysEnum.LAST_NAME]: '',
	[BorrowerProfileKeysEnum.FIRST_NAME]: '',
	[BorrowerProfileKeysEnum.MIDDLE_NAME]: '',
	[BorrowerProfileKeysEnum.GENITIVE_FIO]: '',
	[BorrowerProfileKeysEnum.BIRTH_DATE]: '',
	[BorrowerProfileKeysEnum.POST]: '',
	[BorrowerProfileKeysEnum.PASSPORT_NUMBER]: '',
	[BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY]: '',
	[BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN]: '',
	[BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE]: '',
	[BorrowerProfileKeysEnum.BIRTH_PLACE]: '',
	[BorrowerProfileKeysEnum.REGISTRATION_ADDRESS]: '',
	[BorrowerProfileKeysEnum.PHONE]: '',
	[BorrowerProfileKeysEnum.EMAIL]: '',
	[BorrowerProfileKeysEnum.SNILS]: '',
};

export const BORROWER_REQUIRED_FOR_CREDIT_HISTORY_FIELDS_NAMES  = [
	BorrowerProfileKeysEnum.LAST_NAME,
	BorrowerProfileKeysEnum.FIRST_NAME,
	BorrowerProfileKeysEnum.MIDDLE_NAME,
	BorrowerProfileKeysEnum.BIRTH_DATE,
	BorrowerProfileKeysEnum.BIRTH_PLACE,
	BorrowerProfileKeysEnum.PASSPORT_NUMBER,
	BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN,
	BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY,
	BorrowerProfileKeysEnum.REGISTRATION_ADDRESS,
];

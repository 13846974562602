import React, {FC} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import {
	PASSPORT_DIVISION_CODE_FORMAT,
	PASSPORT_NUMBER_FORMAT
} from '@common/constants';
import {BorrowerProfileKeysEnum} from '@features/user/types';
import {Controller, useFormContext} from 'react-hook-form';
import {validatePassportDivisionCode, validatePassportNumber} from '@common/utils';

const BorrowerAccountDataPassport:FC = () => {

	const {
		register,
		setValue,
		watch,
		formState: {errors}
	} = useFormContext();

	return (
		<ProfileSection title={'Паспортные данные'}>
			<div className="row g-3">
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						label={'Серия номер'}
						id={'BorrowerAccountPassportNumber'}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						numberFormat={PASSPORT_NUMBER_FORMAT}
						onValueChange={({value}) => {
							setValue(BorrowerProfileKeysEnum.PASSPORT_NUMBER, value);
						}}
						placeholder={'0000 000000'}
						value={watch(BorrowerProfileKeysEnum.PASSPORT_NUMBER)}
						register={register}
						name={BorrowerProfileKeysEnum.PASSPORT_NUMBER}
						errorMessage={errors[BorrowerProfileKeysEnum.PASSPORT_NUMBER]?.message}
						registerOptions={{validate: validatePassportNumber}}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						id={'BorrowerAccountPassportSource'}
						fieldType={FormGroupTypes.TEXT}
						label={'Кем выдан'}
						onChange={event => {
							setValue(BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY, event.target.value)
						}}
						value={watch(BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY)}
						register={register}
						name={BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY}
						errorMessage={errors[BorrowerProfileKeysEnum.PASSPORT_ISSUED_BY]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<Controller
						name={BorrowerProfileKeysEnum.PASSPORT_ISSUED_WHEN}
						render={(
							{
								field: { onChange, onBlur, value },
								fieldState: {error},
							}
						) => (
							<FormGroup
								id={'BorrowerAccountPassportWhen'}
								fieldType={FormGroupTypes.DATE}
								label={'Когда выдан'}
								onDateChange={onChange}
								onBlur={onBlur}
								selected={value ? new Date(value) : null}
								errorMessage={error?.message}
							/>
						)}
					/>

				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						id={'BorrowerAccountPassportDivisionCode'}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						numberFormat={PASSPORT_DIVISION_CODE_FORMAT}
						label={'Код подразделения'}
						onValueChange={({value}) => {
							setValue(BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE, value);
						}}
						value={watch(BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE).replace('-', '')}
						name={BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE}
						register={register}
						errorMessage={errors[BorrowerProfileKeysEnum.PASSPORT_DIVISION_CODE]?.message}
						registerOptions={{validate: validatePassportDivisionCode}}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						id={'BorrowerAccountPassportBirthPlace'}
						fieldType={FormGroupTypes.TEXT}
						label={'Место рождения'}
						onChange={event => {
							setValue(BorrowerProfileKeysEnum.BIRTH_PLACE, event.target.value)
						}}
						value={watch(BorrowerProfileKeysEnum.BIRTH_PLACE)}
						register={register}
						name={BorrowerProfileKeysEnum.BIRTH_PLACE}
						errorMessage={errors[BorrowerProfileKeysEnum.BIRTH_PLACE]?.message}
					/>
				</div>
				<div className="col-sm-6 col-lg-4">
					<FormGroup
						id={'BorrowerAccountPassportAddress'}
						fieldType={FormGroupTypes.TEXT}
						label={'Адрес регистрации'}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.REGISTRATION_ADDRESS, event.target.value)}
						value={watch(BorrowerProfileKeysEnum.REGISTRATION_ADDRESS)}
						register={register}
						name={BorrowerProfileKeysEnum.REGISTRATION_ADDRESS}
						errorMessage={errors[BorrowerProfileKeysEnum.REGISTRATION_ADDRESS]?.message}
					/>
				</div>
			</div>
		</ProfileSection>
	);
};

export default BorrowerAccountDataPassport;

import React, { FC } from "react";
import ProfileSection from "@components/profile/Section/ProfileSection";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import { FormGroup } from "@components/ui";
import { useFormContext } from "react-hook-form";
import { BorrowerProfileKeysEnum } from "@features/user/types";
import { validatePhone } from "@common/utils";
import {
	EMAIL_REGEXP,
	MESSAGE_VALIDATION_INVALID_EMAIL,
	MESSAGE_VALIDATION_INVALID_SNILS,
	SNILS_FORMAT,
	SNILS_REGEXP,
} from "@common/constants";

const BorrowerAccountDataContacts: FC = () => {
	const {
		register,
		setValue,
		watch,
		formState: { errors },
	} = useFormContext();

	return (
		<ProfileSection title={"Контактные данные"}>
			<div className='row g-3'>
				<div className='col-sm-6 col-lg-4'>
					<FormGroup
						label={"Телефон"}
						id={"BorrowerAccountContactsPhone"}
						fieldType={FormGroupTypes.PHONE}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setValue(BorrowerProfileKeysEnum.PHONE, event)
						}
						value={watch(BorrowerProfileKeysEnum.PHONE)}
						placeholder={"8 (999) 999 99-99"}
						register={register}
						name={BorrowerProfileKeysEnum.PHONE}
						errorMessage={errors[BorrowerProfileKeysEnum.PHONE]?.message}
						registerOptions={{ validate: validatePhone }}
					/>
				</div>
				<div className='col-sm-6 col-lg-4'>
					<FormGroup
						label={"E-mail"}
						id={"BorrowerAccountContactsEmail"}
						fieldType={FormGroupTypes.EMAIL}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
							setValue(BorrowerProfileKeysEnum.EMAIL, event.target.value)
						}
						value={watch(BorrowerProfileKeysEnum.EMAIL)}
						name={BorrowerProfileKeysEnum.EMAIL}
						register={register}
						errorMessage={errors[BorrowerProfileKeysEnum.EMAIL]?.message}
						registerOptions={{
							pattern: {
								value: EMAIL_REGEXP,
								message: MESSAGE_VALIDATION_INVALID_EMAIL,
							},
						}}
					/>
				</div>
				<div className='col-sm-6 col-lg-4'>
					<FormGroup
						label={"СНИЛС"}
						id={"BorrowerAccountContactsSNILS"}
						fieldType={FormGroupTypes.NUMBER_FORMAT}
						numberFormat={SNILS_FORMAT}
						onValueChange={({ value }) => {
							setValue(BorrowerProfileKeysEnum.SNILS, value);
						}}
						value={watch(BorrowerProfileKeysEnum.SNILS)}
						name={BorrowerProfileKeysEnum.SNILS}
						register={register}
						errorMessage={errors[BorrowerProfileKeysEnum.SNILS]?.message}
						registerOptions={{
							pattern: {
								value: SNILS_REGEXP,
								message: MESSAGE_VALIDATION_INVALID_SNILS,
							},
						}}
					/>
				</div>
			</div>
		</ProfileSection>
	);
};

export default BorrowerAccountDataContacts;

import {IBorrowerBid, IBorrowerBidsParams, IBorrowerLoans, IBorrowerState} from '@features/borrower/types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';

const initialState:IBorrowerState = {
	bidsParams: {} as IBorrowerBidsParams,
	loans: {} as IBorrowerLoans,
	bids: []
};

export const borrowerSlice = createSlice({
	name: 'borrower',
	initialState,
	reducers: {
		setBidsParams: (state, {payload}: PayloadAction<IBorrowerBidsParams>) => {
			state.bidsParams = payload
		},
		setLoans: (state, {payload}: PayloadAction<IBorrowerLoans>) => {
			state.loans = payload
		},
		setBids: (state, {payload}:PayloadAction<IBorrowerBid[]>) => {
			state.bids = payload
		}
	}
});

export default borrowerSlice.reducer;

import React, {FC, useContext} from 'react';
import {authContext} from '@context/authContext';
import {useAppActions} from '@hooks/useAppActions';
import authApi from '@api/authApi';
import {AuthRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useHistory} from 'react-router-dom';
import AuthCreatePassword from '@components/auth/CreatePassword/CreatePassword';

const ChangePassword:FC = () => {

	const
		{
			phone,
			code
		} = useContext(authContext),
		{setAuthLoading} = useAppActions(),
		[sendNewPassword] = authApi.useRecoverPasswordConfirmMutation(),
		history = useHistory()
	;

	const
		handleSubmit = (newPassword: string): void => {
			if (typeof phone === 'number') {
				sendNewPassword({
					phone,
					smsCode: code,
					newPassword
				})
					.unwrap()
					.then(() => {
						history.push(RouteNamesEnum.AUTH+AuthRouteNamesEnum.LOGIN)
					})
					.finally(() => setAuthLoading(false))
			}
		}
	;

	return (
		<AuthCreatePassword
			title={'Придумайте новый пароль'}
			onPasswordSubmit={handleSubmit}
		/>
	);
};

export default ChangePassword;

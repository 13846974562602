import {AuthRolesEnum} from '@features/auth/types';
import {BorrowerRouteNamesEnum, InvestorRouteNamesEnum, RouteNamesEnum} from '@app/router';
import {useAppSelector} from '@hooks/useAppSelector';

type HomeRouteType = string | null;

export const useRedirectToHomePage = ():{baseHomeRoute: HomeRouteType} => {

	const {role} = useAppSelector(state => state.auth);

	const getHomeRoute = (): HomeRouteType => {

		switch (role) {
			case AuthRolesEnum.BORROWER:
				return RouteNamesEnum.BORROWER+BorrowerRouteNamesEnum.ACCOUNT;
			case AuthRolesEnum.INVESTOR:
				return RouteNamesEnum.INVESTOR+InvestorRouteNamesEnum.CABINET;
			default: return null
		}
	};


	return {
		baseHomeRoute: getHomeRoute(),
	}
};

import {BorrowerProjectStatusEnum} from '@features/borrower/types';
import {useEffect, useState} from 'react';


export const useProjectExpired = (status: BorrowerProjectStatusEnum):boolean => {
	const [expired, setExpired] = useState<boolean>(false);

	useEffect(() => {
		setExpired(status === BorrowerProjectStatusEnum.EXPIRED)
	}, [status]);

	return expired
};

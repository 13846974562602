import React, {FC} from 'react';
import {FormGroup} from '@components/ui';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import ProfileSection from '@components/profile/Section/ProfileSection';
import {useFormContext} from 'react-hook-form';
import {INN_MIN_LENGTH, INN_MAX_LENGTH, OGRN_IP_MAX_LENGTH, OGRN_IP_MIN_LENGTH} from '@common/constants';
import {BorrowerProfileKeysEnum} from '@features/user/types';
import {validateInnMinMax} from '@common/utils';

const BorrowerAccountDataRequisites:FC = () => {

	const {
		register,
		setValue,
		watch,
		formState: {errors}
	} = useFormContext();

	return (
		<ProfileSection title={'Реквизиты организации'}>
			<div className="row g-3">
				<div className="col-xxl-8">
					<div className="row g-3">
						<div className="col-12">
							<FormGroup
								fieldType={FormGroupTypes.TEXT}
								id={'BorrowerAccountFullName'}
								label={'Полное наименование'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.ORG_NAME, event.target.value)}
								value={watch(BorrowerProfileKeysEnum.ORG_NAME)}
								register={register}
								name={BorrowerProfileKeysEnum.ORG_NAME}
								errorMessage={errors[BorrowerProfileKeysEnum.ORG_NAME]?.message}
							/>
						</div>
						<div className="col-sm-6">
							<FormGroup
								fieldType={FormGroupTypes.NUMBER}
								id={'BorrowerAccountINN'}
								label={'ИНН'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.INN, event.target.value)}
								value={watch(BorrowerProfileKeysEnum.INN)}
								register={register}
								name={BorrowerProfileKeysEnum.INN}
								errorMessage={errors[BorrowerProfileKeysEnum.INN]?.message}
								registerOptions={
									{
										validate: value => validateInnMinMax(value, INN_MIN_LENGTH, INN_MAX_LENGTH)
									}
								}
							/>
						</div>
						<div className="col-sm-6">
							<FormGroup
								fieldType={FormGroupTypes.NUMBER}
								id={'BorrowerAccountOGRNIP'}
								label={'ОГРН/ОГРНИП'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.OGRN, event.target.value)}
								value={watch(BorrowerProfileKeysEnum.OGRN)}
								register={register}
								name={BorrowerProfileKeysEnum.OGRN}
								errorMessage={errors[BorrowerProfileKeysEnum.OGRN]?.message}
								registerOptions={
									{
										validate: value => validateInnMinMax(value, OGRN_IP_MIN_LENGTH, OGRN_IP_MAX_LENGTH)
									}
								}
							/>
						</div>
						<div className="col-12">
							<FormGroup
								fieldType={FormGroupTypes.TEXT}
								id={'BorrowerAccountLegalAddress'}
								label={'Юридический адрес организации'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.LEGAL_ADDRESS, event.target.value)}
								value={watch(BorrowerProfileKeysEnum.LEGAL_ADDRESS)}
								register={register}
								name={BorrowerProfileKeysEnum.LEGAL_ADDRESS}
								errorMessage={errors[BorrowerProfileKeysEnum.LEGAL_ADDRESS]?.message}
							/>
						</div>
						<div className="col-12">
							<FormGroup
								fieldType={FormGroupTypes.TEXT}
								id={'BorrowerAccountActualAddress'}
								label={'Фактический адрес ведения бизнеса'}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(BorrowerProfileKeysEnum.ACTUAL_ADDRESS, event.target.value)}
								value={watch(BorrowerProfileKeysEnum.ACTUAL_ADDRESS)}
								register={register}
								name={BorrowerProfileKeysEnum.ACTUAL_ADDRESS}
								errorMessage={errors[BorrowerProfileKeysEnum.ACTUAL_ADDRESS]?.message}
							/>
						</div>
					</div>
				</div>
			</div>
		</ProfileSection>
	);
};

export default BorrowerAccountDataRequisites;

import React, {FC, useContext} from 'react';
import {useFormContext} from 'react-hook-form';
import classNames from 'classnames';
import styles from '@components/investor/Views/Qualification/qualification.module.scss';
import {QualificationAttachmentTypeEnum} from '@common/types';
import {FormGroupTypes, IFormGroupProps} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import ProfileQualificationConfirmationNote from '@components/investor/Views/Qualification/Components/ConfirmationNote';
import ProfileQualificationUploadedDocuments from '@components/investor/Views/Qualification/Components/UploadedDocuments';
import useBoolean from '@hooks/useBoolean';
import {qualificationContext} from '@context/qualificationContext';

interface IProfileQualificationSectionProps {
	dataKey: QualificationAttachmentTypeEnum,
	id: string,
	labelContent: IFormGroupProps['label'],
	additionalContent?: React.ReactElement,
	noteMessage: string
}

const ProfileQualificationSection:FC<IProfileQualificationSectionProps> = (
	{
		dataKey,
		id,
		labelContent,
		noteMessage,
		additionalContent
	}
) => {

	const
		{
			documents,
			appendAttachment,
			deleteAttachment
		} = useContext(qualificationContext),
		{
			getValues,
			watch,
			setValue,
			register,
			clearErrors,
			formState: {errors}
		} = useFormContext(),
		{
			value: isLoading,
			setValue: setIsLoading,
		} = useBoolean(false)
	;

	return (
		<div className={classNames(styles.section, {
			[styles.sectionEditable]: getValues(dataKey)
		})}>

			<FormGroup
				id={id}
				fieldType={FormGroupTypes.CHECKBOX}
				className={styles.checkbox}
				checked={watch(dataKey)}
				onChange={(event: React.ChangeEvent<HTMLInputElement>) => setValue(dataKey, event.target.checked)}
				label={labelContent}
				name={dataKey}
				register={register}
				registerOptions={{required: false}}
			/>

			<div className='ps-5'>
				{additionalContent && additionalContent}
				<ProfileQualificationConfirmationNote
					message={noteMessage}
				/>
				<ProfileQualificationUploadedDocuments
					isLoading={isLoading}
					documents={documents[dataKey]}
					disabled={!(getValues(dataKey))}
					onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
						setIsLoading(true);
						appendAttachment(
							event,
							dataKey,
							() => {
								setIsLoading(false);
								clearErrors(dataKey);
							}
						)
					}}
					onDelete={uid => deleteAttachment(dataKey, uid)}
					errorMessage={
						documents[dataKey].length === 0
							? errors[dataKey]?.message
							: undefined
					}
				/>

			</div>

		</div>
	)
};

export default ProfileQualificationSection;

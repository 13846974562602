import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import styles from './withdrawModal.module.scss';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {Button, FormGroup, Heading} from '@components/ui';
import {useAppSelector} from '@hooks/useAppSelector';
import classNames from 'classnames';
import {
	BANK_ACCOUNT_NUMBER_FORMAT,
	BANK_ACCOUNT_REQUIRED_LENGTH,
	BANK_BIK_NUMBER_FORMAT, BANK_BIK_REQUIRED_LENGTH,
	PHONE_NUMBER_FORMAT,
	WITHDRAW_AMOUNT_MAX,
	WITHDRAW_AMOUNT_MIN
} from '@common/constants';
import NumberFormat from 'react-number-format';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import {optimizePhone} from '@common/utils';
import daDataApi from '@api/daDataApi';
import useDebounce from '@hooks/useDebounce';
import {ISuggestionSearchResult, ISuggestion} from '@features/daData/types';
import operationsApi from '@api/operationsApi';
import {withdrawContext} from '@context/withdrawContext';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import AmountInput from '@components/common/AmountInput/AmountInput';
import WithdrawSavedAccounts from '@components/investor/WithdrawRequestModal/SavedAccounts/withdrawSavedAccounts';
import {useNumAmount} from '@hooks/useNumAmount';

const WithdrawRequestModal:FC = () => {

	const
		{
			amount, updateAmount,
			bik, updateBik,
			name, updateName,
			account, updateAccount,
			correspondentAccount, updateCorrespondentAccount,
			requestModal,
			setRequestModal,
			setConfirmModal,
			savedAccountsDisplayed,
			setSavedAccountsDisplayed
		} = useContext(withdrawContext),
		{
			balance,
			phone
		} = useAppSelector(state => state.user.investorInfo);

	const
		numBalance = useNumAmount(balance || ''),
		[searchResult, setSearchResults] = useState<ISuggestion[]>([]),
		[suggestionIsOpen, setSuggestionOpen] = useState<boolean>(false),
		[btnDisabled, setBtnDisabled] = useState<boolean>(true),
		withdrawAvailable = useMemo(() => numBalance >= WITHDRAW_AMOUNT_MIN, [numBalance]),
		[bankSearch, {isLoading: bankResultsIsLoading}] = daDataApi.useBanksSearchMutation(),
		[withdrawInit, {isLoading: withdrawRequestIsLoading}] = operationsApi.useWithdrawInitMutation(),
		[
			savedAccountsRequest,
			{
				data: savedAccounts = [],
				isLoading: savedAccountsIsLoading
			}
		] = operationsApi.useWithdrawRequestMutation()
	;

	const
		bankBikDebounced = useDebounce<string>(bik),
		getWithdrawMaxLimit = (b: number):number => b >= WITHDRAW_AMOUNT_MAX ? WITHDRAW_AMOUNT_MAX : b,
		processedSearchResult = useMemo(() => searchResult
			.map((result):Partial<ISuggestionSearchResult> => ({
				bik: result.data.bic,
				name: result.value,
				address: result.data.address.value,
				correspondent_account: result.data.correspondent_account,
				hid: result.data.hid
			}))
			.filter((result:Partial<ISuggestionSearchResult>) => (result.bik && result.bik.includes(bik))), [searchResult]
		),
		textOutput = useMemo(() => {

			const edge = getWithdrawMaxLimit(numBalance);

			return amount < edge
				? amount?.toString()
				: edge?.toString()
		}, [amount, numBalance])
	;

	const
		handleBankChoice = (choice: Partial<ISuggestionSearchResult>) => {
			choice.bik && updateBik(choice.bik);
			choice.name && updateName(choice.name);
			choice.correspondent_account && updateCorrespondentAccount(choice.correspondent_account);
			setSuggestionOpen(false);
		},
		handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			withdrawInit({
				bik,
				bankName: name,
				accountNumber: account,
				correspondentAccount,
				amount
			})
				.unwrap()
				.then(() => {
					setRequestModal(false);
					setConfirmModal(true)
				})
		}
	;

	useEffect(() => {
		bankBikDebounced && bankSearch(bankBikDebounced)
			.unwrap()
			.then(response => {
				setSearchResults(response);
				if (response.length >= 1)
					setSuggestionOpen(true)
			});
	}, [bankBikDebounced]);

	useEffect(() => {
		setBtnDisabled(
			(bik.length < BANK_BIK_REQUIRED_LENGTH)
			||
			(name.length === 0)
			||
			(account.length < BANK_ACCOUNT_REQUIRED_LENGTH)
		)

	}, [bik, name, account]);

	useEffect(() => {
		setSavedAccountsDisplayed(savedAccounts.length > 0)
	}, [savedAccounts]);

	return (
		<Modal
			show={requestModal}
			onHide={() => {
				setRequestModal(false);
			}}
			dialogClassName={
				classNames(styles.withdraw, {
					[styles.isLoading]: savedAccountsIsLoading && withdrawAvailable
				})
			}
			onEnter={() => {
				updateAmount(WITHDRAW_AMOUNT_MIN);
			}}
			onEntered={() => savedAccountsRequest()}
			onExited={() => {
				setSavedAccountsDisplayed(false);
				updateBik('');
				updateName('');
				updateAccount('');
				updateCorrespondentAccount('');
			}}
			centered
		>
			{(savedAccountsIsLoading && withdrawAvailable) && <Spinner className={styles.loaded} animation={'border'} size={'sm'} variant={'primary'}/>}
			<Modal.Header closeButton/>
			<Modal.Body>
				{
					withdrawAvailable
						? savedAccountsDisplayed
						? <WithdrawSavedAccounts
							counts={savedAccounts}
							onAccountChoose={({bankName, accountNumber, bik, correspondentAccount}) => {
								setSavedAccountsDisplayed(false);
								updateAccount(accountNumber);
								updateCorrespondentAccount(correspondentAccount);
								updateName(bankName);
								updateBik(bik);
								setSuggestionOpen(false);
							}}
						/>
						: <form onSubmit={handleFormSubmit}>

							<div className='mt-sm-n4 mt-xl-0'>
								<AmountInput
									textOnValueChange={({floatValue}) => {
										typeof floatValue !== 'undefined' && updateAmount(floatValue)
									}}
									textOnBlur={() => updateAmount(amount < WITHDRAW_AMOUNT_MIN ? WITHDRAW_AMOUNT_MIN : amount)}
									textOutput={textOutput}
									sliderMinVal={WITHDRAW_AMOUNT_MIN}
									sliderMaxVal={getWithdrawMaxLimit(numBalance)}
									sliderOnChange={updateAmount}
									amount={amount}
									title={'Введите сумму, которую хотите вывести со счета'}
									subTitle={
										<small>
											От&nbsp;
											<NumberFormat
												displayType={'text'}
												thousandSeparator={' '}
												value={WITHDRAW_AMOUNT_MIN}
											/>
											&nbsp;до&nbsp;
											<NumberFormat
												displayType={'text'}
												thousandSeparator={' '}
												value={getWithdrawMaxLimit(numBalance)}
												suffix={' ₽'}
											/>
										</small>
									}
									sliderNote={
										<p className='text-dark'>
											<small>Доступно: {balance && <NumberFormat
												displayType={'text'}
												thousandSeparator={' '}
												value={parseInt(balance)}
												suffix={' ₽'}
											/>}</small>
										</p>
									}
								/>
							</div>

							<div className='pt-3 pb-5'>

								<FormGroup
									id={'withdrawBankBik'}
									fieldType={FormGroupTypes.SUGGESTIONS}
									label={'БИК'}
									numberFormat={BANK_BIK_NUMBER_FORMAT}
									placeholder={'Введите БИК банка'}
									className={'pb-sm-0'}
									value={bik}
									onValueChange={({value}) => updateBik(value)}
									isLoading={bankResultsIsLoading}
									suggestionsSearchResult={processedSearchResult}
									suggestionIsOpen={suggestionIsOpen}
									handleSuggestionChoice={handleBankChoice}
								/>

								<FormGroup
									id={'withdrawCorrespondentAccount'}
									fieldType={FormGroupTypes.NUMBER_FORMAT}
									numberFormat={BANK_ACCOUNT_NUMBER_FORMAT}
									label={'Корреспондентский счет'}
									placeholder={'Введите корреспондентский счет'}
									className={'pb-2 pb-sm-0'}
									onValueChange={({value}) => updateCorrespondentAccount(value)}
									value={correspondentAccount}
								/>
								<FormGroup
									id={'withdrawBankName'}
									fieldType={FormGroupTypes.TEXT}
									className={'pb-sm-0'}
									label={'Название банка'}
									placeholder={'Введите название банка'}
									value={name}
									onChange={(event: React.ChangeEvent<HTMLInputElement>) => updateName(event.target.value)}
								/>
								<FormGroup
									id={'withdrawAccountNumber'}
									fieldType={FormGroupTypes.NUMBER_FORMAT}
									numberFormat={BANK_ACCOUNT_NUMBER_FORMAT}
									label={'Номер счета'}
									placeholder={'Введите номер счета'}
									className={'pb-2 pb-sm-0'}
									onValueChange={({value}) => updateAccount(value)}
									value={account}
								/>
								<small className={'text-dark d-inline-block mt-sm-3'}>Указывайте только свои реквизиты.</small>
							</div>
							<div className={classNames(styles.phone, 'pb-5')}>
								<p className={classNames(styles.phoneTitle, 'text-dark')}>Код будет выслан на номер</p>
								{phone && <NumberFormat
									{...PHONE_NUMBER_FORMAT}
									className={styles.phoneNumber}
									displayType={'text'}
									value={optimizePhone(phone)}
								/>}
							</div>
							<Button
								variant={ButtonVariant.contained}
								as={ButtonTypesEnum.BUTTON}
								type={'submit'}
								className={'mx-auto d-block'}
								disabled={(btnDisabled || withdrawRequestIsLoading)}
								isLoading={withdrawRequestIsLoading}
							>Отправить смс-код</Button>
						</form>
						: <Heading tag={'h3'} className={'text-center'} variant={HeadingVariant.large}>Недостаточно средств ...</Heading>
				}
			</Modal.Body>
		</Modal>
	)
};

export default WithdrawRequestModal;

import React, {FC} from 'react';
import styles from './icon.module.scss'
import classNames from 'classnames';

export interface IBoxIconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
	bgAlpha?: number,
	size?: 'md' | 'sm' | 'm',
	bgColor: string,
	tagName?: keyof JSX.IntrinsicElements
}

const BoxIcon:FC<IBoxIconProps> = (
	{
		bgAlpha = .15,
		size = 'md',
		bgColor,
		children,
		className,
		tagName = 'div' ,
		...other
	}
) => {

	const Tag = tagName as keyof JSX.IntrinsicElements;

	return (
		<Tag
			className={classNames('icon', styles.box, className, styles[size])}
			style={{backgroundColor: `rgba(${bgColor}, ${bgAlpha})`}}
			{...other}
		>{children}</Tag>
	);
};

export default BoxIcon;

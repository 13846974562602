import {
	API_USER_CHECK_AUTHORIZED_URL, API_USER_CHECK_PASSWORD_URL,
	API_USER_CHECK_PHONE_URL,
	API_USER_LOGIN_URL, API_USER_LOGOUT_URL, API_USER_RECOVER_PASSWORD_CHECK_URL, API_USER_RECOVER_PASSWORD_CONFIRM_URL,
	API_USER_RECOVER_PASSWORD_REQUEST_URL,
	API_USER_REGISTER_CONFIRM_URL, API_USER_REGISTER_PASSWORD_CREATE_URL,
	API_USER_REGISTER_REQUEST_URL
} from '@common/constants';

import {
	AuthRolesEnum,
	IAuthCheckPhoneResponse,
	IAuthIsAuthorizedResponse,
	IAuthLoginRequest,
	IAuthLoginResponse,
	IRecoverPasswordCheckRequest,
	IRecoverPasswordConfirmRequest,
	IRecoverPasswordRequest,
	IRegisterConfirmRequest,
	IRegisterConfirmResponse, IRegisterCreatePasswordRequest,
	IRegisterRequest
} from '@features/auth/types';
import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {IDefaultResponse} from '@common/types';

const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery,
	endpoints: build => ({
		isAuthorized: build.mutation<IAuthIsAuthorizedResponse, void>({
			query: () => ({
				url: API_USER_CHECK_AUTHORIZED_URL,
				method: 'post'
			})
		}),
		checkPhone: build.mutation<IAuthCheckPhoneResponse, {phone: number, type: AuthRolesEnum}>({
			query: ({phone, type}) => ({
				url: API_USER_CHECK_PHONE_URL,
				method: 'post',
				body: {phone},
				params: {type}
			})
		}),

		checkPassword: build.mutation<IDefaultResponse, string>({
			query: password => ({
				url: API_USER_CHECK_PASSWORD_URL,
				method: 'post',
				body: {password}
			})
		}),
		logIn: build.mutation<IAuthLoginResponse, IAuthLoginRequest>({
			query: body => ({
				url: API_USER_LOGIN_URL,
				method: 'post',
				body
			})
		}),
		registerRequest: build.mutation<IDefaultResponse, {body: IRegisterRequest, type: AuthRolesEnum}>({
			query: ({body, type}) => ({
				url: API_USER_REGISTER_REQUEST_URL,
				method: 'post',
				body,
				params: {type}
			})
		}),
		registerConfirm: build.mutation<IDefaultResponse, IRegisterConfirmRequest>({
			query: body => ({
				url: API_USER_REGISTER_CONFIRM_URL,
				method: 'post',
				body
			})
		}),
		registerCreatePassword: build.mutation<IRegisterConfirmResponse, IRegisterCreatePasswordRequest>({
			query: body => ({
				url: API_USER_REGISTER_PASSWORD_CREATE_URL,
				method: 'post',
				body
			})
		}),
		recoverPasswordRequest: build.mutation<IDefaultResponse, IRecoverPasswordRequest>({
			query: body => ({
				url: API_USER_RECOVER_PASSWORD_REQUEST_URL,
				method: 'post',
				body
			})
		}),
		recoverPasswordCheck: build.mutation<IDefaultResponse, IRecoverPasswordCheckRequest>({
			query: body => ({
				url: API_USER_RECOVER_PASSWORD_CHECK_URL,
				method: 'post',
				body
			})
		}),
		recoverPasswordConfirm: build.mutation<IDefaultResponse, IRecoverPasswordConfirmRequest>({
			query: body => ({
				url: API_USER_RECOVER_PASSWORD_CONFIRM_URL,
				method: 'post',
				body
			})
		}),
		logOut: build.mutation<IDefaultResponse, void>({
			query: () => ({
				url: API_USER_LOGOUT_URL,
				method: 'post'
			})
		}),
	})
});

export default authApi;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import '@sass/libs.scss';
import '@sass/common.scss';
import {store} from '@app/store';
import {Provider} from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);

import React, {FC} from 'react';
import classNames from 'classnames';
import styles from '@components/profile/reused/DocumentBox/documentBox.module.scss';
import {configureAssetAnchorParams} from '@common/utils';
import BoxIcon from '@components/common/Icon/BoxIcon';
import {RGB_COLORS} from '@common/constants';
import {ReactComponent as DocumentIcon} from '@icons/document-colored.svg';
import {ReactComponent as ArrowRightIcon} from '@icons/arrow-right.svg';

import {IDocument} from '@features/utils/types';

interface IProfileDocumentProps {
	link: IDocument['link']
	title: IDocument['title']
	attachment?: IDocument['attachment']
	className?: string
}


const ProfileDocumentBox: FC<IProfileDocumentProps> = ({attachment, link, title, className}) => {

	return (
		<a
			className={classNames(styles.box, 'd-flex flex-lg-column align-items-center p-4', className)}
			{...configureAssetAnchorParams(attachment, link)}
		>
			<BoxIcon
				bgColor={RGB_COLORS.blue}
				className={'me-3 me-lg-0 flex-shrink-0'}
			>
				<DocumentIcon/>
			</BoxIcon>
			<p className={classNames(styles.title, 'mb-0 pt-lg-3 mt-lg-2 text-lg-center pe-4 pe-lg-0')}>{title}</p>
			<span className={'d-lg-none text-primary ms-auto flex-shrink-0'}>
				<ArrowRightIcon/>
			</span>
		</a>
	);
};

export default ProfileDocumentBox;

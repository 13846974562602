import React, {FC, useContext, useEffect, useState} from 'react';
import {Modal, Spinner} from 'react-bootstrap';
import {Button, FormGroup, Heading} from '@components/ui';
import {HeadingVariant} from '@components/ui/Heading/Heading.props';
import {FormGroupTypes, SelectOptionType} from '@components/ui/FormGroup/FormGroup.props';
import {ButtonTypesEnum, ButtonVariant} from '@components/ui/Button/Button.props';
import questionsApi from '@api/questionsApi';
import {PropsValue} from 'react-select';
import {isNum} from '@common/utils';
import {TRANSITION_TIMEOUT} from '@common/constants';
import {ReactComponent as CheckIcon} from '@icons/check-square.svg';
import {ReactComponent as LogoIcon} from '@icons/auth-logo.svg';
import {questionsContext} from '@context/questionsContext';

const ProfileQuestionsAskModal:FC = () => {
	const
		{askQuestionModal, setAskQuestionModal} = useContext(questionsContext),
		[
			getThemes,
			{
				data: themesData = [],
				isLoading: themesDataIsLoading,
				isFetching: themesDataIsFetching
			}
		] = questionsApi.useLazyGetAskThemesQuery(),
		[
			askQuestion
		] = questionsApi.useAskMutation(),
		[questionTheme, setQuestionTheme] = useState<PropsValue<SelectOptionType>>(null),
		[questionMessage, setQuestionMessage] = useState<string>(''),
		[formIsLoading, setFormLoading] = useState<boolean>(false),
		[submitDisabled, setSubmitDisabled] = useState<boolean>(true),
		[questionSent, setQuestionSent] = useState<boolean>(false)
	;

	useEffect(() => {
		setSubmitDisabled(!Boolean(questionTheme) || questionMessage.length === 0)
	}, [questionTheme, questionMessage]);

	const clearForm = ():void => {
		setQuestionTheme(null);
		setQuestionMessage('');
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		const themeId = (questionTheme as SelectOptionType).value;

		if (isNum(themeId) && questionMessage.length > 0) {
			setSubmitDisabled(true);
			setFormLoading(true);

			askQuestion({
				themeId: +themeId,
				question: questionMessage
			})
				.unwrap()
				.then(() => {
					setQuestionSent(true);
				})
				.finally(() => {
					clearForm();
					setSubmitDisabled(false);
					setFormLoading(false);
				})
		}
	};

	return (
		<Modal
			show={askQuestionModal}
			onHide={() => {
				setAskQuestionModal(false);
				setQuestionSent(false);
			}}
			centered
			onEntered={() => getThemes()}
		>
			<Modal.Header closeButton>
				<LogoIcon className={'modal-header-logo'}/>
			</Modal.Header>
			<Modal.Body className={'text-center'}>

				{formIsLoading && <Spinner className={'spinner--centered'} animation='border' variant='primary'/>}

				<div
					style={{
						transitionProperty: 'opacity, visibility',
						transitionDuration: `${TRANSITION_TIMEOUT}ms`,
						opacity: formIsLoading ? '0' : '1',
						visibility: formIsLoading ? 'hidden' : 'visible'
					}}
				>
					{
						questionSent
							? <>
								<p className={'mb-0'}>Вопрос успешно отправлен. Скоро мы опубликуем ответ на него!</p>
								<div className="pb-5 pt-4">
									<CheckIcon/>
								</div>
								<Button
									variant={ButtonVariant.contained}
									as={ButtonTypesEnum.BUTTON}
									type={'button'}
									onClick={() => setAskQuestionModal(false)}
								>Закрыть</Button>
							</>
							: <>
								<Heading
									variant={HeadingVariant.large}
									tag={'h2'}
								>Задать вопрос</Heading>
								<p className='text-dark'>
									<small>Напишите ниже свой вопрос и мы опубликуем ответ на него</small>
								</p>
								<form onSubmit={handleSubmit}>
									<div className="py-5 text-start">
										<FormGroup
											fieldType={FormGroupTypes.SELECT}
											id={'profileQuestionAskTheme'}
											placeholder={'Выберите тему'}
											isLoading={themesDataIsLoading || themesDataIsFetching}
											label={'Тема вопроса'}
											options={
												themesData
													.map(({title, themeId}) => {
														return {
															value: themeId,
															label: title
														} as SelectOptionType
													})
											}
											onSelectChange={option => setQuestionTheme(option)}
											selectValue={questionTheme}
										/>
										<FormGroup
											fieldType={FormGroupTypes.TEXTAREA}
											id={'profileQuestionAskMessage'}
											placeholder={'Ваш вопрос'}
											onChange={event => setQuestionMessage(event.target.value)}
											value={questionMessage}
										/>
									</div>
									<Button
										variant={ButtonVariant.contained}
										as={ButtonTypesEnum.BUTTON}
										type={'submit'}
										disabled={submitDisabled}
									>Отправить</Button>
								</form>
							</>
					}

				</div>
			</Modal.Body>
		</Modal>
	);
};

export default ProfileQuestionsAskModal;

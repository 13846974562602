import {DetailedHTMLProps, HTMLAttributes} from 'react';

export enum HeadingVariant {
	large = 'large',
	middle = 'middle',
	small = 'small'
}

export interface HeadingProps extends DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
	tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6',
	variant: HeadingVariant
}

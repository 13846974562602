import {
	FormGroupTypes,
	IFormGroupProps,
	SelectOptionType,
} from "@components/ui/FormGroup/FormGroup.props";
import React, { FC, useRef, useState } from "react";
import classNames from "classnames";
import styles from "./formGroup.module.scss";
import NumberFormat from "react-number-format";
import { ConditionalWrapper } from "@components/common/ConditionalWrapper";
import {
	CODE_NUMBER_FORMAT,
	CODE_NUMBER_PLACEHOLDER,
	DATEPICKER_FORMAT,
	TRANSITION_TIMEOUT,
} from "@common/constants";
import { ReactComponent as EyeOnIcon } from "@icons/eye-on.svg";
import { ReactComponent as EyeOffIcon } from "@icons/eye-off.svg";
import { ReactComponent as PlusIcon } from "@icons/plus-thin.svg";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import PhoneInput from "react-phone-number-input/input";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import Select, { components, ControlProps } from "react-select";
import { Spinner } from "react-bootstrap";
import { configureAssetPath, withRootUrl } from "@common/utils";
import LazyImage from "@components/common/LazyImage/LazyImage";
import { ReactComponent as DeleteIcon } from "@icons/delete.svg";
import { ReactComponent as GearIcon } from "@icons/gear.svg";
import SimpleBar from "simplebar-react";

registerLocale("ru", ru);

const FormGroup: FC<IFormGroupProps> = ({
	id,
	fieldType,
	className,
	label,
	placeholder,
	name,
	value,
	onValueChange,
	onChange,
	onSelectChange,
	onDateChange,
	onBlur,
	defaultValue,
	checked,
	selected,
	note,
	numberFormat,
	thousandSeparator,
	decimalSeparator,
	suffix,
	readonly,
	disabled,
	isLoading = false,
	Icon = null,
	options = [],
	selectValue,
	selectDefaultValue,
	register,
	registerOptions,
	errorMessage,
	acceptedFiles = [],
	fileAttachment = null,
	onAttachmentDelete,
	suggestionsSearchResult,
	suggestionIsOpen,
	handleSuggestionChoice,
	rootRef,
	...props
}): JSX.Element => {
	const handleSelectChange = (option: SelectOptionType | null) => {
		if (option && onSelectChange) onSelectChange(option);
	};

	const isPassword = fieldType === FormGroupTypes.PASSWORD,
		visibilityIconRef = useRef(null),
		[passwordShown, setPasswordShown] = useState<boolean>(false);
	const defaultRegisterOptions = {
		required: "Это поле обязательно для заполнения",
	};

	const formRegister =
		register && name
			? {
					...register(
						name,
						Object.assign(
							defaultRegisterOptions,
							registerOptions ? { ...registerOptions } : {},
						),
					),
			  }
			: null;

	const formGroupMain = (): JSX.Element => {
		switch (fieldType) {
			case FormGroupTypes.CHECKBOX:
			case FormGroupTypes.RADIO:
				return (
					<div
						className={classNames(styles[fieldType], {
							checkbox: fieldType === FormGroupTypes.CHECKBOX,
							radio: fieldType === FormGroupTypes.RADIO,
						})}>
						<input
							checked={checked}
							name={name}
							type={fieldType}
							id={id}
							value={value}
							onChange={onChange}
							className={styles[fieldType + "Field"]}
							disabled={disabled || readonly}
							{...formRegister}
						/>
						<label
							htmlFor={id}
							className={classNames("label", styles[fieldType + "Label"])}>
							{label && (
								<span className={"d-inline-block ps-3 pt-1"}>{label}</span>
							)}
						</label>
					</div>
				);
			case FormGroupTypes.NUMBER_FORMAT:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}
						<NumberFormat
							{...numberFormat}
							className={styles.field}
							name={name}
							placeholder={
								numberFormat === CODE_NUMBER_FORMAT
									? CODE_NUMBER_PLACEHOLDER
									: placeholder
							}
							onValueChange={onValueChange}
							onBlur={onBlur}
							value={value}
							defaultValue={defaultValue}
							isNumericString={true}
							readOnly={readonly}
							thousandSeparator={thousandSeparator}
							decimalSeparator={decimalSeparator}
							suffix={suffix}
							{...formRegister}
						/>
					</>
				);
			case FormGroupTypes.PHONE:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}

						<PhoneInput
							country='RU'
							readOnly={readonly}
							className={classNames("field", styles.field)}
							placeholder={placeholder}
							value={`+${value}`} // eslint-disable-next-line
							// @ts-ignore
							onChange={(val) => (val ? onChange(val) : undefined)}
							name={name}
							// autoComplete='off'
							{...formRegister}
						/>
					</>
				);
			case FormGroupTypes.DATE:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}
						<DatePicker
							customInput={<NumberFormat format={"##.##.####"} />}
							locale={"ru"}
							className={classNames("field", styles.field)}
							selected={selected}
							dateFormat={DATEPICKER_FORMAT}
							onChange={(date: Date) => onDateChange && onDateChange(date)}
							onBlur={onBlur}
							readOnly={readonly}
							disabled={disabled}
							placeholderText={placeholder}
							name={name}
						/>
					</>
				);
			case FormGroupTypes.FILE:
				return fileAttachment ? (
					<div className={classNames("uploaded", styles.uploaded)}>
						<div className='row gx-4 py-2 align-items-center'>
							<div className='col-auto'>
								<div className={styles.uploadedPicture}>
									<LazyImage
										src={withRootUrl(configureAssetPath(fileAttachment) || "")}
										alt='Passport first page'
									/>
								</div>
							</div>
							<div className='col'>
								<p className={"mb-0"}>{label}</p>
							</div>
						</div>

						{!readonly && (
							<div className={styles.uploadedControls}>
								<label htmlFor={id} className={styles.uploadedControlsChange}>
									<input
										type='file'
										id={id}
										name={name}
										value={value}
										onChange={(
											event: React.ChangeEvent<
												HTMLInputElement & HTMLTextAreaElement
											>,
										) => {
											onChange && onChange(event);
										}}
										className='visually-hidden'
										accept={
											acceptedFiles?.length
												? acceptedFiles.join(", ")
												: undefined
										}
									/>
									<GearIcon />
									<span className={styles.uploadedControlsNote}>
										Загрузить другое фото
									</span>
								</label>
								<button
									type='button'
									onClick={() =>
										onAttachmentDelete && onAttachmentDelete(fileAttachment)
									}
									className={styles.uploadedControlsDelete}>
									<DeleteIcon />
									<span className={styles.uploadedControlsNote}>
										Удалить фото
									</span>
								</button>
							</div>
						)}
					</div>
				) : (
					<label
						htmlFor={id}
						className={classNames("uploadMain", styles.upload, "text-dark")}
						style={{
							pointerEvents: disabled || readonly ? "none" : undefined,
							userSelect: disabled || readonly ? "none" : undefined,
						}}>
						<PlusIcon className={"uploadPlusIcon"} />

						<span className={"uploadLabel ps-4 ps-sm-0"}>{label}</span>
						<input
							type='file'
							id={id}
							name={name}
							value={value}
							onChange={(
								event: React.ChangeEvent<
									HTMLInputElement & HTMLTextAreaElement
								>,
							) => {
								onChange && onChange(event);
							}}
							defaultValue={defaultValue}
							className={"visually-hidden"}
							disabled={disabled || readonly}
							{...formRegister}
							accept={
								acceptedFiles?.length ? acceptedFiles.join(", ") : undefined
							}
						/>
					</label>
				);
			case FormGroupTypes.SELECT:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}
						<Select
							value={selectValue}
							defaultValue={selectDefaultValue}
							onChange={handleSelectChange}
							options={options}
							name={name}
							classNamePrefix={"custom-select"}
							className={"custom-select__container"}
							placeholder={placeholder}
							isLoading={isLoading}
							noOptionsMessage={({ inputValue }) => {
								const message = "результатов не найдено";

								return (
									<span>
										{inputValue ? "- " : ""} {message}
									</span>
								);
							}}
							isSearchable={false}
							components={
								Icon
									? {
											Control: ({
												children,
												...props
											}: ControlProps<SelectOptionType, false>) => {
												return (
													<components.Control {...props}>
														<div className={"w-100 d-flex align-items-center"}>
															{Icon && (
																<div
																	className={classNames(
																		styles.selectControlIcon,
																		"me-2 me-md-3",
																	)}>
																	<Icon />
																</div>
															)}
															{children}
														</div>
													</components.Control>
												);
											},
									  }
									: undefined
							}
						/>
					</>
				);
			case FormGroupTypes.TEXTAREA:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}

						<textarea
							className={classNames(
								"field",
								"textarea",
								styles.field,
								styles.textarea,
							)}
							name={name}
							value={value}
							onChange={onChange}
							defaultValue={defaultValue}
							readOnly={readonly}
							placeholder={placeholder}></textarea>
					</>
				);
			case FormGroupTypes.SUGGESTIONS:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}
						<div className={styles.suggestions}>
							<div className='position-relative'>
								<NumberFormat
									{...numberFormat}
									className={styles.field}
									name={name}
									placeholder={placeholder}
									onValueChange={onValueChange}
									value={value}
									defaultValue={defaultValue}
									isNumericString={true}
									readOnly={readonly}
									thousandSeparator={thousandSeparator}
									decimalSeparator={decimalSeparator}
									suffix={suffix}
									{...formRegister}
								/>
							</div>
							{suggestionsSearchResult &&
								suggestionsSearchResult.length > 0 &&
								Boolean(value) &&
								suggestionIsOpen && (
									<div className={styles.suggestionsHolder}>
										<div
											className={classNames(
												styles.suggestionNote,
												"text-dark",
											)}>
											<p className={"mb-0"}>
												Выберите вариант или продолжите ввод
											</p>
										</div>
										<SimpleBar
											className={styles.suggestionsScroller}
											autoHide={true}>
											<ul className={"pe-4"}>
												{suggestionsSearchResult.map((result) => (
													<li
														key={result.hid || result?.bik}
														className={styles.suggestionItem}
														onClick={() =>
															handleSuggestionChoice &&
															handleSuggestionChoice(result)
														}>
														{result?.name && (
															<p
																className={classNames(
																	styles.suggestionName,
																	"mb-0",
																)}>
																{result.name}
															</p>
														)}
														{result?.bik && (
															<p
																className={classNames(
																	styles.suggestionDesiredProp,
																	"mb-0",
																)}>
																{result.bik}
															</p>
														)}
														{result?.inn && (
															<p
																className={classNames(
																	styles.suggestionDesiredProp,
																	"mb-0",
																)}>
																{result.inn}
															</p>
														)}
														{result?.address && (
															<p
																className={classNames(
																	styles.suggestionAddress,
																	"mb-0",
																)}>
																{result.address}
															</p>
														)}
													</li>
												))}
											</ul>
										</SimpleBar>
									</div>
								)}
						</div>
					</>
				);
			default:
				return (
					<>
						{label && (
							<label htmlFor={id} className={styles.label}>
								{label}
							</label>
						)}
						<ConditionalWrapper
							condition={isPassword}
							wrapper={(children) => (
								<div className={"position-relative"}>{children}</div>
							)}>
							<>
								<input
									type={
										isPassword
											? passwordShown
												? "text"
												: "password"
											: fieldType
									}
									placeholder={placeholder}
									className={classNames(
										"field",
										styles.field,
										isPassword && {
											[styles.fieldPassword]: true,
											[styles.fieldPasswordIsFilled]: Boolean(value),
											[styles.fieldPasswordIsVisible]: passwordShown,
										},
									)}
									name={name}
									value={value}
									onChange={onChange}
									onBlur={onBlur}
									defaultValue={defaultValue}
									readOnly={readonly}
									autoComplete='off'
									{...formRegister}
								/>

								{isPassword && (
									<button
										type={"button"}
										onClick={() => setPasswordShown(!passwordShown)}
										className={classNames(
											styles.visibilityButton,
											Boolean(value) && styles.visibilityButtonIsVisible,
										)}>
										<SwitchTransition>
											<CSSTransition
												nodeRef={visibilityIconRef}
												key={passwordShown ? "eyeOn" : "eyeOff"}
												timeout={TRANSITION_TIMEOUT / 2}
												classNames={{
													enter: styles.isEnter,
													exit: styles.isExit,
													enterActive: styles.isEnterActive,
													exitActive: styles.isExitActive,
												}}>
												<span
													className={"d-inline-block"}
													ref={visibilityIconRef}>
													{passwordShown ? (
														<EyeOffIcon className={styles.visibilityIcon} />
													) : (
														<EyeOnIcon className={styles.visibilityIcon} />
													)}
												</span>
											</CSSTransition>
										</SwitchTransition>
									</button>
								)}
							</>
						</ConditionalWrapper>
					</>
				);
		}
	};

	const errorRef = useRef(null);

	return (
		<div
			className={classNames(styles.root, className, {
				"is-loading": isLoading,
				"is-error": Boolean(errorMessage),
				[styles.fileType]: fieldType === FormGroupTypes.FILE,
				["is-disabled"]: Boolean(disabled),
			})}
			ref={rootRef}
			{...props}>
			{formGroupMain()}
			{note && (
				<small className={classNames(styles.note, "text-dark")}>{note}</small>
			)}
			{isLoading && fieldType !== FormGroupTypes.SELECT && (
				<Spinner
					className={classNames({
						"spinner--centered": fieldType === FormGroupTypes.FILE,
					})}
					size={"sm"}
					animation={fieldType === FormGroupTypes.FILE ? "grow" : "border"}
					variant='primary'
				/>
			)}

			<CSSTransition
				in={Boolean(errorMessage)}
				classNames={"animation--slide-x"}
				timeout={TRANSITION_TIMEOUT}
				unmountOnExit
				nodeRef={errorRef}>
				<span
					ref={errorRef}
					className={classNames("errorMessage", styles.error)}>
					{errorMessage}
				</span>
			</CSSTransition>
		</div>
	);
};

export default FormGroup;

import { IProject, IProjectDetails } from "@features/projects/types";

export const API_VERSION = "v1";
const _fillCode = (symbol: string, quantity: number): string =>
	new Array(quantity).fill(symbol).join("");

export const STORAGE_TOKEN_KEY = "PANFILOV_TOKEN";
export const STORAGE_USER_ROLE_KEY = "PANFILOV_USER_ROLE";

export const API_USER_CHECK_AUTHORIZED_URL = `${API_VERSION}/users/is-authorized`;
export const API_USER_CHECK_PHONE_URL = `${API_VERSION}/users/check-phone`;
export const API_USER_CHECK_PASSWORD_URL = `${API_VERSION}/users/check-password`;
export const API_USER_LOGIN_URL = `${API_VERSION}/users/login`;
export const API_USER_REGISTER_REQUEST_URL = `${API_VERSION}/users/register/request`;
export const API_USER_REGISTER_CONFIRM_URL = `${API_VERSION}/users/register/confirm`;
export const API_USER_REGISTER_PASSWORD_CREATE_URL = `${API_VERSION}/users/register/create-password`;
export const API_USER_RECOVER_PASSWORD_REQUEST_URL = `${API_VERSION}/users/recover-pass/request`;
export const API_USER_RECOVER_PASSWORD_CHECK_URL = `${API_VERSION}/users/recover-pass/check`;
export const API_USER_RECOVER_PASSWORD_CONFIRM_URL = `${API_VERSION}/users/recover-pass/confirm`;
export const API_USER_LOGOUT_URL = `${API_VERSION}/users/logout`;
export const API_USER_PROFILE_URL = `${API_VERSION}/users/profile`;
export const API_USER_INFO_URL = `${API_VERSION}/users/info`;
export const API_USER_ANALYTIC_INFO_URL = `${API_VERSION}/users/analytics/info`;
export const API_USER_ANALYTIC_FINANCES_URL = `${API_VERSION}/users/analytics/finances`;
export const API_USER_ANALYTIC_PROJECTS_URL = `${API_VERSION}/users/analytics/projects`;

export const API_USER_PROFILE_CHANGE_REQUEST_URL = `${API_USER_PROFILE_URL}/change-request`;
export const API_USER_PROFILE_CHANGE_CONFIRM_URL = `${API_USER_PROFILE_URL}/change-confirm`;

export const API_USER_PROFILE_QUALIFICATION_REQUEST_URL = `${API_USER_PROFILE_URL}/qualify-request`;
export const API_USER_PROFILE_QUALIFICATION_CONFIRM_URL = `${API_USER_PROFILE_URL}/qualify-confirm`;

export const API_USER_PROFILE_JOINING_STATEMENT_URL = `${API_USER_PROFILE_URL}/joining-statement`;
export const API_USER_PROFILE_QUALIFICATION_STATEMENT_URL = `${API_USER_PROFILE_URL}/qualification-statement`;

export const API_USER_PROFILE_PHONE_CHANGE_REQUEST = `${API_USER_PROFILE_URL}/phone-request`;
export const API_USER_PROFILE_PHONE_CHANGE_CONFIRM = `${API_USER_PROFILE_URL}/phone-confirm`;

export const API_USER_OPERATIONS_SEARCH_URL = `${API_VERSION}/users/operations/search`;

export const API_USER_OPERATIONS_INVEST_REQUEST_URL = `${API_VERSION}/users/operations/invest-request`;
export const API_USER_OPERATIONS_INVEST_CONFIRM_URL = `${API_VERSION}/users/operations/invest-confirm`;
export const API_USER_OPERATIONS_INVEST_CANCEL_REQUEST_URL = `${API_VERSION}/users/operations/cancel-invest-request`;
export const API_USER_OPERATIONS_INVEST_CANCEL_CONFIRM_URL = `${API_VERSION}/users/operations/cancel-invest-confirm`;

export const API_USER_INVESTMENTS_URL = `${API_VERSION}/users/investments`;
export const API_USER_INVESTMENTS_SEARCH_URL = `${API_USER_INVESTMENTS_URL}/search`;

export const API_USER_BORROWER_URL = `${API_VERSION}/users/borrower`;

export const GET_API_USER_BORROWER_INVEST_OFFER_URL = (
	bidId: IProjectDetails["bidId"],
): string => {
	return `${API_USER_BORROWER_URL}/bids/${bidId}/invest-offer`;
};
export const GET_API_USER_BORROWER_CONDITIONS_URL = (
	bidId: IProjectDetails["bidId"],
): string => {
	return `${API_USER_BORROWER_URL}/bids/${bidId}/borrower-conditions`;
};

export const API_USER_BORROWER_PROFILE_URL = `${API_USER_BORROWER_URL}/profile`;
export const API_USER_BORROWER_MANAGERS_URL = `${API_USER_BORROWER_URL}/managers`;
export const API_USER_BORROWER_DOCUMENTS_URL = `${API_USER_BORROWER_URL}/docs`;
export const API_USER_BORROWER_BID_REQUEST_URL = `${API_USER_BORROWER_URL}/bid-request`;
export const API_USER_BORROWER_BID_CONFIRM_URL = `${API_USER_BORROWER_URL}/bid-confirm`;
export const API_USER_BORROWER_CREDIT_HISTORY_URL = `${API_USER_BORROWER_URL}/credit-history`;
export const API_USER_BORROWER_PROJECTS_URL = `${API_USER_BORROWER_URL}/projects`;
export const API_USER_BORROWER_PROJECT_URL = `${API_USER_BORROWER_URL}/projects`;
export const API_USER_BORROWER_BIDS_URL = `${API_USER_BORROWER_URL}/bids`;

export const API_USER_BORROWER_BID_SIGN_REQUEST_URL = `${API_USER_BORROWER_BIDS_URL}/sign-request`;
export const API_USER_BORROWER_BID_SIGN_CONFIRM_URL = `${API_USER_BORROWER_BIDS_URL}/sign-confirm`;

export const API_NOTIFICATIONS_USER_URL = `${API_VERSION}/notifications`;
export const API_UTILS_FILE_URL = `${API_VERSION}/utils/file`;
export const API_UTILS_DOCUMENTS_URL = `${API_VERSION}/documents`;
export const API_UTILS_COMPANY_DOCUMENTS_URL = `${API_VERSION}/documents-company`;
export const API_UTILS_SECTIONS_URL = `${API_VERSION}/sections`;
export const API_OPERATIONS_REQUISITES_URL = `${API_VERSION}/requisites`;
export const API_OPERATIONS_WITHDRAW_REQUEST_URL = `${API_VERSION}/operations/withdraw-request`;
export const API_OPERATIONS_WITHDRAW_INIT_URL = `${API_VERSION}/operations/withdraw-init`;
export const API_OPERATIONS_WITHDRAW_CONFIRM_URL = `${API_VERSION}/operations/withdraw-confirm`;
export const API_PROJECTS_SEARCH_URL = `${API_VERSION}/projects/search`;
export const API_PROJECTS_USER_INVEST_URL = `${API_VERSION}/projects/user-invest`;
export const API_PROJECTS_PARAMS_URL = `${API_VERSION}/projects/params`;
export const API_PROJECT_URL = `${API_VERSION}/projects`;
export const GET_API_PROJECTS_INVESTOR_CONDITIONS_URL = (
	projectId: IProject["projectId"],
): string => {
	return `${API_PROJECT_URL}/${projectId}/investor-conditions`;
};
export const GET_API_PROJECTS_ACCEPT_URL = (
	projectId: IProject["projectId"],
): string => {
	return `${API_PROJECT_URL}/${projectId}/accept`;
};
export const GET_API_PROJECT_PARAMS_URL = (
	projectId: IProject["projectId"],
): string => {
	return `${API_PROJECT_URL}/params?project_id=${projectId}`;
};
export const API_CLUB_PROPOSALS_URL = `${API_VERSION}/club/proposals`;
export const API_QUESTIONS_SEARCH_URL = `${API_VERSION}/questions/search`;
export const API_QUESTIONS_USER_SEARCH_URL = `${API_VERSION}/questions/user/search`;
export const API_QUESTIONS_ASK_THEMES_URL = `${API_VERSION}/questions/themes`;
export const API_QUESTIONS_ASK_URL = `${API_VERSION}/questions/ask`;

export const API_DADATA_TOKEN = "e10fbac867a99dbbb466ceeb1dbd7cdbf5760db3";
export const API_DADATA_BANK_URL =
	"https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/bank";
export const API_DADATA_PARTY_URL =
	"https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party";

export const POOLING_INTERVAL_DEFAULT = 20000;

export const REQUIRED_PHONE_LENGTH = 11;
export const REQUIRED_CODE_LENGTH = 5;

export const AUTH_PASSWORD_MIN_LENGTH = 8;
export const AUTH_PASSWORD_MAX_LENGTH = 22;
export const AUTH_PASSWORD_CREATE_RULES_NOTE = `Пароль должен включать буквы (A-Z), цифры(0-9) и иметь длину от ${AUTH_PASSWORD_MIN_LENGTH} до ${AUTH_PASSWORD_MAX_LENGTH} символов. Так же пароль может включать в себя следующие символы: !@#$%^&*.-_`;
export const CHECK_PHONE_EXISTING_INVESTOR =
	"Данный номер уже зарегистрирован в профиле инвестора. Войдите как инвестор с этим номером или используйте другой для регистрации в качестве заемщика.";
export const CHECK_PHONE_EXISTING_BORROWER =
	"Данный номер уже зарегистрирован в профиле заемщика. Войдите как заемщик с этим номером или используйте другой для регистрации в качестве инвестора.";

export const BANK_BIK_REQUIRED_LENGTH = 9;
export const BANK_ACCOUNT_REQUIRED_LENGTH = 20;

export const TOAST_AUTO_HIDE_DELAY = 2500;
export const AUTH_RESEND_CODE_TIMEOUT = 60;

export const AUTH_PASSWORD_REGEXP = new RegExp(
	`^(?=.*[0-9]+.*)(?=.*[a-zA-Z]+.*)([a-zA-Z0-9!@#$%^&*._-]{${AUTH_PASSWORD_MIN_LENGTH},${AUTH_PASSWORD_MAX_LENGTH}})$`,
);
export const EMAIL_REGEXP = new RegExp(
	"^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
);
export const SNILS_REGEXP = new RegExp("^(?:[- ]*\\d){11}$");

export const NUMBER_REGEXP = new RegExp(/^\d+$/);

export const CODE_NUMBER_FORMAT = {
	format: _fillCode("#", REQUIRED_CODE_LENGTH),
	mask: "*",
};

export const BANK_BIK_NUMBER_FORMAT = {
	format: _fillCode("#", BANK_BIK_REQUIRED_LENGTH),
	mask: "*",
};

export const BANK_ACCOUNT_NUMBER_FORMAT = {
	format: _fillCode("#", BANK_ACCOUNT_REQUIRED_LENGTH),
	mask: "*",
};

export const PHONE_NUMBER_FORMAT = {
	format: "+7 (###) ### ##-##",
	mask: "*",
};

export const PASSPORT_NUMBER_FORMAT = { format: "#### ######", mask: "*" };
export const PASSPORT_DIVISION_CODE_FORMAT = { format: "###-###", mask: "*" };
export const SNILS_FORMAT = { format: "###-###-### ##", mask: "*" };

export const DATEPICKER_FORMAT = "dd.MM.yyyy";
export const APP_DATE_FORMAT = "DD.MM.YYYY";
export const API_DATE_FORMAT = "YYYY-MM-DD";

export const CODE_NUMBER_PLACEHOLDER = _fillCode("*", REQUIRED_CODE_LENGTH);

export const PHONE_MASK = "+7 (999) 999 99-99";
export const TRANSITION_TIMEOUT = 300;

export const DEFAULT_ERROR_MESSAGE = "Произошла неизвестная ошибка";
export const PASSWORD_MISMATCH_MESSAGE = "Пароли не совпадают";
export const PASSWORD_INCORRECT_MESSAGE = "Некорректный формат пароля";
export const DATE_INCORRECT_MESSAGE = "Некорректная дата";
export const SMS_CODE_INCORRECT_MESSAGE = "Введен неверный код";

export const WITHDRAW_AMOUNT_MIN = 500;
export const WITHDRAW_AMOUNT_MAX = 500000;

export const INVEST_AMOUNT_MIN = 500;
export const INVEST_AMOUNT_MAX = 500000;

export const PROJECT_PARAM_TERM_MAX = 360; //days
export const PROJECT_PARAM_GOAL_TOTAL_MAX = 12; // millions
export const PROJECT_PARAM_RATE_MAX = 100; // percents

export const SEARCH_LIMIT = 10;
export const DEFAULT_DELAY = 300;

export const INN_MIN_LENGTH = 10;
export const INN_MAX_LENGTH = 12;
export const OGRN_IP_MIN_LENGTH = 13;
export const OGRN_IP_MAX_LENGTH = 15;
export const KPP_MAX_LENGTH = 9;
export const PASSPORT_NUMBER_LENGTH = 10;
export const PASSPORT_DIVISION_CODE_LENGTH = 6;

export const CODES_RESPONSE_SUCCESS = "success";
export const CODES_RESPONSE_FAILED = "failed";

export const RGB_COLORS = {
	blue: "32,170,255",
	yellow: "242,153,74",
	green: "59,218,151",
	grayCrayola: "138,151,216",
	turquoise: "36,221,199",
	red: "235,87,87",
	pear: "242,201,76",
};

export const CUSTOM_EVENT__PROFILE_CONTENT_SCROLLED_DOWN =
	"profile-content-scrolled-down";
export const CUSTOM_EVENT__RESET_PROJECTS_PAGINATION_OFFSET =
	"investor-projects-offset-reset";
export const CUSTOM_EVENT__SET_MODAL_CODE_FOCUS =
	"profile-verification-code-modal-focused";

export const EXTERNAL_LINK_DOCUMENT_RISKS = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/risks.pdf`;
export const EXTERNAL_LINK_DOCUMENT_PLATFORM_RULES = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/rules.pdf`;
export const EXTERNAL_LINK_DOCUMENT_TAX_RESIDENT = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/tax_resident.pdf`;
export const EXTERNAL_LINK_DOCUMENT_EXECUTIVE = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/executive.pdf`;
export const EXTERNAL_LINK_DOCUMENT_INCASSO = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/incasso.pdf`;

export const EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/personal.pdf`;
export const EXTERNAL_LINK_DOCUMENT_PERSONAL_DATA_POLICY = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/politics.pdf`;
export const EXTERNAL_LINK_DOCUMENT_ELECTRONIC_SIGNATURE = `${process.env.REACT_APP_ROOT_URL}media/public/uploads/documents/verification/sign.pdf`;

export const MESSAGE_VALIDATION_REQUIRED_FIELD =
	"Это поле обязательно для заполнения";
export const MESSAGE_VALIDATION_INVALID_EMAIL = "Некорректный email";
export const MESSAGE_VALIDATION_INVALID_SNILS = "Некорректный СНИЛС";
export const MESSAGE_VALIDATION_INVALID_PASSPORT_NUMBER =
	"Неверный формат номера паспорта";
export const MESSAGE_VALIDATION_INVALID_PASSPORT_DIVISION_CODE =
	"Неверный формат кода подразделения";

export const DAYS_WORDS = ["день", "дня", "дней"];
export const MONTHS_WORDS = ["месяц", "месяца", "месяцев"];

export const ABOUT_TITLE =
	"PANFILOV — это краудлендинговая платформа для коллективных инвестиций в недвижимость.";

export const ABOUT_MESSAGES = [
	"В новых реалиях инвесторам нужны понятные и доходные инструменты инвестирования. На фоне нестабильности рынков недвижимость всегда была и будет надежным средством для сохранения и накопления капитала. Краудлендинг даёт возможность начать инвестировать с минимальной суммы и диверсифицировать риски через коллективные инвестиции в различные проекты недвижимости.",
	"Современные технологии позволяют оптимизировать и автоматизировать все процессы, тем самым обеспечить безопасное управление денежными средствами. Высокий уровень защиты обусловлен контролем Центрального Банка за деятельностью операторов инвестиционных платформ в рамках 259 ФЗ.",
];

export const BASIC_SMS_CODE_ARR = new Array(REQUIRED_CODE_LENGTH).fill("");

export const DEFAULT_DEBOUNCE = 300;

export const EXPIRE_TIME = 1800000; // ms, полчаса

export const ADAPTIVE_BREAKPOINTS = {
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1400,
	lt: 1600,
	hd: 1820,
};

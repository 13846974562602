import React, {ReactElement} from 'react';
import { Redirect, Route, RouteProps } from 'react-router';
import {RouteNamesEnum} from '../../router';

export type ProtectedRouteProps = {
	isAuthorized: boolean;
} & RouteProps;

const ProtectedRoute = ({isAuthorized,  ...routeProps}: ProtectedRouteProps): ReactElement => {
	if(isAuthorized) {
		return <Route {...routeProps} />;
	} else {
		return <Redirect to={{ pathname: RouteNamesEnum.AUTH }} />;
	}
};


export default ProtectedRoute;

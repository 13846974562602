import React, { DetailedHTMLProps, FC, HTMLAttributes } from "react";
import { Container, Row } from "react-bootstrap";
import styles from "./technicalWork.module.scss";
import classNames from "classnames";
import { ReactComponent as LogoIcon } from "@icons/auth-logo.svg";

const TechnicalWork: FC<
	DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = (props): JSX.Element => {
	return (
		<div className={classNames(styles.root, "d-flex flex-column")} {...props}>
			<div className={styles.content}>
				<LogoIcon className={styles.icon} />
				<Container fluid={true}>
					<Row className={"justify-content-center py-5"}>
						<div className={"col-sm-8 col-lg-6 col-xl-5 col-lt-4"}>
							<h1>ТЕХНИЧЕСКИЕ РАБОТЫ</h1>
							<p>
								В данный момент на платформе проводятся технические работы,
								связанные с обновлением программного обеспечения.
							</p>
							<p>
								Узнать свой баланс, вывести денежные средства или задать вопросы
								можно в чате технической поддержки платформы:{" "}
								<a
									href='https://t.me/panfilov_support'
									target='_blank'
									rel='noreferrer'>
									https://t.me/panfilov_support
								</a>
							</p>
							<p>
								Телефон горячей линии:{" "}
								<a href='tel:+78001013216' rel='noreferrer'>
									8 800 101 32 16
								</a>
							</p>
						</div>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default TechnicalWork;

import React, { FC } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import { CustomLink } from '@components/ui';
import { LinkIconsVariant, LinkVariant } from '@components/ui/Link/Link.props';

const StyledSocial = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

`;
interface CompanySocialProps {
    className?: string;
}

const CompanySocial: FC<CompanySocialProps> = ({className = ''}) => {
    return (
        <StyledSocial className={className}>
            <Row className={'gx-3'}>
                <Col xs={'auto'}>
                    <CustomLink target={'_blank'} variant={LinkVariant.icon}
                                icon={LinkIconsVariant.instagram}
                                href="http://vk.com/public206666429"
                    />
                </Col>
                <Col xs={'auto'}>
                    <CustomLink
                        href={`http://t.me/panfilov_invest`}
                        target={'_blank'} variant={LinkVariant.icon}
                                icon={LinkIconsVariant.vk} />
                </Col>
            </Row>
        </StyledSocial>
    );
};

export default CompanySocial;

import React, { FC, useContext, useEffect, useState } from "react";
import { Button, FormGroup, Paragraph } from "@components/ui";
import classNames from "classnames";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import dialogStyles from "../Dialog/dialog.module.scss";
import { phoneToString } from "@common/utils";
import { useAppSelector } from "@hooks/useAppSelector";
import { authContext } from "@context/authContext";
import authApi from "@api/authApi";

import { useAppActions } from "@hooks/useAppActions";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { useHistory } from "react-router-dom";
import RoleToggler from "@components/auth/RoleToggler/RoleToggler";
import { toast } from "react-toastify";
import ToastErrorMessage from "@components/common/Toast/ToastErrorMessage";
import {
	CHECK_PHONE_EXISTING_BORROWER,
	CHECK_PHONE_EXISTING_INVESTOR,
	REQUIRED_PHONE_LENGTH,
} from "@common/constants";

export const CheckPhone: FC = (props): JSX.Element => {
	const { phone, updatePhone } = useContext(authContext),
		{ isLoading, role } = useAppSelector((store) => store.auth),
		[checkPhone] = authApi.useCheckPhoneMutation(),
		{ setAuthLoading, setToken } = useAppActions(),
		history = useHistory();
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (typeof phone === "number") {
			setAuthLoading(true);

			checkPhone({ phone, type: role })
				.unwrap()
				.then((result) => {
					if (
						result.isPhoneRegistered &&
						result.existingUser &&
						role !== result.existingUser
					) {
						const message =
							result.existingUser === "investor"
								? CHECK_PHONE_EXISTING_INVESTOR
								: CHECK_PHONE_EXISTING_BORROWER;

						toast.error(<ToastErrorMessage message={message} />, {
							autoClose: 10000,
							position: toast.POSITION.TOP_LEFT,
						});
					} else {
						history.push(
							result.isPhoneRegistered
								? RouteNamesEnum.AUTH + AuthRouteNamesEnum.LOGIN
								: RouteNamesEnum.AUTH + AuthRouteNamesEnum.REGISTRATION_REQUEST,
						);
					}
				})
				.catch((e) => {
					if (e.status === 401) setToken(null);
				})
				.finally(() => setAuthLoading(false));
		}
	};
	useEffect(() => {
		setButtonDisabled(
			phone === null ||
				(phone && String(phone).length < REQUIRED_PHONE_LENGTH) ||
				isLoading,
		);
	}, [phone, isLoading]);

	return (
		<form {...props} onSubmit={handleSubmit}>
			<Paragraph className={classNames(dialogStyles.title)}>
				Войдите или зарегистрируйтесь
			</Paragraph>

			<RoleToggler className='mb-5' />

			<FormGroup
				id={"authCheckPhone"}
				fieldType={FormGroupTypes.PHONE}
				label={"Номер телефона"}
				onChange={(
					event: React.ChangeEvent<HTMLInputElement>, // eslint-disable-next-line
				): void => // @ts-ignore
					updatePhone(event)
				}
				value={phoneToString(phone)}
				placeholder={"8 (999) 999 99-99"}></FormGroup>

			<div className='text-center pt-5 mt-2'>
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={"submit"}
					as={ButtonTypesEnum.BUTTON}>
					Далее
				</Button>
			</div>
		</form>
	);
};

export default CheckPhone;

import {
	INVESTOR_FIELD_NAME_BIRTH_DATE,
	INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED,
	INVESTOR_FIELD_NAME_EMAIL,
	INVESTOR_FIELD_NAME_FIRST_NAME,
	INVESTOR_FIELD_NAME_HEAD_FIRST_NAME,
	INVESTOR_FIELD_NAME_HEAD_LAST_NAME,
	INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME,
	INVESTOR_FIELD_NAME_HEAD_POST,
	INVESTOR_FIELD_NAME_INN,
	INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED, INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG,
	INVESTOR_FIELD_NAME_LAST_NAME,
	INVESTOR_FIELD_NAME_LAW_TYPE,
	INVESTOR_FIELD_NAME_MIDDLE_NAME,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION,
	INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION,
	INVESTOR_FIELD_NAME_ORG_FULL_NAME,
	INVESTOR_FIELD_NAME_ORG_INN,
	INVESTOR_FIELD_NAME_ORG_KPP,
	INVESTOR_FIELD_NAME_ORG_OGRN,
	INVESTOR_FIELD_NAME_ORG_SHORT_NAME,
	INVESTOR_FIELD_NAME_ORGANIZATION_FORM,
	INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE,
	INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE,
	INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY,
	INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN,
	INVESTOR_FIELD_NAME_PASSPORT_NUMBER,
	INVESTOR_FIELD_NAME_PHONE,
	INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED,
	INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS,
	INVESTOR_FIELD_NAME_RESIDENCE_CITY,
	INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED
} from '@components/investor/Views/Data/constants';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {RegisterOptions} from 'react-hook-form/dist/types/validator';

export enum PersonalDataViewsEnum {
	DEFAULT = 'default',
	ENTITY_DEFAULT = 'entityDefault',
	ENTITY_EDIT = 'entityEdit',
	INDIVIDUAL_ENTREPRENEUR_DEFAULT = 'individualEntrepreneurDefault',
	INDIVIDUAL_ENTREPRENEUR_EDIT = 'individualEntrepreneurEdit'
}

export interface IProfileDataFields {
	[INVESTOR_FIELD_NAME_PHONE]: string,
	[INVESTOR_FIELD_NAME_EMAIL]: string,
	[INVESTOR_FIELD_NAME_FIRST_NAME]: string,
	[INVESTOR_FIELD_NAME_MIDDLE_NAME]: string,
	[INVESTOR_FIELD_NAME_LAST_NAME]: string,
	[INVESTOR_FIELD_NAME_BIRTH_DATE]: string,
	[INVESTOR_FIELD_NAME_RESIDENCE_CITY]: string,
	[INVESTOR_FIELD_NAME_INN]: string,
	[INVESTOR_FIELD_NAME_PASSPORT_NUMBER]: string,
	[INVESTOR_FIELD_NAME_PASSPORT_ISSUED_BY]: string,
	[INVESTOR_FIELD_NAME_PASSPORT_ISSUED_WHEN]: string,
	[INVESTOR_FIELD_NAME_PASSPORT_DIVISION_CODE]: string,
	[INVESTOR_FIELD_NAME_PASSPORT_BIRTH_PLACE]: string,
	[INVESTOR_FIELD_NAME_REGISTRATION_ADDRESS]: string,
	[INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED]: boolean,
	[INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED]: boolean,
	[INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED]: boolean,
	[INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED]: boolean,
	[INVESTOR_FIELD_NAME_LAW_TYPE]: string,
	/*Default end*/

	[INVESTOR_FIELD_NAME_ORG_INN]: string
	[INVESTOR_FIELD_NAME_ORGANIZATION_FORM]: string
	[INVESTOR_FIELD_NAME_ORG_SHORT_NAME]: string
	[INVESTOR_FIELD_NAME_ORG_FULL_NAME]: string
	[INVESTOR_FIELD_NAME_ORG_OGRN]: string
	[INVESTOR_FIELD_NAME_ORG_KPP]: string
	[INVESTOR_FIELD_NAME_ORG_DATE_REGISTRATION]: string
	[INVESTOR_FIELD_NAME_HEAD_LAST_NAME]: string
	[INVESTOR_FIELD_NAME_HEAD_FIRST_NAME]: string
	[INVESTOR_FIELD_NAME_HEAD_MIDDLE_NAME]: string
	[INVESTOR_FIELD_NAME_HEAD_POST]: string
	[INVESTOR_FIELD_NAME_ORG_ADDRESS_REGISTRATION]: string
	[INVESTOR_FIELD_NAME_ORG_ADDRESS_LOCATION]: string
	[INVESTOR_FIELD_NAME_IS_AGREEMENT_HEAD_ORG]: boolean
	/*Entity end*/
}

export interface IPersonalDataField {
	id: string
	label: string
	type: FormGroupTypes
	registerOptions?: RegisterOptions
}

import React, {FC, useContext, useEffect, useState} from 'react';
import {Accordion, Modal, Spinner} from 'react-bootstrap';
import {FormGroupTypes} from '@components/ui/FormGroup/FormGroup.props';
import {FormGroup} from '@components/ui';
import {ReactComponent as LogoIcon} from '@icons/auth-logo.svg';
import {questionsContext} from '@context/questionsContext';
import {profileContext} from '@context/profileContext';
import useDebounce from '@hooks/useDebounce';
import questionsApi from '@api/questionsApi';
import {ProjectQuestionsTabsKeysEnum} from '@components/investor/Views/Questions/Questions';
import {searchFiltersByFields, searchWithParams} from '@common/utils';
import {commonQuestionsSearchFields} from '@components/investor/Views/Questions/tabs/QuestionsCommon/QuestionsCommon';
import {IQuestion, IQuestionTheme, IUserQuestion} from '@common/types';
import {userQuestionsSearchFields} from '@components/investor/Views/Questions/tabs/QuestionsMy/QuestionsMy';
import styles from './questionsSearch.module.scss'
import {ReactComponent as ArrowRightIcon} from '@icons/arrow-right.svg';


const ProfileQuestionsSearchModal:FC = () => {

	const
		{
			searchQuestionModal, setSearchQuestionModal
		} = useContext(questionsContext),
		[
			getCommonQuestions,
			{isLoading: commonQuestionsIsLoading}
		] = questionsApi.useSearchMutation(),
		[
			getUserQuestions,
			{isLoading: userQuestionsIsLoading}
		] = questionsApi.useUserSearchMutation(),
		{activeTab} = useContext(profileContext),
		[commonResults, setCommonResults] = useState<IQuestionTheme[]>([]),
		[userResults, setUserResults] = useState<IUserQuestion[]>([]),
		[searchQuery, setSearchQuery] = useState<string>(''),
		debouncedSearchQuery = useDebounce(searchQuery)
	;

	useEffect(() => {

		if (debouncedSearchQuery.length > 0) {
			switch (activeTab?.key) {
				case ProjectQuestionsTabsKeysEnum.COMMON:
					getCommonQuestions(searchWithParams({
						limit: -1,
						filters: searchFiltersByFields(commonQuestionsSearchFields, debouncedSearchQuery)
					}))
						.unwrap()
						.then((response : IQuestionTheme[]) => {
							setCommonResults(response);
						})
						.catch(() => {
							setCommonResults([])
						});

					break;
				case ProjectQuestionsTabsKeysEnum.MY:
					getUserQuestions({
						filter: searchWithParams({
							limit: -1,
							filters: searchFiltersByFields(userQuestionsSearchFields, debouncedSearchQuery)
						}),
						isAnswered: true
					})
						.unwrap()
						.then((response: IUserQuestion[]) => {
							setUserResults(response)
						})
						.catch(() => {
							setUserResults([])
						});
					break;
			}
		} else {
			setCommonResults([]);
			setUserResults([]);
		}

	}, [debouncedSearchQuery]);

	const resultsContent = ():JSX.Element => {

		let items:IQuestion[] = [];

		switch (activeTab?.key) {
			case ProjectQuestionsTabsKeysEnum.COMMON:
				items = commonResults.reduce((acc: IQuestion[], item) => {
					acc = [...acc, ...item.questions];
					return acc
				}, []);
				break;

			case ProjectQuestionsTabsKeysEnum.MY:
				items = userResults;
				break;
		}

		if (debouncedSearchQuery === '') return <></>;

		return items.length > 0
			? <Accordion as={'ul'} className={styles.modalSearchResults}>
				{
					items.map(({answer, questionId, question}) => {
						return (
							<Accordion.Item className={styles.modalSearchItem} key={questionId} eventKey={questionId} as={'li'}>
								<Accordion.Header className={styles.modalSearchHeader} as={'div'}>
									<span className={styles.modalSearchQuestion}>{question}</span>
									<ArrowRightIcon className={styles.modalSearchIcon}/>
								</Accordion.Header>
								<Accordion.Body className={'text-m text-dark pt-2'}>{answer}</Accordion.Body>
							</Accordion.Item>
						)
					})
				}
			</Accordion>
			: <p className={'mb-0 text-sm'}>Результаты не найдены</p>

	};

	return (
		<Modal
			show={searchQuestionModal}
			onHide={() => {
				setSearchQuestionModal(false);
				setSearchQuery('');
				setCommonResults([]);
				setUserResults([]);
			}}
			centered
		>
			<Modal.Header closeButton>
				<LogoIcon className={'modal-header-logo'}/>
			</Modal.Header>
			<Modal.Body>
				<p className='text-md text-dark text-weight-medium'>Поиск</p>
				{
					(commonQuestionsIsLoading || userQuestionsIsLoading) && <Spinner
						size={'sm'}
						className={styles.modalSearchLoader}
						animation='border'
						variant='primary'
					/>
				}
				<form>
					<FormGroup
						fieldType={FormGroupTypes.SEARCH}
						id={'profileQuestionSearch'}
						placeholder={'Поиск...'}
						value={searchQuery}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setSearchQuery(event.target.value)
						}}
					/>
				</form>
				{resultsContent()}
			</Modal.Body>
		</Modal>
	)
};

export default ProfileQuestionsSearchModal;

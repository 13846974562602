import React, {createContext, Dispatch, FC, SetStateAction, useEffect, useState} from 'react';
import {BorrowerDocumentsKeysEnum, IBorrowerDocument} from '@features/user/types';
import {useAppSelector} from '@hooks/useAppSelector';

export type DocumentItemType = {
	[key in keyof BorrowerDocumentsKeysEnum]: IBorrowerDocument | null
}

export interface IDocumentsIsLoading {
	[BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE]: boolean
	[BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE]: boolean
	[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: boolean
}

interface IBorrowerContext {
	profileIsLoading: boolean
	setProfileIsLoading: Dispatch<SetStateAction<boolean>>

	profileChangedSuccessModal: boolean
	setProfileChangedSuccessModal: Dispatch<SetStateAction<boolean>>

	documents: DocumentItemType
	setDocuments: Dispatch<SetStateAction<DocumentItemType>>,
	updateDocuments: (key: BorrowerDocumentsKeysEnum, document: IBorrowerDocument | null) => void

	documentsIsLoading: IDocumentsIsLoading
	updateDocumentsIsLoading: (key: BorrowerDocumentsKeysEnum, value: boolean) => void
}

export const borrowerContext = createContext<IBorrowerContext>({} as IBorrowerContext);

export const BorrowerProvider:FC = ({children}) => {

	const
		{borrowerProfile} = useAppSelector(state => state.user),
		[profileIsLoading, setProfileIsLoading] = useState<boolean>(false),
		[profileChangedSuccessModal, setProfileChangedSuccessModal] = useState<boolean>(false),
		[documents, setDocuments] = useState<DocumentItemType>({} as DocumentItemType),
		[documentsIsLoading, setDocumentsIsLoading] = useState<IDocumentsIsLoading>({
			[BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE]: false,
			[BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE]: false,
			[BorrowerDocumentsKeysEnum.BALANCE_YEAR]: false
		})
	;

	const
		updateDocuments = (key: BorrowerDocumentsKeysEnum, document: IBorrowerDocument | null) => {
			setDocuments(documents => ({
				...documents,
				[key]: document
			}));
		},
		updateDocumentsIsLoading = (key: BorrowerDocumentsKeysEnum, value: boolean) => {
			setDocumentsIsLoading(items => ({
				...items,
				[key]: value
			}))
		}
	;

	useEffect(() => {
		Object.keys(borrowerProfile)
			.map(key => {
				switch (key) {
					case BorrowerDocumentsKeysEnum.PASSPORT_SECOND_THIRD_PAGE:
					case BorrowerDocumentsKeysEnum.PASSPORT_REGISTRATION_PAGE:
					case BorrowerDocumentsKeysEnum.BALANCE_YEAR:
					case BorrowerDocumentsKeysEnum.BALANCE_LAST_DATE:
					case BorrowerDocumentsKeysEnum.INCOME_DECLARATION:
						updateDocuments(key, borrowerProfile[key]);
						break;
				}
			})
	}, [borrowerProfile]);

	return (
		<borrowerContext.Provider value={{
			profileIsLoading, setProfileIsLoading,
			profileChangedSuccessModal, setProfileChangedSuccessModal,
			documents, setDocuments, updateDocuments,
			documentsIsLoading, updateDocumentsIsLoading
		}}>
			{children}
		</borrowerContext.Provider>
	)
};

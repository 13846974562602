import React, { FC, useContext, useEffect } from 'react';
import {
    ProfileDataContacts, ProfileDataPassport,
    ProfileDataPersonal,
    ProfileDataRules,
    ProfileDataChooseLawType
} from '@components/investor/Views/Data/Sections';
import ProfileDataControls from '@components/investor/Views/Data/Ui/ProfileDataControls';
import {
    INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED,
    INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED, INVESTOR_FIELD_NAME_LAW_TYPE,
    INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED, INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED
} from '@components/investor/Views/Data/constants';
import { useFormContext } from 'react-hook-form';
import { InvestorLawTypeEnum, VerificationStatusEnum } from '@features/user/types';
import { useAppSelector } from '@hooks/useAppSelector';
import { personalDataContext } from '@context/personalDataContext';
import { getDefaultParams } from '@components/investor/Views/Data/utils';
import ProfileDataEditButton from '@components/investor/Views/Data/Ui/EditButton/EditButton';


const ProfileDataPageDefault: FC = () => {

    const { watch, getValues, reset } = useFormContext();
    const { investorProfile } = useAppSelector(state => state.user);
    const { verificationStatus } = investorProfile;
    const { setEditMode, editMode } = useContext(personalDataContext);

    useEffect(() => {
        setEditMode(verificationStatus === VerificationStatusEnum.NOT_VERIFIED);

    }, [verificationStatus]);

    return (
        <>
            {
                (verificationStatus === VerificationStatusEnum.VERIFIED && !editMode) && (
                    <div className="d-flex justify-content-end mb-5 mb-sm-0">
                        <div>
                            <ProfileDataEditButton
                                isVisible
                            />
                        </div>
                    </div>
                )
            }
            <ProfileDataContacts />
            <ProfileDataPersonal />
            <ProfileDataPassport />
            {
                (verificationStatus === VerificationStatusEnum.NOT_VERIFIED) && <>
                    <ProfileDataChooseLawType />
                    <ProfileDataRules />
                </>
            }
            {
                (
                    verificationStatus === VerificationStatusEnum.NOT_VERIFIED
                    || verificationStatus === VerificationStatusEnum.VERIFIED
                ) && <>
                    <ProfileDataControls
                        submitIsDisabled={
                            ![
                                watch(INVESTOR_FIELD_NAME_INVEST_RISK_ACCEPTED),
                                watch(INVESTOR_FIELD_NAME_PLATFORM_RULES_ACCEPTED),
                                watch(INVESTOR_FIELD_NAME_RF_RESIDENT_ACCEPTED),
                                watch(INVESTOR_FIELD_NAME_COLLECTION_ASSIGNMENT_ACCEPTED),
                            ].every(Boolean)
                        }
                        submitText={
                            getValues(INVESTOR_FIELD_NAME_LAW_TYPE) === InvestorLawTypeEnum.INDIVIDUAL
                                ? 'Сохранить'
                                : 'Далее'
                        }
                        onCancel={() => {
                            reset(getDefaultParams(investorProfile));
                            setEditMode(false);
                        }}
                    />
                </>
            }
        </>
    );
};

export default ProfileDataPageDefault;

import React, { FC, HTMLAttributes, useContext } from "react";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { Button } from "@components/ui";
import useInvestorStatusCheck from "@hooks/useInvestorStatusCheck";
import { useHistory } from "react-router-dom";
import { investContext } from "@context/investContext";
import { IProject } from "@features/projects/types";
import { useAppSelector } from "@hooks/useAppSelector";
import { InvestorRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { BorrowerProjectStatusEnum } from "@features/borrower/types";
import { ConditionalWrapper } from "@components/common/ConditionalWrapper";
import { useProjectExpired } from "@hooks/useProjectExpired";

const InvestorInvestmentButton: FC<
	{
		projectId: IProject["projectId"];
		status: BorrowerProjectStatusEnum;
		investAmount: IProject["investAmount"];
		onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
		wrapperClassName?: string;
	} & HTMLAttributes<HTMLButtonElement>
> = ({
	projectId,
	status,
	investAmount,
	onClick,
	wrapperClassName,
	...props
}) => {
	const { checkToPersonalDataRedirect } = useInvestorStatusCheck(),
		history = useHistory(),
		{ setRequestModal, setCurrentProjectId, isInvestDisabled } =
			useContext(investContext),
		{ balance } = useAppSelector((state) => state.user.investorInfo),
		expired = useProjectExpired(status);
	return !isInvestDisabled(status, investAmount) && !expired ? (
		<ConditionalWrapper
			condition={Boolean(wrapperClassName)}
			wrapper={(children) => {
				return <div className={wrapperClassName}>{children}</div>;
			}}>
			<Button
				as={ButtonTypesEnum.BUTTON}
				variant={ButtonVariant.contained}
				{...props}
				onClick={(event) => {
					checkToPersonalDataRedirect(() => {
						if (balance !== null && parseFloat(balance) > 0) {
							setRequestModal(true);
							setCurrentProjectId(projectId);
						} else {
							history.push(
								RouteNamesEnum.INVESTOR + InvestorRouteNamesEnum.REQUISITES,
							);
						}
					});

					onClick && onClick(event);
				}}>
				Инвестировать
			</Button>
		</ConditionalWrapper>
	) : (
		<></>
	);
};

export default InvestorInvestmentButton;

import React, { FC, useContext, useEffect, useState } from "react";
import {
	REQUIRED_CODE_LENGTH,
	CODE_NUMBER_FORMAT,
	DEFAULT_ERROR_MESSAGE,
} from "@common/constants";
import { FormGroupTypes } from "@components/ui/FormGroup/FormGroup.props";
import { Button, FormGroup } from "@components/ui";
import ResendCode from "../../common/ResendCode/ResendCode";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { NumberFormatValues } from "react-number-format";
import { authContext } from "@context/authContext";
import { useAppActions } from "@hooks/useAppActions";
import { useAppSelector } from "@hooks/useAppSelector";
import RecoveryNote from "@components/common/RecoveryNote/RecoveryNote";
import authApi from "@api/authApi";
import { AuthRouteNamesEnum, RouteNamesEnum } from "@app/router";
import { useHistory } from "react-router-dom";
import { phoneToString } from "@common/utils";

const RecoverPasswordConfirm: FC = (props) => {
	const {
			code,
			updateCode,
			phone,
			recoverPasswordHandler,
			clearCode,
			updateError,
		} = useContext(authContext),
		{ setAuthLoading } = useAppActions(),
		{ isLoading } = useAppSelector((store) => store.auth),
		[recoverPasswordCheck] = authApi.useRecoverPasswordCheckMutation(),
		history = useHistory();
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
			event.preventDefault();

			if (Boolean(code) && typeof phone === "number") {
				setAuthLoading(true);

				recoverPasswordCheck({ smsCode: code, phone })
					.unwrap()
					.then(() => {
						history.push(
							RouteNamesEnum.AUTH + AuthRouteNamesEnum.CHANGE_PASSWORD,
						);
					})
					.catch((e) => updateError(e.data.message))
					.finally(() => setAuthLoading(false));

				return;
			}

			updateError(DEFAULT_ERROR_MESSAGE);
		},
		handleCodeChange = (formatValues: NumberFormatValues) =>
			updateCode(formatValues);
	useEffect(() => {
		setButtonDisabled(isLoading || code.length < REQUIRED_CODE_LENGTH);
	}, [code, isLoading]);

	return (
		<form {...props} onSubmit={handleSubmit}>
			<RecoveryNote
				phone={phoneToString(phone)}
				message={"Для смены пароля СМС с проверочным кодом отправлено на номер"}
			/>
			<FormGroup
				id={"authRegistrationSmsCode"}
				numberFormat={CODE_NUMBER_FORMAT}
				fieldType={FormGroupTypes.NUMBER_FORMAT}
				label={"Код из СМС"}
				onValueChange={handleCodeChange}
				value={code}
			/>

			<ResendCode
				onResendCode={() => {
					clearCode();
					recoverPasswordHandler();
				}}>
				<a
					href={"#"}
					className={"text-decoration-underline"}
					onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
						event.preventDefault();
						clearCode();
						history.push(RouteNamesEnum.AUTH + AuthRouteNamesEnum.LOGIN);
					}}>
					<small>Я вспомнил пароль</small>
				</a>
			</ResendCode>
			<div className='text-center pt-5 mt-2'>
				<Button
					disabled={buttonDisabled}
					variant={ButtonVariant.contained}
					type={"submit"}
					as={ButtonTypesEnum.BUTTON}>
					Далее
				</Button>
			</div>
		</form>
	);
};

export default RecoverPasswordConfirm;

export enum BorrowerBidRequestKeysEnum {
	PURPOSE = "purpose",
	GOAL_AMOUNT = "goalAmount",
	TERM = "term",
	PRIVACY_POLICY = "privacyPolicy",
	PUBLIC_OFFER = "publicOffer",
}

export interface IBorrowerSimple {
	id: number;
	orgName: string;
}

export interface IBorrowerBidRequest {
	[BorrowerBidRequestKeysEnum.PURPOSE]: string;
	[BorrowerBidRequestKeysEnum.GOAL_AMOUNT]: string;
	[BorrowerBidRequestKeysEnum.TERM]: number;
	[BorrowerBidRequestKeysEnum.PRIVACY_POLICY]: boolean;
	[BorrowerBidRequestKeysEnum.PUBLIC_OFFER]: boolean;
}

export interface IBorrowerBidsParams {
	goalAmountMin: string;
	goalAmountMax: string;
	termMin: number;
	termMax: number;
}

export interface IBorrowerState {
	bidsParams: IBorrowerBidsParams;
	loans: IBorrowerLoans;
	bids: IBorrowerBid[];
}

export interface IBorrower {
	phone: string;
	email: string;
	inn: string;
	ogrn: string;
	borrowerId: string;
	shortName: string;
	fullName: string;
	orgName: string;
	businessAddress: string;
	orgLegalAddress: string;
	openProjectCount: number;
	runProjectCount: number;
	succeededProjectCount: number;
	failedProjectCount: number;
}

export interface IBorrowerIndicators {
	principal_debt: string;
	earlyReturn: string;
	overdueDebt: number;
	preApprovedLoan: number;
	currentDebt: string;
}

export enum BorrowerProjectStatusEnum {
	COLLECTION = "collection",
	LOAN_COLLECTED_MIN = "loan_collected_min",
	COLLECTED = "collected",
	COLLECTION_FINISH = "finish_collection",
	REQUEST_CANCELLED = "request_cancelled",
	ISSUED = "issued",
	PAID = "paid",
	EXPIRED = "expired",
}

export interface IProjectBorrowerSimple {
	id: number;
	orgName: string;
}

export interface IBorrowerProject {
	status: BorrowerProjectStatusEnum;
	rate: number;
	term: number;
	projectId: number;
	bidId: number;
	dateCreated: string;
	goalAmount: number;
	collectedAmount: number;
	nextDate: string;
	nextPayment: number;
}

interface IBorrowerProjectDetailsGraphic {
	date: string;
	percents: string;
	commonSum: string;
	withPercents: string;

	amountWithoutNdfl: string;
	amtPercentWithoutNdfl: string;
	percentAmountTax: string;
}

interface IBorrowerProjectDetailsGraphicTotal {
	percents: string;
	commonSum: string;
	withPercents: string;
	amountNdfl: string;
}

export interface IBorrowerProjectDetails
	extends Pick<
		IBorrowerProject,
		| "status"
		| "rate"
		| "term"
		| "projectId"
		| "bidId"
		| "goalAmount"
		| "collectedAmount"
		| "dateCreated"
	> {
	graphic: IBorrowerProjectDetailsGraphic[];
	graphicTotal: IBorrowerProjectDetailsGraphicTotal;
}

export enum BorrowerBidStatusEnum {
	CONSIDERATION = "consideration",
	APPROVED = "approved",
	REJECTED = "rejected",
}

export interface IBorrowerBid {
	[BorrowerBidRequestKeysEnum.PURPOSE]: string;
	status: BorrowerBidStatusEnum;
	signed: boolean;
	bidId: number;
	[BorrowerBidRequestKeysEnum.GOAL_AMOUNT]: number;
	[BorrowerBidRequestKeysEnum.TERM]: number;
	[BorrowerBidRequestKeysEnum.PRIVACY_POLICY]: boolean;
	[BorrowerBidRequestKeysEnum.PUBLIC_OFFER]: boolean;
	dateCreated: string;
	ableSign: boolean;
}

export interface IBorrowerBidDetails
	extends Pick<
		IBorrowerBid,
		| "status"
		| "bidId"
		| BorrowerBidRequestKeysEnum.GOAL_AMOUNT
		| BorrowerBidRequestKeysEnum.TERM
		| BorrowerBidRequestKeysEnum.PRIVACY_POLICY
		| BorrowerBidRequestKeysEnum.PUBLIC_OFFER
		| "dateCreated"
	> {
	rate: number;
}

export interface IBorrowerLoans {
	indicators: IBorrowerIndicators;
	projects: IBorrowerProject[];
}

import React, { FC, useContext } from "react";
import { IProjectDetailInfoBlock } from "@components/investor/ProjectDetail/ProjectDetails.props";
import { HeadingVariant } from "@components/ui/Heading/Heading.props";
import { Button, Heading } from "@components/ui";
import { ReactComponent as LocationIcon } from "@icons/location-small-colored.svg";
import { ReactComponent as HomeIcon } from "@icons/home-small-colored.svg";
import { ReactComponent as PaperIcon } from "@icons/paper-small-colored.svg";
import ProjectDetailBox from "@components/investor/ProjectDetail/ProjectDetailBox";
import Table from "@app/components/common/Table/Table";
import { v4 as uuidv4 } from "uuid";
import styles from "../projectDetail.module.scss";
import {
	ButtonTypesEnum,
	ButtonVariant,
} from "@components/ui/Button/Button.props";
import { generateBorrowerStatisticsData } from "@components/investor/utils";
import { IProjectDetails } from "@features/projects/types";
import { investContext, InvestmentPlaceEnum } from "@context/investContext";
import InvestorInvestmentButton from "@components/investor/reused/InvestmentButton";
import { useProjectExpired } from "@hooks/useProjectExpired";
import { useNumAmount } from "@hooks/useNumAmount";
import classNames from "classnames";
import { ADAPTIVE_BREAKPOINTS } from "@common/constants";
import useWindowSize from "@hooks/useWindowSize";

const ProjectDetailBorrower: FC<{ details: IProjectDetails }> = ({
	details,
}) => {
	const { borrower, investAmount, status, projectId, title } = details,
		{ setInvestmentPlace } = useContext(investContext),
		expired = useProjectExpired(status),
		{ width: ww } = useWindowSize();
	const infoBlocks: IProjectDetailInfoBlock[] = [
			{
				title: "Фактический адрес",
				Icon: LocationIcon,
				data: borrower.businessAddress,
			},
			{
				title: "Юридический адрес",
				Icon: HomeIcon,
				data: borrower.orgLegalAddress,
			},
			{
				title: "ИНН",
				Icon: PaperIcon,
				data: borrower.inn,
			},
			{
				title: "ОГРН/ОГРНИП",
				Icon: PaperIcon,
				data: borrower.ogrn,
			},
		],
		numInvestAmount = useNumAmount(investAmount);
	return (
		<div className={styles.borrower}>
			<div className='row'>
				<div className='col-md-6'>
					<Heading
						tag={"h3"}
						variant={HeadingVariant.large}
						className={classNames(
							{ "text-uppercase": ww >= ADAPTIVE_BREAKPOINTS.xxl },
							"pb-2",
						)}>
						{title}
					</Heading>
				</div>
			</div>
			{borrower.shortName && (
				<Heading
					tag={"h3"}
					variant={HeadingVariant.large}
					className={"text-uppercase mb-3"}>
					{borrower.shortName}
				</Heading>
			)}
			<p className='text-dark text-uppercase text-md'>{borrower.orgName}</p>

			<div className='row g-4 pt-4 pt-xl-5'>
				<div className='col-md-6'>
					<div className='row g-4'>
						{infoBlocks.map(
							(block) =>
								block.data && (
									<div key={block.title} className='col-sm-6'>
										<ProjectDetailBox
											className={"box"}
											isPhone={true}
											{...block}
										/>
									</div>
								),
						)}
					</div>

					<InvestorInvestmentButton
						status={status}
						onClick={() => {
							setInvestmentPlace(InvestmentPlaceEnum.DETAILED);
						}}
						wrapperClassName={"mt-5 pt-3 d-none d-md-block"}
						projectId={projectId}
						investAmount={investAmount}
					/>
				</div>
				<div className='col-md-6'>
					{borrower ? (
						<Table
							className={"py-3 px-3 px-xl-5"}
							isLoading={false}
							tdCells={generateBorrowerStatisticsData(borrower).map(
								({ icon, key, value }) => {
									return [
										<div
											key={uuidv4()}
											className={
												"d-inline-flex align-items-center text-sm w-100"
											}>
											{icon}
											<span className={"ps-3"}>{key}</span>
										</div>,
										<p key={uuidv4()} className={"mb-0 text-md"}>
											<strong>{value}</strong>
										</p>,
									];
								},
							)}
						/>
					) : (
						<Heading variant={HeadingVariant.middle} tag={"h3"}>
							Информация не найдена
						</Heading>
					)}
				</div>
			</div>
			<div className='mt-5 pt-3 text-end d-md-none'>
				{Math.floor(numInvestAmount) === 0 && !expired && (
					<Button variant={ButtonVariant.contained} as={ButtonTypesEnum.BUTTON}>
						Инвестировать
					</Button>
				)}
			</div>
		</div>
	);
};

export default ProjectDetailBorrower;

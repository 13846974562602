import React, {FC} from 'react';
import ProfileSection from '@components/profile/Section/ProfileSection';
import Table from '@components/common/Table/Table';
import {useAppSelector} from '@hooks/useAppSelector';
import moment from 'moment';
import {APP_DATE_FORMAT, MONTHS_WORDS} from '@common/constants';
import AmountOutput from '@components/common/AmountOutput';
import {declensionOfNumber} from '@common/utils';
import {defineStatusContent} from '@components/borrower/Views/Cabinet/Cabinet';
import {Link} from 'react-router-dom';
import {BorrowerRouteNamesEnum, RouteNamesEnum} from '@app/router';
import classNames from 'classnames';
import styles from '@components/borrower/Views/Cabinet/cabinet.module.scss';

const BorrowerCabinetBids:FC = () => {

	const {bids = []} = useAppSelector(state => state.borrower);

	return (
		bids.length > 0
			? <ProfileSection title={'Заявки'} isTableInside>
				<Table
					horizontallyScrolled
					className={classNames(styles.table, styles.tableBids)}
					thCells={[
						'№',
						'Дата заявки',
						'Цель',
						'Желаемая сумма',
						'Желаемый срок',
						'Статус',
					]}
					tdCells={bids.map(({bidId, dateCreated, purpose, goalAmount, term, status, signed, ableSign}) => {

						const link = ():JSX.Element | string => {
							if (ableSign && !signed) {
								return <Link
									key={bidId}
									to={RouteNamesEnum.BORROWER+BorrowerRouteNamesEnum.BIDS+`/${bidId}`}
									className={'text-primary text-decoration-underline'}
								>{bidId.toString()}</Link>
							} else {
								return bidId.toString()
							}
						};

						return [
							link(),
							moment(dateCreated).format(APP_DATE_FORMAT),
							purpose,
							<AmountOutput className='text-jade' key={bidId} amount={goalAmount.toString()}/>,
							`${term} ${declensionOfNumber(term, MONTHS_WORDS)}`,
							defineStatusContent(status)
						]

					})}
				/>
			</ProfileSection>
			: <></>
	);
};

export default BorrowerCabinetBids;

import {createApi} from '@reduxjs/toolkit/dist/query/react';
import {baseQuery} from '@api/utils';
import {IDefaultResponse, IFilter, IListResponse, IQuestionAsk, IQuestionTheme, IUserQuestion} from '@common/types';
import {
	API_QUESTIONS_ASK_THEMES_URL,
	API_QUESTIONS_ASK_URL,
	API_QUESTIONS_SEARCH_URL,
	API_QUESTIONS_USER_SEARCH_URL
} from '@common/constants';

export interface IQuestionsUserSearchParams {filter: IFilter, isAnswered?: boolean}

const questionsApi = createApi({
	reducerPath: 'questionsApi',
	baseQuery,
	endpoints: build => ({
		search: build.mutation<IQuestionTheme[], IFilter>({
			query: body => ({
				url: API_QUESTIONS_SEARCH_URL,
				method: 'post',
				body
			}),
			transformResponse: (response: IListResponse) => response.items
		}),
		getThemes: build.mutation<IQuestionTheme[], void>({
			query: () => ({
				url: API_QUESTIONS_SEARCH_URL,
				method: 'post'
			}),
			transformResponse: (response: IListResponse) => response.items
		}),
		userSearch: build.mutation<IUserQuestion[], IQuestionsUserSearchParams>({
			query: ({filter, isAnswered}) => {
				const params: {[key: string]: boolean} = {};

				if (typeof isAnswered === 'boolean')
					params.withAnswer = isAnswered;

				return {
					url: API_QUESTIONS_USER_SEARCH_URL,
					method: 'post',
					body: filter,
					params
				}
			},
			transformResponse: (response: IListResponse) => response.items
		}),
		getAskThemes: build.query<IQuestionTheme[], void>({
			query: () => API_QUESTIONS_ASK_THEMES_URL
		}),
		ask: build.mutation<IDefaultResponse, IQuestionAsk>({
			query: body => ({
				url: API_QUESTIONS_ASK_URL,
				method: 'post',
				body
			})
		}),

	})
});

export default questionsApi;

import React, {FC, useContext, useEffect} from 'react';
import styles from './avatar.module.scss';
import {Spinner} from 'react-bootstrap';
import classNames from 'classnames';
import LazyImage from '@components/common/LazyImage/LazyImage';
import {fileListToFormData, withRootUrl} from '@common/utils';
import {ReactComponent as DefaultAvatarIcon} from '@icons/default-avatar.svg';
import {ReactComponent as PlusIcon} from '@icons/plus.svg';
import {SubmitHandler, useForm} from 'react-hook-form';
import utilsApi from '@api/utilsApi';
import userApi from '@api/userApi';
import {useAppActions} from '@hooks/useAppActions';
import {useUserInfoKey} from '@hooks/useUserInfoKey';
import {profileContext} from '@context/profileContext';
import borrowerApi from '@api/borrowerApi';
import {UserDataKeysEnum} from '@features/user/types';

const ProfileAvatar:FC = () => {

	const
		userInfoKey = useUserInfoKey(),
		{register, handleSubmit} = useForm(),
		[fileUtility, {isLoading: fileIsUpLoading}] = utilsApi.useFileMutation(),
		[
			patchInvestorProfile,
			{
				isLoading: patchInvestorProfileIsLoading,
			}
		] = userApi.usePatchProfileMutation(),
		[
			patchBorrowerProfile,
			{
				isLoading: patchBorrowerProfileIsLoading
			}
		] = borrowerApi.usePatchProfileMutation(),

		{setUserData} = useAppActions(),
		{
			userAvatarSrc,
			setUserAvatarSrc,
			userAvatarIsLoading,
			setUserAvatarIsLoading
		} = useContext(profileContext)
	;

	const onFormChange:SubmitHandler<{fileList: FileList}> = async data => {
		const {fileList} = data;

		if (fileList.length > 0) {
			setUserAvatarSrc(null);

			const [fileFromApi] = await fileUtility({
				formData: fileListToFormData(fileList, 'avatar')
			}).unwrap();

			if (Boolean(fileFromApi) && fileFromApi.uid) {
				const requestPayload = {avatar: fileFromApi};

				switch (userInfoKey) {
					case UserDataKeysEnum.BP:
						patchBorrowerProfile(requestPayload)
							.unwrap()
							.then(response => {
								setUserData({
									data: response,
									key: userInfoKey
								});
							});
						break;
					case UserDataKeysEnum.II:
						patchInvestorProfile(requestPayload)
							.unwrap()
							.then(response => {
								setUserData({
									data: response,
									key: userInfoKey
								});
							});
						break;
				}


			}
		}
	};

	useEffect(() => {
		setUserAvatarIsLoading(fileIsUpLoading || patchInvestorProfileIsLoading || patchBorrowerProfileIsLoading)
	}, [fileIsUpLoading, patchInvestorProfileIsLoading]);

	if (userInfoKey === null) return <p className='text-danger'>Ошибка загрузки ProfileAvatar</p>;

	return (
		<form className={styles.form} onChange={handleSubmit(onFormChange)}>

			{userAvatarIsLoading && <Spinner className={'spinner--centered'} size={'sm'} animation='grow' variant='primary'/>}

			<label
				htmlFor='profileAvatarChange'
				className={classNames({
					'visually-hidden': userAvatarIsLoading
				})}
			>
				<input
					id='profileAvatarChange'
					type='file'
					{...register('fileList')}
					className={'visually-hidden'}
					accept='image/png, image/jpg, image/jpeg, image/gif'
				/>
				<div className={styles.picture}>
					{
						userAvatarSrc
							? <LazyImage
								src={withRootUrl(userAvatarSrc)}
								alt='User avatar'
							/>
							: <DefaultAvatarIcon/>
					}
				</div>
				<span className={styles.edit}>
					<PlusIcon className={styles.editIcon}/>
				</span>
			</label>
		</form>
	);
};

export default ProfileAvatar;



